import { useSelector, useDispatch } from 'react-redux'

import { Container, Grid } from '@material-ui/core'
// import RazonSocial from './RazonSocial'
// import useStyles from './Styles'
import { useToast } from '../../../../hooks/useToast'
import GenerarReporteSemanal from './GenerarReporteSemanal'
// import VerLibroVentas from './VerLibroVentas'
// import ResumenLibroVentas from './ResumenLibroVentas'
import { useEffect } from 'react'
import { reporteClear } from '../../../../redux/actions/reporteActions'

const ReporteSeniat = () => {
 // const history = useHistory()
 // const classes = useStyles()
 const { notifyError, notifysucess } = useToast()
 const { isError, error, tipoMsg } = useSelector((state) => state.error)
 const dispatch = useDispatch()

 useEffect(() => {
  dispatch(reporteClear())
 }, [dispatch])

 return (
  <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
   <Grid container spacing={2} style={{ width: 720 }}>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <GenerarReporteSemanal />
    </Grid>
    {/* <Grid item lg={6} sm={6} xl={12} xs={12}>
          <ResumenLibroVentas />
        </Grid> */}
    {/* <Grid item lg={12} sm={12} xl={12} xs={12}>
          <VerLibroVentas />
        </Grid> */}
   </Grid>

   {isError && tipoMsg === 'sucess'
    ? notifysucess(error)
    : tipoMsg === 'error' &&
      error !== 'no hay Sucursal para esta localidad' &&
      notifyError(error)}
  </Container>
 )
}

export default ReporteSeniat
