import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { TextField, Button } from '@material-ui/core';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import NumberFormat from 'react-number-format';

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

// import Swal from 'sweetalert2';
import {
  facturaCompraAddNewDetalle,
  facturaCompraCloseDetalleModal,
  startNewDetalle,
} from '../../../redux/actions/facturaCompraActions'
import {
  startLoadTiposGravamenes,
  tipoGravamenSetActive,
} from '../../../redux/actions/tipoGravamenActions'

const initDetalleFacturaCompra = {
  concepto: '',
  cantidad: '',
  precio: '',
  importe: 0.0,
  unidadMedida: null,
  descuento: '',
  exento: false,
  alicuota: 0,
}

Modal.setAppElement('#root')

export const DetalleFacturaCompraModal = () => {
  const dispatch = useDispatch()

  const { modalDetalleFacturaCompra } = useSelector(
    (state) => state.facturaCompra
  )
  const { tiposGravamenes, activeTipoGravamen } = useSelector(
    (state) => state.tipoGravamen
  )

  const [formValues, setFormValues] = useState(initDetalleFacturaCompra)

  const {
    concepto,
    cantidad,
    precio,
    importe,
    unidadMedida,
    descuento,
    exento,
    alicuota,
  } = formValues

  useEffect(() => {
    dispatch(startLoadTiposGravamenes())
  }, [dispatch])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    })
  }

  const closeModal = () => {
    dispatch(facturaCompraCloseDetalleModal())
    setFormValues(initDetalleFacturaCompra)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    closeModal()
  }

  const [exentoChecked, setExentoChecked] = useState(false)

  const handleClickExento = (e) => {
    setExentoChecked(!exentoChecked)
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.checked,
    })
  }

  const handleTipoGravamen = (e) => {
    dispatch(tipoGravamenSetActive(e.target.value))
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalDetalleFacturaCompra}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      <h3>Agregar producto</h3>
      <hr />
      <div className={styles.modal}>
        <div className='form-group row'>
          <div className='col-md-12'>
            <label htmlFor='concepto' className='form-label'>
              Concepto
            </label>
            <input
              id='concepto'
              type='text'
              className='form-control form-control-sm'
              placeholder='Concepto'
              name='concepto'
              autoComplete='off'
              value={concepto}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-md-6'>
            <label htmlFor='cantidad' className='form-label'>
              Cantidad
            </label>
            <input
              id='cantidad'
              type='text'
              className='form-control form-control-sm'
              placeholder='Cantidad'
              name='cantidad'
              autoComplete='off'
              value={cantidad}
              onChange={handleInputChange}
            />
          </div>
          <div className='col-md-6'>
            <label htmlFor='precio' className='form-label'>
              Base Imponible
            </label>
            <input
              id='precio'
              type='text'
              className='form-control form-control-sm'
              placeholder='0.00'
              name='precio'
              autoComplete='off'
              value={precio}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-md-6'>
            <label htmlFor='unidadMedida' className='form-label mb-1'>
              Unidad de Medida
            </label>
            <select
              className='form-select form-select-sm'
              aria-label='.form-select-sm'
              label='unidadMedida'
              id='unidadMedida'
              value={unidadMedida}
              onChange={handleInputChange}
              name='unidadMedida'
              required={true}
            >
              <option>Seleccione...</option>
              <option key='0' value='KILOGRAMO'>
                Kg.
              </option>
              <option key='1' value='GRAMO'>
                Gr.
              </option>
              <option key='2' value='TONELADA'>
                Tons.
              </option>
              <option key='3' value='UNIDAD'>
                Und.
              </option>
              <option key='4' value='LITRO'>
                Lts.
              </option>
              <option key='5' value='MILILITRO'>
                Ml.
              </option>
              <option key='6' value='METRO'>
                Mts.
              </option>
            </select>
          </div>
          <div className='col-md-6'>
            <label htmlFor='descuento' className='form-label'>
              Descuento %
            </label>
            <input
              id='descuento'
              type='text'
              className='form-control form-control-sm'
              placeholder='0.00'
              name='descuento'
              autoComplete='off'
              value={descuento}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='form-check-inline row mb-1'>
          <div className='col-md-6'>
            <label className='form-check-label'>
              <input
                type='checkbox'
                className='form-check-input'
                onChange={handleClickExento}
                checked={exentoChecked}
                name='exento'
              />
              Exento
            </label>
          </div>
          <div className='col-md-6'>
            <label htmlFor='tipoGravamen' className='form-label mb-1'>
              Tipo Alicuota
            </label>
            <select
              className='form-select form-select-sm'
              aria-label='.form-select-sm example'
              id='tipoGravamen'
              label='tipoGravamen'
              value={
                activeTipoGravamen !== null ? activeTipoGravamen.id : alicuota
              }
              onChange={handleTipoGravamen}
              name='tipoGravamen'
              required
            >
              <option defaultValue={'Seleccione...'}>Seleccione ...</option>
              {tiposGravamenes.map((g) => (
                <option key={g.id} value={g.id}>
                  {g.concepto}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <button
          className='btn btn-outline-primary btn-block btn-lg'
          onClick={handleSubmit}
        >
          <i className='far fa-plus-square'></i>
          <span> Agregar</span>
        </button>
      </div>
    </Modal>
  )
}
