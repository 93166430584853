import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

import Swal from 'sweetalert2'
import {
  estadoCloseModal,
  startSaveEstado,
  startUpdateEstado,
  estadoClearActive,
} from '../../../redux/actions/estadoActions'
import { startLoadPaises } from '../../../redux/actions/paisActions'

const initEstado = {
  id: '',
  nombre: '',
  pais: -1,
  logEstatusRegistro: '1',
  logFechaRegistro: new Date(),
  logUsername: 'sadmin',
  logFechaModificacion: null,
}

Modal.setAppElement('#root')

export const EstadoModal = () => {
  const dispatch = useDispatch()

  const { activeEstado, modalEstadoOpen } = useSelector((state) => state.estado)
  const { paises } = useSelector((state) => state.pais)

  const [formValues, setFormValues] = useState(initEstado)

  const { nombre, pais } = formValues

  useEffect(() => {
    if (activeEstado) {
      setFormValues(activeEstado)
    } else {
      setFormValues(initEstado)
    }
  }, [activeEstado, setFormValues])

  useEffect(() => {
    dispatch(startLoadPaises())
  }, [dispatch])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    })
  }

  const closeModal = () => {
    dispatch(estadoCloseModal())
    dispatch(estadoClearActive())
    setFormValues(initEstado)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (nombre.trim().length < 2) {
      return Swal.fire(
        'Error',
        'El nombre debe contener al menos 2 caracteres',
        'error'
      )
    }

    if (activeEstado) {
      dispatch(
        startUpdateEstado({
          id: activeEstado.id,
          nombre,
          pais: pais.id === activeEstado.pais.id ? activeEstado.pais.id : pais,
          logUsername: 'sadmin',
          logFechaModificacion: new Date(),
        })
      )
    } else {
      dispatch(
        startSaveEstado({
          ...formValues,
        })
      )
    }

    closeModal()
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    selectMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalEstadoOpen}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      {activeEstado ? <h3>Editar Estado</h3> : <h3>Nuevo Estado</h3>}
      <hr />
      <form className='container' onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className='form-group'>
            <label htmlFor='nombre' className='form-label'>
              Nombre
            </label>
            <input
              type='text'
              id='nombre'
              className='form-control input-lg'
              placeholder='Nombre'
              name='nombre'
              autoComplete='off'
              value={nombre}
              onChange={handleInputChange}
              required={true}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='pais' className='form-label'>
              País
            </label>
            <select
              className='form-select form-select-sm'
              aria-label='.form-select-sm example'
              label='pais'
              id='pais'
              value={pais}
              onChange={handleInputChange}
              name='pais'
              required={true}
            >
              <option
                defaultValue={
                  activeEstado ? activeEstado.pais.id : 'Seleccione...'
                }
              >
                {activeEstado ? activeEstado.pais.nombre : 'Seleccione...'}
              </option>
              {paises.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.nombre}
                </option>
              ))}
            </select>
          </div>
          <br />
          <button type='submit' className='btn btn-outline-primary btn-block'>
            <i className='far fa-save'></i>
            <span> Guardar</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
