import { types } from '../types/types'
import { getToken } from '../../Helpers/VericarRutas'

/*
{
    uid: 12415454545445,
    name: 'Juan Carlos'
}

*/

const initialState = {
  isAuthenticated: JSON.parse(getToken())?.isAuthenticated || false,
  UsuarioLogiado: JSON.parse(getToken())?.UsuarioLogiado || null,
}

//Reducer para Autenticación
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.login:
      return {
        uid: action.payload.uid,
        name: action.payload.displayName,
      }
    case types.authLogout:
      return {
        isAuthenticated: false,
        UsuarioLogiado: null,
      }

    case types.testLogin:
      return {
        ...state,
        isAuthenticated: true,
        UsuarioLogiado: action.payload.user,
      }

    default:
      return state
  }
}
