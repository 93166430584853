import { useState } from 'react'
import NumberFormat from 'react-number-format'
import //  filtroLetrasMayusculas,
//  soloTextyNumeros,
'../../../Helpers/formater'

import {
 Grid,
 TextField,
 FormControl,
 Checkbox,
 FormControlLabel,
 Button,
 Typography,
 Divider,
 Select,
 MenuItem,
 InputLabel,
} from '@material-ui/core'
import useStyles from './Styles'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'
// import {
//   localidadClearActive,
//   startUpdateLocalidad,
// } from '../../../redux/actions/localidadActions'

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
// import { useControl } from '../../../hooks/useControl'
import {
 startSaveControlfacturalLocalidad,
 startSaveControlOrdenesLocalidad,
} from '../../../redux/actions/controlFacturaActions'

const schema = yup.object().shape({
 localidadGeneraFac: yup.boolean(),
 cantidadFacturas: yup
  .number()
  .typeError('debe ser un numero')
  .when('localidadGeneraFac', {
   is: false,
   then: (schema) => schema.required('Es requerido'),
  }),
 // .test('cantidadFacturas', 'debe ser mayor a 0', (value) => value > 0)
 // .required('Es requerido'),

 tipoFacturaOrden: yup.string().required('Es Requerido'),

 nroControl: yup
  .number()
  .typeError('debe ser un numero')
  .required('Es requerido'),
 nroControlDesde: yup.number().required('Es requerido'),
 nroControlHasta: yup.number().required('Es requerido'),
 cantidadLocalidad: yup
  .number()
  .typeError('debe ser un numero')
  .when('localidadGeneraFac', {
   is: true,
   then: (schema) => schema.required('Es requerido'),
  }),
 // .test('cantidadLocalidad', 'debe ser mayor a 0', (value) => value > 0),

 serie: yup.string().when('localidadGeneraFac', {
  is: false,
  then: (schema) => schema.max(2, 'Maximo de Caracter es 2'),
  // .matches(/^[A-Z]$/, 'Solo se permiten letras mayusculas'),
 }),
 logEstatusRegistro: yup.string().required('Es Requerido'),
})

export default function CrearControl({ open, handleClose, title }) {
 const classes = useStyles()
 const dispatch = useDispatch()
 const { activeLocalidad } = useSelector((state) => state.localidad)
 const tipodoc = ['ACTIVO', 'ENCOLA']
 const TIPOCONTROL = ['FACTURACION', 'ORDENES/GUIA']

 const [ValoresControlFactura, setValoresControlFactura] = useState({
  id_localidad: activeLocalidad?._id,
  cantidadFacturas: 0,
  nroControl: 0,
  nroControlDesde: 0,
  nroControlHasta: 0,
  nroSubControl: 0,
  localidadGeneraFac: false,
  serie: ' ',
  cantidadLocalidad: 0,
  nroControlLocalidad: 0,
  nroControlDesdeLocalidad: 0,
  nroControlHastaLocalidad: 0,
  nroSubControlLocalidad: 0,
  logEstatusRegistro: 'ACTIVO',
 })

 const { handleSubmit, control } = useForm({
  resolver: yupResolver(schema),
  // defaultValues: {

  // },
 })
 const handleChangeNroControl = (e) => {
  const { value, name, checked } = e.target

  switch (name) {
   case 'nroControl':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHasta:
      parseInt(value) + parseInt(ValoresControlFactura.cantidadFacturas),
     nroControlDesde: parseInt(value),
    }))
    break
   case 'cantidadFacturas':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHasta:
      parseInt(ValoresControlFactura.nroControl) + parseInt(value),
    }))
    break

   case 'localidadGeneraFac':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: checked,
    }))
    checked
     ? setValoresControlFactura((prevState) => ({
        ...prevState,
        cantidadLocalidad: parseInt(ValoresControlFactura.cantidadFacturas),
        nroControlLocalidad: parseInt(ValoresControlFactura.nroControl),
        nroControlDesdeLocalidad: parseInt(ValoresControlFactura.nroControl),
        nroControlHastaLocalidad:
         parseInt(ValoresControlFactura.nroControl) +
         parseInt(ValoresControlFactura.cantidadFacturas),
       }))
     : setValoresControlFactura((prevState) => ({
        ...prevState,
        cantidadLocalidad: 0,
        nroControlLocalidad: 0,
        nroControlDesdeLocalidad: 0,
        nroControlHastaLocalidad: 0,
       }))
    break
   case 'nroControlLocalidad':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHastaLocalidad:
      parseInt(value) + parseInt(ValoresControlFactura.cantidadLocalidad),
     nroControlDesdeLocalidad: parseInt(value),
    }))
    break
   case 'cantidadLocalidad':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHastaLocalidad:
      parseInt(ValoresControlFactura.nroControlLocalidad) + parseInt(value),
    }))
    break
   case 'serie':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: value,
     // [name]: soloTextyNumeros(value, filtroLetrasMayusculas),
    }))
    break
   case 'logEstatusRegistro':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: value,
    }))
    break

   case 'tipoFacturaOrden':
    setValoresControlFactura((prevState) => ({
     ...prevState,
    }))
    break
   default:
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
    }))
    break
  }
 }

 const onSubmitLocalidad = async (data) => {
  console.log('🚀 ~ onSubmitLocalidad ~ data:', data)
  if (data.tipoFacturaOrden === 'FACTURACION') {
   dispatch(startSaveControlfacturalLocalidad(ValoresControlFactura)).then(
    (resul) => {
     if (resul.payload.tipoMsg === 'sucess') {
      handleClose()
     }
    }
   )
  } else if (data.tipoFacturaOrden === 'ORDENES/GUIA') {
   dispatch(startSaveControlOrdenesLocalidad(ValoresControlFactura)).then(
    (resul) => {
     if (resul.payload.tipoMsg === 'sucess') {
      handleClose()
     }
    }
   )
  }
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
    {/* <Divider component='div'>Master</Divider> */}
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={3} sm={3} xl={12} xs={12}>
      {activeLocalidad.rutaLogo && (
       <div>
        <img
         className={classes.logo}
         src={
          activeLocalidad.rutaLogo ? (
           activeLocalidad.rutaLogo
          ) : (
           <AddPhotoAlternateIcon />
          )
         }
         alt='Logo'
        />
       </div>
      )}
     </Grid>
     <Grid item lg={9} sm={9} xl={12} xs={12}>
      <Typography align='jusify' variant='h5'>
       {activeLocalidad.nombre}
      </Typography>
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <FormControl className={classes.radio}>
       <Controller
        control={control}
        name='localidadGeneraFac'
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
         <FormControlLabel
          control={
           <Checkbox
            name='localidadGeneraFac'
            onChange={(e) => {
             handleChangeNroControl(e)
             onChange(e)
            }}
            checked={value}
           />
          }
          label='Número de control de factura Individual'
         />
        )}
       />
      </FormControl>
     </Grid>
     {/* tipo de control de facturas o  ordenes/guias */}
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='tipoFacturaOrden'
       control={control}
       // defaultValue={ValoresControlFactura.cantidadFacturas}
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Tipo de Control
         </InputLabel>
         <Select
          name='tipoFacturaOrden'
          fullWidth
          // disabled={
          //   !CotrolLocalidadActivo?.localidadGeneraFac ? true : false
          // }
          // className={classes.select}
          // onChange={onChange}
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {TIPOCONTROL.map((option) => {
           return (
            <MenuItem
             // className={classes.sublabel}
             key={option}
             value={option}
            >
             {option}
            </MenuItem>
           )
          })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='logEstatusRegistro'
       control={control}
       // defaultValue={ValoresControlFactura.cantidadFacturas}
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Status
         </InputLabel>
         <Select
          name='logEstatusRegistro'
          fullWidth
          // disabled={
          //   !CotrolLocalidadActivo?.localidadGeneraFac ? true : false
          // }
          // className={classes.select}
          // onChange={onChange}
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {tipodoc.map((option) => {
           return (
            <MenuItem
             // className={classes.sublabel}
             key={option.key}
             value={option}
            >
             {option}
            </MenuItem>
           )
          })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>
     {!ValoresControlFactura.localidadGeneraFac && (
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Controller
        name='cantidadFacturas'
        control={control}
        defaultValue={ValoresControlFactura.cantidadFacturas}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <NumberFormat
          format='#########'
          thousandSeparator={true}
          allowNegative={true}
          allowLeadingZeros={false}
          customInput={TextField}
          name='cantidadFacturas'
          className={classes.text}
          label='Cantidad de Facturas (Global)'
          // variant='outlined'
          defaultValue={0}
          size='small'
          fullWidth
          value={value}
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
     )}
     {!ValoresControlFactura.localidadGeneraFac && (
      <>
       <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Controller
         name='nroControl'
         control={control}
         defaultValue={ValoresControlFactura.nroControl}
         render={({ field: { onChange, value }, fieldState: { error } }) => (
          <NumberFormat
           format='#########'
           customInput={TextField}
           thousandSeparator={true}
           allowNegative={true}
           allowLeadingZeros={true}
           name='nroControl'
           className={classes.text}
           label='Nro. de Control Inicial'
           // variant='outlined'
           defaultValue={0}
           size='small'
           s
           fullWidth
           value={value}
           onChange={(e) => {
            handleChangeNroControl(e)
            onChange(e.target.value)
           }}
           error={!!error}
           helperText={error ? error.message : null}
          />
         )}
        />
       </Grid>
       <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Controller
         name='nroControlDesde'
         control={control}
         defaultValue={ValoresControlFactura.nroControlDesde}
         render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
           disabled
           className={classes.text}
           name='nroControlDesde'
           label='Desde'
           size='small'
           fullWidth
           value={ValoresControlFactura.nroControlDesde}
           onChange={onChange}
           error={!!error}
           helperText={error ? error.message : null}
          />
         )}
        />
       </Grid>
       <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Controller
         name='nroControlHasta'
         control={control}
         defaultValue={ValoresControlFactura.nroControlHasta}
         render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
           disabled
           name='nroControlHasta'
           className={classes.text}
           label='Hasta'
           // variant='outlined'
           size='small'
           fullWidth
           value={ValoresControlFactura.nroControlHasta}
           onChange={onChange}
           error={!!error}
           helperText={error ? error.message : null}
          />
         )}
        />
       </Grid>
      </>
     )}
     {ValoresControlFactura.localidadGeneraFac && (
      <Grid
       item
       lg={12}
       sm={12}
       xl={12}
       xs={12}
       style={{ marginBottom: '24px' }}
      >
       <Divider component='div' textAlign='right'>
        <Typography variant='subtitle2'>
         Indique el Nro. de control de facturas individual para esta razon
         social
        </Typography>
       </Divider>
      </Grid>
     )}
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='serie'
       control={control}
       defaultValue={ValoresControlFactura.serie}
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         name='serie'
         maxLength={2}
         className={classes.text}
         label={
          !ValoresControlFactura.localidadGeneraFac ? 'Serie Global' : 'Serie'
         }
         // variant='outlined'
         defaultValue={0}
         size='small'
         fullWidth
         value={ValoresControlFactura.serie}
         onChange={(e) => {
          handleChangeNroControl(e)
          onChange(e.target.value)
         }}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
    </Grid>
    {/* {!ValoresControlFactura.localidadGeneraFac && ( */}
    {ValoresControlFactura.localidadGeneraFac && (
     <Grid container spacing={2} className={classes.root}>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='cantidadLocalidad'
        // defaultValue={0}
        // defaultValue={ValoresControlFactura.cantidadLocalidad}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <NumberFormat
          format='#########'
          thousandSeparator={true}
          // disabled={ValoresControlFactura.localidadGeneraFac}
          allowNegative={true}
          allowLeadingZeros={false}
          customInput={TextField}
          name='cantidadLocalidad'
          className={classes.text}
          label='Cantidad de Facturas'
          // variant='outlined'
          size='small'
          fullWidth
          value={value}
          // value={
          //   ValoresControlFactura.localidadGeneraFac
          //     ? ValoresControlFactura.cantidadLocalidad
          //     : value
          // }
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControlLocalidad'
        control={control}
        defaultValue={ValoresControlFactura.nroControlLocalidad}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <NumberFormat
          format='#########'
          customInput={TextField}
          thousandSeparator={true}
          // disabled={ValoresControlFactura.localidadGeneraFac}
          allowNegative={true}
          allowLeadingZeros={true}
          name='nroControlLocalidad'
          className={classes.text}
          label='Nro. de Control Inicial'
          // variant='outlined'
          defaultValue={0}
          size='small'
          s
          fullWidth
          value={
           ValoresControlFactura.localidadGeneraFac
            ? ValoresControlFactura.nroControlLocalidad
            : value
          }
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControlDesdeLocalidad'
        control={control}
        defaultValue={ValoresControlFactura.nroControlDesdeLocalidad}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <TextField
          disabled
          className={classes.text}
          name='nroControlDesdeLocalidad'
          label='Desde'
          size='small'
          fullWidth
          // value={
          //   ValoresControlFactura.localidadGeneraFac
          //     ? ValoresControlFactura.nroControlDesdeLocalidad
          //     : value
          // }
          value={ValoresControlFactura.nroControlDesdeLocalidad}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControlHastaLocalidad'
        control={control}
        defaultValue={ValoresControlFactura.nroControlHastaLocalidad}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <TextField
          disabled
          name='nroControlHastaLocalidad'
          className={classes.text}
          label='Hasta'
          // variant='outlined'
          size='small'
          fullWidth
          nroControlDesdeLocalidad
          // value={
          //   ValoresControlFactura.localidadGeneraFac
          //     ? ValoresControlFactura.nroControlHastaLocalidad
          //     : value
          // }
          value={ValoresControlFactura.nroControlHastaLocalidad}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
     </Grid>
    )}
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
      >
       Agregar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
