import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'

import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from '@material-ui/core'
import useStyles from './Styles'
import Pagination from './Pagination'
import {
  logsEmaiClearSendgrid,
  startLoadLorSendmGrid,
  startLoadReportLogEmail,
} from '../../../../redux/actions/reporteActions'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import SvgSendGrid from './logoSendgrid'
import LogsSendGrid from './LogsSendGrid'

export default function VerLogsEmail() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    logsEmails,
    limit,
    page,
    // totalResults,
    // totalPages,
    fechaFin,
    fechaInicio,
    nroDoc,
    // recibidoEmail,
  } = useSelector((state) => state.logsemail)
  const pageNumberLimit = 10
  const [loading, setLoading] = useState(true)
  const [ModalStatusCorreo, setModalStatusCorreo] = useState(false)
  const [rowLogsStatus, setrowLogsStatus] = useState({})

  const [currentPage, setCurrentPage] = useState(page)
  const [maxPageLimit, setMaxPageLimit] = useState(limit)
  const [minPageLimit, setMinPageLimit] = useState(0)
  const { localidadActive, sucursalActive } = useRazonSocial()

  const Actualizabuscar = (pageBuscar) => {
    dispatch(
      startLoadReportLogEmail({
        fechaInicio,
        fechaFin,
        nroDoc,
        recibidoEmail: '',
        localidad: localidadActive?._id || '',
        sucursal: sucursalActive?.id || '',
        limit,
        page: pageBuscar,
        status: 1,
      })
    )
  }

  const handleClickOpen = (e) => {
    setrowLogsStatus(e)
    dispatch(logsEmaiClearSendgrid())
    dispatch(startLoadLorSendmGrid(e.messageId))
    setModalStatusCorreo(true)
  }

  const handleClickClose = () => {
    dispatch(logsEmaiClearSendgrid())
    setModalStatusCorreo(false)
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    Actualizabuscar(pageNumber)
  }

  const onPrevClick = () => {
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageLimit(maxPageLimit - pageNumberLimit)
      setMinPageLimit(minPageLimit - pageNumberLimit)
    }
    setCurrentPage((prev) => prev - 1)

    Actualizabuscar(currentPage - 1)
  }

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + pageNumberLimit)
      setMinPageLimit(minPageLimit + pageNumberLimit)
    }
    setCurrentPage((prev) => prev + 1)
    console.log(
      '🚀 ~ file: VerLogsEmail.js:72 ~ onNextClick ~ currentPage:',
      currentPage
    )

    Actualizabuscar(currentPage + 1)
  }

  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    response: logsEmails.results,
    totalPages: logsEmails.totalPages,
  }

  return (
    <Paper>
      <TableContainer component={Paper} style={{ minWidth: 500, height: 450 }}>
        <Table
          stickyHeader
          // style={{ minWidth: 500, maxHeight: 400 }}
          aria-label='custom pagination table'
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tablehead}>Logs Sendgrid</TableCell>
              <TableCell className={classes.tablehead}>Status</TableCell>
              <TableCell className={classes.tablehead} align='left'>
                Correo entregado
              </TableCell>
              <TableCell className={classes.tablehead} align='left'>
                Fecha Envio
              </TableCell>
              <TableCell className={classes.tablehead} align='center'>
                Tipo Doc
              </TableCell>
              <TableCell className={classes.tablehead} align='center'>
                Nro. Doc
              </TableCell>
              <TableCell className={classes.tablehead} align='left'>
                Razón social
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logsEmails?.results
              ?.sort((a, b) => {
                if (a.fechaEmision > b.fechaEmision) return -1
                if (a.fechaEmision < b.fechaEmision) return 1
                return 0
              })
              ?.map((row) => (
                <TableRow
                  className={classes.tablerow}
                  key={row.messageId}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    className={classes.tablerow}
                    component='th'
                    scope='row'
                  >
                    <IconButton
                      size='small'
                      onClick={() => handleClickOpen(row)}
                    >
                      <SvgSendGrid />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className={classes.tablerow}
                    component='th'
                    scope='row'
                  >
                    {row.statusCode === '250'
                      ? `(${row.statusCode}) Aceptado`
                      : row.statusCode}
                  </TableCell>
                  <TableCell className={classes.tablerow} align='left'>
                    {row?.recibido?.map((cc) => `${cc} `)}
                  </TableCell>
                  <TableCell className={classes.tablerowFecha} align='center'>
                    {formatInTimeZone(
                      row.logFechaRegistro,
                      'America/Caracas',
                      'dd/MM/yyyy hh:mm aaaa'
                    )}
                  </TableCell>
                  <TableCell className={classes.tablerow} align='center'>
                    {row.tipoDoc}
                  </TableCell>
                  <TableCell className={classes.tablerow} align='center'>
                    {row.nroDoc}
                  </TableCell>
                  <TableCell className={classes.tablerow} align='left'>
                    {row?.id_localidad?.nombre || row?.id_sucursal?.nombre|| 'no encontrada'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        {logsEmails?.results?.length ? (
          <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange}
          />
        ) : (
          <div className={classes.strinbusquedad}>Realice una busquedad</div>
        )}
      </div>

      {ModalStatusCorreo && (
        <LogsSendGrid
          rowLogsStatus={rowLogsStatus}
          open={handleClickOpen}
          handleClose={handleClickClose}
          title='Status Correo'
        />
      )}
    </Paper>
  )
}
