import { types } from '../types/types'

const initialState = {
  error: null,
  isError: false,
  loading: true,
  tipoMsg: null,
}

//opciones de tipoMSG: error, sucess, SoloLoading, pagina

export const errorReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case types.errorManejo:
      console.log("🚀 ~ file: errorReducer.js:17 ~ errorReducer ~ ction.payload:", action.payload)
      return {
        ...state,
        error: action.payload.Error,
        isError: true,
        loading: false,
        tipoMsg: action.payload.tipoMsg,
        // error: [action.payload.message, state.error],
      }
    case types.errorClearActive:
      return {
        ...state,
        error: null,
        isError: false,
        loading: false,
        tipoMsg: null,
      }

    case types.setStatusLoaidng:
      return {
        ...state,
        loading: true,
        tipoMsg: action.payload.tipoMsg,

      }

    default:
      return state
  }
}
