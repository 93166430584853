import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  inputMaterial: {
    width: '100%',
  },
  boxtamano: {
    width: '350px',
  },
  formcontrol: {
    minWidth: '100%',
  },
  listapago: {
    fontSize: '12px',
  },
  textinput: {
    '& .MuiInputBase-input': {
      fontSize: '12px',
    },
  },
  resumenpago: {
    border: 'solid 1px tomato',
    backgroundColor: '#f5f5f5',
    minHeight: '245px',
  },
  titlefrmpago: {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '4px 0 0 4px',
  },
  textofrmpago: {
    fontSize: '12px',
    fontWeight: 'bold',
    margin: '0 0 0 4px',
  },
  textigtf: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: 'bold',
    // margin: '10px 0px 20px 20px',
    width: '90%',
    backgroundColor: 'tomato',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    // boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
  },
  textigtfcoletilla: {
    fontSize: '8px',
    fontWeight: 'bold',
    textAlign: 'justify',
    margin: '18px 24px 18px 24px',
  },
  apagar: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px 0px 20px 20px',
    backgroundColor: '#054477',
    color: 'white',
    padding: '15px',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
  },
}))

export default useStyles
