import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

import {
  facturaGeneralClearActive,
  facturaGeneralCloseModalDelete,
  startAnularFacturaGeneral,
} from '../../../redux/actions/facturaGeneralActions'

const initFacturaGeneral = {
  nombre: '',
  correo: '',
  urlPaginaWeb: '',
  logEstatusRegistro: '1',
  logFechaRegistro: new Date(),
  logUsername: 'sadmin',
  logFechaModificacion: null,
}

Modal.setAppElement('#root')

export const FacturaGeneralModalAnular = () => {
  const dispatch = useDispatch()

  const { activeFacturaGeneral, modalFacturaGeneralDelete } = useSelector(
    (state) => state.facturaGeneral
  )

  const [formValues, setFormValues] = useState(initFacturaGeneral)

  const { nombre } = formValues

  const closeModal = () => {
    dispatch(facturaGeneralCloseModalDelete())
    dispatch(facturaGeneralClearActive())
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(startAnularFacturaGeneral(activeFacturaGeneral.id))

    closeModal()
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalFacturaGeneralDelete}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      <h3>Anular Factura General</h3>
      <hr />
      <form className='container' onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className='form-group'>
            <div className='mb-0 form-group row'>
              <div className='col-md-auto'>
                <strong>N° Factura: </strong> {activeFacturaGeneral.nroFactura}
              </div>
              <div className='col-md-auto'>
                <strong>N° Control: </strong>
                {activeFacturaGeneral.nroControl}
              </div>
            </div>
          </div>
          <br />
          <button type='submit' className='btn btn-outline-primary btn-block'>
            <i className='fas fa-power-off'></i>
            <span> Anular</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
