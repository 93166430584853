import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import {
  sucursalCloseModal,
  startSaveSucursal,
  startUpdateSucursal,
  sucursalClearActive,
} from "../../../redux/actions/sucursalActions";
import { startLoadMunicipios, startLoadMunicipiosByEstado } from "../../../redux/actions/municipioActions";
import { startLoadEstados } from "../../../redux/actions/estadoActions";
import { startLoadLocalidades } from "../../../redux/actions/localidadActions";

const initSucursal = {
  localidad: -1,
  estado: -1,
  municipio: -1,
  direccion: "",
  telefono:'',
  correo:'',
  nroControlActual:'',
  nroFacturaActual:'',
  serie:'',
  logEstatusRegistro: "1",
  logFechaRegistro: new Date(),
  logUsername: "sadmin",
  logFechaModificacion: null,
};

Modal.setAppElement("#root");

export const SucursalModal = () => {
  const dispatch = useDispatch();

  const { activeSucursal, modalSucursalOpen } = useSelector((state) => state.sucursal);

  const { estados } = useSelector( state => state.estado );

  const { municipiosByEstado } = useSelector((state) => state.municipio);

  const [formValues, setFormValues] = useState(initSucursal);
  const [checked, setChecked] = useState(false);

  const {
    localidad,
    estado,
    municipio,
    direccion,
    telefono,
    correo,
    nroFacturaActual,
    nroControlActual,
    serie
  } = formValues;

  useEffect(() => {
       
    dispatch( startLoadEstados() );
    dispatch( startLoadMunicipios() );
    dispatch( startLoadLocalidades() );

}, [dispatch]);

  useEffect(() => {
    if (activeSucursal) {
      setFormValues(activeSucursal);
    } else {
      setFormValues(initSucursal);
    }
  }, [activeSucursal, setFormValues]);

  const { localidades } = useSelector((state) => state.localidad);

  const handleInputChange = (e) => {

    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleEstadosChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });

    dispatch(startLoadMunicipiosByEstado(e.target.value));
  };

  const closeModal = () => {
    dispatch(sucursalCloseModal());
    dispatch(sucursalClearActive());
    setFormValues(initSucursal);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (activeSucursal) {
      dispatch(startUpdateSucursal({
        id: activeSucursal.id,
        localidad: (localidad.id === activeSucursal.localidad.id )? activeSucursal.localidad.id : localidad,
        municipio: (municipio.id === activeSucursal.municipio.id )? activeSucursal.municipio.id : municipio,
        direccion,
        telefono,
        correo,
        nroFacturaActual,
        nroControlActual,
        serie,
        logUsername:'sadmin'
      }));
    } else {
      dispatch(
        startSaveSucursal({
          ...formValues,
        })
      );
      console.log(formValues)
    }

    closeModal();
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: "100%",
    },
    modal: {
      background: "white",
      borderRadius: "5px",
      color: "rgb(51, 51, 51)",
      maxHeight: "620px",
      maxWidth: "600px",
      outline: "none",
      padding: "10px",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-25%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const styles = useStyles();

  return (
    <Modal
      isOpen={modalSucursalOpen}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName="modal-fondo"
    >
      {activeSucursal ? (
        <h3>Editar Sucursal</h3>
      ) : (
        <h3>Nueva Sucursal</h3>
      )}

      <hr />
      <form className="container" onSubmit={handleSubmit}>
        <div className={styles.modal}>
        <div className="form-group row">
          <div className="col-md-8">
            <label htmlFor="localidad" className="form-label mb-1">
              Razón Social
            </label>
            <select
              className="form-select form-select-sm"
              aria-label=".form-select-sm"
              label="localidad"
              id="localidad"
              value={ localidad }
              onChange={handleInputChange}
              name="localidad"
              required={ true }
            >
                <option defaultValue={ (activeSucursal)? activeSucursal.localidad.id : "Seleccione..." }>{ (activeSucursal)? activeSucursal.localidad.nombre : "Seleccione..." }</option>
              {localidades.map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.nombre}
                </option>
              ))}
            </select>
          </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6">
            <label htmlFor="estado" className="form-label mb-0">Estado</label>
              <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                label="estado"
                id="estado"
                value={ estado }
                onChange={ handleEstadosChange }
                name="estado"
              >
                <option defaultValue={ (activeSucursal)? activeSucursal.municipio.estado.id : "Seleccione..." }>{ (activeSucursal)? activeSucursal.municipio.estado.nombre : "Seleccione..." }</option>
                {
                  estados.map(est => (
                    <option key={est.id} value={est.id}>{est.nombre}</option>
                ))
                }
              </select>
            </div>
            <div className="col-md-6">
            <label htmlFor="municipio" className="form-label mb-0">Municipio</label>
            <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                label="municipio"
                id="municipio"
                value={ municipio }
                onChange={ handleInputChange }
                name="municipio"
                required={true}
              >
                <option defaultValue={ (activeSucursal)? activeSucursal.municipio.id : "Seleccione..." }>{ (activeSucursal)? activeSucursal.municipio.nombre : "Seleccione..." }</option>
                {
                  municipiosByEstado.map(mun => (
                    <option key={mun.id} value={mun.id}>{mun.nombre}</option>
                ))
                }
            </select>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-md-12">
              <label htmlFor="direcccion" className="form-label mb-0">Domicilio fiscal</label>
              <textarea 
              className="form-control" 
              id="direccion" 
              rows="2"
              onChange={handleInputChange}
              value={ direccion }
              name="direccion"
              required={true}
              >
              </textarea>
            </div>
          </div>
        <div className="form-group row">
          <div className="col-md-6">
            <label htmlFor="telefono" className="form-label mb-0">Teléfono</label>
            <input
              type="text"
              id='telefono'
              className="form-control form-control-sm"
              placeholder="telefono"
              name="telefono"
              autoComplete="off"
              value={ telefono }
              onChange={handleInputChange}
              required={true}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="correo" className="form-label mb-0">Correo</label>
            <input
              type="text"
              id='correo'
              className="form-control form-control-sm"
              placeholder="correo"
              name="correo"
              autoComplete="off"
              value={ correo }
              onChange={handleInputChange}
            />
          </div>
          </div>
          <div className="form-group row mb-3">
            <div className="col-md-4">
            <label htmlFor="nroFacturaActual" className="form-label mb-0">N° Factura Actual</label>
            <input
              type="text"
              id='nroFacturaActual'
              className="form-control form-control-sm"
              placeholder="N° de Factura actual"
              name="nroFacturaActual"
              autoComplete="off"
              value={ nroFacturaActual }
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="nroControlActual" className="form-label mb-0">N° Control Actual</label>
            <input
              type="text"
              id='nroControlActual'
              className="form-control form-control-sm"
              placeholder="N° de control actual"
              name="nroControlActual"
              autoComplete="off"
              value={ nroControlActual }
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="serie" className="form-label mb-0">Serie</label>
            <input
              type="text"
              id='serie'
              className="form-control form-control-sm"
              placeholder="serie"
              name="serie"
              autoComplete="off"
              value={ serie }
              onChange={handleInputChange}
            />
          </div>
          </div>
          
          <button type="submit" className="btn btn-outline-primary btn-block">
            <i className="far fa-save"></i>
            <span> Guardar</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
