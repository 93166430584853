import { Container, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FormatedBolivares } from '../../../../Helpers/formater'
import LoadingCirculo from '../../../commons/ui/LoadingCirculo'
// import { useModal } from "../../../../hooks/useModal"
import ModalGenerico from '../../../commons/ui/Modal'
import CondicionPago from './CondicionPago'
import TotalesGenerales from './TotalesGenerales'
import { useStyles } from './Styles'

const DetalleFacturaModal = ({ handleClose, open, title }) => {
  const classes = useStyles()
  const { isError, loading } = useSelector((state) => state.error)
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)

  // const { lstDetallesFacturaGeneral, lstPagos } = activeFacturaGeneral

  return (
    <ModalGenerico handleClose={handleClose} open={open} title={title}>
      {loading ? (
        <LoadingCirculo size={60} height='10vh' />
      ) : (
        <Container
          maxWidth='xl'
          style={{
            backgroundColor: '#f5f5f5',
            overflowY: 'scroll',
            minHeight: '50vh',
          }}
        >
          <Typography varint='h6' style={{ fontWeight: 'bold' }}>
            Datos básicos
          </Typography>
          <Grid container spacing={0} className={classes.dbasicos}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>N° Factura: </strong>
                {activeFacturaGeneral?.factura.nroFactura}
              </Typography>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Typography>
                <strong>N° Control: </strong>
                {activeFacturaGeneral?.factura.nroControl}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>Nombre ó Razón Social: </strong>
                {activeFacturaGeneral?.localidaddetalle
                  ? activeFacturaGeneral?.localidaddetalle.nombre
                  : activeFacturaGeneral.sucursaldetalle?.id_localidad.nombre}
              </Typography>
            </Grid>
            <Grid item lg={3} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Sucursal: </strong>
                {activeFacturaGeneral?.sucursaldetalle?.direccion}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>Tipo venta: </strong>{' '}
                {activeFacturaGeneral?.factura.tipoVenta}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Condición de pago: </strong>
                {activeFacturaGeneral?.factura.condicionPago}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 12 }}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                Cliente
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.dbasicos}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>RIF: </strong>
                {activeFacturaGeneral?.factura.cliente.tipoDocumento} -{' '}
                {activeFacturaGeneral?.factura.cliente.numeroDocumento}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Identificación: </strong>{' '}
                {activeFacturaGeneral?.factura.cliente.identificacion}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Correo: </strong>{' '}
                {activeFacturaGeneral?.factura.cliente.correo}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Dirección: </strong>
                {activeFacturaGeneral?.factura.cliente.direccion}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                Detalle
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            // justifyContent='center'
            // alignItems='center'
            className={classes.headers}
          >
            <Grid item lg={4} sm={4} xl={12} xs={12}>
              <Typography style={{ textAlign: 'left' }}>Concepto</Typography>
            </Grid>
            <Grid item lg={1} sm={1} xl={12} xs={12}>
              <Typography style={{ textAlign: 'center' }}>Cant.</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Precio Uni.</Typography>
            </Grid>
            <Grid item lg={1} sm={1} xl={12} xs={12}>
              <Typography style={{ textAlign: 'center' }}>% Ali</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Monto Ali.</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Importe</Typography>
            </Grid>
          </Grid>

          {activeFacturaGeneral?.factura.lstDetallesFacturaGeneral.length > 0 &&
            activeFacturaGeneral.factura.lstDetallesFacturaGeneral.map(
              (item) => {
                return (
                  <Grid
                    container
                    direction='row'
                    className={classes.body}
                    key={item.id}
                  >
                    <Grid item lg={4} sm={4} xl={12} xs={12}>
                      <Typography
                        style={{ textAlign: 'left' }}
                        className={classes.root}
                      >
                        {item.descripcion}
                      </Typography>
                    </Grid>
                    <Grid item lg={1} sm={1} xl={12} xs={12}>
                      <Typography style={{ textAlign: 'center' }}>
                        {item.cantidad}
                      </Typography>
                    </Grid>
                    <Grid item lg={2} sm={2} xl={12} xs={12}>
                      <Typography>
                        {item?.alicuotaGravamen > 0
                          ? FormatedBolivares(item.precio, 'es-VE', 'VEF')
                          : item.exento
                          ? FormatedBolivares(item.precio, 'es-VE', 'VEF') +
                            '(E)'
                          : FormatedBolivares(item.precio, 'es-VE', 'VEF') +
                            '(EX)'}
                      </Typography>
                    </Grid>
                    <Grid item lg={1} sm={1} xl={12} xs={12}>
                      <Typography style={{ textAlign: 'center' }}>
                        {Number(item.alicuotaGravamen).toFixed(0)}
                      </Typography>
                    </Grid>
                    <Grid item lg={2} sm={2} xl={12} xs={12}>
                      <Typography>
                        {FormatedBolivares(item.montoGravamen, 'es-VE', 'VEN')}
                      </Typography>
                    </Grid>
                    <Grid item lg={2} sm={2} xl={12} xs={12}>
                      <Typography>
                        {FormatedBolivares(item.importe, 'es-VE', 'VEN')}
                      </Typography>
                    </Grid>
                  </Grid>
                )
              }
            )}

          <Grid
            container
            spacing={1}
            style={{ marginTop: 10, fontWeight: 'bold' }}
          >
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                Pagos
              </Typography>
            </Grid>
          </Grid>
          {activeFacturaGeneral?.condicionPago && (
            <CondicionPago
              Condicionpago={activeFacturaGeneral.factura.condicionPago}
              lstPagos={activeFacturaGeneral.factura.lstPagos}
            />
          )}
          {activeFacturaGeneral?.factura.subTotal && (
            <TotalesGenerales
              activeFacturaGeneral={activeFacturaGeneral.factura}
            />
          )}
        </Container>
      )}
    </ModalGenerico>
  )
}
export default DetalleFacturaModal
