import { TextField, Grid, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { setfacturaUpdateVendedor } from '../../../../../redux/actions/facturaGeneralActions'
import { useStyles } from './Styles'
import useFacturaGeneral from '../../../../../hooks/useFacturaGeneral'

export default function AddObservacion({ handleClose }) {
 const { observacion } = useFacturaGeneral()
 const dispatch = useDispatch()
 const classes = useStyles()

 const handleAddObservacion = (event) => {
  dispatch(setfacturaUpdateVendedor(event.target.value))
 }

 const handlesumitObservacion = () => {
  dispatch(setfacturaUpdateVendedor(observacion))
  handleClose()
 }



 return (
  <Grid
   container
   spacing={1}
   alignItems='center'
   className={classes.root}
   style={{ marginBottom: '15px' }}
  >
   <Grid item lg={12} sm={12} xl={12} xs={12}>
    <TextField
     name='Observaciones'
     fullWidth
     multiline
     rows={3}
     value={observacion}
     onChange={(e) => {
      handleAddObservacion(e)
     }}
     InputProps={{
      inputProps: { maxlength: 80 },
     }}
     //  size=''
     //  value={activeVendedor?.nombre}
     label='Observacion'
    />
   </Grid>
   <Grid item lg={12} sm={12} xl={12} xs={12} style={{ textAlign: 'center' }}>
    <Button onClick={handlesumitObservacion}>
     aceptar
     {/* <PeopleAlt color='primary' /> */}
    </Button>
   </Grid>
  </Grid>
 )
}
