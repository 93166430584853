import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
 ordenActive,
 startAnularOrden,
 startLoadOrdenes,
} from '../redux/actions/ordenActions'
import { useNavigate } from 'react-router'

const useOrden = () => {
 const navigate = useNavigate()

 const [pagination, setPagination] = useState({
  pageIndex: 1,
  limit: 10,
  orderBy: 'id',
  order: 'asc',
 })
 const [openView, setOpenView] = useState(false)
 const [openAnularV, setopenAnularV] = useState(false)
 const [openFiltro, setOpenFiltro] = useState(false)

 const {
  ordenes,
  activeOrden,
  motivo,
  direccionEntrega,
  observacion,
  IncluirMontos,
 } = useSelector((state) => state.orden)
 const dispatch = useDispatch()

 useEffect(() => {
  dispatch(
   startLoadOrdenes({ limit: pagination.limit, page: pagination.pageIndex })
  )
 }, [dispatch, pagination.pageIndex, pagination.limit])

 const hnadleAnularOrden = (orden) => {
  dispatch(startAnularOrden(orden))
 }
 const handleordenActive = (orden) => {
  dispatch(ordenActive(orden))
 }

 const handleView = () => {
  setOpenView(true)
 }
 const handleCloseView = () => {
  setOpenView(false)
 }
 const handlecloseAnularV = () => {
  setopenAnularV(false)
 }
 const handleclosefiltro = () => {
  setOpenFiltro(false)
 }
 const hnadleOpenFiltro = () => {
  setOpenFiltro(true)
 }

 const goToBase = (e) => {
  navigate('/facturacion/OrdenesGuias/CrearOrdenesGuias', { replace: true })
 }

 return {
  ordenes,
  openView,
  activeOrden,
  openAnularV,
  pagination,
  openFiltro,
  motivo,
  direccionEntrega,
  observacion,
  IncluirMontos,
  hnadleAnularOrden,
  setOpenView,
  handleView,
  handleCloseView,
  handleordenActive,
  setopenAnularV,
  handlecloseAnularV,
  setPagination,
  setOpenFiltro,
  handleclosefiltro,
  hnadleOpenFiltro,
  goToBase,
 }
}
export default useOrden
