import { useSelector } from 'react-redux'
import { CardHeader, Card, CardContent, Grid, Divider } from '@material-ui/core'
import useStyles from './Styles'
import { FormatedBolivares } from '../../../Helpers/formater'

const TotalesNota = () => {
  const classes = useStyles()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)
  const {
    lstDetallesNota,
    lstGravamenes,
    subTotal,
    totalExento,
    totalExonerado,
    importeTotal,
    tipo,
  } = useSelector((state) => state.nota)

  return (
    <Card className={classes.card}>
      <CardHeader title='Totales Nota' className={classes.cardheader} />
      <CardContent>
        <Grid container spacing={0}>
          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            SubTotal:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              activeFacturaGeneral?.subTotal,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>

          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            Exento:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              activeFacturaGeneral?.totalExento,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            Exonerado:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              activeFacturaGeneral?.totalExonerado,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>

          {/* total gravamen */}

          {activeFacturaGeneral?.lstGravamenes.map((row, index) => (
            <>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                className={classes.texttotal}
              >
                Total IVA: {row.alicuota}
                {'%'}
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                key={index}
                className={classes.texttotalmonto}
              >
                {FormatedBolivares(
                  row.montoAlicuota,
                  'es-US',
                  activeFacturaGeneral?.facturaDivisa
                )}
              </Grid>
            </>
          ))}

          {/* Total */}
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgen}
          >
            Total:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgenmonto}
          >
            {FormatedBolivares(
              activeFacturaGeneral?.importeTotal,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <Divider component='div' />
        <div>Datos al momento de la emision:</div>
        <Grid container spacing={0}>
          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            SubTotal:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              subTotal,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>

          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            Exento:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              totalExento,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.texttotal}>
            Exonerado:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalmonto}
          >
            {FormatedBolivares(
              totalExonerado,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>

          {/* total gravamen */}

          {lstGravamenes.map((row, index) => (
            <>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                className={classes.texttotal}
              >
                Total IVA: {row.alicuota}
                {'%'}
              </Grid>
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                key={index}
                className={classes.texttotalmonto}
              >
                {FormatedBolivares(
                  row.montoAlicuota,
                  'es-US',
                  activeFacturaGeneral?.facturaDivisa
                )}
              </Grid>
            </>
          ))}

          {/* Total */}
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgen}
          >
            Total:
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgenmonto}
          >
            {FormatedBolivares(
              importeTotal,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgen}
          >
            {`Total Nota de ${tipo}:`}
          </Grid>
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={6}
            className={classes.texttotalgenmonto}
          >
            {FormatedBolivares(
              activeFacturaGeneral?.importeTotal - importeTotal,
              'es-US',
              activeFacturaGeneral?.facturaDivisa
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TotalesNota
