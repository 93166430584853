import { FormControlLabel, Grid, IconButton, Switch } from '@material-ui/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useStyles from './Styles'
import CheckIcon from '@material-ui/icons/Check'
import { startActivaMarcaAguaLoalidad } from '../../../redux/actions/localidadActions'

export default function ActivaMarcaAgua() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const [activaMarcaAgua, setactivaMarcaAgua] = useState(
    activeLocalidad?.marcaAguaFactura
  )

  const handleChange = (e) => {
    const { checked } = e.target
    setactivaMarcaAgua(checked)
  }

  const onSubmitParamtros = async () => {
    const paramters = {
      activaMarcaAgua,
      _id: activeLocalidad._id,
    }
    dispatch(startActivaMarcaAguaLoalidad(paramters))
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item lg={10} sm={10} xl={10} xs={10}>
        <FormControlLabel
          style={{ margitTop: '8px' }}
          className={classes.switchgenerico}
          labelPlacement='end'
          control={
            <Switch
              name='activaMarcaAgua'
              // defaultValue={activeLocalidad?.envioEmail}
              defaultChecked={activeLocalidad?.marcaAguaFactura}
              checked={activaMarcaAgua}
              onChange={(e) => handleChange(e)}
            />
          }
          label='Activar/Desactivar marca de agua en facturas'
        />
      </Grid>
      <Grid item lg={2} sm={2} xl={2} xs={2}>
        <IconButton
          onClick={onSubmitParamtros}
          size='small'
          // type='submit'
          variant='contained'
          color='primary'
          fullWidth
          //  onClick={() => peticionPost()}
        >
          <CheckIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
