import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { ProductoModal } from './ProductoModal'

import MaterialTable from 'material-table'
import SearchRounded from '@material-ui/icons/SearchRounded'
import Edit from '@material-ui/icons/Edit'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import Tableproductos from './tableProductos/Tableproductos'

import {
  productoClearActive,
  // productoOpenModal,
  // productoOpenModalDelete,
  // productoOpenModalShow,
  productoSetActive,
  startLoadProductos,
  // startReactivedProducto,
} from '../../../redux/actions/productoActions'
// import { ProductoModalDelete } from './ProductoModalDelete'
// import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
// import { ProductoModalShow } from './ProductoModalShow'
import { useModal } from '../../../hooks/useModal'
import AgregarProductos from './AgregarProductos'
import { useToast } from '../../../hooks/useToast'
import UpdateProductos from './updateProductos'
import ProductoActivaDesativa from './ProductoActivaDesativa'
import { hasPermission } from '../../../Helpers/AuthPermisos'

const Producto = () => {
  const { handleOpen, open, handleClose } = useModal()
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { roles } = useSelector((state) => state.role)
  const { notifyError, notifysucess } = useToast()
  const [OpenUpdateProducto, setOpenUpdateProducto] = useState(false)
  const [OpenActDesProducto, setOpenActDesProducto] = useState(false)

  const dispatch = useDispatch()

  const { productos } = useSelector((state) => state.producto)

  const columnas = [
    {
      title: 'Código',
      field: 'codigo',
    },
    {
      title: 'Descripción',
      field: 'descripcion',
    },
    {
      title: 'Razón Social',
      field: 'nombre',
      defaultSort: 'asc',
      // sorting: true,
      render: (rowData) => (
        <div style={{ fontWeight: 'bold' }}>{rowData?.id_localidad?.nombre}</div>
      ),
    },
    {
      title: 'Tipo Alícuota',
      field: 'tipoGravamen?.concepto',
      render: (rowData) =>
        rowData.exonerado
          ? 'Exonerado'
          : rowData.exento
          ? 'Exento'
          : rowData.tipoGravamen.concepto,
    },
    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
    },
  ]

  useEffect(() => {
    dispatch(startLoadProductos())
  }, [dispatch])

  // const onSelectProducto = (e) => {
  //   dispatch(productoSetActive(e))
  //   dispatch(productoOpenModalShow())
  // }

  const onUpdateProducto = (e) => {
    dispatch(productoSetActive(e))
    setOpenUpdateProducto(true)
  }
  const handleCloseUpdateProducto = () => {
    setOpenUpdateProducto(false)
  }

  const handleActivarDesactivat = (e) => {
    dispatch(productoSetActive(e))
    setOpenActDesProducto(true)
    // dispatch(productoOpenModalDelete())
  }
  const handleCloseActDesProducto = () => {
    setOpenActDesProducto(false)
    dispatch(productoClearActive())
  }

  // const handleDelete = (e) => {
  //   dispatch(productoSetActive(e))
  //   dispatch(productoOpenModalDelete())
  // }

  // const handleReactive = (e) => {
  //   dispatch(startReactivedProducto(e))
  // }

  // const openModal = () => {
  //   handleOpen()
  //   // dispatch(productoOpenModal())
  // }

  return (
    <div>
      <br />
      {/* {modalProductoOpen && <ProductoModal />}
      {modalProductoDelete && <ProductoModalDelete />}
      {modalProductoShow && <ProductoModalShow />} */}

      <MaterialTable
        title='Producto'
        columns={columnas}
        data={productos}
        // detailPanel={(rowData) => {
        //   return <DatosSeguimiento datos={rowData} />
        // }}
        // onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nuevo Producto',
            isFreeAction: true,
            onClick: (event) => {
              handleOpen()
            },
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Producto',
              'add',
              user.user
            ),
          },
          // {
          //   icon: 'search',
          //   tooltip: 'Consultar',
          //   onClick: (event, rowData) => onSelectProducto(rowData),
          // },
          (rowData) => ({
            icon: () => <Edit fontSize='small' htmlColor='#ff6f00' />,
            tooltip: 'Modificar',
            onClick: (event, rowData) => onUpdateProducto(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Producto',
                'update',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => (
              <PowerSettingsNewIcon fontSize='small' htmlColor='tomato' />
            ),
            tooltip: 'Inactivar',
            onClick: (event, rowData) => handleActivarDesactivat(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Producto',
                'cancel',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon fontSize='small' htmlColor='blue' />,
            tooltip: 'Activar',
            onClick: (event, rowData) => handleActivarDesactivat(rowData),
            hidden:
              rowData.logEstatusRegistro === 'ACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Producto',
                'cancel',
                user.user
              ),
          }),
        ]}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />

      {/* <Tableproductos data={productos} handleOpen={handleOpen} /> */}

      {/* <Toaster position='top-center' reverseOrder={false} /> */}

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {open && (
        <AgregarProductos
          open={open}
          handleClose={handleClose}
          title='Nuevo Producto'
        />
      )}
      {OpenUpdateProducto && (
        <UpdateProductos
          open={OpenUpdateProducto}
          handleClose={handleCloseUpdateProducto}
          title='Modificar Producto'
        />
      )}
      {OpenActDesProducto && (
        <ProductoActivaDesativa
          open={OpenActDesProducto}
          handleClose={handleCloseActDesProducto}
          title='Activar/Desactivar Producto'
        />
      )}
    </div>
  )
}

export default Producto
