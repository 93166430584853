import { useState } from 'react'
// import * as yup from 'yup'
// import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Button, CircularProgress, Typography } from '@material-ui/core'
import SvgSendGrid from './logoSendgrid'

// import { useForm, Controller } from 'react-hook-form'
import useStyles from './Styles'
import ModalGenerico from '../../../commons/ui/Modal'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab'
// import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useSelector } from 'react-redux'
// import { resetPasswordUser } from '../../../redux/actions/usuarioActions'

export default function LogsSendGrid({
  rowLogsStatus,
  open,
  handleClose,
  title,
}) {
  console.log('🚀 ~ file: LogsSendGrid.js:35 ~ rowLogsStatus:', rowLogsStatus)
  const classes = useStyles()
  const { logsEmailsSendgrid } = useSelector((state) => state.logsemail)
  console.log(
    '🚀 ~ file: LogsSendGrid.js:38 ~ logsEmailsSendgrid:',
    logsEmailsSendgrid?.body?.messages
  )
  // const [showPassword, setshowPassword] = useState(false)

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <Grid container spacing={2} className={classes.passwordRoot}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Timeline position='alternate'>
            <TimelineItem>
              <TimelineOppositeContent color='red'>
                <Typography variant='inherit' component='span'>
                  status:
                </Typography>
                <Typography>{rowLogsStatus.statusCode}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot className={classes.timelinedote} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  className={classes.typederecha}
                  variant='body2'
                  component='strong'
                >
                  {rowLogsStatus.msg}
                </Typography>
              </TimelineContent>
            </TimelineItem>

            {logsEmailsSendgrid?.body?.messages?.length > 0 &&
              logsEmailsSendgrid.body.messages.map((item, idx) => {
                return (
                  <TimelineItem ref={item.idx}>
                    <TimelineOppositeContent
                      align='left'
                      color='text.secondary'
                    >
                      <Typography variant='inherit' component='span'>
                        status:
                      </Typography>
                      <Typography>{item.status}</Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot className={classes.timelinedote} />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography
                        className={classes.typederecha}
                        variant='body2'
                        component='strong'
                      >
                        {item.to_email}
                      </Typography>
                      <TimelineContent>
                        <Typography variant='caption'>
                          cant. veces Abierto: {item.opens_count}
                        </Typography>
                      </TimelineContent>
                      <TimelineContent>
                        <Typography variant='caption'>
                          cant. de click: {item.clicks_count}
                        </Typography>
                      </TimelineContent>
                    </TimelineContent>
                  </TimelineItem>
                )
              })}
          </Timeline>
        </Grid>

        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Button
              className={classes.btnModal}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              {logsEmailsSendgrid?.body?.messages === undefined ? (
                <CircularProgress
                  color='white'
                  size={20}
                  style={{ alignSelf: 'center' }}
                />
              ) : (
                <Typography variant='inherit' component='span'>
                  Cerrar
                </Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalGenerico>
  )
}
