import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'

const url = `${config.urlServer}/mantenimiento/regiones`

export const startLoadMunicipios = () => {
  return (dispatch) => {
    axios.get(`${url}/getmunicipios`).then((res) => {
      dispatch(municipioLoaded(res.data.result))
    })
  }
}
export const startSaveMunicipio = (municipio) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'POST',
      url: `${url}/crear`,
      data: municipio,
    })
      .then((response) => {
        Swal.fire('Guardado', municipio.nombre, 'success')
        dispatch(municipioAddNew(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startDeleteMunicipio = () => {
  return async (dispatch, getState) => {
    const { activeMunicipio } = getState().municipio

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeMunicipio.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivado', activeMunicipio.nombre, 'success')
        dispatch(deleteMunicipio(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedMunicipio = (municipio) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${municipio.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', municipio.nombre, 'success')
        dispatch(reactiveMunicipio(response.data))
      })
      .catch((error) => {
        const msgError = error
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateMunicipio = (municipio) => {
  return (dispatch, getState) => {
    const { activeMunicipio } = getState().municipio
    const id = activeMunicipio.id

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/editar/${id}`,
      data: municipio,
    })
      .then((response) => {
        Swal.fire('Actualizado', response.data.nombre, 'success')
        dispatch(municipioUpdated(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

const municipioLoaded = (municipios) => ({
  type: types.municipioLoaded,
  payload: municipios,
})

export const startLoadMunicipiosByEstado = (idEstado) => {
  return (dispatch) => {
    dispatch(municipioLoadedByEstado(idEstado))
  }
}

export const municipioLoadedByEstado = (idEstado) => ({
  type: types.municipioLoadedByEstado,
  payload: idEstado,
})

export const municipioOpenModal = () => ({
  type: types.municipioOpenModal,
})

export const municipioCloseModal = () => ({
  type: types.municipioCloseModal,
})

export const municipioOpenModalDelete = () => ({
  type: types.municipioOpenModalDelete,
})

export const municipioCloseModalDelete = () => ({
  type: types.municipioCloseModalDelete,
})

export const municipioAddNew = (municipio) => ({
  type: types.municipioAddNew,
  payload: municipio,
})

export const municipioSetActive = (municipio) => ({
  type: types.municipioSetActive,
  payload: municipio,
})

export const municipioClearActive = () => ({
  type: types.municipioClearActive,
})

export const municipioUpdated = (municipio) => ({
  type: types.municipioUpdated,
  payload: municipio,
})

export const deleteMunicipio = (municipio) => ({
  type: types.municipioDelete,
  payload: municipio,
})

export const reactiveMunicipio = (municipio) => ({
  type: types.municipioReactived,
  payload: municipio,
})
