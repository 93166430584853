import { lazy, Suspense } from 'react'
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import './containers/scss/style.scss'
import { PublicRouteAuth, RequireAuth } from './Helpers/VericarRutas'
import routes from './routes'
import LoadingSvg from './components/commons/ui/LoadingProcet'
import { hasRole } from './Helpers/AuthPermisos'
import { useSelector } from 'react-redux'
// import { startLoadModulos } from './redux/actions/moduloActions'
// import { startLoadRoles } from './redux/actions/roleActions'

const loading = <LoadingSvg width={300} />

console.log("🚀 ~ routes:", routes)
// Containers
const TheLayout = lazy(() => import('./containers/TheLayout'))
const Home = lazy(() => import('./components/home/Home'))

// Pages
const Login = lazy(() => import('./components/LoginTest'))

const App = () => {
  // const dispatch = useDispatch()
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  // const { modulos } = useSelector((state) => state.modulo)
  const { roles } = useSelector((state) => state.role)

  return (
    <Suspense fallback={loading}>
      <Routes>
        {/* <RequireAuth /> */}
        <Route
          path='login'
          exact='true'
          element={
            <PublicRouteAuth>
              <Login />
            </PublicRouteAuth>
          }
        />
        <Route
          path='home'
          exact='true'
          element={
            <PublicRouteAuth>
              <Home />
            </PublicRouteAuth>
          }
        />
        <Route
          path='/'
          exact='true'
          element={
            <RequireAuth>
              <TheLayout />
            </RequireAuth>
          }
        >
          {routes.map((route, idx) => {
            const PermiSos = roles?.filter((rol) => rol.id === user?.user.role)

            const routePermisos = PermiSos[0]?.modulo.reduce((acc, modulo) => {
              return acc.concat(modulo.path)
            }, [])

            return (
              // routePermisos &&
              hasRole([route.path], routePermisos, user?.user) && (
                <Route
                  key={idx}
                  exact={route?.exact}
                  name={route?.name}
                  path={route?.path}
                  element={<RequireAuth>{route?.element}</RequireAuth>}
                />
              )
            )
          })}

          <Route
            path='*'
            exact='true'
            element={
              <PublicRouteAuth>
                <Home />
              </PublicRouteAuth>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default App
