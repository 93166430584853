import { types } from "../types/types";

const initialState = {
  tiposGravamenes: [],
  activeTipoGravamen: null,
  modalTipoGravamenOpen: false,
  modalTipoGravamenDelete: false,
};

export const tipoGravamenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.tipoGravamenOpenModal:
      return {
        ...state,
        modalTipoGravamenOpen: true,
        modalTipoGravamenDelete: false,
      };

    case types.tipoGravamenCloseModal:
      return {
        ...state,
        modalTipoGravamenOpen: false,
      };

    case types.tipoGravamenOpenModalDelete:
      return {
        ...state,
        modalTipoGravamenOpen: false,
        modalTipoGravamenDelete: true,
      };

    case types.tipoGravamenCloseModalDelete:
      return {
        ...state,
        modalTipoGravamenDelete: false,
      };

    case types.tipoGravamenSetActive:
      return {
        ...state,
        activeTipoGravamen: action.payload,
        modalTipoGravamenOpen: true,
      };

    case types.tipoGravamenAddNew:
      return {
        ...state,
        tiposGravamenes: [action.payload, ...state.tiposGravamenes],
      };

    case types.tipoGravamenClearActive:
      return {
        ...state,
        activeTipoGravamen: null,
      };

    case types.tipoGravamenUpdated:
      return {
        ...state,
        tiposGravamenes: state.tiposGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.tipoGravamenLoaded:
      return {
        ...state,
        tiposGravamenes: action.payload,
      };

    case types.tipoGravamenDelete:
      return {
        ...state,
        tiposGravamenes: state.tiposGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeTipoGravamen: null,
      };

    case types.tipoGravamenReactived:
      return {
        ...state,
        tiposGravamenes: state.tiposGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeTipoGravamen: null,
      };

    default:
      return state;
  }
};
