import React, { useEffect } from 'react'
import {
 Grid,
 Typography,
 Button,
 IconButton,
 Card,
 CardHeader,
 CardContent,
 FormControlLabel,
 Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteOutline from '@material-ui/icons/DeleteOutline'

import { useDispatch, useSelector } from 'react-redux'
import {
 setClearlstDetalles,
 startDeleteDetalle,
} from '../../../../redux/actions/facturaGeneralActions'
import { useModal } from '../../../../hooks/useModal'
// import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import { FormatedBolivares, numeroFormat } from '../../../../Helpers/formater'
import useFacturaGeneral from '../../../../hooks/useFacturaGeneral'

import AgregarProducto from '../../General/CrearFactura/AgregarProducto'
import VendedorModal from '../../General/CrearFactura/vendedor/VendedorModal'
import { setUpdateOrden } from '../../../../redux/actions/ordenActions'

const useStyles = makeStyles((theme) => ({
 root: {
  '& > *': {
   margin: theme.spacing(1),
   width: theme.spacing(32),
   height: theme.spacing(32),
  },
 },
 container: {
  alignItems: 'center',
 },
 paper: {
  backgroundColor: 'white',
  padding: '15px',
  borderRadius: 5,
  opacity: '0.7',
 },
 title: {
  fontWeight: 'bold',
  fontSize: '18px',
 },
 headers: {
  fontSize: '11px',
  textAlign: 'right',
  fontWeight: 'bold',
  backgroundColor: '#e0e0e0',
 },
 body: {
  fontSize: '10px',
  textAlign: 'right',
 },
 btn: {
  marginTop: '24px',
  fontSize: '12px',
  textAlign: 'center',
  '&:hover': {
   backgroundColor: '#054477',
   color: 'white',
  },
 },
 CardHeader: {
  backgroundColor: '#054477',
  color: 'white',
  padding: '8px',
 },
 btnVededor: {
  // marginTop: '8px',
  marginRight: '30px',

  backgroundColor: 'white',
  color: '#054477',
  fontWeight: 'bold',
  // fontSize: '10px',
  '&:hover': {
   backgroundColor: '#054477',
   color: 'white',
  },
 },
 cintaVendedor: {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '12px',
  gap: '10px',
  margin: '10px',
 },
 switchgenerico: {
  marginTop: '12px',
  '& .MuiTypography-body1': {
   marginLeft: '8px',
   fontSize: '14px',
  },
 },
}))

const DetalleProductos = () => {
 const classes = useStyles()
 const { IncluirMontos } = useSelector((state) => state.orden)
 const dispatch = useDispatch()
 const { lstPagos, lstDetalles, ActivarBtnProductoOrden, facturaDivisa } =
  useFacturaGeneral()
 const { open, handleClose, handleOpen } = useModal()
 const {
  open: openVendedor,
  handleClose: handleCloseVendedor,
  handleOpen: handleOpenVendedor,
 } = useModal()

 const agregarItem = (e) => {
  // e.preventDefault()
  handleOpen()
  // dispatch(facturaGeneralOpenDetalleModal())
 }

 const handleDelete = (item, index) => {
  dispatch(startDeleteDetalle(item, index))
 }

 useEffect(() => {
  dispatch(setClearlstDetalles())
 }, [dispatch])

 const handleChange = (e) => {
  const { name, checked: value } = e.target
  dispatch(setUpdateOrden({ name, value }))
 }

 console.log("🚀 ~ DetalleProductos ~ IncluirMontos:", IncluirMontos)


 return (
  <>
   <Card
    style={{
     boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    }}
   >
    <CardHeader
     title='Detalle Productos'
     className={classes.CardHeader}
     action={
      <FormControlLabel
       name='IncluirMontos'
       className={classes.switchgenerico}
       labelPlacement='end'
       control={
        <Switch
        //  defaultValue={IncluirMontos}
         defaultChecked={IncluirMontos}
         size='small'
        />
       }
       label={IncluirMontos ? 'Sin Montos' : 'incluir Montos'}
       // value={IncluirMontos}
       checked={IncluirMontos}
       onChange={handleChange}
      />
     }
    />
    <CardContent>
     <Grid container spacing={0}>
      <Grid item lg={4} sm={4} xl={4} xs={12}>
       <Typography style={{ textAlign: 'left' }} className={classes.headers}>
        Concepto
       </Typography>
      </Grid>
      <Grid itemlg={1} sm={1} xl={1} xs={12}>
       <Typography style={{ textAlign: 'center' }} className={classes.headers}>
        Cantidad
       </Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>Precio Unitario</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>% Descuento</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>Monto Descuento</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>% Alicuota</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>Monto Alicuota</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={12}>
       <Typography className={classes.headers}>Importe</Typography>
      </Grid>
      <Grid item lg={1} sm={1} xl={1} xs={1}>
       <Typography style={{ textAlign: 'center' }} className={classes.headers}>
        Acción
       </Typography>
      </Grid>
     </Grid>
     {lstDetalles?.length > 0 &&
      lstDetalles?.map((item, i) => {
       return (
        <Grid key={i} container spacing={1} className={classes.container}>
         <Grid item lg={4} sm={4} xl={4} xs={12}>
          <Typography style={{ textAlign: 'left' }} className={classes.body}>
           {item?.descripcion}
          </Typography>
         </Grid>
         <Grid item lg={1} sm={1} xl={1} xs={12}>
          <Typography style={{ textAlign: 'center' }} className={classes.body}>
           {item?.cantidad}
          </Typography>
         </Grid>
         <Grid itemlg={1} sm={1} xl={1} xs={12}>
          <Typography className={classes.body}>
           {item?.alicuotaGravamen > 0
            ? FormatedBolivares(item.precio, 'es-ES', facturaDivisa)
            : item.exento
            ? FormatedBolivares(item.precio, 'es-ES', facturaDivisa) + '(E)'
            : FormatedBolivares(item.precio, 'es-ES', facturaDivisa) + '(EX)'}
          </Typography>
         </Grid>
         <Grid item lg={1} sm={1} xl={1} xs={12}>
          <Typography className={classes.body}>
           {`${numeroFormat(item?.descuento, facturaDivisa)}%`}
          </Typography>
         </Grid>
         <Grid item lg={1} sm={1} xl={1} xs={12}>
          <Typography className={classes.body}>
           {FormatedBolivares(item?.montoDescuento, 'es-ES', facturaDivisa)}
          </Typography>
         </Grid>
         <Grid item lg={1} sm={1} xl={1} xs={12}>
          <Typography style={{ textAlign: 'center' }} className={classes.body}>
           {item?.alicuotaGravamen}
          </Typography>
         </Grid>
         <Grid item lg={1} sm={1} xl={1} xs={12}>
          <Typography className={classes.body}>
           {FormatedBolivares(item?.montoGravamen, 'es-ES', facturaDivisa)}
          </Typography>
         </Grid>
         <Grid item sitem lg={1} sm={1} xl={1} xs={12}>
          <Typography className={classes.body}>
           {FormatedBolivares(item?.importe, 'es-ES', facturaDivisa)}
          </Typography>
         </Grid>
         <Grid
          item
          lg={1}
          sm={1}
          xl={1}
          xs={12}
          style={{ textAlign: 'center' }}
         >
          <IconButton
           disabled={lstPagos?.length ? true : false}
           size='small'
           onClick={() => handleDelete(item, i)}
          >
           <DeleteOutline
            color={lstPagos?.length > 0 ? 'primary' : 'secondary'}
            fontSize='small'
            viewBox='0 0 24 24'
           />
          </IconButton>
         </Grid>
        </Grid>
       )
      })}
     <Grid container spacing={2}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Button
        disabled={ActivarBtnProductoOrden}
        className={classes.btn}
        variant='outlined'
        fullWidth
        color='primary'
        onClick={agregarItem}
       >
        <i className='fas fa-cart-plus'></i>
        <span style={{ paddingLeft: '12px' }}> Agregar producto</span>
       </Button>
      </Grid>
     </Grid>
     {open && (
      <AgregarProducto
       open={open}
       handleClose={handleClose}
       title='Agregar Producto'
      />
     )}

     {openVendedor && (
      <VendedorModal
       open={openVendedor}
       handleClose={handleCloseVendedor}
       title='Observaciones'
      />
     )}
    </CardContent>
   </Card>
  </>
 )
}
export default DetalleProductos
