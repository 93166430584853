import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet } from '../../Helpers/ApiAxios'
import { errorSetActive } from './errorActions'

const url = `${config.urlServer}/mantenimiento/modulo`

export const startLoadModulos = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/getModulos`, 'GET')
      dispatch(moduloLoaded(res))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }

    // axios.get(`${url}/listar`)
    // .then(res => {
    //     dispatch(moduloLoaded(res.data));
    // });
  }
}

export const startSaveModulo = (modulo) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'POST',
      url: `${url}/crear`,
      data: modulo,
    })
      .then((response) => {
        Swal.fire('Guardado', modulo.nombre, 'success')
        dispatch(moduloAddNew(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startDeleteModulo = () => {
  return async (dispatch, getState) => {
    const { activeModulo } = getState().modulo

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeModulo.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivado', activeModulo.nombre, 'success')
        dispatch(deleteModulo(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedModulo = (modulo) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${modulo.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', modulo.nombre, 'success')
        dispatch(reactiveModulo(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateModulo = (modulo) => {
  return (dispatch, getState) => {
    const { activeModulo } = getState().modulo
    const id = activeModulo.id

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/editar/${id}`,
      data: modulo,
    })
      .then((response) => {
        Swal.fire('Actualizado', response.data, 'success')
        dispatch(moduloUpdated(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

const moduloLoaded = (modulos) => ({
  type: types.moduloLoaded,
  payload: modulos,
})

export const moduloOpenModal = () => ({
  type: types.moduloOpenModal,
})

export const moduloCloseModal = () => ({
  type: types.moduloCloseModal,
})

export const moduloOpenModalDelete = () => ({
  type: types.moduloOpenModalDelete,
})

export const moduloCloseModalDelete = () => ({
  type: types.moduloCloseModalDelete,
})

export const moduloOpenModalShow = () => ({
  type: types.moduloOpenModalShow,
})

export const moduloCloseModalShow = () => ({
  type: types.moduloCloseModalShow,
})

export const moduloAddNew = (modulo) => ({
  type: types.moduloAddNew,
  payload: modulo,
})

export const moduloSetActive = (modulo) => ({
  type: types.moduloSetActive,
  payload: modulo,
})

export const moduloClear = () => ({
  type: types.moduloClear,
})

export const moduloClearActive = () => ({
  type: types.moduloClearActive,
})

export const moduloUpdated = (modulo) => ({
  type: types.moduloUpdated,
  payload: modulo,
})

export const deleteModulo = (modulo) => ({
  type: types.moduloDelete,
  payload: modulo,
})

export const reactiveModulo = (modulo) => ({
  type: types.moduloReactived,
  payload: modulo,
})
