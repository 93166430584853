import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core'
import useStyles from './Styles'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { tipodoc } from '../../../Constants/ConstantesFacturas'
import NumberFormat from 'react-number-format'
import { startSaveCuentaTerceros } from '../../../redux/actions/localidadActions'

const schema = yup.object().shape({
  // es requrdio si cuentaTercero es true
  domicilioPrestador: yup
    .string()
    .nullable()
    .max(250, 'Maximo 250 caracteres permitidos, para la descripcion')
    .required('El domicilio es requerido'),

  nombrePrestador: yup
    .string()
    .nullable()
    .max(100, 'Maximo 100 caracteres permitidos, para la descripcion')
    .required('El Nombre Prestador  es requerido'),

  numeroDocumento: yup
    .string()
    .max(11, 'minimo valor requerido es 11 caracteres')
    .required('El Nro. Documento es requerido'),
  tipoDocumento: yup.string().required('El domicilio es requerido'),
})

export default function AgregarCuentaTerceros({ handleClose, handleAccion }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const { activeLocalidad } = useSelector((state) => state.localidad)
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmitLocalidad = async (data) => {
    const {
      domicilioPrestador,
      nombrePrestador,
      tipoDocumento,
      numeroDocumento,
    } = data

    const rifPrestador1 = tipoDocumento + '-' + numeroDocumento

    const cuenta = {
      localidad: activeLocalidad?._id,
      domicilioPrestador: domicilioPrestador,
      nombrePrestador: nombrePrestador,
      rifPrestador: rifPrestador1,
    }

    dispatch(startSaveCuentaTerceros(cuenta)).then((res) => {
      if (res.payload?.tipoMsg === 'sucess') {
        handleAccion('listar')
      }
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
      <Grid container spacing={1} className={classes.root}>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <Controller
            control={control}
            name='tipoDocumento'
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  Tipo Doc.
                </InputLabel>
                <Select
                  helperText={error ? error.message : null}
                  fullWidth
                  className={classes.select}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                >
                  {tipodoc.map((option) => {
                    return (
                      <MenuItem
                        className={classes.sublabel}
                        key={option.key}
                        value={option.value}
                      >
                        {option.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <Controller
            name='numeroDocumento'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberFormat
                allowLeadingZeros={true}
                // format='#########'
                // aceptar numero a la izquierda
                customInput={TextField}
                className={classes.text}
                label='Nro. Documento'
                // variant='outlined'
                defaultValue={0}
                size='small'
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                // helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Controller
            name='nombrePrestador'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                className={classes.text}
                label='Nombre  Prestador'
                size='small'
                fullWidth
                value={value}
                onChange={onChange}
                // onChange={(e) =>
                //   onChange(soloTextyNumeros(e.target.value, filtroNumLetras))
                // }
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>

        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Controller
            name='domicilioPrestador'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                id='outlined-multiline-static'
                className={classes.text}
                // variant='outlined'
                label='Domicilio Prestador'
                size='small'
                fullWidth
                multiline
                rows={3}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.margen}>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <Button
            className={classes.btn}
            size='small'
            type='submit'
            variant='contained'
            color='primary'
            fullWidth
            //  onClick={() => peticionPost()}
          >
            Aceptar
          </Button>
        </Grid>
        <Grid item md={6} xs={6}>
          <Button
            className={classes.btn}
            size='small'
            variant='contained'
            fullWidth
            onClick={handleClose}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
