import { types } from '../types/types'

const initialState = {
  logsEmails: [],
  logsEmailsSendgrid: [],
  fechaInicio: '',
  fechaFin: '',
  nroDoc: '',
  recibidoEmail: '',
  limit: 10,
  page: 1,
}

export const logsEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.logsEmailLoaded:
      return {
        ...state,
        logsEmails: action.payload,
      }
    case types.logsEmailClear:
      return {
        ...state,
        logsEmails: [],
        logsEmailsSendgrid: [],
        fechaInicio: '',
        fechaFin: '',
        nroDoc: '',
        recibidoEmail: '',
      }
    case types.logsEmailparams:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }
    case types.logsEmailSendgrid:
      return {
        ...state,
        logsEmailsSendgrid: action.payload,
      }

    case types.logsEmaiClearSendgrid:
      return {
        ...state,
        logsEmailsSendgrid: [],
      }

    default:
      return state
  }
}
