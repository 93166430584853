import { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Button,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import useStyles from './Styles'
import ModalGenerico from '../../commons/ui/Modal'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordUser } from '../../../redux/actions/usuarioActions'

const schema = yup.object().shape({
  keyStorePassword: yup.string().required('Password is requerido'),
  RekeyStorePassword: yup
    .string()
    .required('Password is requerido')
    .oneOf([yup.ref('keyStorePassword'), null], 'Passwords no Coinsiden'),
})

export default function ResetPassword({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeUsuario } = useSelector((state) => state.usuario)
  const [showPassword, setshowPassword] = useState(false)


  const [values, setvalues] = useState({
    keyStoreAlias: null,
    keyStorePassword: null,
    RekeyStorePassword: null,
    keyStoreName: null,
  })
  const {
    handleSubmit,
    control,
    // register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const handlesumitPassword = (data) => {
    dispatch(
      resetPasswordUser({
        userID: activeUsuario.id,
        password: data.RekeyStorePassword,
      })
    )
      .then((res) => {
        if (res.payload.tipoMsg === 'sucess') {
          handleClose()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (e) => {
    console.log('e', e.target.name)
    setvalues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    console.log('event', event)
    event.preventDefault()
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(handlesumitPassword)} autoComplete='off'>
        <Grid container spacing={2} className={classes.passwordRoot}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='keyStorePassword'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor='standard-adornment-password'>
                    Password
                  </InputLabel>
                  <Input
                    name='keyStorePassword'
                    id='standard-adornment-password'
                    className={classes.text}
                    // label='Repita el password'
                    type={showPassword ? 'text' : 'password'}
                    size='small'
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      handleChange(e)
                      onChange(e.target.value)
                    }}
                    error={!!error}
                    helperText={error ? error.message : null}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p className={classes.error}>
                    {error ? error.message : null}
                  </p>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='RekeyStorePassword'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor='standard-adornment-Repassword'>
                    Repita el Password
                  </InputLabel>
                  <Input
                    name='RekeyStorePassword'
                    id='standard-adornment-Repassword'
                    className={classes.text}
                    // label='Repita el password'
                    type={showPassword ? 'text' : 'password'}
                    size='small'
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    // helperText={error ? error.message : null}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <p className={classes.error}>
                    {error ? error.message : null}
                  </p>
                </FormControl>
              )}
            />
          </Grid>
          <Grid container spacing={2} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                Agregar
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
