import { memo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
 FormControl,
 Grid,
 InputLabel,
 MenuItem,
 Select,
 TextField,
 Button,
 Checkbox,
 FormControlLabel,
 Switch,
 List,
 ListItem,
 ListItemText,
 ListItemSecondaryAction,
} from '@material-ui/core'

// import Visibility from '@material-ui/icons/Visibility'
// import VisibilityOff from '@material-ui/icons/VisibilityOff'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import useStyles from './Styles'

import { tipodoc } from '../../../Constants/ConstantesFacturas'
import NumberFormat from 'react-number-format'
// import { Autocomplete } from '@material-ui/lab'
import { useRazonSocial } from '../../../hooks/useRazonSocial'
import {
 // startSaveUsuario,
 startUpdateUsuario,
} from '../../../redux/actions/usuarioActions'
import { sucursalLoadedByLocalidadID } from '../../../redux/actions/sucursalActions'
import { USERPORTAL } from '../../mantenimiento/Reportes/SeniatParam/constants'

const schema = yup.object().shape({
 tipoDocumento: yup.string().required('Tipo de documento es requerido'),
 numeroDocumento: yup.string().required('Número de documento es requerido'),
 firstName: yup.string().required('El nombre es requerido'),
 lastName: yup.string().required('Los apellidos son requeridos'),
 userAlias: yup.string().required('El alias es requerido'),
 email: yup
  .string()
  .email('El email no es válido')
  .required('El email es requerido'),
 role: yup.string().when('tipoUsuarioYup', {
  is: false,
  then: yup.string().required('El rol es requerido'),
 }),
 userseniat: yup.string(),
 // localidad: yup.string(), // .required('La localidad es requerida'),
 // sucursales: yup.array(), //.min(1, 'La sucursal es requerida'),
})

// const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
// const checkedIcon = <CheckBoxIcon fontSize='small' />

export default function UpdateUsuario({ open, handleClose, title }) {
 const classes = useStyles()
 const dispatch = useDispatch()
 const { roles } = useSelector((state) => state.role)
 const { activeUsuario } = useSelector((state) => state.usuario)
 const { localidades } = useSelector((state) => state.localidad)
 const { sucursalesByLocalidad } = useRazonSocial()
 const [Bysucursales, setBysucursales] = useState(activeUsuario.sucursales)
 const [sucursalesSelect, setsucursalesSelect] = useState([])
 const [addLocalidades, setaddLocalidades] = useState(
  activeUsuario.id_localidad
 )
 const [localidadActiva, setlocalidadActiva] = useState([])

 const [tipousuario, setTipoUsuario] = useState(activeUsuario.masterAdmin)

 // const [localidadNew, setlocalidadNew] = useState([])

 const { handleSubmit, control } = useForm({
  resolver: yupResolver(schema),
  defaultValues: {
   tipoDocumento: activeUsuario.tipoDocumento,
   numeroDocumento: parseInt(activeUsuario.numeroDocumento),
   firstName: activeUsuario.firstName,
   lastName: activeUsuario.lastName,
   userAlias: activeUsuario.userAlias,
   email: activeUsuario.email,
   role: activeUsuario.role,
   localidad: activeUsuario.id_localidad,
   sucursales: activeUsuario.sucursales,
   userseniat: USERPORTAL.filter(
    (item) => item.value === activeUsuario.userPortal
   )[0].value,
  },
 })

 const onSubmitUsusario = (data) => {
  console.log('onSubmitUsusario', data)

  const user = {
   tipoDocumento: data.tipoDocumento,
   numeroDocumento: data.numeroDocumento,
   masterAdmin: tipousuario,
   email: data.email,
   firstName: data.firstName,
   lastName: data.lastName,
   userAlias: data.userAlias,
   role: tipousuario ? undefined : data.role,
   id_localidad: !tipousuario ? addLocalidades : [],
   sucursales: !tipousuario ? Bysucursales : [],
   userPortal: data.userseniat,
  }
  dispatch(startUpdateUsuario(user))
   .then((res) => {
    if (res.payload.tipoMsg === 'sucess') {
     handleClose()
    }
   })
   .catch((error) => {
    console.log(error)
   })
 }

 const handleChange = (e, localidad) => {
  const { checked, name } = e.target
  // console.log('LOCALIDADES handleChange', localidad)
  // console.log('LOCALIDADES handleChange 2', checked, name)
  if (name === 'localidad') {
   checked
    ? setaddLocalidades([...addLocalidades, localidad._id])
    : setaddLocalidades(addLocalidades.filter((item) => item !== localidad._id))
  } else if (name === 'sucursal') {
   checked
    ? setBysucursales([...Bysucursales, localidad.id])
    : setBysucursales(Bysucursales.filter((item) => item !== localidad.id))
  }
 }

 // const handleChange = (data) => {
 //   // const { lstsucursal } = data
 //   setsucursalesSelect('')
 //   console.log('DATA', data)
 //   if (data !== null) {
 //     setBysucursales(data.lstSucursales)
 //     console.log('entro')
 //   }
 //   console.log('sucursalesSelect', sucursalesSelect)
 // }

 // useEffect(() => {
 //   const sucurCarga = () => {
 //     if (localidades.length > 0) {
 //       setBysucursales(
 //         localidades.filter(
 //           (item) => item._id === activeUsuario.id_localidad
 //         )[0]?.lstSucursales || []
 //       )
 //       setlocalidadActiva(
 //         localidades.filter(
 //           (item) => item._id === activeUsuario.id_localidad
 //         )[0]
 //       )
 //       // setTipoUsuario(activeUsuario.masterAdmin)
 //     }
 //   }
 //   sucurCarga()
 //   // console.log('Bysucursales', Bysucursales)
 // }, [activeUsuario.id_localidad, activeUsuario.masterAdmin, localidades])

 // useEffect(() => {
 //   const sucurCargaSelect = () => {
 //     if (localidades.length > 0) {
 //       setsucursalesSelect(
 //         localidades
 //           .filter((item) => item._id === activeUsuario?.id_localidad)[0]
 //           ?.lstSucursales?.filter((item) =>
 //             activeUsuario?.sucursales?.includes(item._id)
 //           ) || []
 //       )
 //     }
 //   }
 //   sucurCargaSelect()
 // }, [activeUsuario?.id_localidad, activeUsuario?.sucursales, localidades])

 const handleChangeSwch = (e) => {
  console.log('e', e.target.name)
  setTipoUsuario(e.target.checked)
 }

 const renderOpciones = (e, local) => {
  console.log('LOCALIDADES handleChange Click', local)
  dispatch(sucursalLoadedByLocalidadID(local._id))
  // setBysucursales(sucursalesByLocalidad)
 }

 // console.log('activeUsuario WWWWW', tipousuario)
 // console.log('activeUsuario WWWWW activeUsuario', activeUsuario)
 // console.log('localidades', localidades)
 // console.log('localidades Activa', localidadActiva)
 // console.log('addLocalidades', addLocalidades)
 // console.log('Bysucursales', Bysucursales)

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form onSubmit={handleSubmit(onSubmitUsusario)} autoComplete='off'>
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='tipoDocumento'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Tipo Documento
         </InputLabel>
         <Select
          fullWidth
          // defaultValue=''
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {tipodoc.map((option) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={option.key}
             value={option.value}
            >
             {option.value}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='numeroDocumento'
       control={control}
       defaultValue=''
       // value: /^[0-9]*$/,

       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <NumberFormat
         format='########'
         customInput={TextField}
         className={classes.text}
         label='Nro. Documento'
         // variant='outlined'
         defaultValue={0}
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='firstName'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Nombres'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='lastName'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Apellidos'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='userAlias'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Alias'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='email'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         type='email'
         className={classes.text}
         label='Correo Electrónico'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       control={control}
       name='userseniat'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Usuario Portal Seniat
         </InputLabel>
         <Select
          fullWidth
          disabled={tipousuario}
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
          // defaultValue={USERPORTAL[0].value}
         >
          {USERPORTAL.map((rol) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={rol.value}
             value={rol.value}
            >
             {rol.label}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='tipoUsuarioYup'
       control={control}
       defaultChecked={tipousuario}
       render={({
        field: { onChange, value, checked },
        fieldState: { error },
       }) => (
        <FormControlLabel
         style={{ margitTop: '8px' }}
         className={classes.switchgenerico}
         labelPlacement='end'
         control={
          // <swtichGenerico defaultChecked/>
          <Switch name='tipoUsuarioYup' defaultChecked={tipousuario} />
         }
         defaultChecked={tipousuario}
         label='Usuario admin Master'
         value={value}
         checked={checked}
         onChange={(e) => {
          onChange(e)
          handleChangeSwch(e)
         }}
        />
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='role'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Rol
         </InputLabel>
         <Select
          fullWidth
          // defaultValue=''
          disabled={tipousuario}
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {roles.map((rol) => {
           return (
            <MenuItem className={classes.sublabel} key={rol.id} value={rol.id}>
             {rol.rol}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>

     {/* asugnar rozon social o localidad */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <InputLabel variant='standard' htmlFor='localidad'>
       Razon Social
      </InputLabel>
      <List dense className={classes.listar} disabled={tipousuario}>
       {localidades
        ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((local) => (
         <ListItem key={local._id}>
          <Button
           disabled={tipousuario}
           name='seleccion'
           onClick={(e) => renderOpciones(e, local)}
          >
           <ListItemText
            disableTypography
            className={classes.listarItem}
            primary={local.nombre}
           />
          </Button>
          <ListItemSecondaryAction>
           <Checkbox
            defaultChecked={activeUsuario.id_localidad.includes(local._id)}
            disabled={tipousuario}
            name='localidad'
            edge='end'
            size='small'
            onChange={(e) => handleChange(e, local)}
           />
          </ListItemSecondaryAction>
         </ListItem>
        ))}
      </List>
     </Grid>
     {/* sucrusakes seleccion */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <InputLabel variant='standard' htmlFor='localidad'>
       Sucursales
      </InputLabel>
      <List dense className={classes.listar} disabled={tipousuario}>
       {sucursalesByLocalidad
        ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((sucur) => (
         <ListItem key={sucur.id}>
          <Button
           disabled={tipousuario}
           name='sucursal'
           // onClick={(e) => renderOpciones(e, sucur)}
          >
           <ListItemText
            disableTypography
            className={classes.listarItem}
            primary={sucur.nombre}
           />
          </Button>
          <ListItemSecondaryAction>
           <Checkbox
            defaultChecked={activeUsuario.sucursales.includes(sucur.id)}
            disabled={tipousuario}
            name='sucursal'
            edge='end'
            size='small'
            onChange={(e) => handleChange(e, sucur)}
           />
          </ListItemSecondaryAction>
         </ListItem>
        ))}
      </List>
     </Grid>
    </Grid>
    {/* botton de accion    */}
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       //  onClick={() => peticionPost()}
      >
       Modificar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
