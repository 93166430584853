import { types } from "../types/types";

const initialState = {
  incoterms: [],
  activeIncoterm: null,
  modalIncotermOpen: false,
  modalIncotermDelete: false,
};

export const incotermReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.incotermOpenModal:
      return {
        ...state,
        modalIncotermOpen: true,
        modalIncotermDelete: false,
      };

    case types.incotermCloseModal:
      return {
        ...state,
        modalIncotermOpen: false,
      };

    case types.incotermOpenModalDelete:
      return {
        ...state,
        modalIncotermOpen: false,
        modalIncotermDelete: true,
      };

    case types.incotermCloseModalDelete:
      return {
        ...state,
        modalIncotermDelete: false,
      };

    case types.incotermSetActive:
      return {
        ...state,
        activeIncoterm: action.payload,
        modalIncotermOpen: true,
      };

    case types.incotermAddNew:
      return {
        ...state,
        incoterms: [action.payload, ...state.incoterms],
      };

    case types.incotermClearActive:
      return {
        ...state,
        activeIncoterm: null,
      };

    case types.incotermUpdated:
      return {
        ...state,
        incoterms: state.incoterms.map((i) =>
          i.id === action.payload.id ? action.payload : i
        ),
      };

    case types.incotermLoaded:
      return {
        ...state,
        incoterms: action.payload,
      };

    case types.incotermDelete:
      return {
        ...state,
        incoterms: state.incoterms.map((i) =>
          i.id === action.payload.id ? action.payload : i
        ),
        activeIncoterm: null,
      };

    case types.incotermReactived:
      return {
        ...state,
        incoterms: state.incoterms.map((i) =>
          i.id === action.payload.id ? action.payload : i
        ),
        activeIncoterm: null,
      };

    default:
      return state;
  }
};
