// import ModalGenerico from '../../../commons/ui/Modal'
// import useStyles from './Styles'
import useStyles from './Styles'

import ViewPdfSeniatNotas from './ViewPdfSeniatNotas'
import ViewJsonNotas from './ViewJsonNotas'
import ModalGenerico from '../../commons/ui/Modal'

export default function ConsultaSeniatNotas({ open, handleClose, title }) {
  const classes = useStyles()
  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      top={60}
      left={60}
    >
      <div className={classes.ViewGenerico}>
        <ViewPdfSeniatNotas />
        <ViewJsonNotas />
      </div>
    </ModalGenerico>
  )
}
