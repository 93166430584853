import React from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import {
  setUpdateFactura,
  setClearlstDetalles,
  // startLoadCanbioDivisa,
} from '../../../../redux/actions/facturaGeneralActions'
import { MODELO_FACTURA } from '../../nota/constants'

import { useStyles } from './Styles'

export default function FacturaServicio() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { periodoFacturacion, pagueAntes, nroContrato, direccionServicio } =
    useRazonSocial()

  const handleChange = (e) => {
    let { name, value } = e.target

    // dispatch(setClearlstDetalles())
    if (name === 'pagueAntes') {
      // const valuepagueAntes = `${value}T00:00:01.001`
      value = `${value}T00:00:01.001`
    }
    dispatch(setUpdateFactura({ name, value }))
    // dispatch(startLoadCanbioDivisa())
  }

  return (
    <Card
      style={{
        height: 247,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        title='Datos para factura de servicio'
        className={classes.CardHeader}
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              required
              label='Nro. Contrato'
              className={classes.texto}
              name='nroContrato'
              size='small'
              fullWidth
              value={nroContrato}
              InputLabelProps={{
                shrink: true,
              }}
              // value={nroFactura}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='pagueAntes'
              id='date'
              label='Pagar Antes del'
              fullWidth
              type='date'
              value={pagueAntes}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handleChange(e)}
              className={classes.texto}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TextField
              required
              name='direccionServicio'
              id='direccionServicio'
              label='Dirección Servicio'
              fullWidth
              value={direccionServicio}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handleChange(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TextField
              required
              name='periodoFacturacion'
              id='periodoFacturacion'
              label='Periodo Facturacion'
              fullWidth
              value={periodoFacturacion}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handleChange(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
