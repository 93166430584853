import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { filtroNumLetras, soloTextyNumeros } from '../../../Helpers/formater'

import {
 Grid,
 TextField,
 MenuItem,
 Select,
 InputLabel,
 FormControl,
 Checkbox,
 FormControlLabel,
 Button,
 Fab,
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import useStyles from './Styles'
import toast from 'react-hot-toast'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
 // phoneRegExp,
 tipodoc,
 tipoProveedor,
} from '../../../Constants/ConstantesFacturas'

import ModalGenerico from '../../commons/ui/Modal'
// import { startLoadEstados } from '../../../redux/actions/estadoActions'
import { useEstados } from '../../../hooks/useEstados'
import { startSaveLocalidad } from '../../../redux/actions/localidadActions'
import { convertBase64 } from '../../../Helpers/formater'
// import { min } from 'date-fns'
// import { $CombinedState } from 'redux'
// import { startSaveProducto } from '../../../redux/actions/productoActions'

const schema = yup.object().shape({
 aerolínea: yup.boolean().default(false).required('Es requerido'),
 contribuyente: yup.boolean().default(false).required('Es requerido'),
 correo: yup
  .string()
  .email('debe ser un correo valido')
  .required('Es requerido'),
 direccion: yup
  .string()
  .max(200, 'Maximo 200 caracteres permitidos, para la descripcion')
  .required('Es requerido'),
 estado: yup.string().required('Es requerido'),
 nombre: yup
  .string()
  .max(100, 'el maximo de caracteres es de 100 ')
  .required('Es requerido'),
 municipio: yup.string().required('Es requerido'),
 numeroDocumento: yup
  .string()
  .test(
   'numeroDocumento',
   'debe ser mininmo 10 catacteres ej: J0000000-0',
   (value) => {
    console.log('values normoeee', value, value.length)

    return value.length >= 9
    // return value.length < 9
   }
  )
  .required('Es requerido'),

 // .min(10, 'el minimo requerido son 10 digitos')
 // // .max(10, 'el maximo requerido son 10 digitos')
 // .required('Es requerido'),
 telefono: yup.string().required('Es requerido'),
 tipoDocumento: yup.string().required('Es requerido'),
 tipoProveedor: yup.string().required('Es requerido'),
 rutaLogo: yup
  .mixed()
  .test('required', 'Debe Selecionar un Logo', (value) => {
   console.log('value', value)
   return value && value.length
  })
  .test('fileSize', 'El archivo es muy Pesado', (value, context) => {
   return value && value[0] && value[0].size <= 200000
  }),
})

export default function AgregarLocalidad({ open, handleClose, title }) {
 const [logo, setlogo] = useState()
 const classes = useStyles()
 const dispatch = useDispatch()
 const { estados, municipio, buscarMunicipio } = useEstados()
 const {
  handleSubmit,
  control,
  register,
  formState: { errors },
 } = useForm({
  resolver: yupResolver(schema),
 })
 // const NumeroDocumento = watch('numeroDocumento')
 // console.log('NumeroDocumento', NumeroDocumento)

 const notifyerror = (res) => toast.error(res)

 const onSubmitLocalidad = async (data) => {
  console.log(' dataeeeeeee', data.tipoProveedorta)
  dispatch(
   startSaveLocalidad({
    nombre: data.nombre,
    // descripcion: data.direccion,
    tipoDocumento: data.tipoDocumento,
    numeroDocumento: data.numeroDocumento,
    id_municipio: data.municipio,
    id_estado: data.estado,
    direccion: data.direccion,
    telefono: data.telefono,
    correo: data.correo,
    contribuyenteEspecial: data.contribuyente,
    // totalRetencion: null,
    aerolinea: data.aerolínea,
    rutaLogo: logo,
    tipoProveedor: data.tipoProveedor,
    // logUsername": "sadmin"
   })
  ).then((resul) => {
   if (resul.payload.tipoMsg === 'sucess') {
    handleClose()
   }
  })
 }

 const uploadImage = async (e) => {
  const file = e.target.files[0]
  console.log('FILE', file)
  if (file.type !== 'image/jpeg') {
   notifyerror(`Solo se Admiten archivos con formato .JPG`)
   return false
  }
  const base64 = await convertBase64(file)
  console.log('base64', base64)
  setlogo(base64)
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='tipoDocumento'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Tipo Doc.
         </InputLabel>
         <Select
          fullWidth
          className={classes.select}
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {tipodoc.map((option) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={option.key}
             value={option.value}
            >
             {option.value}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='numeroDocumento'
       control={control}
       defaultValue=''
       // value: /^[0-9]*$/,

       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <NumberFormat
         format='#########'
         max={10}
         allowLeadingZeros={true}
         customInput={TextField}
         className={classes.text}
         label='Nro. Documento'
         // variant='outlined'
         defaultValue={0}
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='nombre'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Nombre'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         // onChange={(e) =>
         //   onChange(soloTextyNumeros(e.target.value, filtroNumLetras))
         // }
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='estado'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Estados
         </InputLabel>
         <Select
          fullWidth
          name='estados'
          className={classes.select}
          onChange={(e) => {
           buscarMunicipio(e.target.value)
           onChange(e.target.value)
          }}
          value={value}
          error={!!error}
         >
          {estados
           .sort((a, b) => {
            if (a.estado > b.estado) return 1
            if (a.estado < b.estado) return -1
            return 0
           })
           .map((option) => {
            return (
             <MenuItem
              className={classes.sublabel}
              key={option.id_estado}
              value={option.id_estado}
             >
              {option.estado}
             </MenuItem>
            )
           })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='municipio'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Municipios
         </InputLabel>
         <Select
          label='Estado'
          fullWidth
          className={classes.select}
          onChange={onChange}
          value={value}
          error={!!error}
         >
          {municipio
           .sort((a, b) => {
            if (a.municipio > b.municipio) return 1
            if (a.municipio < b.municipio) return -1
            return 0
           })
           .map((option) => {
            return (
             <MenuItem
              className={classes.sublabel}
              key={option._id}
              value={option._id}
             >
              {option.municipio}
             </MenuItem>
            )
           })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='direccion'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         id='outlined-multiline-static'
         className={classes.text}
         // variant='outlined'
         label='Dirección'
         size='small'
         fullWidth
         multiline
         rows={3}
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='telefono'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Teléfono'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />

        // <NumberFormat
        //  customInput={TextField}
        //  format='(###) #######'
        //  className={classes.text}
        //  label='Teléfono'
        //  size='small'
        //  fullWidth
        //  value={value}
        //  onChange={onChange}
        //  error={!!error}
        //  helperText={error ? error.message : 'ej: (424) 2345665'}
        //  placeholder='ej: (424) 2345665'
        // />
       )}
      />
      <Controller
       name='correo'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='correo'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={4} sm={4} xl={12} xs={12}>
      <Controller
       control={control}
       name='tipoProveedor'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Tipo Proveedor
         </InputLabel>
         <Select
          fullWidth
          className={classes.select}
          onChange={onChange}
          value={value}
          error={!!error}
         >
          {tipoProveedor.map((option) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={option.key}
             value={option.value}
            >
             {option.value}
            </MenuItem>
           )
          })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={8} sm={8} xl={12} xs={12}>
      <FormControl style={{ flexDirection: 'row' }} className={classes.radio}>
       <Controller
        name='contribuyente'
        control={control}
        render={({ field }) => (
         <FormControlLabel
          className={classes.formcontrol}
          label='Contribuyente especial'
          component='legend'
          control={
           <Checkbox
            name='contribuyente'
            defaultChecked={false}
            onChange={field.onChange}
            checked={field.value ? true : false}
           />
          }
         />
        )}
       />
       <Controller
        name='aerolínea'
        control={control}
        render={({ field }) => (
         <FormControlLabel
          className={classes.formcontrol}
          label='Aerolínea'
          component='legend'
          control={
           <Checkbox
            name='aerolínea'
            // disabled={exonerado}
            defaultChecked={false}
            onChange={field.onChange}
            checked={field.value ? true : false}
           />
          }
         />
        )}
       />
      </FormControl>
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <label htmlFor='rutaLogo' style={{ width: '100%' }}>
       <input
        // style={{
        //   display: 'none',
        //   // alingItems: 'center',
        //   // width: '100%',
        // }}
        {...register('rutaLogo')}
        type='file'
        id='rutaLogo'
        name='rutaLogo'
        onChange={(e) => uploadImage(e)}
       />
       {/* <Fab
                style={{ alingItems: 'center', width: '100%' }}
                color='secondary'
                size='small'
                component='span'
                aria-label='add'
                variant='extended'
              >
                Subir logo empresa
              </Fab> */}
      </label>

      {errors.rutaLogo && (
       <p style={{ color: 'red' }}>{errors.rutaLogo.message}</p>
      )}
      {/* <Controller
              name='rutaLogo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <label htmlFor='rutaLogo' style={{ width: '100%' }}>
                  <input
                    style={{
                      display: 'none',
                      alingItems: 'center',
                      width: '100%',
                    }}
                    id='rutaLogo'
                    name='rutaLogo'
                    type='file'
                    accept='image/jpeg'
                    // accept='image/*.jpg'
                    value={value}
                    onChange={(e) => uploadImage(e)}
                    error={!!error}
                    // helperText={error ? error.message : null}
                  />

                  <Fab
                    style={{ alingItems: 'center', width: '100%' }}
                    color='secondary'
                    size='small'
                    component='span'
                    aria-label='add'
                    variant='extended'
                  >
                    Subir logo empresa
                  </Fab>
                  <p className={classes.error}>
                    {error ? error.message : null}
                  </p>
                </label>
              )}
            /> */}
     </Grid>
    </Grid>
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       //  onClick={() => peticionPost()}
      >
       Agregar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
