import axios from 'axios'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { errorSetActive, errorStatusLoading } from './errorActions'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { MESES } from '../../components/mantenimiento/Reportes/SeniatParam/constants'

const url = `${config.urlServer}/mantenimiento/reportes`
const urlLogs = `${config.urlServer}/mantenimiento/logEmails`

export const startLoadReportLibroVenta = (libro) => {
  const { fechaInicio, fechaFin, localidad, sucursal } = libro
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/getlibroVentas?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id_localidad=${localidad}&id_sucursal=${sucursal}`,
        'GET'
      )
      console.log('Reporte', res)
      dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}
export const startLoadReportLibroOrdenes = (libro) => {
  const { fechaInicio, fechaFin, localidad, sucursal } = libro
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/getlibroOrdenes?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id_localidad=${localidad}&id_sucursal=${sucursal}`,
        'GET'
      )
      console.log('Reporte', res)
      dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportSemanal = (fechaRango) => {
  const { fechaInicio, fechaFin, email } = fechaRango
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/reportSemanalSeniat?tipoReporte=1&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&email=${email}`,
        'GET'
      )
      return dispatch(
        errorSetActive(
          'Reporte generado con exito, en breve llegara a tu correo',
          'sucess'
        )
      )
      // return dispatch(reporteLoaded(res))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startReporteMensualparams = (reporte) => {
  const {
    anio,
    email,
    selectedMeses,
    localidadesSelect,
    tipoReport,
    valueFormato,
  } = reporte
  let tipoReporteDEF = tipoReport

  const mesEnviar = MESES.filter((item) =>
    selectedMeses.includes(item.label)
  ).map((item) => item.value)

  if (mesEnviar.length > 0 && tipoReport === 2) tipoReporteDEF = 3

  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/reportSemanalSeniat?tipoReporte=${tipoReporteDEF}&anio=${anio}&email=${email}&mes=${
          mesEnviar.length > 0 ? mesEnviar : '0'
        }&rif=${
          localidadesSelect.length > 0 ? localidadesSelect : null
        }&valueFormato=${valueFormato}`,
        'GET'
      )
      dispatch(errorSetActive(res?.msg, 'sucess'))
      // return dispatch(reporteLoaded(res))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

/**
 * Fetches a report of provisional article 28 for a given date range, municipality, store, and email.
 *
 * @param {Object} params - An object containing the following properties:
 *   - {string} fechaInicio - The start date of the report.
 *   - {string} fechaFin - The end date of the report.
 *   - {string} localidad - The municipality of the report.
 *   - {string} sucursal - The store of the report.
 *   - {string} email - The email of the report recipient.
 * @return {Promise} A promise that resolves to the fetched report. If the fetch fails, the promise is rejected with an error message.
 */
export const startLoadReportProvi32Art28 = (params) => {
  const { fechaInicio, fechaFin, rif, email } = params
  return async (dispatch) => {
    try {
      const res = await ApiAxiosPost(
        `${url}/reportPeriodoGlobalSeniat?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&email=${email}`,
        'POST',
        { rifsArray: rif }
      )
      //    return res
      return dispatch(
        errorSetActive(
          'Reporte generado con exito, en breve llegara a tu correo',
          'sucess'
        )
      )

      //    dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startDescargaReportSemanal = () => {
  // const { fechaInicio, fechaFin } = fechaRango
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/descargarReportSemanalSeniat`,
        'GET'
      )
      console.log('Reporte', res)
      return dispatch(reporteLoaded(res))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportGeneral = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/reportSemanalGeneral`, 'GET')
      console.log('Reporte', res)
      return dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportLibroVentaExcel = (libro) => {
  const { fechaInicio, fechaFin, localidad, sucursal } = libro
  console.log('libro', libro)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/getLibroVentasExcel?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id_localidad=${localidad}&id_sucursal=${sucursal}`,
        'GET'
      )
      // console.log("🚀 ~ file: reporteActions.js:41 ~ return ~ res", res)
      return dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportOrdenesExcel = (libro) => {
  const { fechaInicio, fechaFin, localidad, sucursal } = libro
  console.log('libro', libro)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/getOrdenesExcel?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id_localidad=${localidad}&id_sucursal=${sucursal}`,
        'GET'
      )
      // console.log("🚀 ~ file: reporteActions.js:41 ~ return ~ res", res)
      return dispatch(reporteLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportLogs = (logs) => {
  const { fechaInicio, fechaFin, idUsuario } = logs
  console.log('logs FRONTES', logs)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${url}/logs/getLogsByRange?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idUsuario=${idUsuario}`,
        'GET'
      )
      console.log('LOGS ACTION', res)
      // return res
      dispatch(reporteLoadedLogsPdf(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadReportLogEmail = (LogsEmail) => {
  const {
    fechaInicio,
    fechaFin,
    localidad,
    sucursal,
    nroDoc,
    recibidoEmail,
    limit,
    page,
    status,
  } = LogsEmail
  console.log(
    '🚀 ~ file: reporteActions.js:156 ~ startLoadReportLogEmail ~ LogsEmail:',
    LogsEmail
  )

  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${urlLogs}/queryLogEmail?fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&id_localidad=${localidad}&id_sucursal=${sucursal}&nroDoc=${nroDoc}&correoTo=${recibidoEmail}&limit=${limit}&page=${page}&status=${status}`,
        'GET'
      )
      console.log('🚀 ~ file: reporteActions.js:41 ~ return ~ res', res)
      return dispatch(logsEmailLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadLorSendmGrid = (id) => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${urlLogs}/getemailSendgrid?idMMessage=${id}`,
        'GET'
      )
      console.log('🚀 ~ file: reporteActions.js:41 ~ return ~ res', res)
      return dispatch(logsEmailSendgrid(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      console.log(
        '🚀 ~ file: reporteActions.js:193 ~ return ~ error:',
        error.response.data
      )
      return dispatch(
        errorSetActive(
          error?.response?.data?.message?.message ||
            `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

// logsemail
export const logsEmailLoaded = (logsemail) => ({
  type: types.logsEmailLoaded,
  payload: logsemail,
})
export const logsEmaiClear = () => ({
  type: types.logsEmailClear,
})
export const logsEmaiParams = (logsemail) => ({
  type: types.logsEmailparams,
  payload: logsemail,
})
export const logsEmailSendgrid = (logsemailsendgrid) => ({
  type: types.logsEmailSendgrid,
  payload: logsemailsendgrid,
})
export const logsEmaiClearSendgrid = () => ({
  type: types.logsEmaiClearSendgrid,
})

// reportes
export const reporteLoaded = (libro) => ({
  type: types.reporteLoaded,
  payload: libro,
})

export const reporteClear = () => ({
  type: types.reporteClear,
})

export const reporteLoadedLogsPdf = (pdfLogs) => ({
  type: types.reporteLoadedLogsPdf,
  payload: pdfLogs,
})
