import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { errorReducer } from './reducers/errorReducer'
import { authReducer } from './reducers/authReducer'
import { bancoReducer } from './reducers/bancoReducer'
import { divisaReducer } from './reducers/divisaReducer'
import { paisReducer } from './reducers/paisReducer'
import { estadoReducer } from './reducers/estadoReducer'
import { municipioReducer } from './reducers/municipioReducer'
import { localidadReducer } from './reducers/localidadReducer'
import { sucursalReducer } from './reducers/sucursalReducer'
import { categoriaNotaReducer } from './reducers/categoriaNotaReducer'
import { productoReducer } from './reducers/productoReducer'
import { descuentoReducer } from './reducers/descuentoReducer'
import { incotermReducer } from './reducers/incotermsReducer'
import { changeStateReducer } from './reducers/changeStateReducer'
import { tipoGravamenReducer } from './reducers/tipoGravamenReducer'
import { valorGravamenReducer } from './reducers/valorGravamenReducer'
import { tasaReducer } from './reducers/tasaReducer'
import { facturaGeneralReducer } from './reducers/facturaGeneralReducer'
import { facturaCompraReducer } from './reducers/facturaCompraReducer'
import { clienteReducer } from './reducers/clienteReducer'
import { notaReducer } from './reducers/notaReducer'
import { roleReducer } from './reducers/roleReducer'
import { usuarioReducer } from './reducers/usuarioReducer'
import { moduloReducer } from './reducers/moduloReducer'
import { controlReducer } from './reducers/controlReducer'
import { reporteReducer } from './reducers/reporteReducer'
import { logsEmailReducer } from './reducers/logsEmailReducer'
import { vendedorReducer } from './reducers/vendedorReducer'
import { ordenReducer } from './reducers/ordenReducer'

//De esta forma tener todos los reducer combinados y no cada uno por separado, hace más fácil su manejo.
const rootReducer = combineReducers({
 error: errorReducer,
 changeState: changeStateReducer,
 auth: authReducer,
 banco: bancoReducer,
 divisa: divisaReducer,
 pais: paisReducer,
 estado: estadoReducer,
 municipio: municipioReducer,
 localidad: localidadReducer,
 sucursal: sucursalReducer,
 categoriaNota: categoriaNotaReducer,
 producto: productoReducer,
 descuento: descuentoReducer,
 incoterm: incotermReducer,
 tipoGravamen: tipoGravamenReducer,
 valorGravamen: valorGravamenReducer,
 tasas: tasaReducer,
 facturaGeneral: facturaGeneralReducer,
 facturaCompra: facturaCompraReducer,
 cliente: clienteReducer,
 nota: notaReducer,
 role: roleReducer,
 usuario: usuarioReducer,
 modulo: moduloReducer,
 controlFactura: controlReducer,
 reporte: reporteReducer,
 logsemail: logsEmailReducer,
 vendedor: vendedorReducer,
 orden: ordenReducer,
})

//herramientas de desarrollador para poder ver el comportamiento del reducer en diferentes tiempos
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function generateStore() {
 const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
 )

 return store
}
