import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { errorClearActive, errorSetActive } from './errorActions'

const url = `${config.urlServer}`
const URL = `${config.urlServer}/users`
const urlAuth = `${config.urlServer}/auth`

export const startLoadUsuarios = () => {
  console.log(url)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${URL}/allUser`, 'GET')
      console.log('res USERS', res)
      dispatch(usuarioLoaded(res))
      // dispatch(errorClearActive())
    } catch (error) {
      dispatch(
        errorSetActive(
          error?.response?.data.message || 'ocurrio un error de Red'
        )
      )
    }
  }
}

export const startSaveUsuario = (usuario) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      const res = await ApiAxiosPost(`${URL}/addUser`, 'POST', usuario)
      dispatch(usuarioAddNew(res))
      return dispatch(errorSetActive('Usuario Registrado', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || 'ocurrio un error de Red'
        )
      )
    }
  }
}

export const startDeleteUsuario = () => {
  return async (dispatch, getState) => {
    const { activeUsuario } = getState().usuario

    try {
      const res = await ApiAxiosPost(
        `${URL}/ActivaDesactivaUserById/${activeUsuario.id}`,
        'PUT'
      )
      console.log('res ACTIVO DESACTIVO', res)
      dispatch(deleteUsuario(res))
      return dispatch(
        errorSetActive(`Usuario ${res?.logEstatusRegistro}`, 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || 'ocurrio un error de Red'
        )
      )
    }
  }
  //Llamado al endpoint usando Axios
  //   axios({
  //     method: 'PUT',
  //     url: `${URL}/ActivaDesactivaUserById/${activeUsuario.id}`,
  //   })
  //     .then((response) => {
  //       Swal.fire('Inactivado', activeUsuario.nombre, 'success')
  //       dispatch(deleteUsuario(response.data))
  //     })
  //     .catch((error) => {
  //       const msgError = error.response.data.message
  //       console.log(error.response.data.message)
  //       Swal.fire('Error', msgError, 'error')
  //     })
  // }
}

export const startReactivedUsuario = (usuario) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${usuario.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', usuario.nombre, 'success')
        dispatch(reactiveUsuario(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateUsuario = (usuario) => {
  return async (dispatch, getState) => {
    const { activeUsuario } = getState().usuario
    const id = activeUsuario.id

    try {
      const res = await ApiAxiosPost(`${URL}/updateUser/${id}`, 'PUT', usuario)
      dispatch(usuarioUpdated(res))
      return dispatch(
        errorSetActive('Usuario Actualizado con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || 'ocurrio un error de Red'
        )
      )
    }
  }
}

export const resetPasswordUser = (usuario) => {
  const { userID, password } = usuario

  const data = JSON.stringify({
    password: password,
  })

  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios

    try {
      await ApiAxiosPost(
        `${urlAuth}/change-password/?userID=${userID}`,
        'POST',
        data
      )
      return dispatch(
        errorSetActive('Password Actualizado con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || 'ocurrio un error de Red'
        )
      )
    }

    // await ApiAxiosPost(
    //   `${urlAuth}/change-password/?userID=${userID}`,
    //   'POST',
    //   data
    // )
    //   .then(() => {
    //     dispatch(errorSetActive('Password Actualizado con exito !!!', 'sucess'))
    //   })
    //   .catch((error) => {
    //     console.log(
    //       '🚀 ~ file: usuarioActions.js:139 ~ return ~ error:',
    //       error?.response?.data.code
    //     )
    //     return dispatch(
    //       errorSetActive(
    //         error?.response?.data.message || 'ocurrio un error de Red'
    //       )
    //     )
    //   })
  }
}

const usuarioLoaded = (usuarios) => ({
  type: types.usuarioLoaded,
  payload: usuarios,
})

export const usuarioOpenModal = () => ({
  type: types.usuarioOpenModal,
})

export const usuarioCloseModal = () => ({
  type: types.usuarioCloseModal,
})

export const usuarioOpenModalDelete = () => ({
  type: types.usuarioOpenModalDelete,
})

export const usuarioCloseModalDelete = () => ({
  type: types.usuarioCloseModalDelete,
})

export const usuarioAddNew = (usuario) => ({
  type: types.usuarioAddNew,
  payload: usuario,
})

export const usuarioSetActive = (usuario) => ({
  type: types.usuarioSetActive,
  payload: usuario,
})

export const usuarioClearActive = () => ({
  type: types.usuarioClearActive,
})

export const usuarioUpdated = (usuario) => ({
  type: types.usuarioUpdated,
  payload: usuario,
})

export const deleteUsuario = (usuario) => ({
  type: types.usuarioDelete,
  payload: usuario,
})

export const reactiveUsuario = (usuario) => ({
  type: types.usuarioReactived,
  payload: usuario,
})
