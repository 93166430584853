import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import useSWR from 'swr'

// import axios from 'axios'
// import { ApiAxiosGet } from '../../../../Helpers/ApiAxios'

// import { config } from '../../../../config/configProperties'

import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
  Divider,
} from '@material-ui/core'

import useStyles from '../Styles'

import {
  startDescargaReportSemanal,
  startLoadReportGeneral,
  startLoadReportSemanal,
} from '../../../../redux/actions/reporteActions'
// import LoadingCirculo from '../../commons/ui/LoadingCirculo'
import CircularProgress from '@material-ui/core/CircularProgress'
import { s2ab } from '../../../../Helpers/Search'
import {
  Fingerprint,
  DateRangeOutlined,
  CloudCircle,
  Dashboard,
} from '@material-ui/icons'
import { useToast } from '../../../../hooks/useToast'
import ReporteSeniatParams from '../SeniatParam/ReporteSeniatParams'
import { hasPermission } from '../../../../Helpers/AuthPermisos'
import ReporteProvi32Art28 from './ReporteProvi32Art28'
import ReporteProcertSemanal from '../SeniatParam/ReporteProcertSemanal'

// const fetcher = (url) => ApiAxiosGet(url).then((res) => res)

export default function GenerarReporteSemanal() {
  const { roles } = useSelector((state) => state.role)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)

  //  const { notifyAliatorio } = useToast()

  //  const url = `${config.urlServer}/mantenimiento/reportes`

  //  const { data: ReportDescarga, error } = useSWR(
  //   `${url}/descargarReportSemanalSeniat`,
  //   fetcher
  //   //   {
  //   //    refreshInterval: 30000,
  //   //   }
  //  )
  const classes = useStyles()

  const dispatch = useDispatch()
  const [estadoReport, setEstadoReport] = useState({
    fechaInicio: '',
    fechaFin: '',
    email: '',
  })

  // const [loading, setLoading] = useState(true)
  const [loadingExcel, setLoadingExcel] = useState({
    generar: true,
    descargar: true,
  })
  const [tipoReport, setTipoReport] = useState(0)

  //  if (error?.response?.status === 401)
  //   notifyAliatorio('Por favor debe autenticarse')
  //  else if (error !== undefined) notifyAliatorio(error?.message)

  const Verificador = [
    estadoReport.fechaInicio !== '',
    estadoReport.fechaFin !== '',
    estadoReport.email !== '',
    // tipoReport !== null,
  ].some((valor) => {
    return valor === false
  })

  const handleClickGenerarSemanal = (status) => {
    setLoadingExcel((prevState) => ({
      ...prevState,
      generar: status === 1 ? false : true,
      descargar: status === 3 ? false : true,
    }))
    if (status === 1) {
      dispatch(
        startLoadReportSemanal({
          fechaInicio: estadoReport.fechaInicio,
          fechaFin: estadoReport.fechaFin,
          email: estadoReport.email,
        })
      ).then((res) => {
        setLoadingExcel((prevState) => ({ ...prevState, generar: true }))
        // setLoadingExcel(true)
        // handleGenerarXls(res.payload)
      })
    } else if (status === 2) {
      dispatch(startLoadReportGeneral()).then((res) => {
        setLoadingExcel((prevState) => ({ ...prevState, generar: true }))
        // setLoadingExcel(true)
        handleGenerarXls(res.payload)
      })
    } else if (status === 3) {
      dispatch(startDescargaReportSemanal()).then((res) => {
        setLoadingExcel((prevState) => ({ ...prevState, descargar: true }))
        // setLoadingExcel(true)
        handleGenerarXls(res.payload.excelBuffer)
      })
    }
  }

  const handleClickVerLibroVentas = (e) => {
    const { name, value } = e.target
    setEstadoReport({ ...estadoReport, [name]: value })
  }

  const handleGenerarXls = (ReportesXls) => {
    if (ReportesXls) {
      try {
        var ab = s2ab(ReportesXls)
        const blob = new Blob([ab], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        })
        const url = URL.createObjectURL(blob) //some browser may use window.URL
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.href = url
        a.download = !tipoReport
          ? `ReporteSemanal_${estadoReport.fechaInicio}_al_${
              estadoReport.fechaFin
            }_${new Date().getTime()}.xlsx`
          : `ReporteGeneral_${estadoReport.fechaInicio}_al_${
              estadoReport.fechaFin
            }_${new Date().getTime()}.xlsx`
        a.click()

        setTimeout(() => {
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }, 0)
      } catch (error) {
        console.log(
          '🚀 ~ file: GenerarReporteSemanal.js:96 ~ handleGenerarXls ~ error',
          error
        )
      }
    }
  }

  const handleClickReporteProvi0032 = (tipo) => {
    setTipoReport(tipo)
  }

  const handleClickReporte = (tipo) => {
    setTipoReport(tipo)
    setEstadoReport({ ...estadoReport, fechaInicio: '', fechaFin: '' })
  }

  return (
    <Card
      style={{
        minHeight: 550,
        minWidth: 800,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        title='Generar Reporte Seniat'
        className={classes.CardHeader}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid
            container
            spacing={2}
            direction='row'
            alignItems='flex-start'
            alignContent='flex-start'
          >
            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'repProvi0032',
              user.user
            ) && (
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                style={{ marginTop: '14px' }}
              >
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporteProvi0032(1)}
                  aria-label='fingerprint'
                  //  color='secondary'
                  style={{
                    // borderColor: 'red',
                    backgroundColor: '#0074D9',
                    height: '50px',
                  }}
                >
                  <Fingerprint style={{ color: 'white' }} />
                  <Typography variant='subtitle1' style={{ color: 'white' }}>
                    Reporte Providencia 0032 Art. 28
                  </Typography>
                </Button>

                {/* </St  ack> */}
              </Grid>
            )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'repProvi0032art28',
              user.user
            ) && (
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={6}
                style={{ marginTop: '14px' }}
              >
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporteProvi0032(6)}
                  aria-label='fingerprint'
                  //  color='#82bd69'
                  style={{ backgroundColor: '#156410', height: '50px' }}
                >
                  <Fingerprint
                    style={{
                      color: 'white',
                    }}
                  />
                  <Typography
                    variant='subtitle1'
                    style={{ color: 'white', lineHeight: 1 }}
                  >
                    Reporte Contribuyentes Providencia 0032 Art. 28
                  </Typography>
                </Button>
              </Grid>
            )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteSemanal',
              user.user
            ) && (
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporte(2)}
                  aria-label='DateRangeOutlined'
                  //  color='primary'
                  style={{ backgroundColor: '#FF4136', height: '50px' }}
                >
                  <DateRangeOutlined style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }} variant='subtitle1'>
                    Reporte Semanal
                  </Typography>
                </Button>
              </Grid>
            )}
            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteMensual',
              user.user
            ) && (
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporte(4)}
                  aria-label='CloudCircle'
                  style={{ backgroundColor: '#723015', height: '50px' }}
                >
                  <CloudCircle style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }} variant='subtitle1'>
                    Reporte Mensual
                  </Typography>
                </Button>
                {/* </St  ack> */}
              </Grid>
            )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteAnual',
              user.user
            ) && (
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporte(5)}
                  aria-label='CloudCircle'
                  style={{ backgroundColor: ' #852C9B', height: '50px' }}
                >
                  <Dashboard style={{ color: 'white' }} />
                  <Typography style={{ color: 'white' }} variant='subtitle1'>
                    Reporte Anual
                  </Typography>
                </Button>
                {/* </St  ack> */}
              </Grid>
            )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteSemanalProcert',
              user.user
            ) && (
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Button
                  className={classes.button}
                  onClick={() => handleClickReporte(7)}
                  aria-label='DateRangeOutlined'
                  //  color='primary'
                  style={{ backgroundColor: '#9ea3ea', height: '50px' }}
                >
                  <DateRangeOutlined style={{ color: 'black' }} />
                  <Typography style={{ color: 'black' }} variant='subtitle1'>
                    Reporte Semanal Procert
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} justify='space-between'>
            {tipoReport === 0 && (
              <Grid
                item
                lg={12}
                sm={12}
                xl={12}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '300px',
                }}
              >
                <Typography variant='h6'>
                  Selecione el reporte a generar
                </Typography>
              </Grid>
            )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'repProvi0032',
              user.user
            ) &&
              tipoReport === 1 && (
                <Grid
                  container
                  style={{
                    backgroundColor: '#ebedef',
                    marginTop: '24px',
                    marginLeft: '12px',
                    marginRight: '12px',
                    marginBottom: '12px',
                    borderRadius: '8px',
                    padding: '2px 30px 30px 30px',
                  }}
                >
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography variant='h6' align='center'>
                      Reporte Providencia 0032 Art. 28{' '}
                    </Typography>
                    <Divider component={'div'} />
                  </Grid>
                  <Grid item lg={6} sm={6} xl={12} xs={12}>
                    <Divider component={'div'} />
                    <TextField
                      name='fechaInicio'
                      id='date'
                      label='Desde'
                      type='date'
                      fullWidth
                      value={estadoReport.fechaInicio}
                      onChange={(e) => handleClickVerLibroVentas(e)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xl={12} xs={12}>
                    <TextField
                      name='fechaFin'
                      id='date'
                      label='Hasta'
                      type='date'
                      fullWidth
                      value={estadoReport.fechaFin}
                      onChange={(e) => handleClickVerLibroVentas(e)}
                      // defaultValue={new Date().toISOString().substr(0, 10)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: new Date().toISOString().split('T')[0],
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <TextField
                      name='email'
                      id='date'
                      fullWidth
                      label='Correo electronico a enviar reporte'
                      type='email'
                      value={estadoReport.email}
                      onChange={(e) => handleClickVerLibroVentas(e)}
                      // defaultValue={new Date().toISOString().substr(0, 10)}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              )}

            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/reportes/ReportSeniat',
                'repProvi0032',
                user.user
              ) &&
                tipoReport === 1 && (
                  <>
                    <Button
                      size='large'
                      // variant='outlined'
                      onClick={() => handleClickGenerarSemanal(1)}
                      disabled={Verificador}
                      fullWidth
                      className={classes.btn}
                      style={{
                        backgroundColor: Verificador ? '#6c757d' : '#054477',
                        color: 'white',
                        boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
                      }}
                    >
                      <>
                        {loadingExcel ? (
                          <i className='far fa-file-excel'></i>
                        ) : (
                          <CircularProgress size={18} />
                        )}

                        <span style={{ marginLeft: '12px' }}>
                          Generar Reporte por Periodo
                        </span>
                      </>
                    </Button>
                  </>
                )}
            </Grid>

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteSemanal',
              user.user
            ) &&
              tipoReport === 2 && (
                <Grid container spacing={2} justify='space-between'>
                  <ReporteSeniatParams
                    tipoReport={tipoReport}
                    title={'Reporte Semanal'}
                  />
                </Grid>
              )}
            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteMensual',
              user.user
            ) &&
              tipoReport === 4 && (
                <Grid container spacing={2} justify='space-between'>
                  <ReporteSeniatParams
                    tipoReport={tipoReport}
                    title={'Reporte Mensual'}
                  />
                </Grid>
              )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteAnual',
              user.user
            ) &&
              tipoReport === 5 && (
                <Grid container spacing={2} justify='space-between'>
                  <ReporteSeniatParams
                    tipoReport={tipoReport}
                    title={'Reporte Anual'}
                  />
                </Grid>
              )}
            {/* reporte provi0032art28 */}
            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'repProvi0032art28',
              user.user
            ) &&
              tipoReport === 6 && (
                <Grid container spacing={2}>
                  <ReporteProvi32Art28
                    tipoReport={tipoReport}
                    title={'Reporte Contribuyentes Providencia 0032 Art. 28'}
                    // Reporte Providencia 0032 Art. 28'
                  />
                </Grid>
              )}

            {hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/reportes/ReportSeniat',
              'reporteSemanalProcert',
              user.user
            ) &&
              tipoReport === 7 && (
                <Grid container spacing={2} justify='space-between'>
                  <ReporteProcertSemanal
                    tipoReport={tipoReport}
                    title={'Reporte Semanal Procert'}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
