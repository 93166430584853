import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DivisaModal } from './DivisaModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import {
  divisaOpenModal,
  divisaOpenModalDelete,
  divisaSetActive,
  startgetDivisas,
  startLoadDivisas,
  startReactivedDivisa,
} from '../../../redux/actions/divisaActions'
import { DivisaModalDelete } from './DivisaModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'

const Divisa = () => {
  const dispatch = useDispatch()

  const { divisas, modalDivisaDelete } = useSelector((state) => state.divisa)

  const columnas = [
    {
      title: 'Nombre',
      field: 'name',
      defaultSort: 'asc',
    },
    {
      title: 'Siglas',
      field: 'iso',
    },
    {
      title: 'Valor',
      field: 'value',
    },
    {
      title: 'Simbolo',
      field: 'symbol',
      render: (rowData) => (
        <span
          style={{ fontSize: '18px', color: '#ff9800', fontWeight: 'bold' }}
        >
          {rowData.symbol}
        </span>
      ),
    },
    {
      title: 'Fecha Valor',
      field: 'fechaValor',
    },
  ]

  useEffect(() => {
    dispatch(startLoadDivisas())
  }, [dispatch])

  const onUpdateDivisa = (e) => {
    dispatch(divisaSetActive(e))
    dispatch(divisaOpenModal())
  }

  const handleDelete = (e) => {
    dispatch(divisaSetActive(e))
    dispatch(divisaOpenModalDelete())
  }

  const handleReactive = (e) => {
    dispatch(startReactivedDivisa(e))
    dispatch(startLoadDivisas())
  }

  const openModal = () => {
    dispatch(divisaOpenModal())
  }

  const cargarDivisas = () => {
    dispatch(startgetDivisas())
  }

  return (
    <div>
      <br />
      {/* <DivisaModal />
      {modalDivisaDelete && <DivisaModalDelete />} */}
      <MaterialTable
        title='Divisas'
        columns={columnas}
        data={divisas.data}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nueva Divisa',
            isFreeAction: true,
            onClick: (event) => {
              cargarDivisas()
            },
          },
          //   (rowData) => ({
          //     icon: 'edit',
          //     tooltip: 'Modificar',
          //     onClick: (event, rowData) => onUpdateDivisa(rowData),
          //     hidden: rowData.logEstatusRegistro === 'INACTIVO',
          //   }),
          // (rowData) => ({
          //   icon: () => <PowerSettingsNewIcon />,
          //   tooltip: 'Inactivar',
          //   onClick: (event, rowData) => handleDelete(rowData),
          //   hidden: rowData.logEstatusRegistro === 'INACTIVO',
          // }),
          // (rowData) => ({
          //   icon: () => <SettingsPowerIcon />,
          //   tooltip: 'Activar',
          //   onClick: (event, rowData) => handleReactive(rowData),
          //   hidden: rowData.logEstatusRegistro === 'ACTIVO',
          // }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
    </div>
  )
}

export default Divisa
