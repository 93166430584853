import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { errorSetActive, errorStatusLoading } from './errorActions'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { Exposure } from '@material-ui/icons'

const url = `${config.urlServer}/mantenimiento/roles`

export const startLoadRoles = () => {
  console.log(url)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/getroles`, 'GET')
      console.log('Rol', res)
      dispatch(roleLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }

    // axios.get(`${url}/listar`)
    // .then(res => {
    //     dispatch(roleLoaded(res.data));
    // });
  }
}

export const startLoadRolesByModulo = (idModulo) => {
  console.log(url)
  return (dispatch) => {
    axios.get(`${url}/listarByModulo/${idModulo}`).then((res) => {
      dispatch(roleLoaded(res.data))
    })
  }
}

export const startSaveRole = (role) => {
  return async (dispatch, getState) => {
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${url}/addrol`, 'POST', role)
      console.log('RRRRRRRR', res)
      dispatch(roleAddNew(res))
      return dispatch(errorSetActive('Rol registrado', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startDeleteRole = () => {
  return async (dispatch, getState) => {
    const { activeRole } = getState().role

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeRole.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivado', activeRole.nombre, 'success')
        dispatch(deleteRole(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedRole = (role) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${role.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', role.nombre, 'success')
        dispatch(reactiveRole(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateRole = (role) => {
  return async (dispatch, getState) => {
    const { activeRole } = getState().role
    const id = activeRole.id

    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${url}/updaterol/${id}`, 'PUT', role)
      console.log('UPDATE ROL', res)
      dispatch(roleUpdated(res))
      return dispatch(errorSetActive('Rol Actualizado !!!', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

const roleLoaded = (roles) => ({
  type: types.roleLoaded,
  payload: roles,
})

export const roleOpenModal = () => ({
  type: types.roleOpenModal,
})

export const roleCloseModal = () => ({
  type: types.roleCloseModal,
})

export const roleOpenModalDelete = () => ({
  type: types.roleOpenModalDelete,
})

export const roleCloseModalDelete = () => ({
  type: types.roleCloseModalDelete,
})

export const roleAddNew = (role) => ({
  type: types.roleAddNew,
  payload: role,
})

export const roleSetActive = (role) => ({
  type: types.roleSetActive,
  payload: role,
})

export const roleClearActive = () => ({
  type: types.roleClearActive,
})

export const roleClear = () => ({
  type: types.roleClear,
})

export const roleUpdated = (role) => ({
  type: types.roleUpdated,
  payload: role,
})

export const deleteRole = (role) => ({
  type: types.roleDelete,
  payload: role,
})

export const reactiveRole = (role) => ({
  type: types.roleReactived,
  payload: role,
})
