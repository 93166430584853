import { types } from '../types/types'

const initialState = {
  localidades: [],
  activeLocalidad: null,
  modalLocalidadOpen: false,
  modalLocalidadDelete: false,
  modalLocalidadShow: false,
  municipioSelected: false,
  municipioActive: null,
  CtrNrofacturas: [],
}

export const localidadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.localidadOpenModal:
      return {
        ...state,
        modalLocalidadOpen: true,
        modalLocalidadDelete: false,
      }

    case types.localidadCloseModal:
      return {
        ...state,
        modalLocalidadOpen: false,
      }

    case types.localidadOpenModalDelete:
      return {
        ...state,
        modalLocalidadOpen: false,
        modalLocalidadDelete: true,
      }

    case types.localidadCloseModalDelete:
      return {
        ...state,
        modalLocalidadDelete: false,
      }

    case types.localidadOpenModalShow:
      return {
        ...state,
        modalLocalidadShow: true,
        modalLocalidadOpen: false,
        modalLocalidadDelete: false,
      }

    case types.localidadCloseModalShow:
      return {
        ...state,
        modalLocalidadShow: false,
      }

    case types.localidadSetActive:
      return {
        ...state,
        activeLocalidad: action.payload,
        modalLocalidadOpen: true,
      }

    case types.localidadAddNew:
      return {
        ...state,
        localidades: [action.payload, ...state.localidades],
      }

    case types.localidadcuentaTercerosAddNew:
      return {
        ...state,
        localidades: state.localidades.map((localidad) => {
          if (localidad._id === action.payload.creaCuentaTercero.id_localidad) {
            return {
              ...localidad,
              lstCuentaTerceros: [
                action.payload.creaCuentaTercero,
                ...localidad.lstCuentaTerceros,
              ],
            }
          }
          return localidad
        }),
        activeLocalidad: {
          ...state.activeLocalidad,
          lstCuentaTerceros: [
            action.payload.creaCuentaTercero,
            ...state.activeLocalidad.lstCuentaTerceros,
          ],
        },
      }

    case types.localidadcuentaTercerosUpdated:
      return {
        ...state,
        localidades: state.localidades.map((localidad) => {
          if (localidad._id === action.payload.id_localidad) {
            return {
              ...localidad,
              lstCuentaTerceros: localidad.lstCuentaTerceros.map(
                (cuentaTercero) => {
                  if (cuentaTercero._id === action.payload.id) {
                    return {
                      ...cuentaTercero,
                      rifPrestador: action.payload.rifPrestador,
                      nombrePrestador: action.payload.nombrePrestador,
                      domicilioPrestador: action.payload.domicilioPrestador,
                    }
                  }
                  return cuentaTercero
                }
              ),
            }
          }
          return localidad
        }),
        activeLocalidad: {
          ...state.activeLocalidad,
          lstCuentaTerceros: state.activeLocalidad.lstCuentaTerceros.map(
            (cuentaTercero) => {
              if (cuentaTercero._id === action.payload.id) {
                return {
                  ...cuentaTercero,
                  rifPrestador: action.payload.rifPrestador,
                  nombrePrestador: action.payload.nombrePrestador,
                  domicilioPrestador: action.payload.domicilioPrestador,
                }
              }
              return cuentaTercero
            }
          ),
        },
      }

    case types.localidadcuentaTercerosDelete:
      return {
        ...state,
        localidades: state.localidades.map((localidad) => {
          if (localidad._id === action.payload.id_localidad) {
            return {
              ...localidad,
              lstCuentaTerceros: localidad.lstCuentaTerceros.filter(
                (cuentaTercero) => cuentaTercero._id !== action.payload.id
              ),
            }
          }
          return localidad
        }),
        activeLocalidad: {
          ...state.activeLocalidad,
          lstCuentaTerceros: state.activeLocalidad.lstCuentaTerceros.filter(
            (cuentaTercero) => cuentaTercero._id !== action.payload.id
          ),
        },
      }

    case types.localidadClearActive:
      return {
        ...state,
        activeLocalidad: null,
      }

    case types.localidadClear:
      return {
        ...state,
        localidades: [],
      }

    case types.localidadUpdated:
      return {
        ...state,
        localidades: state.localidades.map((d) =>
          d._id === action.payload._id ? action.payload : d
        ),
      }
    case types.certificadoUpdated:
      return {
        ...state,
        localidades: state.localidades.reduce((acc, curr) => {
          if (curr._id === action.payload.id) {
            return acc.concat({
              ...curr,
              keyStoreAlias: action.payload.keyStoreAlias,
              keyStorePassword: action.payload.keyStorePassword,
              keystoreArchivo: action.payload.keystoreArchivo,
            })
          }
          return acc.concat(curr)
        }, []),
      }

    case types.localidadLoaded:
      return {
        ...state,
        localidades: action.payload,
      }

    case types.localidadDelete:
      return {
        ...state,
        localidades: state.localidades.map((d) =>
          d._id === action.payload._id ? action.payload : d
        ),
        activeLocalidad: null,
      }

    case types.localidadReactived:
      return {
        ...state,
        localidades: state.localidades.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeLocalidad: null,
      }

    case types.localidadMunicipioSelected:
      return {
        ...state,
        municipioSelected: true,
        municipioActive: action.payload,
      }

    case types.localidadCargaFacturasJsonSetActive:
      return {
        ...state,
        activeLocalidad: {
          ...state.activeLocalidad,
          cargaFacturasJson: action.payload,
        },
      }

    case types.CtrNrotofacturasLoaded:
      return {
        ...state,
        CtrNrofacturas: action.payload,
      }

    case types.updateAsignaNroFacturasLocalidad:
      return {
        ...state,
        localidades: state.localidades.map((localidad) => {
          if (localidad._id === action.payload.id) {
            return {
              ...localidad,
              asignaNroFacturas: action.payload.asignaNroFacturas,
            }
          }
          return localidad
        }),
      }

    default:
      return state
  }
}
