import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadBancos } from '../redux/actions/bancoActions'
import { startLoadDivisas } from '../redux/actions/divisaActions'

const useDivisaBancos = () => {
  const { divisas, activeDivisa } = useSelector((state) => state.divisa)
  const { bancos } = useSelector((state) => state.banco)
  const dispatch = useDispatch()
  // const [DesBanco, setDesBanco] = useState('no hay banco registrado')

  useEffect(() => {
    dispatch(startLoadDivisas())
    dispatch(startLoadBancos())
  }, [dispatch])

  const buscarBanco = (id) => {
    var banco = bancos.find((b) => b.id === id)
    return banco
  }
  const buscardivisa = (iso) => {
    var divisa = divisas.data.find((b) => b.iso === iso)
    console.log('divisa', divisa)
    return divisa
  }

  return { divisas, bancos, activeDivisa, buscarBanco, buscardivisa }
}

export default useDivisaBancos
