import { useSelector } from 'react-redux'

const useDivisas = () => {
  const { divisas } = useSelector((state) => state.divisa)

  const buscardivisa = (iso) => {
    var divisa = divisas?.data?.find((b) => b.iso === iso)
    return divisa
  }

  return { divisas, buscardivisa }
}
export default useDivisas
