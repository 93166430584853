import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import {
  errorClearActive,
  errorSetActive,
  errorStatusLoading,
} from './errorActions'

const url = `${config.urlServer}/mantenimiento/localidad`

const URL = `${config.urlServer}/mantenimiento/localidad`

export const startSaveLocalidad = (localidad) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${URL}/addlocalidad`, 'POST', localidad)
      console.log('RRRRRRRR', res.result)
      dispatch(localidadAddNew(res.result))
      return dispatch(errorSetActive('Localidad registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startDeleteLocalidad = () => {
  return async (dispatch, getState) => {
    const {
      activeLocalidad,
      activeLocalidad: { _id },
    } = getState().localidad
    console.log(' _id, logEstatusRegistro', _id, activeLocalidad)

    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/inactivarLocalidad/${_id}`,
        'PUT',
        activeLocalidad
      )
      console.log('RRRRRRRR', res.result)
      dispatch(deleteLocalidad(res.result))
      return dispatch(
        errorSetActive(
          res.result.logEstatusRegistro === 'ACTIVO'
            ? 'Localidad fue Activada'
            : 'Localidad Inactiva',
          'sucess'
        )
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startReactivedLocalidad = (localidad) => {
  return async (dispatch) => {
    dispatch(startDeleteLocalidad())
  }
}
export const startUpdateLocalidad = (localidad) => {
  return async (dispatch, getState) => {
    const { activeLocalidad } = getState().localidad
    console.log('activeLocalidad', activeLocalidad)
    const _id = activeLocalidad._id

    try {
      const res = await ApiAxiosPost(
        `${URL}/updatelocalidad/${_id}`,
        'PUT',
        localidad
      )
      console.log('RES UpdateLocalidad,', _id, res.result)
      dispatch(localidadUpdated(res.result))
      return dispatch(errorSetActive('Localidad registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startLoadLocalidades = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${URL}/getlocalidades/`, 'GET')
      dispatch(errorClearActive())
      console.log('RES Localidades,', res)
      dispatch(localidadLoaded(res))
    } catch (error) {
      console.log('ERRORRROR', error)
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startSaveCertficado = (certificado, id) => {
  return async (dispatch, getState) => {
    // const resid = getState().localidad.activeLocalidad._id
    const {
      activeLocalidad: { _id },
    } = getState().localidad
    //Llamado al endpoint usando Axios

    console.log('RES UpdateLocalidad,', _id)
    console.log('IDID', id)

    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/uploadcertificado/${_id}`,
        'PUT',
        certificado
      )
      console.log('RRRRRRRR', res.result)
      // dispatch(localidadAddNew(res.result))
      dispatch(certificadoUpdated(res.result))
      return dispatch(errorSetActive('Certificado registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startActivaenvioEmail = (paramters) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/activarEnvioEmail`,
        'PUT',
        paramters
      )
      // console.log('RRRRRRRR', res.result)
      dispatch(localidadUpdated(res.result))
      return dispatch(
        errorSetActive('Parametros actualizados con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startActivaMarcaAguaLoalidad = (paramters) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/activarMarcaAguaLocalidad`,
        'PUT',
        paramters
      )
      console.log('RRRRRRRR', res.result)
      dispatch(localidadUpdated(res.result))
      return dispatch(
        errorSetActive('Parametros actualizados con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startCargaFacturaXls = (archivo) => {
  return async (dispatch) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${URL}/excelToJson`, 'POST', archivo)
      dispatch(localidadCargaFacturasJsonSetActive(res.result))
      return dispatch(errorSetActive('Archivo Cargado con Exito', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startSaveCuentaTerceros = (cuentaTerceros) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/cuentatercero/addcuentatercero`,
        'POST',
        cuentaTerceros
      )
      // console.log('RRRRRRRR CUENTA TERCEROS', res.result)
      dispatch(cuentaTercerosAddNew(res.result))
      return dispatch(errorSetActive('Cuenta de Terceros registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startupdateCuentaTerceros = (cuentaTerceros) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/cuentatercero/updatecuentatercero/${cuentaTerceros.id}`,
        'PUT',
        cuentaTerceros
      )
      dispatch(cuentaTercerosUpdated(res.result))
      return dispatch(
        errorSetActive('Cuenta de Terceros Actualizada', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startdeleteCuentaTerceros = (id) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/cuentatercero/deletecuentatercero/${id}`,
        'DELETE'
      )
      dispatch(deletecuentatercero(res.result))
      return dispatch(errorSetActive('Cuenta de Terceros eliminada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

const localidadLoaded = (localidades) => ({
  type: types.localidadLoaded,
  payload: localidades,
})

export const localidadOpenModal = () => ({
  type: types.localidadOpenModal,
})

export const localidadCloseModal = () => ({
  type: types.localidadCloseModal,
})

export const localidadOpenModalDelete = () => ({
  type: types.localidadOpenModalDelete,
})

export const localidadCloseModalDelete = () => ({
  type: types.localidadCloseModalDelete,
})

export const localidadOpenModalShow = () => ({
  type: types.localidadOpenModalShow,
})

export const localidadCloseModalShow = () => ({
  type: types.localidadCloseModalShow,
})

export const localidadAddNew = (localidad) => ({
  type: types.localidadAddNew,
  payload: localidad,
})

export const localidadSetActive = (localidad) => ({
  type: types.localidadSetActive,
  payload: localidad,
})

export const localidadClearActive = () => ({
  type: types.localidadClearActive,
})

export const localidadClear = () => ({
  type: types.localidadClear,
})

export const localidadUpdated = (localidad) => ({
  type: types.localidadUpdated,
  payload: localidad,
})

export const certificadoUpdated = (localidad) => ({
  type: types.certificadoUpdated,
  payload: localidad,
})

export const deleteLocalidad = (localidad) => ({
  type: types.localidadDelete,
  payload: localidad,
})

export const reactiveLocalidad = (localidad) => ({
  type: types.localidadReactived,
  payload: localidad,
})

//Acciones para Municipio
export const setMunicipioActive = (municipio) => ({
  type: types.localidadMunicipioSelected,
  payload: municipio,
})

export const localidadCargaFacturasJsonSetActive = (FacturasJson) => ({
  type: types.localidadCargaFacturasJsonSetActive,
  payload: FacturasJson,
})

const cuentaTercerosAddNew = (cuentaTerceros) => ({
  type: types.localidadcuentaTercerosAddNew,
  payload: cuentaTerceros,
})

const cuentaTercerosUpdated = (cuentaTerceros) => ({
  type: types.localidadcuentaTercerosUpdated,
  payload: cuentaTerceros,
})

const deletecuentatercero = (cuentaTerceros) => ({
  type: types.localidadcuentaTercerosDelete,
  payload: cuentaTerceros,
})
