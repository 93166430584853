import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { BancoModal } from './BancoModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import {
  bancoOpenModal,
  bancoOpenModalDelete,
  bancoSetActive,
  startLoadBancos,
  startReactivedBanco,
} from '../../../redux/actions/bancoActions'
import { BancoModalDelete } from './BancoModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'

const Banco = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const { bancos, modalBancoDelete } = useSelector((state) => state.banco)

  const columnas = [
    {
      title: 'Banco',
      field: 'nombre',
      defaultSort: 'asc',

    },
    {
      title: 'Codigo',
      field: 'codigo',
    },
    {
      title: 'Estatus',
      field: 'status',
    },
  ]

//   useEffect(() => {
//     //Llenar la lista de Bancos
//     dispatch(startLoadBancos())
//   }, [dispatch])

  //Editar
//   const onUpdateBanco = (e) => {
//     dispatch(bancoSetActive(e))
//     dispatch(bancoOpenModal())
//   }

//   //Inactivar / Borrar
//   const handleDelete = (e) => {
//     dispatch(bancoSetActive(e))
//     dispatch(bancoOpenModalDelete())
//   }

//   //Reactivar
//   const handleReactive = (e) => {
//     dispatch(startReactivedBanco(e))
//   }

  //Abrir el modal
  const openModal = () => {
    dispatch(bancoOpenModal())
  }

  return (
    <div>
      <br />

      <MaterialTable
        title='Bancos'
        columns={columnas}
        data={bancos}
        // detailPanel={(rowData) => {
        //   return <DatosmunicipiosAsoc estados={rowData} />
        // }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nuevo Estado',
            isFreeAction: true,
            onClick: (event) => {
              openModal()
            },
          },

        //   (rowData) => ({
        //     icon: () => <PowerSettingsNewIcon />,
        //     tooltip: 'Inactivar',
        //     onClick: (event, rowData) => handleDelete(rowData),
        //     hidden: rowData.logEstatusRegistro === 'INACTIVO',
        //   }),
        //   (rowData) => ({
        //     icon: () => <SettingsPowerIcon />,
        //     tooltip: 'Activar',
        //     onClick: (event, rowData) => handleReactive(rowData),
        //     hidden: rowData.logEstatusRegistro === 'ACTIVO',
        //   }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
    </div>
  )
}

export default Banco
