import { useDispatch, useSelector } from 'react-redux'
import {
  CardHeader,
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import useStyles from './Styles'
import {
  NOTA_TIPO,
  NOTA__CREDITO_CATYEGORIA,
  NOTA__DEBITO_CATYEGORIA,
} from './constants'
import { notaDatosAdd } from '../../../redux/actions/notaActions'
import { useState } from 'react'


const DatosGnotas = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [Statetipo, setStateTipo] = useState([])
  const [StateCategoria, setStateCategoria] = useState('')

  const handlChange = (e) => {
    console.log(e)
    const { name, value } = e.target
    console.log('NAMA CHANGE', name, value)
    dispatch(notaDatosAdd({ name, value }))
    switch (name) {
      case 'tipo':
        setStateTipo(() => {
          if (value === 'Debito') {
            return NOTA__DEBITO_CATYEGORIA
          } else if (value === 'Credito') {
            return NOTA__CREDITO_CATYEGORIA
          } else {
            return []
          }
        })
        break
      case 'categoria':
        setStateCategoria(() => Statetipo[value].label)
        dispatch(notaDatosAdd( {name: 'concepto',  value: Statetipo[value].label}))
        break
      case 'concepto':
        setStateCategoria(value)
        break
      default:
        break
    }
  }
  // console.log('Statetipo', Statetipo)
  // console.log('StateCategoria', StateCategoria)
  return (
    <Card className={classes.card}>
      <CardHeader
        title='Datos de la Nota'
        className={classes.cardheader}
        style={{ backgroundColor: 'gray' }}
      />
      <CardContent>
        <Grid container spacing={1} className={classes.root}>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel variant='standard' htmlFor='tipo'>
                Tipo Nota
              </InputLabel>
              <Select
                className={classes.select}
                fullWidth
                name='tipo'
                defaultValue=''
                onChange={(e) => handlChange(e)}
              >
                {NOTA_TIPO.map((option) => {
                  return (
                    <MenuItem
                      className={classes.select}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <FormControl fullWidth>
              <InputLabel variant='standard' htmlFor='categoria'>
                Categoria *
              </InputLabel>
              <Select
                fullWidth
                name='categoria'
                defaultValue=''
                onChange={(e) => handlChange(e)}
                className={classes.select}
              >
                {Statetipo.map((option) => {
                  return (
                    <MenuItem
                      className={classes.select}
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TextField
              name='concepto'
              multiline
              rows={4}
              className={classes.text}
              label='Concepto'
              InputLabelProps={{ shrink: true, required: true }}
              defaultValue={StateCategoria}
              size='small'
              fullWidth
              value={StateCategoria}
              onChange={(e) => handlChange(e)}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              type='date'
              // defaultValue={new Date().toISOString().split('T')[0]}
              name='fechaEmision'
              className={classes.text}
              label='Fecha de emision nota'
              InputLabelProps={{ shrink: true, required: true }}
              size='small'
              fullWidth
              onChange={(e) => handlChange(e)}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='nroNota'
              className={classes.text}
              label='Número de nota'
              size='small'
              fullWidth
              onChange={(e) => handlChange(e)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DatosGnotas
