export const isAuthenticated = (user) => !!user

export const isAllowed = (user, rights) =>
  rights.some((right) => user.rights.includes(right))

export const hasRole = (rutas, permisosRoles, user) => {
  // console.log('permisos PAPA', permisosRoles)
  // console.log('USER PAPA', user)

  if (user?.masterAdmin) return true
  if (permisosRoles?.length === 0 || permisosRoles === undefined) return false

  return permisosRoles.some((role) => rutas.includes(role))
}

export const hasPermission = (permission, ruta, Accion, user) => {
  // console.log('permission', permission)
  // console.log('ruta', ruta)
  // console.log('Accion', Accion)
  // console.log('user', user)

  if (user?.masterAdmin) return true
  if (permission.length === 0) return false
  if (permission[0].modulo.length === 0) return false

  const Modulo = permission[0].modulo.filter((modulo) => modulo.path === ruta)
  // console.log('Modulo', Modulo)

  if (Modulo?.length === 0) return false

  const arcion = Modulo[0].acciones.filter((acc) => acc.key === Accion)
  // console.log('arcion FINAL', arcion[0])
  return arcion[0]?.status
}
