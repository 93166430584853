import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'

const url = `${config.urlServer}/mantenimiento/regiones`

export const startLoadEstados = () => {
  return (dispatch) => {
    axios.get(`${url}/getestados`).then((res) => {
      dispatch(estadoLoaded(res.data.result))
    })
  }
}

const estadoLoaded = (estados) => ({
  type: types.estadoLoaded,
  payload: estados,
})

export const startSaveEstado = (estado) => {
  return async (dispatch, getState) => {
    console.log(estado)
    //Llamado al endpoint usando Axios
    axios({
      method: 'POST',
      url: `${url}/crear`,
      data: estado,
    })
      .then((response) => {
        Swal.fire('Guardado', estado.nombre, 'success')
        dispatch(estadoAddNew(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const estadoAddNew = (estado) => ({
  type: types.estadoAddNew,
  payload: estado,
})

export const startDeleteEstado = () => {
  return async (dispatch, getState) => {
    const { activeEstado } = getState().estado

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeEstado.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivado', response.data.nombre, 'success')
        dispatch(deleteEstado(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedEstado = (estado) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${estado.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', estado.nombre, 'success')
        dispatch(reactiveEstado(response.data))
      })
      .catch((error) => {
        const msgError = error
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateEstado = (estado) => {
  return (dispatch, getState) => {
    const { activeEstado } = getState().estado
    const id = activeEstado.id

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/editar/${id}`,
      data: estado,
    })
      .then((response) => {
        Swal.fire('Actualizado', response.data.nombre, 'success')
        dispatch(estadoUpdated(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const estadoOpenModal = () => ({
  type: types.estadoOpenModal,
})

export const estadoCloseModal = () => ({
  type: types.estadoCloseModal,
})

export const estadoOpenModalDelete = () => ({
  type: types.estadoOpenModalDelete,
})

export const estadoCloseModalDelete = () => ({
  type: types.estadoCloseModalDelete,
})

export const estadoSetActive = (estado) => ({
  type: types.estadoSetActive,
  payload: estado,
})

export const estadoClearActive = () => ({
  type: types.estadoClearActive,
})

export const estadoUpdated = (estado) => ({
  type: types.estadoUpdated,
  payload: estado,
})

export const deleteEstado = (estado) => ({
  type: types.estadoDelete,
  payload: estado,
})

export const reactiveEstado = (estado) => ({
  type: types.estadoReactived,
  payload: estado,
})
