import React, { useRef } from 'react'
import {
 Card,
 CardHeader,
 CardContent,
 // Grid,
 TextField,
 // Container,
 CardActions,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
// import { useSelector } from 'react-redux'
import Autocomplete, {
 createFilterOptions,
} from '@material-ui/lab/Autocomplete'

import { DatosCliente } from './DatosCliente'
import { useCliente } from '../../../hooks/useCliente'
import { useModal } from '../../../hooks/useModal'
import CrearClienteph from './CrearClienteph'
import Skeletonph from '../../commons/ui/skeleton/Skeletonph'

const useStyles = makeStyles((theme) => ({
 root: {
  '& .MuiAutocomplete-inputRoot': {
   fontSize: '12px',
  },
 },

 paper: {
  backgroundColor: 'white',
  padding: '15px',
  borderRadius: 5,
  height: '100%',
  minHeight: '215px',
  opacity: '0.7',
 },
 CardHeader: {
  backgroundColor: '#054477',
  color: 'white',
  padding: '8px',
 },
 cardheader_icons: {
  color: 'white',
  // padding: '8px 8px 8px 0',
 },
}))

function BuscarClienteph() {
 const classes = useStyles()
 const refAuto = useRef()
 const { handleOpen, open, handleClose } = useModal()

 const {
  clienteActive,
  clientes,
  handleInputChange,
  hadleselectCliente,
  clienteSelected,
  loading,
 } = useCliente()

 const filterCliente = createFilterOptions({
  // stringify: (option) => option.identificacionCliente,
  ignoreCase: true,
  matchFrom: 'start',
  limit: 10,
 })

 if (!loading) return <Skeletonph />

 return (
  <Card
   style={{
    minHeight: 315,
    boxShadow:
     '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
   }}
  >
   <CardHeader
    className={classes.CardHeader}
    title='Clientes'
    action={
     <IconButton
      onClick={handleOpen}
      disabled={clienteSelected}
      aria-label='addCliente'
     >
      <PersonAddIcon className={classes.cardheader_icons} />
     </IconButton>
    }
   />
   <CardContent>
    <Autocomplete
     className={classes.root}
     id='search-cliente'
     ref={refAuto}
     size='small'
     filterOptions={filterCliente}
     value={clienteActive}
     name='cliente'
     options={clientes}
     getOptionLabel={(option) => option.numeroDocumento}
     renderOption={(option) => (
      <div style={{ fontSize: '12px' }}>
       <span>
        {option.tipoDocumento}-{option.numeroDocumento.replace(/\s+/g, '')}
       </span>
       / <span>{option.identificacion}</span>
      </div>
     )}
     renderInput={(params) => <TextField {...params} label='Cliente' />}
     onChange={hadleselectCliente}
     onInputChange={handleInputChange}
     selectOnFocus
     clearOnBlur
     clearText='Limpiar'
     noOptionsText='No hay registros'
    />
   </CardContent>
   <CardActions>{clienteSelected && <DatosCliente />}</CardActions>
   {open && (
    <CrearClienteph
     open={open}
     handleClose={handleClose}
     title='Nuevo cliente'
    />
   )}
  </Card>
 )
}

export default BuscarClienteph
