import { types } from "../types/types";

const initialState = {
  descuentos: [],
  activeDescuento: null,
  modalDescuentoOpen: false,
  modalDescuentoDelete: false,
};

export const descuentoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.descuentoOpenModal:
      return {
        ...state,
        modalDescuentoOpen: true,
        modalDescuentoDelete: false,
      };

    case types.descuentoCloseModal:
      return {
        ...state,
        modalDescuentoOpen: false,
      };

    case types.descuentoOpenModalDelete:
      return {
        ...state,
        modalDescuentoOpen: false,
        modalDescuentoDelete: true,
      };

    case types.descuentoCloseModalDelete:
      return {
        ...state,
        modalDescuentoDelete: false,
      };

    case types.descuentoSetActive:
      return {
        ...state,
        activeDescuento: action.payload,
        modalDescuentoOpen: true,
      };

    case types.descuentoAddNew:
      return {
        ...state,
        descuentos: [action.payload, ...state.descuentos],
      };

    case types.descuentoClearActive:
      return {
        ...state,
        activeDescuento: null,
      };

    case types.descuentoUpdated:
      return {
        ...state,
        descuentos: state.descuentos.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
      };

    case types.descuentoLoaded:
      return {
        ...state,
        descuentos: action.payload,
      };

    case types.descuentoDelete:
      return {
        ...state,
        descuentos: state.descuentos.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeDescuento: null,
      };

    case types.descuentoReactived:
      return {
        ...state,
        descuentos: state.descuentos.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeDescuento: null,
      };

    default:
      return state;
  }
};
