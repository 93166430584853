import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";

import Swal from "sweetalert2";
import {
  descuentoCloseModal,
  descuentoClearActive,
  startSaveDescuento,
  startUpdateDescuento,
} from "../../../redux/actions/descuentoActions";
import { startLoadLocalidades } from "../../../redux/actions/localidadActions";
import { startLoadSucursales, startLoadSucursalesByLocalidad } from "../../../redux/actions/sucursalActions";

const initDescuento = {
  titulo: "",
  descripcion: "",
  tipoMonto: -1,
  monto: "",
  sucursal: -1,
  localidad: -1,
  logUsername: "sadmin",
};

Modal.setAppElement("#root");

export const DescuentoModal = () => {
  const dispatch = useDispatch();

  const { activeDescuento, modalDescuentoOpen } = useSelector(
    (state) => state.descuento
  );
  const { localidades } = useSelector((state) => state.localidad);
  const { sucursalesByLocalidad } = useSelector((state) => state.sucursal);

  const [formValues, setFormValues] = useState(initDescuento);

  const { titulo, descripcion, tipoMonto, monto, sucursal, localidad } = formValues;

  useEffect(() => {
    dispatch(startLoadLocalidades());
    dispatch(startLoadSucursales());
  }, [dispatch]);

  useEffect(() => {
    if (activeDescuento) {
      setFormValues(activeDescuento);
    } else {
      setFormValues(initDescuento);
    }
  }, [activeDescuento, setFormValues]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleLocalidadChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
    
    //Llamada al action
    dispatch(startLoadSucursalesByLocalidad( e.target.value ))
  };

  const closeModal = () => {
    dispatch(descuentoCloseModal());
    dispatch(descuentoClearActive());
    setFormValues(initDescuento);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (titulo.trim().length < 2) {
      return Swal.fire(
        "Error",
        "El título debe contener al menos 2 caracteres",
        "error"
      );
    } else if (descripcion.trim().length < 10) {
      return Swal.fire(
        "Error",
        "La descripción debe contener al menos 10 caracteres" ,
        "error"
      );
    }

    if (activeDescuento) {
      dispatch(startUpdateDescuento(formValues));
    } else {
      dispatch(
        startSaveDescuento({
          ...formValues,
        })
      );
    }

    closeModal();
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: "100%",
    },
    modal: {
      background: "white",
      borderRadius: "5px",
      color: "rgb(51, 51, 51)",
      maxHeight: "620px",
      maxWidth: "600px",
      outline: "none",
      padding: "10px",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-25%",
      transform: "translate(-50%, -50%)",
    },
  }));

  const styles = useStyles();

  return (
    <Modal
      isOpen={modalDescuentoOpen}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName="modal-fondo"
    >
      {activeDescuento ? <h3>Editar Descuento</h3> : <h3>Nuevo Descuento</h3>}

      <hr />
      <form className="container" onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="titulo" className="form-label mb-0">
              Título
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Título"
              name="titulo"
              autoComplete="off"
              value={titulo}
              onChange={handleInputChange}
              required={true}
            />
          </div>
          </div>
          <div className="form-group row">
          <div className="col-md-12">
            <label htmlFor="descripcion" className="form-label mb-0">
              Descripción
            </label>
            <textarea
              className="form-control"
              id="descripcion"
              rows="2"
              value={ descripcion }
              onChange={handleInputChange}
              name="descripcion"
              required={true}
            ></textarea>
          </div>
          </div>
          <div className="form-group row">
            <div className="col-md-6">
              <label htmlFor="tipoMonto" className="form-label mb-0">
                Tipo Monto
              </label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm"
                label="tipoMonto"
                id="tipoMonto"
                value={tipoMonto}
                onChange={handleInputChange}
                name="tipoMonto"
                required={true}
              >
                <option defaultValue="Seleccione...">Seleccione...</option>
                <option key="0" value="0">
                  Bs.
                </option>
                <option key="1" value="1">
                  %
                </option>
                <option key="2" value="2">
                  UT
                </option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="monto" className="form-label mb-0">
                Monto
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Monto"
                name="monto"
                autoComplete="off"
                value={monto}
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </div>
          <div className="form-group row mb-5">
            <div className="col-md-6">
              <label htmlFor="localidad" className="form-label mb-1">
                Razón Social
              </label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm"
                label="localidad"
                id="localidad"
                onChange={ handleLocalidadChange }
                name="localidad"
                value={ (activeDescuento)? activeDescuento.sucursal.localidad.id : localidad }
              >
                <option defaultValue="Seleccione...">Seleccione...</option>
                {localidades.map((loc) => (
                  <option key={loc.id} value={loc.id}>
                    {loc.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="sucursal" className="form-label mb-1">
                Sucursal
              </label>
              <select
                className="form-select form-select-sm"
                aria-label=".form-select-sm"
                label="sucursal"
                id="sucursal"
                value={ (activeDescuento)? activeDescuento.sucursal.id : sucursal }
                onChange={handleInputChange}
                name="sucursal"
              >
                <option defaultValue="Seleccione...">Seleccione...</option>
                {sucursalesByLocalidad.map((suc) => (
                  <option key={suc.id} value={suc.id}>
                    {suc.direccion}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button type="submit" className="btn btn-outline-primary btn-block">
            <i className="far fa-save"></i>
            <span> Guardar</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
