import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento/descuento`;

export const startSaveDescuento = (descuento) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: descuento
        })
            .then((response) => {
                
                Swal.fire('Guardado', descuento.titulo, 'success');
                dispatch(descuentoAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeleteDescuento = () => {

    return async (dispatch, getState) => {
       
        const { activeDescuento } = getState().descuento;
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeDescuento.id}`
        })
            .then((response) => {
                
                Swal.fire('Inactivado', response.data.titulo, 'success');
                dispatch(deleteDescuento(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedDescuento = (descuento) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${descuento.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', descuento.titulo, 'success');
                dispatch(reactiveDescuento(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateDescuento = (descuento) => {

    return (dispatch, getState) => {

        const { activeDescuento } = getState().descuento;
        const id = activeDescuento.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: descuento
        })
            .then((response) => {
                
                Swal.fire('Actualizado', activeDescuento.titulo, 'success');
                dispatch(descuentoUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}

export const startLoadDescuentos = () => {
    return (dispatch) => {

        axios.get(`${url}/listar`)
        .then(res => {
            dispatch(descuentoLoaded(res.data));
        });
    }
}

const descuentoLoaded = (descuentos) => ({
    type: types.descuentoLoaded,
    payload: descuentos
});

export const descuentoOpenModal = () => ({
    type: types.descuentoOpenModal
});

export const descuentoCloseModal = () => ({
    type: types.descuentoCloseModal
});

export const descuentoOpenModalDelete = () => ({
    type: types.descuentoOpenModalDelete
});

export const descuentoCloseModalDelete = () => ({
    type: types.descuentoCloseModalDelete
});

export const descuentoAddNew = ( descuento ) => ({

    type: types.descuentoAddNew,
    payload: descuento
});

export const descuentoSetActive = ( descuento ) => ({
    type: types.descuentoSetActive,
    payload: descuento
});

export const descuentoClearActive = ( ) => ({
    type: types.descuentoClearActive,
   
});

export const descuentoUpdated = ( descuento ) => ({
    type: types.descuentoUpdated,
    payload: descuento
   
});

export const deleteDescuento = (descuento) => ({
    type: types.descuentoDelete,
    payload: descuento
});

export const reactiveDescuento = ( descuento ) => ({
    type: types.descuentoReactived,
    payload: descuento
});