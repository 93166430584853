// import MaterialTable from 'material-table'
import { columnas } from './elementos'
import useOrden from '../../../hooks/useOrden'
import {
 Table,
 TableHead,
 TableRow,
 TableCell,
 TableBody,
 TableSortLabel,
} from '@material-ui/core'
import { EnhancedTableToolbar } from './funcionesTable'
import useStyles from './Styles'
import MenuActions from './menuactions'
import MostrarPdf from './MostrarPdf'
import AnularOrden from './anularoden'
import { Pagination } from '@material-ui/lab'
import { useSelector } from 'react-redux'
import { useToast } from '../../../hooks/useToast'

export default function Ordenes() {
 const { isError, tipoMsg } = useSelector((state) => state.error)
 const { notifyError, notifysucess } = useToast()

 const classes = useStyles()

 const {
  ordenes,
  pagination,
  openAnularV,
  openView,
  openFiltro,
  setPagination,
  setOpenView,
  handleCloseView,
  handleordenActive,
  setopenAnularV,
  handlecloseAnularV,
  handleclosefiltro,
  hnadleOpenFiltro,
  activeOrden,
  goToBase,
 } = useOrden()

 return (
  <div style={{ width: '100%', marginTop: '20px', backgroundColor: 'white' }}>
   <EnhancedTableToolbar
    title={`Ordenes/Guias (${ordenes?.totalResults || 0})`}
    handleclosefiltro={handleclosefiltro}
    hnadleOpenFiltro={hnadleOpenFiltro}
    openFiltro={openFiltro}
    goToBase={goToBase}
   />
   <Table id='datos' className={classes.table}>
    <TableHead className={classes.tableHeader}>
     <TableRow>
      {columnas?.map((column) => (
       <TableCell
        key={column.field}
        align={column.numeric ? 'rigth' : 'center'}
        padding={column.disablePadding ? 'none' : 'default'}
        sortDirection={
         pagination.orderBy === column.field ? pagination.order : false
        }
       >
        <TableSortLabel
         active={pagination.orderBy === column.field}
         direction={pagination.order}
         onClick={() => {
          setPagination({
           ...pagination,
           orderBy: column.field,
           order: pagination.order === 'asc' ? 'desc' : 'asc',
          })
          console.log('🚀 ~ Ordenes ~ column.field:', column.field)
         }}
        >
         {column.title}
        </TableSortLabel>
       </TableCell>
      ))}
      <TableCell>Acciones</TableCell>
     </TableRow>
    </TableHead>
    <TableBody
     className={classes.tableBody}
     style={{ height: '400px', overflowY: 'auto' }}
    >
     {ordenes?.results?.map((row) => (
      <TableRow key={row.id}>
       {columnas?.map((column) => (
        <TableCell
         key={column.field}
         style={{ fontSize: '10px', padding: '4px' }}
        >
         {column.render ? column.render(row) : row[column.field]}
        </TableCell>
       ))}
       <TableCell>
        <MenuActions
         rowData={row}
         setopenAnularV={setopenAnularV}
         setOpenView={setOpenView}
         handlecloseAnularV={handlecloseAnularV}
         handleordenActive={handleordenActive}
         openAnularV={openAnularV}
        />
       </TableCell>
      </TableRow>
     ))}
    </TableBody>
   </Table>
   <div
    style={{
     display: 'flex',
     justifyContent: 'flex-end',
     width: '100%',
     alignItems: 'right',
     margin: '10px 0px 10px 0px',
     padding: '10px 0px 0px 0px',
    }}
   >
    <Pagination
     defaultPage={pagination.pageIndex}
     count={ordenes?.totalPages}
     onChange={(_event, newPage) => {
      setPagination({ ...pagination, pageIndex: newPage })
     }}
     size='medium'
     color='primary'
     showFirstButton
     showLastButton
     siblingCount={1}
     boundaryCount={1}
     shape='rounded'
     variant='outlined'
     page={pagination.pageIndex}
    />
   </div>
   {openView && (
    <MostrarPdf
     open={openView}
     handleClose={handleCloseView}
     title='Vista de Odenes en pdf'
     activeOrden={activeOrden}
    />
   )}

   {openAnularV && (
    <AnularOrden
     open={openAnularV}
     handleClose={handlecloseAnularV}
     title='Anular Ordenes/Guias'
     activeOrden={activeOrden}
    />
   )}

   {isError && tipoMsg === 'sucess'
    ? notifysucess()
    : tipoMsg === 'error' && notifyError()}
  </div>
 )
}
