import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Card, CardHeader } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  media: {
    minHeight: 155,
  },
}))

export default function Skeletonph({ action = true }) {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        title={
          <Skeleton
            animation='wave'
            height={30}
            width='80%'
            // style={{ marginBottom: 6 }}
          />
        }
        // subheader={<Skeleton animation='wave' height={10} width='40%' />}
        action={
          action ? (
            <Skeleton
              animation='wave'
              variant='circle'
              width={40}
              height={40}
              style={{ marginTop: 6 }}
            />
          ) : null
        }
      />
      <Skeleton animation='wave' variant='rect' className={classes.media} />
    </Card>
  )
}
