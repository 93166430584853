import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  // InputLabel,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@material-ui/core'

import useStyles from './Styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useRazonSocial } from '../../../hooks/useRazonSocial'
import {
  startLoadReportLibroOrdenes,
  startLoadReportLibroVenta,
  startLoadReportLibroVentaExcel,
  startLoadReportOrdenesExcel,
} from '../../../redux/actions/reporteActions'
// import LoadingCirculo from '../../commons/ui/LoadingCirculo'
import CircularProgress from '@material-ui/core/CircularProgress'
import { s2ab } from '../../../Helpers/Search'

export default function GenerarLibroOrdenes() {
  // const { Reporte } = useSelector((state) => state.reporte)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [estadoReport, setEstadoReport] = useState({
    fechaInicio: '',
    fechaFin: '',
  })
  const [loading, setLoading] = useState(true)
  const [loadingExcel, setLoadingExcel] = useState(true)

  const {
    handleselectSucursal,
    HandleselectLocalidad,
    localidadActive,
    sucursalActive,
    localidades,
    sucursalesByLocalidad,
  } = useRazonSocial()

  const Verificador = [
    estadoReport.fechaInicio !== '',
    estadoReport.fechaFin !== '',
    localidadActive !== null,
    // sucursalActive === null,
  ].some((valor) => {
    return valor === false
  })

  const handleClickGenerarLibroVentas = (status) => {
    if (status === 1) {
      setLoading(false)
      dispatch(
        startLoadReportLibroOrdenes({
          fechaInicio: estadoReport.fechaInicio,
          fechaFin: estadoReport.fechaFin,
          localidad: localidadActive?._id,
          sucursal: sucursalActive?.id || '',
        })
      ).then((res) => setLoading(true))
    } else if (status === 2) {
      console.log("🚀 ~ handleClickGenerarLibroVentas ~ status:", status)
      setLoadingExcel(false)
      dispatch(
        startLoadReportOrdenesExcel({
          fechaInicio: estadoReport.fechaInicio,
          fechaFin: estadoReport.fechaFin,
          localidad: localidadActive?._id,
          sucursal: sucursalActive?.id || '',
        })
      ).then((res) => {
        setLoadingExcel(true)
        handleGenerarXls(res.payload)
      })
    }
  }

  const handleClickVerLibroVentas = (e) => {
    const { name, value } = e.target
    setEstadoReport({ ...estadoReport, [name]: value })
  }

  const handleGenerarXls = (ReportesXls) => {
    if (ReportesXls.libro) {
      var ab = s2ab(ReportesXls?.libro?.libroExcel)
      const blob = new Blob([ab], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      })
      const url = URL.createObjectURL(blob) //some browser may use window.URL
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `Ordenes_Guias_${new Date().getTime()}.xlsx`
      a.click()

      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }
  }

  return (
    <Card
      style={{
        minHeight: 315,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        title='Generar Reporte Ordenes/Guias'
        className={classes.CardHeader}
      />
      <CardContent>
        <Grid container spacing={0}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Autocomplete
              size='medium'
              id='localidad'
              clearText='Limpiar'
              noOptionsText='No hay Localidades'
              options={localidades}
              getOptionLabel={(option) => option.nombre || ''}
              value={localidadActive}
              onChange={HandleselectLocalidad}
              // filterSelectedOptions
              renderOption={(option) => {
                return <h6 className={classes.texto}>{option.nombre}</h6>
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.root}
                  variant='standard'
                  label='Razón Social'
                />
              )}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            {sucursalesByLocalidad.length > 0 && (
              <Autocomplete
                size='medium'
                id='sucursal'
                // disableClearable
                noOptionsText='No hay Sucursales Asociadas'
                options={sucursalesByLocalidad}
                getOptionLabel={(option) => option.nombre || ''}
                value={sucursalActive}
                onChange={handleselectSucursal}
                filterSelectedOptions
                renderOption={(option) => {
                  return <h6 className={classes.texto}>{option.nombre}</h6>
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.root}
                    variant='standard'
                    label='Sucursal'
                  />
                )}
              />
            )}
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='fechaInicio'
              id='date'
              label='Desde'
              type='date'
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handleClickVerLibroVentas(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='fechaFin'
              id='date'
              label='Hasta'
              type='date'
              onChange={(e) => handleClickVerLibroVentas(e)}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // variant='outlined'
              onClick={() => handleClickGenerarLibroVentas(1)}
              disabled={Verificador}
              fullWidth
              className={classes.btn}
            >
              <>
                {loading ? (
                  <i className='far fa-save'></i>
                ) : (
                  <CircularProgress size={18} />
                )}

                <span style={{ marginLeft: '12px' }}> Generar Reporte</span>
              </>
            </Button>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // variant='outlined'
              onClick={() => handleClickGenerarLibroVentas(2)}
              disabled={Verificador}
              fullWidth
              className={classes.btn}
            >
              <>
                {loadingExcel ? (
                  <i className='far fa-file-excel'></i>
                ) : (
                  <CircularProgress size={18} />
                )}

                <span style={{ marginLeft: '12px' }}>
                  {' '}
                  Generar hoja de calculo
                </span>
              </>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
