import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'

export const useLibroVenta = () => {
  const { Reporte } = useSelector((state) => state.reporte)
  const [newAcumuladoGravamenTerceros, setNewAcumuladoGravamenTerceros] =
    useState([])

  const traerTerceros = useCallback(async () => {
    const AcumuladoImporteTerceros = Reporte?.libro?.AcumuladoImporteTerceros
    const AcumuladoGravamenTerceros = Reporte?.libro?.AcumuladoGravamenTerceros

    const newAcumuladoGravamenTerceros = await AcumuladoImporteTerceros?.reduce(
      (acc, obj1) => {
        const etiqueta = Object.keys(obj1)[0]

        const obj2 = AcumuladoGravamenTerceros.find(
          (obj2) => Object.keys(obj2)[0] === `${etiqueta}`
        )

        if (obj2) {
          obj2[`${etiqueta}`] = { ...obj2, ...obj1[`${etiqueta}`] }
        }

        return acc
      },
      AcumuladoGravamenTerceros
    )
    setNewAcumuladoGravamenTerceros(newAcumuladoGravamenTerceros)
  }, [
    Reporte?.libro?.AcumuladoGravamenTerceros,
    Reporte?.libro?.AcumuladoImporteTerceros,
  ])

  useEffect(() => {
    traerTerceros()
  }, [traerTerceros])

  return {
    newAcumuladoGravamenTerceros,
  }
}
