import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadClientes } from '../redux/actions/clienteActions'
import {
  setClienteActive,
  setClienteClearActive,
} from '../redux/actions/facturaGeneralActions'

export const useCliente = () => {
  const { clienteSelected, clienteActive } = useSelector(
    (state) => state.facturaGeneral
  )

  const { clientes } = useSelector((state) => state.cliente)
  const dispatch = useDispatch()
  const [loading, setloading] = useState(true)

  // const [value] = useState(clientes[0])

  useEffect(() => {
    dispatch(startLoadClientes())
    // setloading(false)
  }, [dispatch])

  const hadleselectCliente = (e, value, reason) => {
    if (reason !== 'clear') {
      dispatch(setClienteActive(value))
    }
  }

  const handleInputChange = (e, value, reason) => {
    if (reason === 'clear') {
      dispatch(setClienteClearActive())
    }
 
 
  }
  return {
    loading,
    clienteActive,
    clientes,
    handleInputChange,
    hadleselectCliente,
    clienteSelected,
  }
}
