import { useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import useStyles from './Styles'
// import { ConsultaNroFacturaAuto } from '../../../redux/actions/controlFacturaActions'
import NumberFormat from 'react-number-format'
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined'
import CheckIcon from '@material-ui/icons/Check'
import { startSaveNroFacturaAuto } from '../../../redux/actions/controlFacturaActions'

// const schema = yup.object().shape({
//   // es requrdio si cuentaTercero es true
//   CantidadNro: yup.string().nullable(),
// })

export default function ParamsNroFactura() {
  // const { activeLocalidad, CtrNrofacturas } = useSelector(
  //   (state) => state.localidad
  // )
  const { activeSucursal, CtrNrofacturas } = useSelector(
    (state) => state.sucursal
  )

  const classes = useStyles()
  const dispatch = useDispatch()
  // const { handleSubmit, control } = useForm()
  const [values, setvalues] = useState(
    activeSucursal?.asignaNroFacturas === 'INACTIVO' ? false : true
  )
  const [CtrNrotofacturas, setCtrNrotofacturas] = useState({
    id_sucursal: activeSucursal?.id,
    cantidadFacturas: CtrNrofacturas?.cantidadFacturas || 0,
    nroFacturaActual: CtrNrofacturas?.nroFacturaActual || 0,
    nroFacturaDesde: CtrNrofacturas?.nroFacturaDesde || 0,
    nroFacturaHasta: CtrNrofacturas?.nroFacturaHasta || 0,
    serie: CtrNrofacturas?.serie || ' ',
    asignaNroFacturas: 'INDIVIDUAL',
    tipoAsigna: CtrNrofacturas?.asignaNroFacturas === 'GLOBAL' ? true : false,
  })

  const onSubmitParamtrosNro = async () => {
    // e.preventDefault()
    console.log('ENTRO AL SUMIT values', { values })

    setCtrNrotofacturas((prevState) => ({
      ...prevState,
      id_sucursal: activeSucursal?.id,
    }))

    console.log('ENTRO AL SUMIT', CtrNrotofacturas)

    // CtrNrotofacturas.asignaNroFacturas = 'INDIVIDUAL'
    dispatch(startSaveNroFacturaAuto(CtrNrotofacturas))

    // console.log(' dataeeeeeee', e, activeLocalidad._id)
  }

  const handleChange = (e) => {
    const { name, checked, value } = e.target
    console.log(name, checked)
    if (name === 'activafacturaAuto') {
      setvalues(checked)
      if (!checked) {
        setCtrNrotofacturas((prevState) => ({
          ...prevState,
          asignaNroFacturas: 'INACTIVO',
        }))
        // dispatch(startSaveNroFacturaAuto(CtrNrotofacturas))
      } else {
        setCtrNrotofacturas((prevState) => ({
          ...prevState,
          asignaNroFacturas: prevState.tipoAsigna ? 'GLOBAL' : 'INDIVIDUAL',
        }))
      }
    } else if (name === 'tipoAsigna') {
      setCtrNrotofacturas((prevState) => ({
        ...prevState,
        [name]: checked,
        asignaNroFacturas: checked ? 'GLOBAL' : 'INDIVIDUAL',
      }))
    } else {
      setCtrNrotofacturas((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  // useEffect(() => {
  //   if (activeLocalidad?.asignaNroFacturas) {
  //     dispatch(ConsultaNroFacturaAuto(activeLocalidad._id, 'id_localidad'))
  //   }
  // }, [activeLocalidad, dispatch])

  // console.log(
  //   'activeLocalidad BIENE DE nrofactura activeSucursal',
  //   activeSucursal,
  //   CtrNrofacturas
  // )

  return (
    // <form onSubmit={handleSubmit(onSubmitParamtrosNro)}>
    <>
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={10} sm={10} xl={12} xs={12}>
          <FormControlLabel
            style={{ margitTop: '8px' }}
            className={classes.switchgenerico}
            labelPlacement='end'
            defaultValue={activeSucursal?.asignaNroFacturas}
            defaultChecked={
              activeSucursal?.asignaNroFacturas === 'INACTIVO' ? false : true
            }
            control={<Switch />}
            name='activafacturaAuto'
            label='Activar Nro. Factura Automatico'
            value={values}
            checked={values}
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <IconButton
            // className={classes.btn}
            onClick={onSubmitParamtrosNro}
            size='small'
            // type='submit'
            variant='contained'
            color='primary'
            fullWidth
            //  onClick={() => peticionPost()}
          >
            {CtrNrofacturas ? <CheckIcon /> : <AddCircleOutlineOutlined />}
          </IconButton>
        </Grid>
      </Grid>

      {values && (
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              name='serie'
              className={classes.text}
              label='Serie'
              defaultValue={CtrNrofacturas?.serie || ''}
              size='small'
              fullWidth
              InputProps={{
                inputProps: { maxlength: 2 },
              }}
              value={CtrNrotofacturas.serie}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <NumberFormat
              name='cantidadFacturas'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Cantidad'
              defaultValue={CtrNrofacturas?.cantidadFacturas || 0}
              size='small'
              fullWidth
              value={CtrNrotofacturas.cantidadFacturas}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <NumberFormat
              name='nroFacturaActual'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Actual'
              // variant='outlined'
              defaultValue={CtrNrofacturas?.nroFacturaActual || 0}
              size='small'
              fullWidth
              value={CtrNrotofacturas.nroFacturaActual}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <NumberFormat
              name='nroFacturaDesde'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Desde'
              // variant='outlined'
              size='small'
              fullWidth
              defaultValue={CtrNrofacturas?.nroFacturaDesde || 0}
              value={CtrNrotofacturas.nroFacturaDesde}
              onChange={(e) => handleChange(e)}
              // helperText={error ? error.message : null}
            />
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <NumberFormat
              name='nroFacturaHasta'
              allowLeadingZeros={true}
              customInput={TextField}
              className={classes.text}
              label='Hasta'
              // variant='outlined'
              size='small'
              fullWidth
              defaultValue={CtrNrofacturas?.nroFacturaHasta || 0}
              value={CtrNrotofacturas.nroFacturaHasta}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
