import React, { useEffect, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
 Grid,
 TextField,
 Button,
 Divider,
 FormControl,
 FormLabel,
 RadioGroup,
 FormControlLabel,
 Radio,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import toast, { Toaster } from 'react-hot-toast'
import { CalcularImporte } from './vendedor/Helper'

import ModalGenerico from '../../../commons/ui/Modal'

import { useDispatch, useSelector } from 'react-redux'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import { startLoadProductosByLocalidadByID } from '../../../../redux/actions/productoActions'
import {
 startAddProductDetalle,
 // startNewDetalle,
} from '../../../../redux/actions/facturaGeneralActions'
import { FormatedBolivares } from '../../../../Helpers/formater'
// import { errorClearActive } from '../../../../redux/actions/errorActions'
// import { useToast } from '../../../../hooks/useToast'
import { startAddProductDetalleNota } from '../../../../redux/actions/notaActions'
// import { startLoadSucursalesByLocalidad } from '../../../../redux/actions/sucursalActions'

const useStyles = makeStyles((theme) => ({
 container: {
  maxWidth: '400px',
  alignItems: 'center',
  '& .MuiInputBase-input': {
   fontSize: '14px',
   color: '#01579b',
   alignItems: 'center',
   // margin: '0 24px 0 24px',
  },
 },
 labelprecio: {
  fontSize: '12px',
  width: '100%',
  color: '#0000008A',
  textAlign: 'right',
 },
 precio: {
  fontSize: '12px',
  width: '100%',
  color: '#01579b',
  textAlign: 'right',
 },

 textproduct: {
  width: 350,
 },
 btn: {
  // color: theme.palette.getContrastText('#3949AB'),
  marginTop: '24px',
  width: '100%',
  padding: '6px 12px',
  borderRadius: 10,
 },
 formControl: {
  width: '100%',
  // margin: '0 24px 0 24px',
  fontSize: '8px',
  color: '#01579b',
  textAlign: 'center',
  alignItems: 'end',
 },
 radioGroup: {
  '& .MuiAutocomplete-inputRoot': {
   fontSize: '12px',
   color: '#01579b',
  },
  '& .MuiFormControlLabel-label': {
   fontSize: '12px',
   color: '#01579b',
  },
  '& .MuiSvgIcon-root': {
   fontSize: 16,
  },
 },
 formLabel: {
  '& .MuiFormLabel-root': {
   fontSize: '10px',
   color: '#01579b',
  },
 },
}))

const AgregarProducto = ({ open, handleClose, title, notaIdLocalidad }) => {
 const classes = useStyles()
 const { productosByLocalidad } = useSelector((state) => state.producto)
 const { facturaDivisa } = useSelector((state) => state.facturaGeneral)
 const [valorDescuento, setvalorDescuento] = useState('')
 const [ImportTotal, setImportTotal] = useState(0)

 const dispatch = useDispatch()
 const { localidadActive, localidadSelected } = useRazonSocial()
 const [valueProducto, setvalueProducto] = useState(null)
 const [valueCantidad, setvalueCantidad] = useState(1)
 const [tipoDescuento, setTipoDescuento] = useState('sindescuento')

 useEffect(() => {
  if (localidadSelected) {
   dispatch(startLoadProductosByLocalidadByID(localidadActive._id))
  }
 }, [dispatch, localidadActive, localidadSelected])

 useEffect(() => {
  if (notaIdLocalidad) {
   dispatch(startLoadProductosByLocalidadByID(notaIdLocalidad))
  }
 }, [dispatch, notaIdLocalidad])

 const handleChangeProducto = (_, producto) => {
  setvalueProducto(producto)
  actualizaImporte(producto, valueCantidad)
 }

 const handleChangeCantidad = (e) => {
  setvalueCantidad(e.target.value < 0 ? (e.target.value = 0) : e.target.value)
  actualizaImporte(valueProducto, e.target.value)
 }

 const handleAddProduct = () => {
  if (notaIdLocalidad !== undefined) {
   dispatch(
    startAddProductDetalleNota(
     valueProducto,
     valueCantidad,
     facturaDivisa,
     tipoDescuento,
     valorDescuento
    )
   )
  } else {
   dispatch(
    startAddProductDetalle(
     valueProducto,
     valueCantidad,
     facturaDivisa,
     tipoDescuento,
     valorDescuento
    )
   )
  }
 }

 const actualizaImporte = (producto, cantidad) => {
  let valor = 0

  if (facturaDivisa === 'USD') valor = producto?.divisaPrecio
  if (facturaDivisa === 'VEF') valor = producto?.precio
  if (facturaDivisa === 'PTR') valor = producto?.precioPtr
  if (facturaDivisa === 'EUR') valor = producto?.precioEur
  setImportTotal(valor * cantidad)
 }

 const handleChangeDescuento = (event) => {
  setTipoDescuento(event.target.value)
  setvalorDescuento(0)
  actualizaImporte(valueProducto, valueCantidad)
 }

 const manejarValoracion = (e) => {
  if (e.target.value.length === 9) {
   return
  }

  if (Number(e.target.value) > 100 && tipoDescuento === 'porcentaje') {
   return
  }

  setvalorDescuento(e.target.value)

  setImportTotal(
   CalcularImporte(
    facturaDivisa,
    tipoDescuento,
    valueProducto,
    Number(e.target.value), // valorDescuento,
    valueCantidad
   )
  )
 }


 return (
  <>
   {/* <Toaster position='top-center' reverseOrder={false} /> */}
   <ModalGenerico open={open} handleClose={handleClose} title={title}>
    {/* {isError && tipoMsg === 'error' ? <div>{notifyError()} </div> : null} */}
    <Grid container spacing={2} className={classes.container}>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Autocomplete
       size='medium'
       id='producto'
       clearText='Limpiar'
       noOptionsText='No hay Productos'
       options={productosByLocalidad}
       getOptionLabel={(option) => option.descripcion || ''}
       value={valueProducto}
       onChange={handleChangeProducto}
       // filterSelectedOptions
       renderOption={(option) => {
        return <h6>{option.descripcion}</h6>
       }}
       renderInput={(params) => (
        <TextField
         {...params}
         // className={classes.textproduct}
         variant='standard'
         label='Productos'
        />
       )}
      />
     </Grid>
     <Grid item lg={4} sm={4} xl={4} xs={4}>
      <TextField
       fullWidth
       className={classes.textcantidad}
       variant='standard'
       label='Cantidad'
       type='number'
       InputProps={{
        inputProps: { min: 1 },
       }}
       size='small'
       value={valueCantidad}
       onChange={handleChangeCantidad}
      />
     </Grid>
     <Grid item lg={8} sm={8} xl={8} xs={8}>
      <FormControl component='fieldset' className={classes.formControl}>
       <FormLabel component={'legend'} className={classes.formLabel}>
        Descuento
       </FormLabel>
       <RadioGroup
        className={classes.radioGroup}
        row
        aria-label='descuento'
        name='Descuento'
        value={tipoDescuento}
        onChange={handleChangeDescuento}
       >
        <FormControlLabel
         disabled={valueProducto === null ? true : false}
         value='sindescuento'
         control={<Radio color='#01579b' size='small' />}
         label='Sin Desc.'
        />
        <FormControlLabel
         disabled={valueProducto === null ? true : false}
         value='porcentaje'
         control={<Radio size='small' color='#01579b' />}
         label='%'
        />
        <FormControlLabel
         disabled={valueProducto === null ? true : false}
         value='monto'
         control={<Radio size='small' color='#01579b' />}
         label='Monto'
        />
       </RadioGroup>
      </FormControl>
     </Grid>

     <Grid item lg={4} sm={4} xl={4} xs={4}>
      {tipoDescuento !== 'sindescuento' && (
       <TextField
        fullWidth
        className={classes.textcantidad}
        variant='standard'
        label='Descuento'
        size='small'
        //   type='number'
        inputProps={{
         type: 'number',
         inputMode: 'numeric', // NO FUNCIONO QUITAR LOS BOTONES  DE SUBIR Y BAJAR
        }}
        //   InputProps={{ inputMode: 'numeric' }}
        value={valorDescuento}
        //   error={!valido}
        //   helperText={valido ? '' : ErrorMessage}
        onChange={manejarValoracion}
       />
      )}
     </Grid>

     <Grid item lg={4} sm={4} xl={4} xs={4}>
      <label className={classes.labelprecio}>Precio Unitario</label>
      <label className={classes.precio}>
       {facturaDivisa === 'USD' &&
        FormatedBolivares(valueProducto?.divisaPrecio, 'es-ES', 'USD')}
       {facturaDivisa === 'VEF' &&
        FormatedBolivares(valueProducto?.precio, 'es-ES', 'VEF')}
       {facturaDivisa === 'PTR' &&
        FormatedBolivares(valueProducto?.PrecioPtr, 'es-ES', 'PTR')}
       {facturaDivisa === 'EUR' &&
        FormatedBolivares(valueProducto?.PrecioEur, 'es-ES', 'EUR')}
      </label>
     </Grid>

     <Grid item lg={4} sm={4} xl={4} xs={4}>
      <label className={classes.labelprecio}>Precio Total</label>
      <label className={classes.precio}>
       {facturaDivisa === 'USD' &&
        FormatedBolivares(
         ImportTotal,
         // tipoDescuento === 'monto'
         //  ? valueProducto?.divisaPrecio * valueCantidad - valorDescuento
         //  : tipoDescuento === 'sindescuento'
         //  ? valueProducto?.divisaPrecio * valueCantidad
         //  : (valueProducto?.divisaPrecio -
         //     (valueProducto?.divisaPrecio * valorDescuento) / 100) *
         //    valueCantidad,
         'es-ES',
         'USD'
        )}
       {facturaDivisa === 'VEF' &&
        FormatedBolivares(
         ImportTotal,
         // tipoDescuento === 'monto'
         //  ? valueProducto?.precio * valueCantidad - valorDescuento
         //  : tipoDescuento === 'sindescuento'
         //  ? valueProducto?.precio * valueCantidad
         //  : (valueProducto?.precio -
         //     (valueProducto?.precio * valorDescuento) / 100) *
         //    valueCantidad,
         'es-ES',
         'VEF'
        )}
       {facturaDivisa === 'PTR' &&
        FormatedBolivares(
         ImportTotal,
         // tipoDescuento === 'monto'
         //  ? valueProducto?.PrecioPtr * valueCantidad - valorDescuento
         //  : tipoDescuento === 'sindescuento'
         //  ? valueProducto?.precioPtr * valueCantidad
         //  : (valueProducto?.PrecioPtr -
         //     (valueProducto?.PrecioPtr * valorDescuento) / 100) *
         //    valueCantidad,
         'es-ES',
         'PTR'
        )}
       {facturaDivisa === 'EUR' &&
        FormatedBolivares(
         ImportTotal,
         // tipoDescuento === 'monto'
         //  ? valueProducto?.PrecioEur * valueCantidad - valorDescuento
         //  : tipoDescuento === 'sindescuento'
         //  ? valueProducto?.precioEur * valueCantidad
         //  : (valueProducto?.PrecioEur -
         //     (valueProducto?.PrecioEur * valorDescuento) / 100) *
         //    valueCantidad,
         'es-ES',
         'EUR'
        )}
      </label>
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Divider component={'div'} />
     </Grid>
     {/* <Grid container spacing={1} className={classes.container}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Divider component={'div'} />
      </Grid>

      <Grid item lg={6} sm={6} xl={12} xs={12}>
       %
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       Monto
      </Grid>
     </Grid> */}
    </Grid>

    <Grid container spacing={1}>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       onClick={() => handleAddProduct()}
      >
       Agregar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       // onClick={() => cerrarModal({ type: 'ABRIR_MODAL' })}
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </ModalGenerico>
  </>
 )
}
export default AgregarProducto
