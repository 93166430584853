import React, { createElement } from 'react'
import { useSelector } from 'react-redux'
import { Route, Navigate, useLocation } from 'react-router-dom'
// import { useUserState } from '../Context/UserContext'
const TOKEN_KEY = 'token_sigece'

export function RequireAuth({ children }) {
  const { isAuthenticated } = useSelector((state) => state.auth)
  let location = useLocation()
  // console.log('ETRO A RequireAuth', isAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />
  }
  return children
}

export function PublicRouteAuth({ children }) {
  const { isAuthenticated } = useSelector((state) => state.auth)
  let location = useLocation()
  // console.log('ETRO A public', location)

  if (isAuthenticated) {
    return <Navigate to='/' state={{ from: location }} />
  }

  return children
}

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token)
}
export function getToken() {
  return localStorage.getItem(TOKEN_KEY)
}
export function deleteToken() {
  console.log('entro a eliminar token')
  localStorage.removeItem(TOKEN_KEY)
}

export function verificaNavegador() {
  let sBrowser,
    sUsrAg = navigator.userAgent

  if (sUsrAg.indexOf('Chrome') > -1) return (sBrowser = 'Chrome')
  if (sUsrAg.indexOf('Firefox') > -1) return (sBrowser = 'Firefox')
  if (sUsrAg.indexOf('Opera') > -1) return (sBrowser = 'Opera')
  if (sUsrAg.indexOf('MSIE') > -1) return (sBrowser = 'Explorer')
  // eslint-disable-next-line no-unused-vars
  if (sUsrAg.indexOf('Safari') > -1) return (sBrowser = 'Safari')
}

/**
 * Creates an anchor element `<a></a>` with
 * the base64 pdf source and a filename with the
 * HTML5 `download` attribute then clicks on it.
 * @param  {string} pdf
 * @return {void}
 */
export function downloadPDF(pdf) {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')

  const fileName = 'Libro_de_ventas.pdf'

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}
export function downloadPDFOrdenes(pdf) {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')

  const fileName = 'Ordenes/Guias.pdf'

  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}
