import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    // height: '22px',
    '& .MuiAutocomplete-inputRoot': {
      fontSize: '12px',
      color: '#01579b',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '10px',
      color: '#01579b',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      // marginTop: 0,
    },
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '12px',
    ' & .MuiCardHeader-title': {
      fontSize: '18px',
    },
  },
  cardheader_icons: {
    color: 'white',
    // padding: '8px 8px 8px 0',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  texto: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  switchgenerico: {
    marginTop: '8px',
    '& .MuiTypography-body1': {
      marginLeft: '8px',
      fontSize: '14px',
    },
  },
  radio: {
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: 'blue',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
}))
