import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { facturaCompraOpenDetalleModal, startDeleteDetalle } from '../../../redux/actions/facturaCompraActions';
import { DetalleFacturaCompraModal } from './DetalleFacturaCompraModal';


const DetalleFacturaCompra = () => {

  const dispatch = useDispatch();

  const { lstDetalles } = useSelector(state => state.facturaCompra);

  
    const useStyles = makeStyles((theme) => ({
        root: {
          "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(32),
            height: theme.spacing(16)
          }
        },
        paper: {
          backgroundColor: "white",
          padding: "15px",
          borderRadius: 5,
          opacity: "0.7",
        },
        title: {
            fontWeight:'bold',
            fontSize: "18px"
        },
        headers: {
            textAlign:"center",
            fontWeight:'bold',
        }
      }));

      const classes = useStyles();

      const agregarItem = (e) => {
        e.preventDefault();
        dispatch(facturaCompraOpenDetalleModal());
      }

      const handleDelete = (index) => {
       dispatch(startDeleteDetalle(index));
      }
    
    return (
        
        <div>
         <DetalleFacturaCompraModal />
          <Paper elevation={2} className={classes.paper}>
         
            <div className="row">
                <h6 className={classes.title}>Detalle</h6>
            </div>
            <div className="form-group row mb-2">
              <div className="col-md-4">
                  <span className={classes.headers}>Concepto</span>
              </div>
              <div className="col-md-2 text-lg-center">
                  <span className={classes.headers}>Cantidad</span>
              </div>
              <div className="col-md-2 text-lg-right">
              <span className={classes.headers}>Precio</span>
              </div>
              <div className="col-md-2 text-lg-right">
              <span className={classes.headers}>Importe</span>
              </div>
              <div className="col-md-2">
              
              </div>
            </div>
            {lstDetalles.map((det, i) => (
                       
                       <div key={i} className="form-group row">
                       <div className="col-md-4">
                           { det.producto }
                       </div>
                       <div className="col-md-2 text-lg-center">
                          { det.cantidad }
                       </div>
                       <div className="col-md-2 text-lg-right">
                       { det.precio }
                       </div>
                       <div className="col-md-2 text-lg-right">
                       { det.importe }
                       </div>
                       <div onClick= { () => handleDelete(i) } className="detalle__delete col-md-2 text-lg-center">
                         <i className="fas fa-trash-alt"></i>
                       </div>
                     </div> 
                       ))
            }
            
            <button
               className="btn btn-outline-primary btn-block btn-md mt-2"
               onClick={ agregarItem }
            >
                <i className="fas fa-cart-plus"></i>
                <span className="ml-2"> Agregar producto</span>
            </button>
        </Paper>
        </div>
    )
}

export default DetalleFacturaCompra;
