import { types } from '../types/types'

const initialState = {
  Reporte: null,
  reporteLogPdf: null,
  isLoadingReporte: true,
}

export const reporteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.reporteLoaded:
      return {
        ...state,
        Reporte: action.payload,
        isLoadingReporte: true,
      }

    case types.reporteClear:
      return {
        ...state,
        Reporte: null,
        reporteLogPdf: null,
        isLoadingReporte: false,
      }
    case types.reporteLoadedLogsPdf:
      return {
        ...state,
        reporteLogPdf: action.payload,
      }

    default:
      return state
  }
}
