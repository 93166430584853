import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadLocalidades } from '../redux/actions/localidadActions'
import {
 sucursalLoadedByLocalidadID,
 sucursalClearByLocalidadph,
} from '../redux/actions/sucursalActions'

import {
 setLocalidadActive,
 setLocalidadClearActive,
 setSucursalActive,
 setSucursalClearActive,
 setTipoVentaActive,
 setClearlstDetalles,
 setNrofactura,
 facturaClearVendedor,
} from '../redux/actions/facturaGeneralActions'
import { setVendedoresClearActive } from '../redux/actions/vendedorActions'

export const useRazonSocial = () => {
 const dispatch = useDispatch()
 const { localidades } = useSelector((state) => state.localidad)
 const { sucursalesByLocalidad } = useSelector((state) => state.sucursal)
 const {
  localidadActive,
  localidadSelected,
  sucursalSelected,
  sucursalActive,
  tipoVentaSelected,
  tipoVentaActive,
  nroFactura,
  facturaDivisa,
  cambioDivisa,
  modeloFactura,
  cuentaTerceros,
  nombrePrestador,
  rifPrestador,
 } = useSelector((state) => state.facturaGeneral)

 useEffect(() => {
  dispatch(startLoadLocalidades())
 }, [dispatch])

 const HandleselectLocalidad = (_, option) => {
  // console.log('locao>>>>', option._id)
  if (option) {
   dispatch(setLocalidadActive(option))
   dispatch(sucursalLoadedByLocalidadID(option._id))
   dispatch(setSucursalClearActive())
   dispatch(setClearlstDetalles())
   dispatch(setVendedoresClearActive())
   dispatch(facturaClearVendedor())
  } else {
   dispatch(setLocalidadClearActive())
   dispatch(setSucursalClearActive())
   dispatch(sucursalClearByLocalidadph())
   dispatch(setClearlstDetalles())
  }
 }

 const handleselectSucursal = (_, sucu) => {
  if (sucu) {
   // console.log('sucu', sucu)
   // setValueSucursal(sucu)
   dispatch(setSucursalActive(sucu))
   dispatch(setVendedoresClearActive())
   dispatch(facturaClearVendedor())
  } else {
   dispatch(setSucursalClearActive())
   dispatch(sucursalClearByLocalidadph())
  }
 }

 const handleChangeTipoventa = (e) => {
  // console.log('handleChangeTipoventa', e.target.value)
  // setTipoVenta(e.target.value)
  dispatch(setTipoVentaActive(e.target.value))
 }

 const handleChangenroFactura = (e) => {
  dispatch(setNrofactura(e.target.value))
 }

 return {
  handleChangeTipoventa,
  handleselectSucursal,
  HandleselectLocalidad,
  handleChangenroFactura,
  localidades,
  sucursalesByLocalidad,
  localidadActive,
  localidadSelected,
  sucursalSelected,
  sucursalActive,
  tipoVentaSelected,
  tipoVentaActive,
  nroFactura,
  facturaDivisa,
  cambioDivisa,
  modeloFactura,
  cuentaTerceros,
  nombrePrestador,
  rifPrestador,
 }
}
