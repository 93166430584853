import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { errorSetActive, errorStatusLoading } from './errorActions'

const url = `${config.urlServer}/mantenimiento/tipoGravamen`

const URL = `${config.urlServer}/mantenimiento/gravamen`

/**
 *  Selecionar el Gramen Activo
 * @param {Gravemen} SelectGravamen
 * @returns
 */
export const startSetActiveTipoGravamenes = (SelectGravamen) => {
  return (dispatch, getState) => {
    const { tiposGravamenes } = getState().tipoGravamen

    const tiGravamenSelected = tiposGravamenes.find(
      (b) => b.id === SelectGravamen.value
    )
    dispatch(tipoGravamenSetActive(tiGravamenSelected))
  }
}

export const startLoadTiposGravamenes = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${URL}/querygravemen/`, 'GET')
      console.log('GRAVAMEN', res)

      dispatch(tipoGravamenLoaded(res.results))
    } catch (error) {
      //   console.log('ERRORRROR GRAVAMEN', error)
      // dispatch(errorSetActive(error.response.data.message))
      dispatch(
        errorSetActive(
          error?.response?.data.message ||
            'Ocurrio un error al Despelgar productos',
          'error'
        )
      )
    }
  }
}

export const startSaveTipoGravamen = (tipoGravamen) => {
  return async (dispatch) => {
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${URL}/addgravemen`, 'POST', tipoGravamen)
      console.log('RRRRRRRR', res.result)
      dispatch(tipoGravamenAddNew(res.result))
      return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startDeleteTipoGravamen = () => {
  return async (dispatch, getState) => {
    const { activeTipoGravamen } = getState().tipoGravamen
    console.log('ACTIVETIPOGRAVAMEN', activeTipoGravamen)

    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/ActDesGravamen`,
        'PUT',
        activeTipoGravamen
      )
      console.log('RRRRRRRR', res.result)
      dispatch(deleteTipoGravamen(res.result))
      console.log(res.result)
      return dispatch(errorSetActive('Tipo de Gravamen Actualizada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }

    //Llamado al endpoint usando Axios
    // axios({
    //   method: 'PUT',
    //   url: `${url}/eliminar/${activeTipoGravamen.id}`,
    // })
    //   .then((response) => {
    //     Swal.fire('Inactivado', activeTipoGravamen.concepto, 'success')
    //     dispatch(deleteTipoGravamen(response.data))
    //   })
    //   .catch((error) => {
    //     const msgError = error.response.data.message
    //     console.log(error.response.data.message)
    //     Swal.fire('Error', msgError, 'error')
    //   })
  }
}

export const startReactivedTipoGravamen = (tipoGravamen) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${tipoGravamen.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', tipoGravamen.concepto, 'success')
        dispatch(reactiveTipoGravamen(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateTipoGravamen = (tipoGravamen) => {
  return (dispatch, getState) => {
    const { activeTipoGravamen } = getState().tipoGravamen
    const id = activeTipoGravamen.id

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/editar/${id}`,
      data: tipoGravamen,
    })
      .then((response) => {
        Swal.fire('Actualizado', response.data.concepto, 'success')
        dispatch(tipoGravamenUpdated(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

const tipoGravamenLoaded = (tiposGravamenes) => ({
  type: types.tipoGravamenLoaded,
  payload: tiposGravamenes,
})

export const tipoGravamenOpenModal = () => ({
  type: types.tipoGravamenOpenModal,
})

export const tipoGravamenCloseModal = () => ({
  type: types.tipoGravamenCloseModal,
})

export const tipoGravamenOpenModalDelete = () => ({
  type: types.tipoGravamenOpenModalDelete,
})

export const tipoGravamenCloseModalDelete = () => ({
  type: types.tipoGravamenCloseModalDelete,
})

export const tipoGravamenAddNew = (tipoGravamen) => ({
  type: types.tipoGravamenAddNew,
  payload: tipoGravamen,
})

export const tipoGravamenSetActive = (tipoGravamen) => ({
  type: types.tipoGravamenSetActive,
  payload: tipoGravamen,
})

export const tipoGravamenClearActive = () => ({
  type: types.tipoGravamenClearActive,
})

export const tipoGravamenUpdated = (tipoGravamen) => ({
  type: types.tipoGravamenUpdated,
  payload: tipoGravamen,
})

export const deleteTipoGravamen = (tipoGravamen) => ({
  type: types.tipoGravamenDelete,
  payload: tipoGravamen,
})

export const reactiveTipoGravamen = (tipoGravamen) => ({
  type: types.tipoGravamenReactived,
  payload: tipoGravamen,
})
