import { useSelector } from 'react-redux'
import { useState } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import useStyles from './Styles'
// import { useLibroVenta } from '../../../hooks/useLibroVenta'

export default function ResumenLibroOrdenes() {
  const { Reporte } = useSelector((state) => state.reporte)
  console.log('🚀 ~ ResumenLibroOrdenes ~ Reporte:', Reporte)
  const classes = useStyles()

  const excel =
    Reporte?.libro?.libroExcel !== undefined ||
    Reporte?.libro?.consolidado === 0
      ? 'No hay ordenes para generar el excel'
      : 'Debe generar el excel, para ver totales de ordenes'

  if (Reporte === null || Reporte?.libro?.consolidado === 0) {
    return (
      <Card
        style={{
          overflowY: 'scroll',
          height: 360,
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <CardHeader title='Resumen' className={classes.CardHeader} />
        <CardContent>
          <Grid container spacing={0}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography variant='h5' align='center'>
                {excel}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  if (Reporte?.libro?.consolidado > 0) {
    return (
      <Card
        style={{
          overflowY: 'scroll',
          height: 260,
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <CardHeader title='Resumen' className={classes.CardHeader} />
        <CardContent>
          <Grid container spacing={0} style={{}}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div className={classes.nameorden}>Cantidad de Ordenes/Guias </div>
              <div className={classes.caption2}>
                {Reporte?.libro?.consolidado}
              </div>
              <Divider component={'div'} />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div className={classes.nameorden}>Importe Total</div>
              <div className={classes.caption2}>
                {Reporte?.libro?.totalFacturas?.totalImporteTotalSinTerceros.toLocaleString(
                  'es-VE',
                  { maximumFractionDigits: 2 }
                )}
              </div>
              <Divider component={'div'} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}
