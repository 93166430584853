import { makeStyles } from '@material-ui/core/styles'

export const StylesConsultaNotas = makeStyles((theme) => ({
  root: {
    fontSize: '10px',
    textAlign: 'right',
  },
  dbasicos: {
    '& .MuiTypography-body1': {
      fontSize: '10px',
      padding: '0px 8px 0px 0px',
    },
  },
  headers: {
    '& .MuiTypography-body1': {
      fontSize: '10px',
      textAlign: 'right',
      fontWeight: 'bold',
      padding: '2px 4px 2px 4px',
      border: '.1px solid #000',
    },
    backgroundColor: '#e0e0e0',
  },
  body: {
    '& .MuiTypography-body1': {
      fontSize: '9px',
      textAlign: 'right',

      // fontWeight: 'bold',
      // padding: "2px 4px 2px 4px",
      // border: '.1px solid #000'
    },
    alignItems: 'end',
  },
  textotitulos: {
    fontSize: '10px',
    fontWeight: 'bold'
  },
}))
