import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'
import {
  productoClearActive,
  startActivaDesactivaProducto,
} from '../../../redux/actions/productoActions'
import useStyles from './Styles'

const ProductoActivaDesativa = ({ handleClose, open, title }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { activeProducto } = useSelector((state) => state.producto)

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log({ e })
    dispatch(startActivaDesactivaProducto())
    dispatch(productoClearActive())

    handleClose()
  }

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      // top={55}
      // left={55}
    >
      <form onSubmit={handleSubmit}>
        <Container
          style={{
            backgroundColor: '#f5f5f5',
            // Width: '40vh',
            // minHeight: '20vh',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Código: ${activeProducto?.codigo}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Producto: ${activeProducto?.descripcion}`}
              </Typography>
            </Grid>
            {/* <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Telefono Movil: ${activeFacturaGeneral?.cliente.telefonoMovil}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Correo: ${activeFacturaGeneral?.cliente.correo}`}
              </Typography>
            </Grid> */}
          </Grid>
          <Grid container spacing={2} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                {activeProducto?.logEstatusRegistro === 'ACTIVO'
                  ? 'Desactivar'
                  : 'Activar'}
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </ModalGenerico>
  )
}

export default ProductoActivaDesativa
