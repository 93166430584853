import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";

import {
  moduloCloseModalShow,
  moduloClearActive,
} from "../../../redux/actions/moduloActions";

Modal.setAppElement("#root");

export const ModuloModalShow = () => {
  const dispatch = useDispatch();

  const { activeModulo, modalModuloShow } = useSelector((state) => state.modulo);
  const { roles } = useSelector( state => state.role );

  const closeModalShow = () => {
    dispatch(moduloCloseModalShow());
    dispatch(moduloClearActive());
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: "100%",
    },
    modal: {
      background: "white",
      borderRadius: "5px",
      color: "rgb(51, 51, 51)",
      maxHeight: "620px",
      minWidth: "300px",
      maxWidth: "700px",
      outline: "none",
      padding: "10px",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-25%",
      transform: "translate(-50%, -50%)",
    },
    headers: {
      textAlign:"left",
      fontWeight: "Bold"
  },
  }));

  const styles = useStyles();

  return (
    <Modal
      isOpen={modalModuloShow}
      closeTimeoutMS={200}
      onRequestClose={closeModalShow}
      className={styles.modal}
      overlayClassName="modal-fondo"
    >
      <h3>Detalles de Módulo</h3>
      <hr />

      <div className="container">
        <div className={styles.modal}>
          <div className="row mb-3">
              <div className="col-sm-12 col-md-3"><strong>ID: </strong> { activeModulo.id }</div>
          
              <div className="col-sm-12 col-md-9"><strong>Nombre: </strong>{ activeModulo.nombre }</div>
          </div>
          
          <h5 className="mt-0 mb-1">Roles</h5>
          <div className="form-group row mb-1">
              <div className="col-sm-12 col-md-3">
                  <span className={styles.headers}>ID</span>
              </div>
              <div className="col-sm-12 col-md-9">
                  <span className={styles.headers}>Nombre (Authority)</span>
              </div>
          </div>

              {
            roles.map((r, i) => (
                       
              <div key={i} className="form-group row mb-0">
              <div className="col-md-3">
                  { r.id }
              </div>
              <div className="col-md-9">
                 { r.nombre }
              </div>
            </div> 
                      ))
              }
              
        </div>
      </div>
    </Modal>
  );
};
