import { types } from '../types/types'

const initialState = {
 facturasGenerales: [],
 lstDetalles: [],
 lstPagos: [],
 lstGravamenes: [],
 activeFacturaGeneral: null,
 modalFacturaGeneralOpen: false,
 modalFacturaGeneralShow: false,
 modalFacturaGeneralDelete: false,
 modalDetalleFacturaGeneral: false,
 modalPagoFacturaGeneral: false,
 clienteSelected: false,
 clienteActive: null,
 modalClienteOpen: false,
 facturaGeneralSelected: false,
 facturaGeneralActive: null,
 localidadSelected: false,
 localidadActive: null,
 sucursalSelected: false,
 sucursalActive: null,
 tipoVentaSelected: false,
 tipoVentaActive: null,
 condicionSelected: true,
 condicionActive: 'CONTADO',
 bancoSelected: false,
 bancoActive: null,
 medioPagoSelected: false,
 medioPagoActive: null,
 divisaSelected: false,
 divisaActive: null,
 tipoColetilla: true,
 facturaDivisa: 'VEF',
 cambioDivisa: null,
 modeloFactura: 'GENERTAL',
 tipoCambioDiaUsd: null,
 diasCreditoSelected: false,
 diasCreditoActive: null,
 subTotal: 0.0,
 exonerado: 0.0,
 exento: 0.0,
 nroFactura: '',
 totalDescuento: 0.0,
 totalIva: 0.0,
 importeTotal: 0.0,
 faltaPorPagar: 0.0,
 igtfAcumulado: 0.0,
 coletillaIGTF: null,
 observacion: null,
 totalNeto: 0.0,
 facturaCodificada: null,
 facturaGenerada: false,
 // Agregado nuevo pn oct 4
 // condicionPago: null,
 // diasCredito: null,
 estatusCredito: null,
 fechaVencimiento: null,
 periodoFacturacion: '',
 pagueAntes: null,
 nroContrato: '',
 direccionServicio: '',
 cuentaTerceros: false,
 rifPrestador: null,
 nombrePrestador: null,
 domicilioPrestador: null,
}

export const facturaGeneralReducer = (state = initialState, action) => {
 switch (action.type) {
  case types.facturaGeneralOpenModal:
   return {
    ...state,
    modalFacturaGeneralOpen: true,
    modalFacturaGeneralDelete: false,
   }

  case types.facturaGeneralCloseModal:
   return {
    ...state,
    modalFacturaGeneralOpen: false,
   }

  case types.facturaGeneralOpenDetalleModal:
   return {
    ...state,
    modalDetalleFacturaGeneral: true,
    modalFacturaGeneralDelete: false,
    modalPagoFacturaGeneral: false,
   }

  // Reducer para actualizar cualquier valor de factura simple
  case types.setUpdateFactura:
   return {
    ...state,
    [action.payload.name]: action.payload.value,
   }

  case types.facturaGeneralCloseDetalleModal:
   return {
    ...state,
    modalDetalleFacturaGeneral: false,
   }

  case types.facturaGeneralOpenPagoModal:
   return {
    ...state,
    modalPagoFacturaGeneral: true,
    modalDetalleFacturaGeneral: false,
    modalFacturaGeneralDelete: false,
   }

  case types.facturaGeneralClosePagoModal:
   return {
    ...state,
    modalPagoFacturaGeneral: false,
   }

  case types.facturaGeneralOpenModalDelete:
   return {
    ...state,
    modalFacturaGeneralOpen: false,
    modalFacturaGeneralDelete: true,
   }

  case types.facturaGeneralCloseModalDelete:
   return {
    ...state,
    modalFacturaGeneralDelete: false,
   }

  case types.facturaGeneralOpenModalShow:
   return {
    ...state,
    modalFacturaGeneralShow: true,
   }

  case types.facturaGeneralCloseModalShow:
   return {
    ...state,
    modalFacturaGeneralShow: false,
    modalFacturaGeneralOpen: false,
    modalFacturaGeneralDelete: false,
    activeFacturaGeneral: null,
    lstPagos: null,
    lstGravamenes: null,
    lstDetalles: null,
   }

  case types.facturaGeneralSetActive:
   return {
    ...state,
    activeFacturaGeneral: action.payload,
    modalFacturaGeneralOpen: true,
   }

  case types.facturaGeneralClienteSelected:
   return {
    ...state,
    clienteSelected: true,
    clienteActive: action.payload,
   }

  case types.facturaGeneralClienteClearSelected:
   return {
    ...state,
    clienteSelected: false,
    clienteActive: null,
   }

  case types.facturaGeneralClienteOpenModal:
   return {
    ...state,
    modalClienteOpen: true,
   }

  case types.facturaGeneralClienteCloseModal:
   return {
    ...state,
    modalClienteOpen: false,
   }

  case types.facturaGeneralFacturaGeneralSelected:
   return {
    ...state,
    facturaGeneralSelected: true,
    facturaGeneralActive: action.payload,
   }

  case types.facturaGeneralFacturaGeneralClearSelected:
   return {
    ...state,
    facturaGeneralSelected: false,
    facturaGeneralActive: null,
   }

  case types.facturaGeneralSucursalSelected:
   return {
    ...state,
    sucursalSelected: true,
    sucursalActive: action.payload,
   }

  case types.facturaGeneralSucursalClearSelected:
   return {
    ...state,
    sucursalSelected: false,
    sucursalActive: null,
   }

  case types.facturaGeneralLocalidadSelected:
   return {
    ...state,
    localidadSelected: true,
    localidadActive: action.payload,
   }

  case types.facturaGeneralLocalidadClearSelected:
   return {
    ...state,
    localidadSelected: false,
    localidadActive: null,
   }

  case types.facturaGeneralTipoVentaSelected:
   return {
    ...state,
    tipoVentaSelected: true,
    tipoVentaActive: action.payload,
   }

  case types.facturaGeneraTipoVentaClearSelected:
   return {
    ...state,
    tipoVentaSelected: false,
    tipoVentaActive: null,
   }

  case types.facturaGeneralNrofacturaSelected:
   return {
    ...state,
    nroFactura: action.payload,
   }
  case types.facturaGeneralNrofacturaClearSelected:
   return {
    ...state,
    nroFactura: '',
   }

  case types.facturaGeneralCondicionSelected:
   return {
    ...state,
    condicionSelected: true,
    condicionActive: action.payload,
   }

  case types.facturaGeneralCondicionClearSelected:
   return {
    ...state,
    condicionSelected: false,
    condicionActive: null,
   }

  case types.facturaGeneralDiasCreditoSelected:
   return {
    ...state,
    diasCreditoSelected: true,
    diasCreditoActive: action.payload,
   }

  case types.facturaGeneraDiasCreditoClearSelected:
   return {
    ...state,
    diasCreditoSelected: false,
    diasCreditoActive: null,
   }

  case types.facturaGeneralBancoSelected:
   return {
    ...state,
    bancoSelected: true,
    bancoActive: action.payload,
   }

  case types.facturaGeneralBancoClearSelected:
   return {
    ...state,
    bancoSelected: false,
    bancoActive: null,
   }

  case types.facturaGeneralMedioPagoSelected:
   return {
    ...state,
    medioPagoSelected: true,
    medioPagoActive: action.payload,
   }

  case types.facturaGeneralMedioPagoClearSelected:
   return {
    ...state,
    medioPagoSelected: false,
    medioPagoActive: null,
   }

  case types.facturaGeneralDivisaSelected:
   console.log('facturaGeneralDivisaSelected', action.payload)
   return {
    ...state,
    divisaSelected: true,
    divisaActive: action.payload,
   }

  case types.facturaGeneralDivisaClearSelected:
   return {
    ...state,
    divisaSelected: false,
    divisaActive: null,
   }

  case types.facturaGeneralAddNew:
   return {
    ...state,
    facturasGenerales: [action.payload, ...state.facturasGenerales],
   }

  case types.facturaGeneralAddNewDetalle:
   return {
    ...state,
    lstDetalles: [action.payload, ...state.lstDetalles],
   }

  case types.facturaGeneralAddNewPago:
   //  console.log('facturaGeneralAddNewPagoXXXXX>>>> payload', action.payload)
   //  console.log('facturaGeneralAddNewPagoXXXXX>>>> state', state)
   //  console.log('facturaGeneralAddNewPagoXXXXX>>>> state igtf', state.igtf)

   return {
    ...state,
    igtf: action.payload.pago.igtf,
    faltaPorPagar:
     state.facturaDivisa === action.payload.pago.divisa
      ? Number(state.faltaPorPagar) - Number(action.payload.pago.monto)
      : Number(state.faltaPorPagar) -
        (Number(action.payload.pago.monto) / Number(state.cambioDivisa)) *
         Number(action.payload.pago.tasaDiaDivisa),
    lstPagos: [action.payload.pago, ...state.lstPagos], // bolvares
    igtfAcumulado: state.igtfAcumulado + action.payload.pago.igtfValor,
    totalNeto: action.payload.acumulado + state.importeTotal,

    // cambie importeTotal X faltaPorPagar
   }
  case types.facturaGeneralDeletePago:
   //  console.log(
   //   'facturaGeneralAddNewPagoXXXXX>>>> payload DELETE',
   //   action.payload
   //  )
   //  console.log('facturaGeneralAddNewPagoXXXXX>>>> state DELETE', state)
   //  console.log(
   //   'facturaGeneralAddNewPagoXXXXX>>>> state igtf DELETE',
   //   state.igtf
   //  )
   //  console.log(
   //   'facturaGeneralAddNewPagoXXXXX>>>> RRSULTADO FIND',
   //   action.payload.pago.igtfValor
   //  )

   return {
    ...state,
    igtfAcumulado: state.igtfAcumulado - action.payload.pago.igtfValor,
    faltaPorPagar:
     state.facturaDivisa === action.payload.pago.divisa
      ? state.faltaPorPagar + Number(parseFloat(action.payload.pago.monto))
      : state.faltaPorPagar +
        (Number(parseFloat(action.payload.pago.monto)) /
         Number(parseFloat(state.cambioDivisa))) *
         Number(parseFloat(action.payload.pago.tasaDiaDivisa)),
    medioPagoSelected: false,
    medioPagoActive: null,
    lstPagos: state.lstPagos.filter((pag, i) => i !== action.payload.idPago),
    totalNeto:
     state.igtfAcumulado - action.payload.pago.igtfValor + state.importeTotal,
   }

  // modificada por ph para no duplicar las gravamens en los totales
  case types.facturaGeneralAddNewGravamen:
   const IsGramavames = state.lstGravamenes.some(
    (gra) => gra.tipo === action.payload.tipo
   )
   console.log('IsGramavames', IsGramavames)
   console.log('state.lstGravamenes', state.lstGravamenes)
   console.log('action.payload', action.payload)
   return {
    ...state,
    lstGravamenes: !IsGramavames
     ? [action.payload, ...state.lstGravamenes]
     : state.lstGravamenes.reduce((acc, _lstGravamenes) => {
        // console.log(
        //   action.payload,
        //   'RRRRRRR_lstGravamenes._lstGravamenes',
        //   _lstGravamenes
        // )

        if (action.payload.montoGravamen === _lstGravamenes.montoGravamen) {
         return acc.concat({
          ..._lstGravamenes,
          baseImponible:
           parseFloat(_lstGravamenes.baseImponible) +
           parseFloat(action.payload.baseImponible),
          totalGravamen:
           parseFloat(_lstGravamenes.totalGravamen) +
           parseFloat(action.payload.totalGravamen),
         })
        }

        return acc.concat([_lstGravamenes])
       }, []),
   }

  case types.facturaGeneralClearActive:
   return {
    ...state,
    activeFacturaGeneral: null,
    lstDetalles: [],
    lstPagos: [],
    clienteSelected: false,
    clienteActive: null,
    lstGravamenes: [],
    facturaGeneralSelected: false,
    facturaGeneralActive: null,
    sucursalSelected: false,
    localidadActive: null,
    localidadSelected: false,
    sucursalActive: null,
    bancoSelected: false,
    bancoActive: null,
    medioPagoSelected: false,
    medioPagoActive: null,
    divisaSelected: false,
    divisaActive: null,
    diasCreditoSelected: false,
    diasCreditoActive: null,
    tipoColetilla: true,
    // facturaDivisa: 'VEF',
    modeloFactura: 'GENERAL',
    exento: 0.0,
    nroFactura: '',
    exonerado: 0.0,
    subTotal: 0.0,
    tipoVentaSelected: false,
    tipoVentaActive: null,
    totalDescuento: 0.0,
    importeTotal: 0.0,
    igtfAcumulado: 0.0,
    totalNeto: 0.0,
    cambioDivisa: null,
    periodoFacturacion: '',
    pagueAntes: null,
    nroContrato: '',
    direccionServicio: '',
    cuentaTerceros: false,
    rifPrestador: null,
    nombrePrestador: null,
    domicilioPrestador: null,
    observacion: null,

    // tipoCambioDiaUsd: null,
   }

  case types.facturaGeneralUpdated:
   return {
    ...state,
    facturasGenerales: state.facturasGenerales.map((b) =>
     b.id === action.payload.id ? action.payload : b
    ),
   }

  case types.facturaGeneralLoaded:
   return {
    ...state,
    facturasGenerales: action.payload,
   }

  case types.facturaGeneralDetallesLoaded:
   return {
    ...state,
    lstDetalles: action.payload,
   }

  case types.facturaGeneralPagosLoaded:
   return {
    ...state,
    lstPagos: action.payload,
   }

  case types.facturaGeneralGravamenesLoaded:
   return {
    ...state,
    lstGravamenes: action.payload,
   }

  case types.facturaGeneralAnular:
   return {
    ...state,
    // facturasGenerales: state.facturasGenerales.filter(
    //   (f) => f.id !== action.payload
    // ),
    facturasGenerales: state.facturasGenerales.reduce(
     (acc, _facturasGenerales) => {
      if (action.payload.idFactura === _facturasGenerales.id) {
       return acc.concat({
        ..._facturasGenerales,
        logEstatusRegistro: action.payload.logEstatusRegistro,
       })
      }
      return acc.concat(_facturasGenerales)
     },
     []
    ),
    activeFacturaGeneral: null,
   }
  case types.facturaGeneralDelete:
   return {
    ...state,
    facturasGenerales: state.facturasGenerales.filter(
     (i) => i.id !== action.payload
    ),
   }

  case types.facturaGeneralDeleteDetalle:
   return {
    ...state,
    lstDetalles: state.lstDetalles.filter((det, i) => i !== action.payload),
   }

  case types.facturaGeneralReactived:
   return {
    ...state,
    facturasGenerales: state.facturasGenerales.map((b) =>
     b.id === action.payload.id ? action.payload : b
    ),
    activeFacturaGeneral: null,
   }

  case types.facturaGeneralUpdateSubTotal:
   return {
    ...state,
    subTotal:
     action.payload.operacion === 'suma'
      ? state.subTotal + action.payload.monto
      : state.subTotal - action.payload.monto,
   }

  case types.facturaGeneralUpdateExento:
   return {
    ...state,
    exento:
     action.payload.operacion === 'suma'
      ? state.exento + action.payload.monto
      : state.exento - action.payload.monto,
   }

  case types.facturaGeneralUpdateTotalDescuento:
   return {
    ...state,
    totalDescuento:
     action.payload.operacion === 'suma'
      ? state.totalDescuento + action.payload.monto
      : state.totalDescuento - action.payload.monto,
   }

  case types.facturaGeneralUpdateExonerado:
   return {
    ...state,
    exonerado:
     action.payload.operacion === 'suma'
      ? state.exonerado + action.payload.monto
      : state.exonerado - action.payload.monto,
   }

  case types.facturaGeneralUpdateTotalIva:
   return {
    ...state,
    totalIva:
     action.payload.operacion === 'suma'
      ? state.totalIva + action.payload.monto
      : state.totalIva - action.payload.monto,
   }

  case types.facturaGeneralUpdateImporteTotal:
   return {
    ...state,
    importeTotal:
     action.payload.operacion === 'suma'
      ? parseFloat(state.importeTotal + action.payload.monto)
      : parseFloat(state.importeTotal - action.payload.monto),
    faltaPorPagar:
     action.payload.operacion === 'suma'
      ? parseFloat(state.faltaPorPagar + action.payload.monto)
      : parseFloat(state.faltaPorPagar - action.payload.monto),
   }

  case types.facturaGeneralUpdateTotalesModificar:
   return {
    ...state,
    importeTotal: action.payload.importetotal,
    faltaPorPagar: 0.0,
   }

  case types.facturaGeneralGenerada:
   return {
    ...state,
    facturasGenerales: state.facturasGenerales.reduce(
     (acc, _facturasGenerales) => {
      if (action.payload.idFactura === _facturasGenerales.id) {
       console.log('action.payload facturasGenerales', action.payload)
       // console.log(action.payload)
       return acc.concat({
        ..._facturasGenerales,
        // facturaCodificada: action.payload.FacguraDell,
        serie: action.payload.FacguraDell.serie,
        nroControl: action.payload.FacguraDell.nroControl,
        facturaGenerada: action.payload.FacguraDell.facturaGenerada,
       })
      }
      return acc.concat(_facturasGenerales)
     },
     []
    ),
   }

  case types.facturaGeneralUpdatelLstGravamenes:
   console.log('facturaGeneralUpdatelLstGravamenes>>>><<<<<', action.payload)
   return {
    ...state,
    lstGravamenes: state.lstGravamenes.map((grav) =>
     grav.tipo === action.payload.tipo
      ? {
         ...grav,
         baseImponible: grav.baseImponible + action.payload.baseImponible,
         montoAlicuota: grav.montoAlicuota + action.payload.montoAlicuota,
        }
      : grav
    ),
   }

  // Se realizo las funcion de nuevo por no traer los valor indicados ph
  case types.facturaGeneralUpdateTotalGravamen:
   return {
    ...state,
    lstGravamenes: state.lstGravamenes.reduce((acc, _lstGravamenes) => {
     if (
      action.payload.idTipoGravamen.idTipoGravamen?.id ===
      _lstGravamenes.idTipoGravamen
     ) {
      return acc.concat({
       ..._lstGravamenes,
       baseImponible:
        parseFloat(_lstGravamenes.baseImponible) -
        action.payload.idTipoGravamen.baseImponible,
       montoAlicuota:
        parseFloat(_lstGravamenes.totalGravamen) -
        parseFloat(action.payload.idTipoGravamen.montoGravamen),
      })
     }
     return acc.concat(_lstGravamenes)
    }, []),
   }

  case types.facturaupdatedeleteTotalGravame:
   return {
    ...state,
    lstGravamenes: state.lstGravamenes.reduce((acc, _lstGravamenes) => {
     if (action.payload.idgravamen === _lstGravamenes.tipo) {
      if (
       parseFloat(_lstGravamenes.baseImponible) -
        parseFloat(action.payload.baseImponible) ===
       0
      ) {
       return acc.splice(0, 1, { ..._lstGravamenes })
      } else {
       return acc.concat({
        ..._lstGravamenes,
        baseImponible:
         parseFloat(_lstGravamenes.baseImponible) -
         action.payload.baseImponible,
        montoAlicuota:
         parseFloat(_lstGravamenes.montoAlicuota) -
         parseFloat(action.payload.montoGravamen),
       })
      }
     }

     return acc.concat(_lstGravamenes)
    }, []),
   }

  case types.facturaupdateDetalleProductoExistente:
   return {
    ...state,
    lstDetalles: state.lstDetalles.reduce((acc, _lstDetalle) => {
     if (action.payload.codigoProducto === _lstDetalle.codigoProducto) {
      return acc.concat({
       ..._lstDetalle,
       cantidad:
        parseInt(_lstDetalle.cantidad) + parseInt(action.payload.cantidad),
       importe:
        parseFloat(_lstDetalle.importe) + parseFloat(action.payload.importe),
       // gravamen:
       //   parseInt(_lstDetalle.gravamen) +
       //   parseInt(action.payload.gravamen),
       montoDescuento:
        parseFloat(_lstDetalle.montoDescuento) +
        parseFloat(action.payload.montoDescuento),
       montoGravamen:
        parseFloat(_lstDetalle.montoGravamen) +
        parseFloat(action.payload.montoGravamen),
       descuento: parseFloat(_lstDetalle.descuento), // + parseFloat(action.payload.descuento),
      })
     }
     return acc.concat(_lstDetalle)
    }, []),
   }

  case types.facturaGeneralVerPdf:
   return {
    ...state,
    facturasGenerales: state.facturasGenerales.reduce(
     (acc, _facturasGenerales) => {
      if (action.payload.id === _facturasGenerales.id) {
       return acc.concat({
        ..._facturasGenerales,
        facturaCodificada: action.payload.facturaCodificada,
       })
      }
      return acc.concat(_facturasGenerales)
     },
     []
    ),
   }
  // return {
  //   ...state,
  //   facturaCodificada: action.payload,
  // }

  case types.facturaGeneralClearPdf:
   return {
    ...state,
    facturaCodificada: null,
   }
  case types.facturaGeneralClearlstDetalles:
   return {
    ...state,
    lstDetalles: [],
    lstPagos: [],
    lstGravamenes: [],
    exento: 0.0,
    exonerado: 0.0,
    subTotal: 0.0,
    // nroFactura: '',
    igtfAcumulado: 0.0,
    totalNeto: 0.0,
    totalDescuento: 0.0,
    importeTotal: 0.0,
    totalIva: 0.0,
    faltaPorPagar: 0.0,
    cambioDivisa: null,
    tipoCambioDiaUsd: null,
    periodoFacturacion: '',
    pagueAntes: null,
    nroContrato: '',
    direccionServicio: '',
   }
  case types.clearDetalleProducto:
   return {
    ...state,
    lstDetalles: [],
   }
  case types.facturaGeneralLoadCambioDivisa:
   return {
    ...state,
    cambioDivisa: action.payload,
   }
  case types.facturaGeneralLoadtLoadtipoCambioDiaUsd:
   return {
    ...state,
    tipoCambioDiaUsd: action.payload.value,
   }

  case types.facturaGeneracuentaTerceros:
   return {
    ...state,
    cuentaTerceros: action.payload.cuentaTerceros,
    domicilioPrestador: action.payload.domicilioPrestador,
    nombrePrestador: action.payload.nombrePrestador,
    rifPrestador: action.payload.rifPrestador,
   }

  case types.facturaGeneracuentaTercerosClear:
   return {
    ...state,
    cuentaTerceros: false,
    rifPrestador: null,
    nombrePrestador: null,
    domicilioPrestador: null,
   }
  case types.facturaAsignaNroFacturaLoaded:
   return {
    ...state,
    nroFactura: action.payload,
   }

  case types.facturaUpdateVendedor:
   return {
    ...state,
    observacion: action.payload,
   }

  case types.facturaClearVendedor:
   return {
    ...state,
    observacion: null,
   }

  default:
   return state
 }
}
