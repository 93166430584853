import { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import useStyles from './Styles'

import { useSelector } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'

import ListarCuentasTerceros from './ListarCuentasTerceros'
import AgregarCuentaTerceros from './AgregarCuentaTerceros'
import UpdateCuentaTerceros from './UpdateuentaTerceros'

export default function CuentaTercerosBase({ open, handleClose, title }) {
  const classes = useStyles()
  const { activeLocalidad } = useSelector((state) => state.localidad)
  const [Accion, setAccion] = useState('listar')
  const [Item, setItem] = useState(null)

  console.log('activeLocalidad', activeLocalidad)

  const handleAccion = (accion, item = null) => {
    setAccion(accion)
    setItem(item)
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <Grid container spacing={1}>
        {Accion === 'listar' && activeLocalidad?.lstCuentaTerceros?.length > 0 && (
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <ListarCuentasTerceros
              lstCuentaTerceros={activeLocalidad.lstCuentaTerceros}
              handleAccion={handleAccion}
            />
          </Grid>
        )}
        {(Accion === 'agregar' ||
          activeLocalidad.lstCuentaTerceros.length === 0) && (
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <AgregarCuentaTerceros
              handleClose={handleClose}
              handleAccion={handleAccion}
            />
          </Grid>
        )}
        {Accion === 'update' && (
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <UpdateCuentaTerceros
              handleClose={handleClose}
              handleAccion={handleAccion}
              Item={Item}
            />
          </Grid>
        )}
      </Grid>

      {Accion === 'listar' && activeLocalidad.lstCuentaTerceros.length > 0 && (
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => handleAccion('agregar')}
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      )}
    </ModalGenerico>
  )
}
