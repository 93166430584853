import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { TIPOORDEN } from '../nota/constants'

export const columnas = [
 {
  title: 'Nro. Orden/Guia',
  field: 'nroOrden',
  numeric: false,
  render: (rowData) => {
   return (
    <div
     style={{
      fontSize: '12px',
      backgroundColor: '#054477',
      color: 'whitesmoke',
      textAlign: 'right',
     }}
    >
     {rowData.nroOrden}
    </div>
   )
  },
 },
 {
  title: 'Nro. Control',
  field: 'nrocontrol',
  numeric: false,
  render: (rowData) => {
   return (
    <div
     style={{
      minWidth: '80px',
      fontSize: '12px',
      backgroundColor: '#054477',
      color: 'whitesmoke',
      textAlign: 'right',
     }}
    >
     {rowData?.nroControl
      ? rowData?.serie + rowData?.nroControl
      : 'No generada'}
    </div>
   )
  },
 },
 {
  title: 'Tipo Doc.',
  field: 'tipodoc',
  numeric: false,
  render: (rowData) => {
   return (
    <div
     style={{
      fontSize: '10px',
      backgroundColor:
       rowData?.modeloOrden === TIPOORDEN[0] ? '#29a347' : '#fae88e',
      color: rowData?.modeloOrden === TIPOORDEN[0] ? 'white' : 'black',
      textAlign: 'center',
      // textTransform: 'uppercase',
      width: '100px',
      padding: '2px',
      borderRadius: '5px',
      // border: 'solid 1px black',
      margin: 'auto',
     }}
    >
     {rowData?.modeloOrden === TIPOORDEN[0]
      ? 'Orden de Entrega'
      : 'Guia de despacho'}
    </div>
   )
  },
 },
 {
  title: 'Razón Social o Sucursal',
  field: 'nrocontrol',
  numeric: false,
  render: (rowData) => {
   return (
    <div style={{ fontSize: '10px' }}>
     {rowData?.id_sucursal === undefined ? (
      <div style={{ width: '200px' }}>{rowData?.id_localidad?.nombre}</div>
     ) : (
      <div style={{ width: '200px' }}>{rowData?.id_sucursal?.nombre}</div>
     )}
    </div>
   )
  },
 },
 {
  title: 'Gen.',
  field: 'facturaGenerada',
  align: 'center',
  numeric: false,
  render: (rowData) => (
   <div style={{ fontSize: '14px', textAlign: 'center' }}>
    {rowData?.facturaGenerada === 'true' ? (
     <div style={{ color: 'blue', fontWeight: 'bold' }}>Si</div>
    ) : (
     <div style={{ color: 'tomato', fontWeight: 'bold' }}>No</div>
    )}
   </div>
  ),
 },
 {
  title: 'Fecha Emisión',
  field: 'fechaEmision  ',
  numeric: false,
  // defaultSort: 'desc',
  render: (rowData) => (
   <div style={{ fontSize: '12px', width: '125px' }}>
    {format(
     new Date(`${String(rowData?.fechaEmision).substring(0, 10)}T00:00:00`),
     'dd/MM/yyyy',
     {
      locale: es,
      timeZone: 'America/Caracas',
     }
    )}
   </div>
  ),
 },
 {
  title: 'Cliente',
  field: 'identificacion',
  numeric: false,
  render: (rowData) => (
   <div style={{ width: '200px', height: '30px' }}>
    {rowData.cliente.identificacion}
   </div>
  ),
 },

 {
  id: 'logEstatusRegistro',
  title: 'Estatus',
  field: 'logEstatusRegistro',
  numeric: false,
  render: (rowData) => (
   <div style={{ fontSize: '12px' }}>
    {rowData?.logEstatusRegistro === 'INACTIVO' ? (
     <div
      style={{
       color: 'white',
       backgroundColor: 'tomato',
       textAlign: 'center',
       // textTransform: 'uppercase',
       width: '100px',
       padding: '2px',
       borderRadius: '5px',
       // border: 'solid 1px black',
       margin: 'auto',
      }}
     >
      Anulada
     </div>
    ) : (
     <div
      style={{
       color: 'blue',
       textAlign: 'center',
       // textTransform: 'uppercase',
       width: '100px',
       padding: '2px',
       borderRadius: '5px',
       // border: 'solid 1px black',
       margin: 'auto',
      }}
     >
      Activa
     </div>
    )}
   </div>
  ),
 },
 //  {
 //   id: 'acciones',
 //   title: 'Acciones',
 //   field: 'acciones',
 //   render: (rowData) => {
 //    console.log('🚀 ~ rowData:', rowData.id)
 //    return <MenuActions rowData={rowData} />
 //   },
 //   // render: (rowData) => <MenuActions rowData={rowData} />,
 //  },
]

export const opciones = {
 sorting: true,
 actionsColumnIndex: -1,
 headerStyle: {
  backgroundColor: '#085c9f',
  color: '#FFF',
 },
 exportButton: true,
 rowStyle: (rowData) => {
  if (rowData.logEstatusRegistro === 'INACTIVO') {
   return { backgroundColor: '#F3EFEE' }
  }
 },
}

export const localization = {
 header: {
  actions: 'Acciones',
 },
 toolbar: {
  searchPlaceholder: 'Buscar',
  searchTooltip: 'Buscar',
  exportTitle: 'Exportar',
  exportCSVName: 'Exportar en formato excel',
  exportPDFName: 'Exportar como pdf',
 },
 pagination: {
  firstAriaLabel: 'Primera página',
  firstTooltip: 'Primera página',
  previousAriaLabel: 'Página anterior',
  previousTooltip: 'Página anterior',
  nextAriaLabel: 'Página siguiente',
  nextTooltip: 'Página siguiente',
  lastAriaLabel: 'Última página',
  lastTooltip: 'Última página',
  labelDisplayedRows: '{from}-{to} de {count}',
  labelRowsSelect: 'filas',
 },
 body: {
  emptyDataSourceMessage: 'No existen datos para mostrar',
 },
}
