import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadEstados } from '../redux/actions/estadoActions'

export const useEstados = () => {
  const [municipio, setmunicipio] = useState([])

  const dispatch = useDispatch()
  const { estados, activeEstado } = useSelector((state) => state.estado)

  useEffect(() => {
    dispatch(startLoadEstados())
  }, [dispatch])

  const buscarMunicipio = (id_estado) => {
    const muni = estados.find((b) => b.id_estado === id_estado)
    setmunicipio(muni?.municipios)
    // return divisa
  }

  return {
    estados,
    activeEstado,
    municipio,
    buscarMunicipio,
  }
}
