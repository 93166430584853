import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { startDeleteFacturaGeneral } from '../../../redux/actions/facturaGeneralActions'
import ModalGenerico from '../../commons/ui/Modal'
import { useStyles } from '../General/FacturaGeneralModalShow/Styles'
import useOrden from '../../../hooks/useOrden'
import { startAnularOrden } from '../../../redux/actions/ordenActions'

const AnularOrden = ({ handleClose, open, title, activeOrden }) => {
 const classes = useStyles()
 const dispatch = useDispatch()
//  const { activeOrden } = useOrden()

 const handleSubmit = (e) => {
//   e.preventDefault()
console.log('ENTRO AL SUMIT', activeOrden)
  dispatch(startAnularOrden(activeOrden))
  handleClose()
 }

 return (
  <ModalGenerico
   open={open}
   handleClose={handleClose}
   title={title}
   // top={55}
   // left={55}
  >
   <form onSubmit={handleSubmit}>
    <Container
     style={{
      backgroundColor: '#f5f5f5',
      // Width: '40vh',
      // minHeight: '20vh',
     }}
    >
     <Grid container spacing={2}>
      <Grid item lg={6} sm={6} xl={6} xs={6}>
       <Typography varint='h6' style={{ fontWeight: 'bold' }}>
        {`N° Orden/Guia: ${activeOrden?.nroOrden}`}
       </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={6} xs={6}>
       <Typography varint='h6' style={{ fontWeight: 'bold' }}>
        {`Cliente: ${activeOrden?.cliente.identificacion}`}
       </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={6} xs={6}>
       <Typography varint='h6' style={{ fontWeight: 'bold' }}>
        {`Telefono Movil: ${activeOrden?.cliente.telefonoMovil}`}
       </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={6} xs={6}>
       <Typography varint='h6' style={{ fontWeight: 'bold' }}>
        {`Correo: ${activeOrden?.cliente.correo}`}
       </Typography>
      </Grid>
     </Grid>
     <Grid container spacing={2} className={classes.margen}>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Button
        className={classes.btn}
        size='small'
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
        //  onClick={() => peticionPost()}
       >
        Anular
       </Button>
      </Grid>
      <Grid item md={6} xs={6}>
       <Button
        className={classes.btn}
        size='small'
        variant='contained'
        fullWidth
        onClick={handleClose}
       >
        Cancelar
       </Button>
      </Grid>
     </Grid>
    </Container>
   </form>
  </ModalGenerico>
 )
}

export default AnularOrden
