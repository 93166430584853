import { Divider, Grid, Typography } from '@material-ui/core'

export const DatosmunicipiosAsoc = ({ estados }) => {
  return (
    <Grid container spacing={1} style={{ padding: '1rem' }}>
      <Typography>Municipios</Typography>
      {estados.municipios.length > 0 ? (
        estados.municipios.map((municipio) => {
          return (
            <Grid container spacing={0}>
              <Grid item sm={12} style={{ paddingLeft: '1rem' }}>
                <Typography variant='body2'>{municipio.municipio}</Typography>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid container spacing={1}>
          <Grid item sm={12} style={{ paddingLeft: '1rem' }}>
            <Typography variant='caption'>Sin Sucursales</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
