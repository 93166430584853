import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

import MaterialTable from 'material-table';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';

import { facturaCompraOpenModal, facturaCompraOpenModalDelete, facturaCompraSetActive, startLoadFacturasCompras, startReactivedFacturaCompra } from '../../../redux/actions/facturaCompraActions';
import { FacturaCompraModalDelete } from './FacturaCompraModalDelete';
import { DatosSeguimiento } from '../../commons/DatosSeguimiento';

//Idioma
moment.locale('es');

const FacturaCompra = () => {

    const dispatch = useDispatch();

    const { facturasCompras, modalFacturaCompraDelete } = useSelector( state => state.facturaCompra );

    const columnas = [
        {
            title: 'ID',
            field: 'id',
            type: 'numeric',
            defaultSort: 'asc',
            width: "5%",
        },
        {
            title: 'N° Control',
            field: 'nroControl',
            width: "10%",
        },
        {
            title: 'N° Factura',
            field: 'nroFactura',
            width: "10%",
        },
        {
            title: 'Fecha',
            field: 'fechaFactura',
            width: "10%",
            render: rowData => moment(rowData.fechaFactura).format('DD/MM/YYYY')
        },
        {
            title: 'Cliente',
            field: 'cliente.identificacion',
            width: "30%",
        },
        {
            title: 'Proveedor',
            field: 'localidad.nombre',
            width: "30%",
        },
        {
            title: 'Estatus',
            field: 'logEstatusRegistro',
            width: "15%",
        }
    ];

    useEffect(() => {
       
        dispatch( startLoadFacturasCompras() );

    }, [dispatch]);

    const onUpdateFacturaCompra = (e) => {
        dispatch(facturaCompraSetActive(e));
        dispatch(facturaCompraOpenModal());
    }

    const handleDelete = (e) => {
        dispatch(facturaCompraSetActive(e));
        dispatch(facturaCompraOpenModalDelete());
    }

    const handleReactive = (e) => {
        dispatch( startReactivedFacturaCompra(e) );
    }

    const openModal = () => {
        dispatch(facturaCompraOpenModal());
    }

    const navigate = useNavigate();
    const formBase = () => navigate.push("/facturacion/facturaCompra");

    return (

        <div>
            <br />
            
            { (modalFacturaCompraDelete) && <FacturaCompraModalDelete /> }
            <MaterialTable
                title="Facturas de compras"
                columns={columnas}
                data={facturasCompras}
                detailPanel={rowData => {
                    return (
                      <DatosSeguimiento datos={rowData} />
                    )
                  }
                }

                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Nueva Factura Compra',
                        isFreeAction: true,
                        onClick: (event) => { formBase() }
                    },
                    rowData => ({
                        icon: 'edit',
                        tooltip: 'Modificar',
                        onClick: (event, rowData) => onUpdateFacturaCompra(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <PowerSettingsNewIcon />,
                        tooltip: 'Inactivar',
                        onClick: (event, rowData) => handleDelete(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <SettingsPowerIcon />,
                        tooltip: 'Activar',
                        onClick: (event, rowData) => handleReactive(rowData),
                        hidden: rowData.logEstatusRegistro ==="ACTIVO",
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#085c9f',
                        color: '#FFF'
                    },
                    exportButton: true,
                    paginationType: 'stepped',
                    rowStyle: rowData => {
                        if(rowData.logEstatusRegistro === "INACTIVO") {
                          return {backgroundColor: '#F3EFEE'};
                        }
                        
                        return {};
                      }
                }}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    toolbar: {
                        searchPlaceholder: 'Buscar',
                        searchTooltip: 'Buscar',
                        exportTitle: "Exportar",
                        exportCSVName: "Exportar en formato excel",
                        exportPDFName: "Exportar como pdf"
                    },
                    pagination: {
                        firstAriaLabel: 'Primera página',
                        firstTooltip: 'Primera página',
                        previousAriaLabel: 'Página anterior',
                        previousTooltip: 'Página anterior',
                        nextAriaLabel: 'Página siguiente',
                        nextTooltip: 'Página siguiente',
                        lastAriaLabel: 'Última página',
                        lastTooltip: 'Última página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos para mostrar',
                    }
                }}

            />
            
        </div>
    )
}

export default FacturaCompra;