import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

import Swal from 'sweetalert2'
import {
  valorGravamenCloseModal,
  startSaveValorGravamen,
  startUpdateValorGravamen,
  valorGravamenClearActive,
} from '../../../redux/actions/valorGravamenActions'

const initValorGravamen = {
  valor: '',
  tipoGravamen: -1,
  fechaDesde: new Date(),
  fechaHasta: '',
  logEstatusRegistro: '1',
  logFechaRegistro: new Date(),
  logUsername: 'sadmin',
  logFechaModificacion: null,
}

Modal.setAppElement('#root')

export const ValorGravamenModal = () => {
  const dispatch = useDispatch()

  const { activeValorGravamen, modalValorGravamenOpen } = useSelector(
    (state) => state.valorGravamen
  )
  const { tiposGravamenes } = useSelector((state) => state.tipoGravamen)

  const [formValues, setFormValues] = useState(initValorGravamen)

  const { valor, tipoGravamen, fechaDesde, fechaHasta } = formValues

  useEffect(() => {
    if (activeValorGravamen) {
      setFormValues(activeValorGravamen)
    } else {
      setFormValues(initValorGravamen)
    }
  }, [activeValorGravamen, setFormValues])

  const handleInputChange = ({ target }) => {
    console.log(target.value)

    setFormValues({
      ...formValues,
      [target.name]: target.value,
    })
  }

  const closeModal = () => {
    dispatch(valorGravamenCloseModal())
    dispatch(valorGravamenClearActive())
    setFormValues(initValorGravamen)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (valor.length < 1) {
      return Swal.fire(
        'Error',
        'El valor debe contener al menos 1 caracteres',
        'error'
      )
    }

    if (activeValorGravamen) {
      dispatch(
        startUpdateValorGravamen({
          id: activeValorGravamen.id,
          valor,
          tipoGravamen:
            tipoGravamen.id === activeValorGravamen.id
              ? activeValorGravamen.id
              : tipoGravamen,
          logUsername: 'sadmin',
          logFechaModificacion: new Date(),
        })
      )
    } else {
      dispatch(
        startSaveValorGravamen({
          ...formValues,
        })
      )
    }

    closeModal()
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
    datePicker: {
      width: '60px',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalValorGravamenOpen}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      {activeValorGravamen ? (
        <h3>Editar Valor gravamen</h3>
      ) : (
        <h3>Nuevo Valor gravamen</h3>
      )}

      <hr />
      <form className='container' onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className='form-group row'>
            <div className='col-md'>
              <label htmlFor='valor' className='mb-1 form-label'>
                Valor
              </label>
              <input
                type='text'
                id='valor'
                className='form-control form-control-sm'
                placeholder='valor'
                name='valor'
                autoComplete='off'
                value={valor}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md'>
              <label htmlFor='tipoGravamen' className='mb-1 form-label'>
                Tipo Alicuota
              </label>
              <select
                className='form-select form-select-sm'
                aria-label='.form-select-sm example'
                id='tipoGravamen'
                label='tipoGravamen'
                value={tipoGravamen}
                onChange={handleInputChange}
                name='tipoGravamen'
              >
                <option
                  defaultValue={
                    activeValorGravamen
                      ? activeValorGravamen.id
                      : 'Seleccione...'
                  }
                >
                  {activeValorGravamen
                    ? activeValorGravamen.concepto
                    : 'Seleccione...'}
                </option>
                {tiposGravamenes.map((g) => (
                  <option key={g.id} value={g.id}>
                    {g.concepto}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-6'>
              <label htmlFor='fechaDesde' className='mb-1 form-label'>
                Fecha desde
              </label>
              <input
                id='fechaDesde'
                name='fechaDesde'
                className='datePicker'
                type='date'
                value={fechaDesde}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md-6'>
              <label htmlFor='fechaDesde' className='mb-1 form-label'>
                Fecha hasta
              </label>
              <input
                id='fechaHasta'
                name='fechaHasta'
                className='datePicker'
                type='date'
                value={fechaHasta}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <br />
          <button
            type='submit'
            className='btn btn-outline-primary btn-block btn-lg'
          >
            <i className='far fa-save'></i>
            <span> Guardar</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
