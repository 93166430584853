import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'

import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  RadioGroup,
  FormControl,
  Checkbox,
  FormControlLabel,
  Radio,
  Button,
} from '@material-ui/core'
import useStyles from './Styles'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { unidadMedida } from '../../../Constants/ConstantesFacturas'
import { useRazonSocial } from '../../../hooks/useRazonSocial'
import { useTipoGravamenes } from '../../../hooks/useTipoGravamenes'
import {
  startSetActiveTipoGravamenes,
  tipoGravamenClearActive,
} from '../../../redux/actions/tipoGravamenActions'

import ModalGenerico from '../../commons/ui/Modal'
import { startSaveProducto } from '../../../redux/actions/productoActions'

const schema = yup.object().shape({
  descripcion: yup
    .string()
    .max(200, 'Maximo 200 caracteres permitidos, para la descripcion')
    .required(''),
  localidad: yup.string().required('Debe Ingresar la descripción del producto'),

  // al menos un valor debe ser mayor a cero para el precio y precioPtr hacerlo con testes
  precio: yup
    .number()
    .test(
      'precio',
      'Debe Ingresar el precio del producto',
      function (value, context) {
        console.log('PRECIO', value, context.parent.PrecioPtr)
        return value > 0 || context.parent.PrecioPtr > 0 || context.parent.divisaPrecio > 0
      }
    ),

  divisaPrecio: yup
    .number()
    .test(
      'precio',
      'Debe Ingresar el precio del producto',
      function (value, context) {
        console.log('PRECIO', value, context.parent.precio)
        return (
          value > 0 || context.parent.precio > 0 || context.parent.PrecioPtr > 0
        )
      }
    ),
  PrecioPtr: yup
    .number()
    .test(
      'precio',
      'Debe Ingresar el precio del producto',
      function (value, context) {
        console.log('PRECIO', value, context.parent.precio)
        return value > 0 || context.parent.precio > 0 || context.parent.divisaPrecio > 0
      }
    ),
    PrecioEur: yup
    .number()
    .test(
      'precio',
      'Debe Ingresar el precio del producto',
      function (value, context) {
        console.log('PRECIO', value, context.parent.precio)
        return value > 0 || context.parent.precio > 0 || context.parent.divisaPrecio > 0
      }
    ),
  stock: yup.string().required(''),
  codigo: yup.string().required(''),
  exonerado: yup.boolean().default(false),
  exento: yup.boolean().default(false),
  unidadMedida: yup.string().required(''),
  tipoVenta: yup.string().required('tipoproducto'),
  // exoExec: yup.string().required(''),
  tipoalicuota: yup.string(),
})

export default function AgregarProductos({ open, handleClose, title }) {
  const [alicuota, setalicuota] = useState(false)
  const [exento, setexento] = useState(false)
  const [exonerado, setexonerado] = useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const { localidades } = useRazonSocial()
  const { tiposGravamenes, activeTipoGravamen } = useTipoGravamenes()
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      precio: 0,
      divisaPrecio: 0,
      PrecioPtr: 0,
      PrecioEur: 0,

    },
  })

  const onSubmitProducto = async (data) => {
    let tipoGravamen = {},
      producto = {}
    console.log(' data', data)

    if (activeTipoGravamen) {
      const lstvalores = activeTipoGravamen.lstValores.find(
        (lst) => lst.logEstatusRegistro === 'ACTIVO'
      )

      tipoGravamen = {
        idgravamen: activeTipoGravamen.id,
        concepto: activeTipoGravamen.concepto,
        ivaGeneral: activeTipoGravamen.ivaGeneral,
        lstValores: [
          {
            valor: lstvalores.valor,
            fechaHasta: lstvalores.fechaHasta,
            fechaDesde: lstvalores.fechaDesde,
            // logEstatusRegistro: lstvalores.logEstatusRegistro,
            // logUsername: null,
          },
        ],
      }
    }
    producto = {
      id_localidad: data.localidad,
      descripcion: data.descripcion,
      codigo: data.codigo,
      precio: parseFloat(data.precio),
      divisaPrecio: parseFloat(data.divisaPrecio),
      PrecioPtr: parseFloat(data.PrecioPtr),
      PrecioEur: parseFloat(data.PrecioEur),
      stock: parseFloat(data.stock),
      exento: data.exento,
      exonerado: data.exonerado,
      unidadMedida: data.unidadMedida,
      tipoVenta: data.tipoVenta,
      tipoGravamen: tipoGravamen,
      // logEstatusRegistro: 'ACTIVO',
      // logUsername: 'admin',
    }
    console.log('PRODUCTO HANDLEEE', producto)
    dispatch(startSaveProducto(producto)).then((res) => {
      console.log('res', res)
      if (res.payload.tipoMsg === 'sucess') {
        dispatch(tipoGravamenClearActive())
        handleClose()
      }
    })

    console.log('tipoGravamen', tipoGravamen)
  }

  const handleChangeAlicuota = (e) => {
    dispatch(startSetActiveTipoGravamenes(e))
  }

  useEffect(() => {
    if (exonerado) {
      setalicuota(exonerado)
    }
    if (exento) {
      setalicuota(exento)
    }
  }, [exonerado, exento])

  const handleChangeExcepciones = (e) => {
    const { name, checked } = e.target

    if (name === 'exento') {
      setexento(checked)
    }
    if (name === 'exonerado') {
      setexonerado(checked)
    }

    if (exento || exonerado) {
      setalicuota(false)
    } else if (!exento || exonerado) {
      setalicuota(false)
    }
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitProducto)} autoComplete='off'>
        <Grid container spacing={1} className={classes.root}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <InputLabel className={classes.text} id='localidad'>
              Razón Social
            </InputLabel>
            <Controller
              control={control}
              name='localidad'
              label='Razón Social'
              render={({ field: { onChange }, fieldState: { error } }) => (
                <Select
                  label='Razón Social'
                  size='small'
                  fullWidth
                  variant='outlined'
                  className={classes.select}
                  onChange={(e) => {
                    onChange(e.target.value)
                  }}
                  error={!!error}
                >
                  {localidades.map((option) => {
                    return (
                      <MenuItem
                        // className={classes.sublabel}
                        key={option._id}
                        value={option._id}
                      >
                        {option.nombre}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <Controller
              name='descripcion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  //   className={classes.text}
                  label='Descripción'
                  //   variant='outlined'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <Controller
              name='codigo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  //   className={classes.text}
                  label='Código'
                  //   variant='outlined'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <Controller
              name='precio'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  // format='##########'
                  customInput={TextField}
                  //   className={classes.text}
                  label='Precio (Bs.)'
                  //   variant='outlined'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <Controller
              name='divisaPrecio'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  // format='##########'
                  customInput={TextField}
                  label='Precio (USD)'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <Controller
              name='PrecioPtr'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  // format='##########'
                  customInput={TextField}
                  label='Precio (PTR)'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={3} sm={3} xl={12} xs={12}>
            <Controller
              name='PrecioEur'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  // format='##########'
                  customInput={TextField}
                  label='Precio (EUR)'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='stock'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  customInput={TextField}
                  //   className={classes.text}
                  label='Stock'
                  //   variant='outlined'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <InputLabel className={classes.text} id='unidadMedida'>
              Unidad de Medida
            </InputLabel>
            <Controller
              control={control}
              name='unidadMedida'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  InputLabelProps={{ shrink: true, required: true }}
                  label='Unidad de Medida'
                  size='small'
                  fullWidth
                  //   variant='outlined'
                  //   className={classes.select}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                >
                  {unidadMedida.map((option) => {
                    return (
                      <MenuItem
                        // className={classes.sublabel}
                        key={option.key}
                        value={option.value}
                      >
                        {option.description}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.control}>
              <InputLabel className={classes.text} id='tipoVenta'>
                Tipo Venta
              </InputLabel>
              <Controller
                name='tipoVenta'
                control={control}
                className={classes.formcontrol}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <RadioGroup
                    className={classes.radio}
                    aria-label='tipoVenta'
                    // {...field}
                    // value={value}
                    onChange={onChange}
                    error={!!error}
                  >
                    <FormControlLabel
                      className={classes.formcontrol}
                      value='Interna'
                      control={<Radio />}
                      label='Interna'
                    />
                    <FormControlLabel
                      className={classes.formcontrol}
                      value='Exportación'
                      control={<Radio />}
                      label='Exportación'
                    />
                  </RadioGroup>
                )}
              />
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={6} className={classes.control}>
              <InputLabel className={classes.text} id='exo'>
                Tipos de Excepciones
              </InputLabel>
              <FormControl className={classes.radio}>
                <Controller
                  name='exonerado'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      className={classes.formcontrol}
                      label='Exonerado'
                      component='legend'
                      control={
                        <Checkbox
                          name='exonerado'
                          disabled={exento}
                          defaultChecked={false}
                          onChange={(e) => {
                            field.onChange(e.target.checked)
                            handleChangeExcepciones(e)
                          }}
                          checked={field.value ? true : false}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  name='exento'
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      className={classes.formcontrol}
                      label='exento'
                      component='legend'
                      control={
                        <Checkbox
                          name='exento'
                          disabled={exonerado}
                          defaultChecked={false}
                          onChange={(e) => {
                            field.onChange(e.target.checked)
                            handleChangeExcepciones(e)
                            console.log('field.value', field.value)
                          }}
                          checked={field.value ? true : false}
                        />
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <InputLabel className={classes.text} id='tipoalicuota'>
              Tipo Alicuota
            </InputLabel>
            <Controller
              control={control}
              name='tipoalicuota'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  disabled={alicuota}
                  size='small'
                  fullWidth
                  value={!alicuota ? value : ''}
                  // value={value}
                  name='tipoalicuota'
                  error={!!error}
                  onChange={(e) => {
                    handleChangeAlicuota(e.target)
                    onChange(e.target.value)
                  }}
                >
                  {tiposGravamenes
                    .filter(
                      (tipoGravamenActivos) =>
                        tipoGravamenActivos.logEstatusRegistro === 'ACTIVO'
                    )
                    .map((option) => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={!alicuota ? option.id : ''}
                        >
                          {option.concepto}
                        </MenuItem>
                      )
                    })}
                </Select>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.margen}>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
