// import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { queryFacturasCodificada } from '../../../../redux/actions/facturaGeneralActions'

export default function ViewPdfSeniatNotas({ open, handleClose, title }) {
  const { activeNota } = useSelector((state) => state.nota)

  return (
    <iframe
      src={`data:application/pdf;base64,${activeNota?.notaCodificada}`}
      title='Factura'
      name='Factura'
      style={{
        border: 'none',
        minHeight: '65vh',
        minWidth: '65vh',
      }}
    />
  )
}
