import { useSelector, useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { ConsultacontrolSucursalLocalidad, ConsultacontrolSucursalLocalidadOrdenesGuias } from '../../../redux/actions/controlFacturaActions'

const useStyles = makeStyles((theme) => ({
 button: {
  margin: theme.spacing(1),
 },
 inputMaterial: {
  width: '100%',
 },
 modal: {
  background: 'white',
  borderRadius: '5px',
  color: 'rgb(51, 51, 51)',
  maxHeight: '620px',
  minWidth: '300px',
  maxWidth: '700px',
  outline: 'none',
  padding: '10px',
 },
 content: {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-25%',
  transform: 'translate(-50%, -50%)',
 },
 logo: {
  width: '95px',
  height: '65px',
  objectFit: 'fill',
  objectPosition: 'center',
  display: 'block',
  // marginLeft: 'auto',
  // marginRight: 'auto',
  // marginTop: '5px',
 },
}))

export default function ConsultaSucursal({ open, handleClose, title }) {
 const dispatch = useDispatch()
 const { activeSucursal } = useSelector((state) => state.sucursal)
 const styles = useStyles()
 const [Control, setControl] = useState(null)
 const [ControlOrden, setControlOrden] = useState(null)

 useEffect(() => {
  dispatch(ConsultacontrolSucursalLocalidad(activeSucursal, 'Sucursal')).then(
   (res) => {
    setControl(res.payload.resultado)
   }
  )
 }, [activeSucursal, dispatch])

 useEffect(() => {
  dispatch(ConsultacontrolSucursalLocalidadOrdenesGuias(activeSucursal, 'Sucursal')).then(
   (res) => {
    setControlOrden(res.payload.resultado)
   }
  )
 }, [activeSucursal, dispatch])

 return (
  <ModalGenerico open={open} handleClose={() => handleClose()} title={title}>
   <div className='row'>
    {activeSucursal?.id_localidad?.rutaLogo && (
     <div className='col-4'>
      <img
       className={styles.logo}
       src={
        activeSucursal.id_localidad.rutaLogo ? (
         activeSucursal?.id_localidad.rutaLogo
        ) : (
         <AddPhotoAlternateIcon />
        )
       }
       alt='Logo'
      />
     </div>
    )}
    <div className='col-8'>
     <h3>{activeSucursal.nombre}</h3>
    </div>
   </div>
   <div className='container'>
    <div className={styles.modal}>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Domicilio fiscal: </strong>
       {activeSucursal.direccion}
      </div>
     </div>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Estado: </strong>
       {activeSucursal?.id_estado.estado}
      </div>
     </div>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Municipio: </strong> {activeSucursal?.id_municipio.municipio}
      </div>
     </div>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Teléfonos: </strong>
       {activeSucursal.telefono}
      </div>
     </div>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Correo: </strong>
       {activeSucursal.correo}
      </div>
     </div>
     <div className='row'>
      <div className='col-md-auto'>
       <strong>Codigo (Envio Masivo): </strong>
       {activeSucursal?.codigoSucursal}
      </div>
     </div>

     {/* consulta control sucursales por facturacion  */}
     <div className='row'>
      <div className='row mt-4'>
       {!Control?.controlX && (
        <div className='row'>
         <div className='col-12'>
          <div className='text-center text-uppercase text-white bg-danger text-bold'>
           NO tiene Nro. De control Asignado para Factura
          </div>
         </div>
        </div>
       )}

       <div className='col-12'>
        <strong className='text-uppercase text-info'>
         N° Control: Facturas
        </strong>
       </div>

       {/* <div className='col-12'>
        <strong className='text-uppercase text-success'>
         N° Control: Ordenes/Guias
        </strong>
       </div> */}

       <div className='col-12'>
        <strong className='text-uppercase'>
         Tipo: {Control?.controlX?.tipo}
        </strong>
       </div>

       <div className='row'>
        <div className='col-1 fw-bold'>Status</div>
        <div className='col-2 fw-bold'>Asignadas</div>
        <div className='col-2 fw-bold'>Actual</div>
        <div className='col-2 fw-bold'>Desde</div>
        <div className='col-2 fw-bold'>Hasta</div>
        <div className='col-2 fw-bold'>Disp.</div>
        <div className='col-1 fw-bold'>Serie</div>
       </div>
      </div>

      {Control?.controlX?.tipo === 'Global' &&
       Control?.controlX?.global.map((ind) => (
        <div
         className='row mt-1'
         key={ind.id}
         style={{ textAlign: 'left', fontSize: '10px' }}
        >
         {ind.logEstatusRegistro === 'ACTIVO' ? (
          <div
           className='col-1'
           style={{
            color: 'green',
            // backgroundColor: 'green',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         ) : (
          <div
           className='col-1'
           style={{
            color: 'orange',
            // backgroundColor: 'orange',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         )}
         <div className='col-2'>{ind.cantidadFacturas}</div>
         <div className='col-2'>{ind.nroControlActual}</div>
         <div className='col-2'>{ind.nroControlDesde}</div>
         <div className='col-2'>{ind.nroControlHasta}</div>
         <div className='col-2'>
          {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
         </div>

         <div className='col-1'>{ind.serie}</div>
        </div>
       ))}

      {Control?.controlX?.tipo === 'Individual' &&
       Control?.controlX?.individual.map((ind) => (
        <div
         className='row mt-1'
         key={ind.id}
         style={{ textAlign: 'left', fontSize: '10px' }}
        >
         {ind.logEstatusRegistro === 'ACTIVO' ? (
          <div
           className='col-1'
           style={{
            color: 'green',
            // backgroundColor: 'green',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         ) : (
          <div
           className='col-1'
           style={{
            color: 'orange',
            // backgroundColor: 'orange',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         )}
         <div className='col-2'>{ind.cantidadFacturas}</div>
         <div className='col-2'>{ind.nroControlActual}</div>
         <div className='col-2'>{ind.nroControlDesde}</div>
         <div className='col-2'>{ind.nroControlHasta}</div>
         <div className='col-2'>
          {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
         </div>

         <div className='col-1'>{ind.serie}</div>
        </div>
       ))}
     </div>
     {/* consulta control sucursales por Ordenes/Guias  */}
     <div className='row'>
      <div className='row mt-4'>
       {!ControlOrden?.controlX && (
        <div className='row'>
         <div className='col-12'>
          <div className='text-center text-uppercase text-white bg-danger text-bold'>
           NO tiene Nro. De control Asignado para Ordenes/Guias
          </div>
         </div>
        </div>
       )}

       <div className='col-12'>
        <strong className='text-uppercase text-success'>
         N° Control: Ordenes/Guias
        </strong>
       </div>

       <div className='col-12'>
        <strong className='text-uppercase'>
         Tipo: {Control?.controlX?.tipo}
        </strong>
       </div>

       <div className='row'>
        <div className='col-1 fw-bold'>Status</div>
        <div className='col-2 fw-bold'>Asignadas</div>
        <div className='col-2 fw-bold'>Actual</div>
        <div className='col-2 fw-bold'>Desde</div>
        <div className='col-2 fw-bold'>Hasta</div>
        <div className='col-2 fw-bold'>Disp.</div>
        <div className='col-1 fw-bold'>Serie</div>
       </div>
      </div>

      {ControlOrden?.controlX?.tipo === 'Global' &&
       ControlOrden?.controlX?.global.map((ind) => (
        <div
         className='row mt-1'
         key={ind.id}
         style={{ textAlign: 'left', fontSize: '10px' }}
        >
         {ind.logEstatusRegistro === 'ACTIVO' ? (
          <div
           className='col-1'
           style={{
            color: 'green',
            // backgroundColor: 'green',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         ) : (
          <div
           className='col-1'
           style={{
            color: 'orange',
            // backgroundColor: 'orange',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         )}
         <div className='col-2'>{ind.cantidadFacturas}</div>
         <div className='col-2'>{ind.nroControlActual}</div>
         <div className='col-2'>{ind.nroControlDesde}</div>
         <div className='col-2'>{ind.nroControlHasta}</div>
         <div className='col-2'>
          {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
         </div>

         <div className='col-1'>{ind.serie}</div>
        </div>
       ))}

      {ControlOrden?.controlX?.tipo === 'Individual' &&
       ControlOrden?.controlX?.individual.map((ind) => (
        <div
         className='row mt-1'
         key={ind.id}
         style={{ textAlign: 'left', fontSize: '10px' }}
        >
         {ind.logEstatusRegistro === 'ACTIVO' ? (
          <div
           className='col-1'
           style={{
            color: 'green',
            // backgroundColor: 'green',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         ) : (
          <div
           className='col-1'
           style={{
            color: 'orange',
            // backgroundColor: 'orange',
            fontSize: '10px',
            textAlign: 'left',
            fontWeight: 'bold',
           }}
          >
           {ind.logEstatusRegistro}
          </div>
         )}
         <div className='col-2'>{ind.cantidadFacturas}</div>
         <div className='col-2'>{ind.nroControlActual}</div>
         <div className='col-2'>{ind.nroControlDesde}</div>
         <div className='col-2'>{ind.nroControlHasta}</div>
         <div className='col-2'>
          {Number(ind.nroControlHasta) - Number(ind.nroControlActual)}
         </div>

         <div className='col-1'>{ind.serie}</div>
        </div>
       ))}
     </div>
    </div>
   </div>
  </ModalGenerico>
 )
}
