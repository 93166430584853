import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ValorGravamenModal } from './ValorGravamenModal'
import moment from 'moment'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import {
  valorGravamenOpenModal,
  valorGravamenOpenModalDelete,
  valorGravamenSetActive,
  startReactivedValorGravamen,
  startLoadValoresGravamenes,
} from '../../../redux/actions/valorGravamenActions'
import { ValorGravamenModalDelete } from './ValorGravamenModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import { startLoadTiposGravamenes } from '../../../redux/actions/tipoGravamenActions'

//Idioma
moment.locale('es')

const ValorGravamen = () => {
  const dispatch = useDispatch()

  const { valoresGravamenes, modalValorGravamenDelete } = useSelector(
    (state) => state.valorGravamen
  )

  const columnas = [
    {
      title: 'ID',
      field: 'id',
      type: 'numeric',
      cellStyle: {
        width: 20,
        maxWidth: 20,
      },
    },
    {
      title: 'Valor (%)',
      field: 'valor',
      type: 'numeric',
      cellStyle: {
        paddingRight: '10px',
      },
    },
    {
      title: 'Tipo Alícuota',
      field: 'tipoGravamen.concepto',
    },
    {
      title: 'Fecha desde',
      field: 'fechaDesde',
      type: 'date',
        defaultSort: 'desc',
    //   customSort: (a, b) => a.fechaDesde - b.fechaDesde,
      render: (rowData) => moment(rowData.fechaDesde).format('DD/MM/YYYY'),
    },
    {
      title: 'Fecha hasta',
      field: 'fechaHasta',
      type: 'date',
      render: (rowData) => moment(rowData.fechaHasta).format('DD/MM/YYYY'),
    },
    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
    },
  ]

  useEffect(() => {
    dispatch(startLoadValoresGravamenes())
    dispatch(startLoadTiposGravamenes())
  }, [dispatch])

  const onUpdateValorGravamen = (e) => {
    dispatch(valorGravamenSetActive(e))
    dispatch(valorGravamenOpenModal())
  }

  const handleDelete = (e) => {
    dispatch(valorGravamenSetActive(e))
    dispatch(valorGravamenOpenModalDelete())
  }

  const handleReactive = (e) => {
    dispatch(startReactivedValorGravamen(e))
  }

  const openModal = () => {
    dispatch(valorGravamenOpenModal())
  }

  return (
    <div>
      <br />
      <ValorGravamenModal />
      {modalValorGravamenDelete && <ValorGravamenModalDelete />}
      <MaterialTable
        title='Valores Gravámenes'
        columns={columnas}
        data={valoresGravamenes}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nuevo Valor',
            isFreeAction: true,
            onClick: (event) => {
              openModal()
            },
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Modificar',
            onClick: (event, rowData) => onUpdateValorGravamen(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <PowerSettingsNewIcon />,
            tooltip: 'Inactivar',
            onClick: (event, rowData) => handleDelete(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon />,
            tooltip: 'Activar',
            onClick: (event, rowData) => handleReactive(rowData),
            hidden: rowData.logEstatusRegistro === 'ACTIVO',
          }),
        ]}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
    </div>
  )
}

export default ValorGravamen
