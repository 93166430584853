import { useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { startdeleteCuentaTerceros } from '../../../redux/actions/localidadActions'

export default function ListarCuentasTerceros({
  lstCuentaTerceros,
  handleAccion,
  Item,
}) {
  const [selectedIndex, setSelectedIndex] = useState()
  const dispatch = useDispatch()

  console.log('handleAccion', handleAccion)

  const handleclickSelect = (item, index) => {
    console.log('item', item)
    setSelectedIndex(index)
    handleAccion('update', item)
  }

  console.log('LISTA CUENTAS', Item)

  const handleDelete = (e, item) => {
    console.log('itemTTTTTT', item)
    dispatch(startdeleteCuentaTerceros(item._id)).then((res) => {
      console.log('res', res)
      if (res.payload?.tipoMsg === 'sucess') {
        // handleClose()
        handleAccion('listar')
      }
    })
  }

  return (
    <Grid container spacing={1} style={{ width: '350px' }}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <div
          style={{
            width: '100%',
            // maxWidth: 360,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 200,
            '& ul': { padding: 0 },
          }}
        >
          <List
            sx={{
              width: '100%',
              // maxWidth: 360,
              bgcolor: 'background.paper',
            }}
          >
            {lstCuentaTerceros?.map((item, idx) => (
              <ListItem
                key={item.id}
                selected={selectedIndex === idx}
                button
                onClick={() => handleclickSelect(item, idx)}
              >
                <ListItemText
                  secondary={item.rifPrestador}
                  primary={item.nombrePrestador}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    name='deleteCuentaterceros'
                    size='small'
                    edge='end'
                    onClick={(e) => handleDelete(e, item)}
                  >
                    <DeleteIcon htmlColor='tomato' fontSize='inherit' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
    </Grid>
  )
}
