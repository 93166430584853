import { ApiAxiosGet, ApiAxiosPost, ApiAxiosPut } from '../../Helpers/ApiAxios'
import { config } from '../../config/configProperties'
import { types } from '../types/types'
import { errorSetActive } from './errorActions'
const URLORDENS = `${config.urlServer}/ordenes`

export const startLoadOrdenes = ({ limit = 10, page = 1 }) => {
 let filtroFin = `?limit=${limit}&page=${page}`
 //  console.log('🚀 ~ startLoadOrdenes ~ datos:', datos)
 console.log('🚀 ~ startLoadOrdenes ~ page:', page)

 return async (dispatch, getState) => {
  const { filtro } = getState().orden
  console.log('🚀 ~ return ~ filtroOrden:', filtro)

  if (filtro.datos !== undefined) {
   if (filtro.datos.rif !== 'undefined-undefined') {
    filtroFin = `${filtroFin}&rif=${filtro.datos?.rif}`
   }
   if (
    filtro.datos.fechaInicio !== undefined &&
    filtro.datos.fechaFin !== undefined
   ) {
    filtroFin = `${filtroFin}&fechaInicio=${filtro.datos?.fechaInicio}&fechaFin=${filtro.datos?.fechaFin}`
   }
   if (filtro.datos.codigoSucursal !== undefined) {
    filtroFin = `${filtroFin}&codigoSucursal=${filtro.datos?.codigoSucursal}`
   }
   if (filtro.datos.id_localidad !== undefined) {
    filtroFin = `${filtroFin}&id_localidad=${filtro.datos?.id_localidad}`
   }
   if (filtro.datos.nroOrden !== undefined) {
    filtroFin = `${filtroFin}&nroOrden=${filtro?.datos?.nroOrden}`
   }
  }
  console.log('🚀 ~ return ~ filtroFin:', filtroFin)

  try {
   const res = await ApiAxiosGet(
    `${URLORDENS}/queryordenes/${filtroFin}`,
    'GET'
   )
   console.log('🚀 ~ return ~ res:', res)

   dispatch(ordenesLoaded(res))
  } catch (error) {
   console.log('ERRORRROR', error)
  }
 }
}

export const startAnularOrden = (orden) => {
 const { id_localidad, id_sucursal } = orden

 const body = [
  {
   rif: id_sucursal ? id_sucursal?.id_localidad.Rif : id_localidad.Rif,
   codigoSucursal: id_sucursal ? id_sucursal.codigoSucursal : undefined,
   nroOrden: orden.nroOrden,
  },
 ]

 return async (dispatch) => {
  try {
   const res = await ApiAxiosPost(`${URLORDENS}/anular`, 'PUT', body)
   if (res.result.DetalleErrorOrdenes.length > 0) {
    console.log('🚀 ~ return ~ res:', res)
    const error = res.result.DetalleErrorOrdenes.flat()
    console.log('🚀 ~ return ~ error:', error)
    dispatch(
     errorSetActive(
      `Ocurrio un Error al Anular Orden/Guia N°: ${
       error[0].nroOrden || error[0].NroOrden
      } - ${error[0].msg || error[0].Mensaje}`,
      'error'
     )
    )
    throw new Error('Error al anular la orden')
   }

   dispatch(anularOrdenGuia(orden.nroOrden, 'INACTIVO'))

   dispatch(
    errorSetActive(
     `Orden N°: ${res.result.DetalleOrdensAnuladaExitosas[0].NroOrden} - ${res.result.DetalleOrdensAnuladaExitosas[0].Mensaje}`,
     'sucess'
    )
   )
  } catch (error) {
   console.log('ERRORRROR', error)
  }
 }
}

export const startLoadOrdenCodificada = (id) => {
 return async (dispatch) => {
  try {
   const res = await ApiAxiosGet(`${URLORDENS}/ordencodificada/${id}`, 'GET')
   //    dispatch(ordenesLoaded(res))
   return res
  } catch (error) {
   console.log('ERRORRROR', error)
  }
 }
}

export const startFiltroOrdenesAdd = (orden) => {
 console.log('🚀 ~ startFiltroOrdenesAdd ~ orden:', orden)
 return async (dispatch) => {
  try {
   dispatch(filtroOrdenAdd(orden))
  } catch (error) {
   console.log('ERRORRROR', error)
  }
 }
}

export const setUpdateOrden = (valor) => ({
 type: types.setUpdateOrden,
 payload: valor,
})

// dispached para reducer
const ordenesLoaded = (ordenes) => ({
 type: types.ordenesLoaded,
 payload: ordenes,
})

export const ordenesClear = () => ({
 type: types.ordenesClear,
})

export const ordenActive = (orden) => ({
 type: types.ordenesActive,
 payload: orden,
})

export const ordenesClearAvtive = () => ({
 type: types.ordenesClearAvtive,
})

export const filtroOrdenAdd = (orden) => ({
 type: types.filtroOrdenAdd,
 payload: orden,
})

export const ordenesClearFiltro = () => ({
 type: types.ordenesClearFiltro,
})

export const anularOrdenGuia = (idOrden, logEstatusRegistro) => ({
 type: types.anularOrdenGuia,
 payload: { idOrden, logEstatusRegistro },
})

export const ordenClearActive = () => ({
 type: types.ordenClearActive,
})
