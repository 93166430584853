import { types } from '../types/types'

const initialState = {
  id_sucursal: null,
  id_localidad: null,
  loading: false,
  activeNota: null,
  notas: [],
  razonSocial: 0,
  sucursal: 0,
  tipo: null,
  categoria: null,
  nroFactura: null,
  nroNota: null,
  concepto: null,
  importeTotal: 0,
  subTotal: 0,
  montoDescuento: 0,
  totalExento: 0,
  totalExonerado: 0,
  tasaCambioDivisa: 0,
  fechaEmision: null,
  importeTotalDev: 0,
  subTotalDev: 0,
  facturaDivisa: false,
  montoDescuentoDev: 0,
  totalExentoDev: 0,
  totalExoneradoDev: 0,
  lstDetallesNota: [],
  lstGravamenes: [],
}

export const notaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.notaOpenModal:
      return {
        ...state,
        modalNotaOpen: true,
        modalNotaDelete: false,
      }

    case types.notaCloseModal:
      return {
        ...state,
        modalNotaOpen: false,
      }

    case types.notaOpenModalDelete:
      return {
        ...state,
        modalNotaOpen: false,
        modalNotaDelete: true,
      }

    case types.notaCloseModalDelete:
      return {
        ...state,
        modalNotaDelete: false,
      }

    case types.notaSetActive:
      return {
        ...state,
        activeNota: action.payload,
      }

    case types.notaAddNew:
      return {
        ...state,
        notas: [action.payload, ...state.notas],
      }

    case types.notaClearActive:
      return {
        ...state,
        id_sucursal: null,
        id_localidad: null,
        loading: false,
        notas: [],
        razonSocial: 0,
        sucursal: 0,
        tipo: null,
        categoria: null,
        nroFactura: null,
        nroNota: null,
        concepto: null,
        importeTotal: 0,
        subTotal: 0,
        montoDescuento: 0,
        totalExento: 0,
        totalExonerado: 0,
        facturaDivisa: false,
        tasaCambioDivisa: 0,
        fechaEmision: null,
        lstDetallesNota: [],
        lstGravamenes: [],
      }
    case types.activeClearNota:
      return {
        ...state,
        activeNota: null,
      }

    case types.notaUpdated:
      return {
        ...state,
        notas: state.notas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      }

    case types.notaLoaded:
      return {
        ...state,
        notas: action.payload,
      }

    case types.notaDelete:
      return {
        ...state,
        notas: state.notas.filter((i) => i.id !== action.payload),
        activeNota: null,
      }

    case types.notaAnulada:
      return {
        ...state,
        notas: state.notas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
        activeNota: null,
      }

    case types.notaReactived:
      return {
        ...state,
        notas: state.notas.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
        activeNota: null,
      }

    case types.notaDatosAdd:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }
    case types.cargadetalleNota:
      return {
        ...state,
        id_sucursal: action.payload.id_sucursal
          ? action.payload.id_sucursal.id
          : null,
        id_localidad: action.payload.id_localidad
          ? action.payload.id_localidad.id
          : null,
        id_factura: action.payload.id,
        tasaCambio: action.payload.cambioDivisa,
        importeTotal: action.payload.importeTotal,
        subTotal: action.payload.subTotal,
        montoDescuento: action.payload.montoDescuento,
        totalExento: action.payload.totalExento,
        totalExonerado: action.payload.totalExonerado,
        // devoluciones inicio
        importeTotalDev: action.payload.importeTotal,
        subTotalDev: action.payload.subTotal,
        montoDescuentoDev: action.payload.montoDescuento,
        totalExentoDev: action.payload.totalExento,
        totalExoneradoDev: action.payload.totalExonerado,
        // devoluciones fin
        tasaCambioDivisa: action.payload.tasaCambioDivisa,
        nroFactura: action.payload.nroFactura,
        facturaDivisa: action.payload.facturaDivisa,
        lstGravamenes: action.payload.lstGravamenes.reduce(
          (acc, _lstGravamenes) => {
            return acc.concat({
              _id: _lstGravamenes._id,
              baseImponible: _lstGravamenes.baseImponible, // base imponible
              alicuota: _lstGravamenes.alicuota, // alicuota
              montoAlicuota: _lstGravamenes.montoAlicuota, // monto alicuota
              montoAlicuotaDev: _lstGravamenes.montoAlicuota, // monto alicuota Devoluciones
              tipo: _lstGravamenes.tipo, // tipo alicuota))
            })
          },
          []
        ),
        lstDetallesNota: action.payload.lstDetallesFacturaGeneral.reduce(
          (acc, _lstDetalleNota) => {
            return acc.concat({
              _id: _lstDetalleNota._id,
              alicuotaGravamen: _lstDetalleNota.alicuotaGravamen,
              cantidad: _lstDetalleNota.cantidad,
              unidadMedida: _lstDetalleNota.unidadMedida,
              codigoProducto: _lstDetalleNota.codigoProducto,
              descripcion: _lstDetalleNota.descripcion,
              descuento: _lstDetalleNota.descuento,
              exento: _lstDetalleNota.exento,
              exonerado: _lstDetalleNota.exonerado,
              idgravamen:
                _lstDetalleNota.idgravamen === undefined
                  ? null
                  : _lstDetalleNota.idgravamen,
              importe: _lstDetalleNota.importe,
              montoDescuento: _lstDetalleNota.montoDescuento,
              montoGravamen: _lstDetalleNota.montoGravamen,
              precio: _lstDetalleNota.precio,
              precioOriginal: _lstDetalleNota.precio,
              cantidadDevolucion: 0,
              precioDevolucion: 0,
              isDevolucion: false,
            })
          },
          []
        ),
      }

    case types.deletedetalleNota:
      return {
        ...state,
        lstDetallesNota: state.lstDetallesNota.filter(
          (det, i) => i !== action.payload
        ),
      }
    case types.notaUpdateSubTotal:
      return {
        ...state,
        subTotal: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          return acc + _lstDetalle.importe
        }, 0),
        totalExento: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          return acc + (_lstDetalle.exento ? _lstDetalle.importe : 0)
        }, 0),
        totalExonerado: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          return acc + (_lstDetalle.exonerado ? _lstDetalle.importe : 0)
        }, 0),
        montoDescuento: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          return acc + _lstDetalle.montoDescuento
        }, 0),
        importeTotal:
          state.lstDetallesNota.reduce((acc, _lstDetalle) => {
            return acc + _lstDetalle.importe
          }, 0) +
          state.lstGravamenes.reduce((acc, _lstGravamenes) => {
            return acc + _lstGravamenes.montoAlicuota
          }, 0),
      }
    case types.notaUpdateTotalIva:
      return {
        ...state,
        totalIva:
          action.payload.operacion === 'suma'
            ? state.totalIva + action.payload.monto
            : state.totalIva - action.payload.monto,
      }
    case types.notaUpdateImporteTotal:
      return {
        ...state,
        importeTotal:
          action.payload.operacion === 'suma'
            ? parseFloat(
                Number(state.importeTotal + action.payload.monto).toFixed(2)
              )
            : parseFloat(
                Number(state.importeTotal - action.payload.monto).toFixed(2)
              ),
        faltaPorPagar:
          action.payload.operacion === 'suma'
            ? parseFloat(
                Number(state.faltaPorPagar + action.payload.monto).toFixed(2)
              )
            : parseFloat(
                Number(state.faltaPorPagar - action.payload.monto).toFixed(2)
              ),
      }
    case types.notaUpdateExento:
      return {
        ...state,
        totalExento:
          action.payload.operacion === 'suma'
            ? state.totalExento + action.payload.monto
            : state.totalExento - action.payload.monto,
      }
    case types.notaUpdateExonerado:
      return {
        ...state,
        totalExonerado:
          action.payload.operacion === 'suma'
            ? state.totalExonerado + action.payload.monto
            : state.totalExonerado - action.payload.monto,
      }

    case types.notaAddNewDetalle:
      return {
        ...state,
        lstDetallesNota: [action.payload, ...state.lstDetallesNota],
      }

    case types.updateDetalleNotaConexpcion:
      return {
        ...state,
        lstDetallesNota: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          if (action.payload.codigoProducto === _lstDetalle._id) {
            return acc.concat({
              ..._lstDetalle,
              cantidadDevolucion:
                // parseInt(_lstDetalle.cantidadDevolucion) -
                parseInt(action.payload.cantidadDevolucion),
              importe:
                // parseFloat(_lstDetalle.importe) -
                parseFloat(action.payload.importe),
              descuento:
                // parseFloat(_lstDetalle.descuento) -
                parseFloat(action.payload.descuento),
              isDevolucion: action.payload.isDevolucion,
            })
          }
          return acc.concat(_lstDetalle)
        }, []),
      }
    case types.updateDetalleNotaConInpusto:
      return {
        ...state,
        lstDetallesNota: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          if (action.payload.codigoProducto === _lstDetalle._id) {
            return acc.concat({
              ..._lstDetalle,
              cantidadDevolucion:
                // parseInt(_lstDetalle.cantidadDevolucion)  -
                parseInt(action.payload.cantidadDevolucion),
              importe:
                // parseFloat(_lstDetalle.importe) -
                parseFloat(action.payload.importe),

              montoGravamen:
                // parseFloat(_lstDetalle.montoGravamen) -
                parseFloat(action.payload.montoGravamen),
              descuento:
                // parseFloat(_lstDetalle.descuento) -
                parseFloat(action.payload.descuento),
              isDevolucion: action.payload.isDevolucion,
            })
          }
          return acc.concat(_lstDetalle)
        }, []),
      }

    case types.updateDetalleNotaConexpcionPrecio:
      return {
        ...state,
        lstDetallesNota: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          if (action.payload.codigoProducto === _lstDetalle._id) {
            return acc.concat({
              ..._lstDetalle,
              precioDevolucion:
                // parseInt(_lstDetalle.cantidadDevolucion) -
                parseInt(action.payload.precioDevolucion),
              importe:
                // parseFloat(_lstDetalle.importe) -
                parseFloat(action.payload.importe),
              descuento:
                // parseFloat(_lstDetalle.descuento) -
                parseFloat(action.payload.descuento),
              isDevolucion: action.payload.isDevolucion,
            })
          }
          return acc.concat(_lstDetalle)
        }, []),
      }
    case types.updateDetalleNotaConInpustoPrecio:
      return {
        ...state,
        lstDetallesNota: state.lstDetallesNota.reduce((acc, _lstDetalle) => {
          if (action.payload.codigoProducto === _lstDetalle._id) {
            return acc.concat({
              ..._lstDetalle,
              precioDevolucion:
                // parseInt(_lstDetalle.cantidadDevolucion)  -
                parseInt(action.payload.precioDevolucion),
              importe:
                // parseFloat(_lstDetalle.importe) -
                parseFloat(action.payload.importe),

              montoGravamen:
                // parseFloat(_lstDetalle.montoGravamen) -
                parseFloat(action.payload.montoGravamen),
              descuento:
                // parseFloat(_lstDetalle.descuento) -
                parseFloat(action.payload.descuento),
              isDevolucion: action.payload.isDevolucion,
            })
          }
          return acc.concat(_lstDetalle)
        }, []),
      }

    case types.notaUpdatelLstGravamenes:
      return {
        ...state,
        lstGravamenes: state.lstGravamenes.map((grav) =>
          grav.tipo === action.payload.tipo
            ? {
                ...grav,
                baseImponible:
                  grav.baseImponible + action.payload.baseImponible,
                montoAlicuota:
                  grav.montoAlicuota + action.payload.montoAlicuota,
              }
            : grav
        ),
      }

    // Sjumar gragamenes luego de modficar para generar totaltes
    case types.notaTotales:
      return {
        ...state,
        lstGravamenes: state.lstGravamenes.map((grav) => {
          return grav.alicuota === action.payload.alicuotaGravamen
            ? {
                ...grav,
                montoAlicuota: state.lstDetallesNota.reduce(
                  (acc, _lstDetalle) => {
                    if (
                      action.payload.alicuotaGravamen ===
                      _lstDetalle.alicuotaGravamen
                    ) {
                      return acc + _lstDetalle.montoGravamen
                    }
                    return acc
                  },
                  0
                ),
              }
            : grav
        }),
      }

    // modificada por ph para no duplicar las gravamens en los totales
    case types.notaAddNewLstGravamen:
      const IsGramavames = state.lstGravamenes.some(
        (gra) => gra.tipo === action.payload.tipo
      )
      return {
        ...state,
        lstGravamenes: !IsGramavames
          ? [action.payload, ...state.lstGravamenes]
          : state.lstGravamenes.reduce((acc, _lstGravamenes) => {
              if (action.payload.tipo === _lstGravamenes.tipo) {
                return acc.concat({
                  ..._lstGravamenes,
                  baseImponible: parseFloat(_lstGravamenes.baseImponible),
                  // -
                  // parseFloat(action.payload.baseImponible),
                  montoAlicuota: parseFloat(_lstGravamenes.montoAlicuota),
                  //  -
                  // parseFloat(action.payload.montoAlicuota),
                })
              }

              return acc.concat([_lstGravamenes])
            }, []),
      }
    case types.notaUpdateTotalDescuento:
      return {
        ...state,
        montoDescuento:
          action.payload.operacion === 'suma'
            ? state.montoDescuento + action.payload.monto
            : state.montoDescuento - action.payload.monto,
      }
    case types.NotaGeneradaUpdate:
      return {
        ...state,
        notas: state.notas.reduce((acc, _nota) => {
          if (action.payload.idNota === _nota.id) {
            return acc.concat({
              ..._nota,
              notaCodificada: action.payload.NotaCodficada64.notaCodificada,
            })
          }
          return acc.concat(_nota)
        }, []),
      }

    case types.notaDetalleajustado:
      return {
        ...state,
        lstDetallesNota: action.payload,
      }
    case types.NotaGeneraLoaded:
      return {
        ...state,
        notas: action.payload,
      }

    default:
      return state
  }
}
