import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { FormatedBolivares } from '../../../../Helpers/formater'
// import { useStyles } from './Styles'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '10px',
    textAlign: 'right',
  },
  dbasicos: {
    fontSize: '12px',
    padding: '0px 8px 0px 0px',
  },
  headers: {
    fontSize: '10px',
    textAlign: 'left',
    fontWeight: 'bold',
    padding: '2px 4px 2px 4px',
    border: '.1px solid #000',
    backgroundColor: '#e0e0e0',
  },
  body: {
    fontSize: '9px',
    textAlign: 'left',
    alignItems: 'end',
  },
}))

function CondicionPago({ Condicionpago, lstPagos }) {
  const classes = useStyles()
  return (
    <>
      <Grid container>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <Typography className={classes.headers}>Medio de Pago</Typography>
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <Typography className={classes.headers}>Banco</Typography>
        </Grid>
        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <Typography className={classes.headers}>comprob.</Typography>
        </Grid>
        <Grid item lg={1} sm={1} xl={1} xs={1}>
          <Typography className={classes.headers}>Divisa</Typography>
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <Typography
            style={{ textAlign: 'right' }}
            className={classes.headers}
          >
            Monto
          </Typography>
        </Grid>

        {lstPagos.map((pago, i) => {
          return (
            <Grid container key={i} style={{ alignItems: 'center' }}>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <Typography className={classes.body}>
                  {pago.modoPago}
                </Typography>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <Typography className={classes.body}>
                  {pago.banco?.nombre}
                  {/* {buscarBanco(pago.banco)?.nombre} */}
                </Typography>
              </Grid>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <Typography
                  style={{ textAlign: 'center' }}
                  className={classes.body}
                >
                  {pago.nro || 0}
                </Typography>
              </Grid>
              <Grid item lg={1} sm={1} xl={1} xs={1}>
                <Typography
                  style={{ textAlign: 'center' }}
                  className={classes.body}
                >
                  {pago.divisa.siglas}

                  {/* {buscardivisa(pago.divisa.id)?.siglas} */}
                </Typography>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <Typography
                  style={{ textAlign: 'right' }}
                  className={classes.body}
                >
                  {pago.divisa.id === 1 &&
                    FormatedBolivares(pago.monto, 'es-VE', 'VEN')}
                  {pago.divisa.id === 3 &&
                    FormatedBolivares(pago.monto, 'es-US', 'USD')}
                  {pago.divisa.id === 2 &&
                    FormatedBolivares(pago.monto, 'es-US', 'EUR')}
                  {pago.divisa.id === 4 &&
                    FormatedBolivares(pago.monto, 'es-CO', 'COP')}
                </Typography>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default CondicionPago
