import { useSelector, useDispatch } from 'react-redux'

import { Button, Container, Grid } from '@material-ui/core'
// import RazonSocial from './RazonSocial'
// import useStyles from './Styles'
import { useToast } from '../../../hooks/useToast'
import GenerarLibroVentas from './GenerarLibroVentas'
import VerLibroVentas from './VerLibroVentas'
import ResumenLibroVentas from './ResumenLibroVentas'
import { useEffect } from 'react'
import { reporteClear } from '../../../redux/actions/reporteActions'
import GenerarLibroOrdenes from './GenerarLibroOrdenes'
import ResumenLibroOrdenes from './ResumenLibroOrdenes'
import VerLibroOrdenes from './VerLibroOrdenes'

const LibroOrdenes = () => {
  // const history = useHistory()
  // const classes = useStyles()
  const { notifyError, notifysucess } = useToast()
  const { isError, error, tipoMsg } = useSelector((state) => state.error)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(reporteClear())
  }, [dispatch])

  return (
    <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <GenerarLibroOrdenes />
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <ResumenLibroOrdenes />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <VerLibroOrdenes />
        </Grid>

        {isError && tipoMsg === 'sucess'
          ? notifysucess()
          : tipoMsg === 'error' &&
            error !== 'no hay Sucursal para esta localidad' &&
            notifyError()}
      </Grid>
    </Container>
  )
}

export default LibroOrdenes
