import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';
import { incotermCloseModal, startSaveIncoterm, startUpdateIncoterm, incotermClearActive } from '../../../redux/actions/incotermsActions';

const initIncoterm = {
    nombre: '',
    descripcion: '',
    tipo: -1,
    logEstatusRegistro: "1",
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}

Modal.setAppElement('#root');

export const IncotermModal = () => {

    const dispatch = useDispatch();

    const { activeIncoterm, modalIncotermOpen } = useSelector(state => state.incoterm);

    const [formValues, setFormValues] = useState( initIncoterm );

    const { nombre, descripcion, tipo } = formValues;

    useEffect(() => {
        if ( activeIncoterm ) {
            setFormValues( activeIncoterm );
        } else {
            setFormValues( initIncoterm );
        }
    }, [activeIncoterm, setFormValues])

    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const closeModal = () => {

        dispatch(incotermCloseModal());
        dispatch(incotermClearActive());
        setFormValues(initIncoterm);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (nombre.trim().length < 2) {
            return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        }

        if (activeIncoterm) {
            dispatch(startUpdateIncoterm(formValues));
        } else {
            dispatch(startSaveIncoterm({
                ...formValues
            }));
        }

        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',

        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={modalIncotermOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            { (activeIncoterm) ? <h3>Editar Incoterm</h3> : <h3>Nuevo Incoterm</h3> } 
            
            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >

                <div className={styles.modal}>
                    <div className="form-group row">
                    <div className="col-md">
                    <label htmlFor="nombre" className="form-label">Nombre</label>
                        <input
                            type="text"
                            id='nombre'
                            className="form-control form-control-sm"
                            placeholder="Nombre"
                            name="nombre"
                            autoComplete="off"
                            value={nombre}
                            onChange={handleInputChange}
                        />
                    </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md">
                        <label htmlFor="descripcion" className="form-label">Descripción</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id='descripcion'
                            placeholder="Descripción"
                            name="descripcion"
                            autoComplete="off"
                            value={ descripcion }
                            onChange={handleInputChange}
                        />
                        </div>
                    </div>
                    <div className="form-group row mb-3">
                    <div className="col-md">
                        <label htmlFor="tipo" className="form-label">Tipo</label>
                        <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm"
                            label="tipo"
                            id="tipo"
                            value={tipo}
                            onChange={handleInputChange}
                            name="tipo"
                            required={true}
                        >
                            <option defaultValue="Seleccione...">Seleccione...</option>
                            <option key="0" value="GRUPO_C">GRUPO C</option>
                            <option key="1" value="GRUPO_D">GRUPO D</option>
                            <option key="2" value="GRUPO_E">GRUPO E</option>
                            <option key="3" value="GRUPO_F">GRUPO F</option>
                        </select>
                    </div>
                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block"
                    >
                        <i className="far fa-save"></i>
                        <span> Guardar</span>
                    </button>
                </div>
            </form>
        </Modal>

    )
}


