import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

import Swal from 'sweetalert2'
import {
  categoriaNotaCloseModal,
  startSaveCategoriaNota,
  startUpdateCategoriaNota,
  categoriaNotaClearActive,
} from '../../../redux/actions/categoriaNotaActions'

const initCategoriaNota = {
  id: '',
  nombre: '',
  tipoNota: '',
  logEstatusRegistro: '1',
  logFechaRegistro: new Date(),
  logUsername: 'sadmin',
  logFechaModificacion: null,
}

Modal.setAppElement('#root')

export const CategoriaNotaModal = () => {
  const dispatch = useDispatch()

  const { activeCategoriaNota, modalCategoriaNotaOpen } = useSelector(
    (state) => state.categoriaNota
  )

  const [formValues, setFormValues] = useState(initCategoriaNota)

  const { nombre, tipoNota } = formValues

  useEffect(() => {
    if (activeCategoriaNota) {
      setFormValues(activeCategoriaNota)
    } else {
      setFormValues(initCategoriaNota)
    }
  }, [activeCategoriaNota, setFormValues])

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    })
  }

  const closeModal = () => {
    dispatch(categoriaNotaCloseModal())
    dispatch(categoriaNotaClearActive())
    setFormValues(initCategoriaNota)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (nombre.trim().length < 2) {
      return Swal.fire(
        'Error',
        'El nombre debe contener al menos 2 caracteres',
        'error'
      )
    }

    if (activeCategoriaNota) {
      dispatch(startUpdateCategoriaNota(formValues))
    } else {
      dispatch(
        startSaveCategoriaNota({
          ...formValues,
        })
      )
    }

    closeModal()
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalCategoriaNotaOpen}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      {activeCategoriaNota ? (
        <h3>Editar Categoría Nota</h3>
      ) : (
        <h3>Nueva Categoría Nota</h3>
      )}

      <hr />
      <form className='container' onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className='form-group row'>
            <div className='col-md'>
              <label htmlFor='nombre' className='form-label'>
                Nombre
              </label>
              <input
                type='text'
                id='nombre'
                className='form-control form-control-sm'
                placeholder='Nombre'
                name='nombre'
                autoComplete='off'
                value={nombre}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-md'>
              <label htmlFor='tipoNota' className='form-label'>
                Tipo
              </label>
              <select
                className='form-select form-select-sm'
                aria-label='.form-select-sm'
                label='tipoNota'
                id='tipoNota'
                value={tipoNota}
                onChange={handleInputChange}
                name='tipoNota'
                required={true}
              >
                <option defaultValue='Seleccione...'>Seleccione...</option>
                <option key='0' value='CREDITO'>
                  Crédito
                </option>
                <option key='1' value='DEBITO'>
                  Débito
                </option>
                <option key='2' value='ENTREGA'>
                  Entrega
                </option>
              </select>
            </div>
          </div>

          <br />
          <button type='submit' className='btn btn-outline-primary btn-block'>
            <i className='far fa-save'></i>
            <span> Guardar</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
