import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Grid,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

import useStyles from './Styles'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'

// import { startActivaenvioEmail } from '../../../redux/actions/localidadActions'
import { startActivaEnvioEmail } from '../../../redux/actions/sucursalActions'
import ParamsNroFactura from './ParamsNroFactura'
import ActivaMarcaAgua from './ActivaMarcaAgua'

export default function Parametros({ open, handleClose, title }) {
  const { activeSucursal } = useSelector((state) => state.sucursal)

  const classes = useStyles()
  const dispatch = useDispatch()
  const [activaEnvioEmail, setactivaEnvioEmail] = useState(
    activeSucursal?.envioEmail
  )

  // const { estados, municipio, buscarMunicipio } = useEstados(),
  const { handleSubmit, control } = useForm()

  const onSubmitParamtros = async () => {
    const paramters = {
      activaEnvioEmail,
      id: activeSucursal.id,
    }
    console.log(' paramters', paramters)
    dispatch(startActivaEnvioEmail(paramters))
    // .then((resul) => {
    //   console.log('result PARAM SUCURSAL', resul)
    // if (resul.payload.tipoMsg === 'sucessresul') {
    //   handleClose()
    // }
    // })
  }

  const handleChange = (e) => {
    const { name, checked, value } = e.target
    console.log('e.target', name, checked, value)
    setactivaEnvioEmail(checked)
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitParamtros)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={10} sm={10} xl={10} xs={10}>
            <FormControlLabel
              style={{ margitTop: '8px' }}
              className={classes.switchgenerico}
              labelPlacement='end'
              control={
                <Switch
                  name='activaEnvioEmail'
                  defaultValue={activeSucursal?.envioEmail}
                  defaultChecked={activeSucursal?.envioEmail}
                  checked={activaEnvioEmail}
                />
              }
              label='Activar envio de Facturas por correo'
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item lg={2} sm={2} xl={2} xs={2}>
            <IconButton
              // className={classes.btn}
              onClick={onSubmitParamtros}
              size='small'
              // type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              <CheckIcon />
            </IconButton>
          </Grid>
        </Grid>
        <ActivaMarcaAgua />
        <ParamsNroFactura />
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
