import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MunicipioModal } from './MunicipioModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import {
  municipioOpenModal,
  municipioOpenModalDelete,
  municipioSetActive,
  startLoadMunicipios,
  startReactivedMunicipio,
} from '../../../redux/actions/municipioActions'
import { MunicipioModalDelete } from './MunicipioModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'

const Municipio = () => {
  const dispatch = useDispatch()

  const { municipios, modalMunicipioDelete } = useSelector(
    (state) => state.municipio
  )

  const columnas = [
    {
      title: 'Nombre',
      field: 'municipio',
      defaultSort: 'asc',
    },
    {
      title: 'Codigo',
      field: 'iso_3166-2',
    },
  ]

  useEffect(() => {
    dispatch(startLoadMunicipios())
  }, [dispatch])

  const onUpdateMunicipio = (e) => {
    dispatch(municipioSetActive(e))
    dispatch(municipioOpenModal())
  }

  const handleDelete = (e) => {
    dispatch(municipioSetActive(e))
    dispatch(municipioOpenModalDelete())
  }

  const handleReactive = (e) => {
    dispatch(startReactivedMunicipio(e))
  }

  const openModal = () => {
    dispatch(municipioOpenModal())
  }

  return (
    <div>
      <br />
      <MunicipioModal />
      {modalMunicipioDelete && <MunicipioModalDelete />}
      <MaterialTable
        title='Municipios'
        columns={columnas}
        data={municipios}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nuevo Municipio',
            isFreeAction: true,
            onClick: (event) => {
              openModal()
            },
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: 'Modificar',
            onClick: (event, rowData) => onUpdateMunicipio(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <PowerSettingsNewIcon />,
            tooltip: 'Inactivar',
            onClick: (event, rowData) => handleDelete(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon />,
            tooltip: 'Activar',
            onClick: (event, rowData) => handleReactive(rowData),
            hidden: rowData.logEstatusRegistro === 'ACTIVO',
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
    </div>
  )
}

export default Municipio
