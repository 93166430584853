import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    minHeight: '300px',
    // border: 'solid 1px black',
  },
  cardheader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '2px 8px 2px 8px',
  },
  cardcontent: {
    overflowY: 'scroll',
    scrollBehavior: 'smooth',
    marginBottom: '6px',
  },
  text: {
    '& .MuiFormLabel-root': {
      fontSize: '0.7rem',
      // color: '#054477',
    },
    '& .MuiInputBase-input': {
      fontSize: '0.7rem',
      color: '#054477',
    },
  },
  select: {
    fontSize: '0.8rem',
    color: '#054477',
    '& .MuiSelect-select	': {
      fontSize: '0.8rem',
      color: '#054477',
    },
  },
  encabezado: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    color: '#054477',
    backgroundColor: '#f2f2f2',
    borderRadius: '4px',
    textAlign: 'right',
  },
  detalle: {
    fontSize: '0.6rem',
    color: '#054477',
    textAlign: 'right',
    padding: 0,
    // margin: '0, 8, 0, 8'
  },
  texttotal: {
    fontSize: '0.7rem',
    color: '#054477',
    textAlign: 'left',
  },
  texttotalmonto: {
    fontSize: '0.7rem',
    color: '#054477',
    textAlign: 'right',
  },
  texttotalgen: {
    fontSize: '0.7rem',
    color: '#054477',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  texttotalgenmonto: {
    fontSize: '0.7rem',
    color: '#054477',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  textdetalleActivo: {
    color: '054477',
  },
  textdetalleinactivo: {
    color: 'tomato',
    fontWeight: 'bold',
  },
  textdetallprecio: {
    color: 'blue',
    fontWeight: 'bold',
  },
  margenAgregarnota: {
    margin: '0, 8, 0, 8',
  },
  jsonView: {
    maxWidth: '70vh',
    padding: '8px',
    backgroundColor: '#f5f5f5',
    overflowY: 'scroll',
    maxHeight: '65vh',
  },
  jsonItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignContent: 'center',
    alignItems: 'stretch',
    gap: '12px',
  },
  jsonKey: {
    color: '#00f',
    fontSize: '14px',
  },
  jsonValue: {
    maxWidth: '250px',
    color: '#f00',
    fontSize: '12px',
    alignSelf: 'justify',
  },
  jsonItemProducto: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    // gap: '6px',
  },
  ViewGenerico: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignContent: 'center',
    alignItems: 'stretch',
    gap: '12px',
  },
}))

export default useStyles
