import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { startDeleteFacturaGeneral } from '../../../../redux/actions/facturaGeneralActions'
import ModalGenerico from '../../../commons/ui/Modal'
import { useStyles } from './Styles'

const FacturaGeneralDelete = ({ handleClose, open, title }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(startDeleteFacturaGeneral(activeFacturaGeneral.id))
    handleClose()
  }

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      // top={55}
      // left={55}
    >
      <form onSubmit={handleSubmit}>
        <Container
          style={{
            backgroundColor: '#f5f5f5',
            // Width: '40vh',
            // minHeight: '20vh',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`N° Factura: ${activeFacturaGeneral?.nroFactura}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Cliente: ${activeFacturaGeneral?.cliente.identificacion}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Telefono Movil: ${activeFacturaGeneral?.cliente.telefonoMovil}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Correo: ${activeFacturaGeneral?.cliente.correo}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                Eliminar
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </ModalGenerico>
  )
}

export default FacturaGeneralDelete
