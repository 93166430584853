import {useState} from 'react';



export const useModal = (initialState = false) => {
  const [open, setOpen] = useState(initialState)

  const handleOpen = () => {
    console.log(open, 'entro')
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return {handleOpen, handleClose, open }
}
