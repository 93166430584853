import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/facturacion/facturaCompra`;

export const startLoadFacturasCompras = () => {
    return (dispatch) => {

        axios.get(`${url}/listar`)
        .then(res => {
            dispatch(facturaCompraLoaded(res.data));
        });
    }
}

export const startSaveFacturaCompra = (facturaCompra) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: facturaCompra
        })
            .then((response) => {
                
                Swal.fire('Guardado', facturaCompra.nroControl, 'success');
                dispatch(facturaCompraAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeleteFacturaCompra = () => {

    return async (dispatch, getState) => {
       
        const { activeFacturaCompra } = getState().facturaCompra;
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeFacturaCompra.id}`
        })
            .then((response) => {
                
                Swal.fire('Inactivado', activeFacturaCompra.nroFactura, 'success');
                dispatch(deleteFacturaCompra(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedFacturaCompra = (facturaCompra) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${facturaCompra.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', facturaCompra.nroFactura, 'success');
                dispatch(reactiveFacturaCompra(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateFacturaCompra = (facturaCompra) => {

    return (dispatch, getState) => {

        const { activeFacturaCompra } = getState().facturaCompra;
        const id = activeFacturaCompra.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: facturaCompra
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.data.nroFactura, 'success');
                dispatch(facturaCompraUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}

export const startDeleteDetalle = (index) => {

        console.log("Index: " + index);
    return (dispatch, getState) => {
         
        dispatch(deleteDetalle(index));
    }
}

export const startNewDetalle = (detalle) => {

return (dispatch, getState) => {
     
    const { productosByLocalidad } = getState().producto;

    const prod = productosByLocalidad.find(p => p.id === detalle.producto);

    dispatch(facturaCompraAddNewDetalle({
        producto: prod.descripcion,
        cantidad: detalle.cantidad,
        precio: prod.precio,
        importe: prod.precio * detalle.cantidad
    }));
}
}

const facturaCompraLoaded = (facturaCompraes) => ({
    type: types.facturaCompraLoaded,
    payload: facturaCompraes
});

export const facturaCompraOpenModal = () => ({
    type: types.facturaCompraOpenModal
});

export const facturaCompraCloseModal = () => ({
    type: types.facturaCompraCloseModal
});

export const facturaCompraOpenDetalleModal = () => ({
    type: types.facturaCompraOpenDetalleModal
});

export const facturaCompraCloseDetalleModal = () => ({
    type: types.facturaCompraCloseDetalleModal
});

export const facturaCompraOpenModalDelete = () => ({
    type: types.facturaCompraOpenModalDelete
});

export const facturaCompraCloseModalDelete = () => ({
    type: types.facturaCompraCloseModalDelete
})

export const facturaCompraAddNew = ( facturaCompra ) => ({
    type: types.facturaCompraAddNew,
    payload: facturaCompra
});

export const facturaCompraSetActive = ( facturaCompra ) => ({
    type: types.facturaCompraSetActive,
    payload: facturaCompra
});

export const facturaCompraClearActive = ( ) => ({
    type: types.facturaCompraClearActive,
});

export const facturaCompraUpdated = ( facturaCompra ) => ({
    type: types.facturaCompraUpdated,
    payload: facturaCompra
});

export const deleteFacturaCompra = ( facturaCompra ) => ({
    type: types.facturaCompraDelete,
    payload: facturaCompra
});

export const reactiveFacturaCompra = ( facturaCompra ) => ({
    type: types.facturaCompraReactived,
    payload: facturaCompra
});

//Acciones para Detalle
export const facturaCompraAddNewDetalle = ( detalle ) => ({
    type: types.facturaCompraAddNewDetalle,
    payload: detalle
});

export const deleteDetalle = ( index ) => ({
    type: types.facturaCompraDeleteDetalle,
    payload: index
});

//Acciones para proveedores
export const setProveedorActive = ( proveedor ) => ({
    type: types.facturaCompraProveedorSelected,
    payload: proveedor
});

export const setProveedorClearActive = ( ) => ({
    type: types.facturaCompraProveedorClearSelected,
});

//Acciones para Localidad
export const setLocalidadActive = ( localidad ) => ({
    type: types.facturaCompraLocalidadSelected,
    payload: localidad
});

export const setLocalidadClearActive = ( ) => ({
    type: types.facturaCompraLocalidadClearSelected,
});


//Acciones para Sucursal
export const setSucursalActive = ( sucursal ) => ({
    type: types.facturaCompraSucursalSelected,
    payload: sucursal
});

export const setSucursalClearActive = ( ) => ({
    type: types.facturaCompraSucursalClearSelected,
});

//Acciones para Tipo Compra
export const setTipoCompraActive = ( tipoCompra ) => ({
    type: types.facturaCompraTipoCompraSelected,
    payload: tipoCompra
});

export const setTipoCompraClearActive = ( ) => ({
    type: types.facturaCompraTipoCompraClearSelected,
});
