import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useStyles } from './Styles'
import { FormatedBolivares } from '../../../../Helpers/formater'

function TotalesGenerales({ activeFacturaGeneral }) {
  const {
    subTotal,
    importeTotal,
    totalDescuento,
    totalExento,
    totalExonerado,
    lstGravamenes,
  } = activeFacturaGeneral
  const classes = useStyles()

  return (
    <Grid container style={{ marginTop: '16px', marginBottom: '16px' }}>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          SubTotal:
        </Typography>
      </Grid>
      <Grid item lg={4} sm={4} xl={4} xs={4}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          {FormatedBolivares(subTotal, 'es-VE', 'VEN')}
        </Typography>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          Exonerado
        </Typography>
      </Grid>
      <Grid item lg={4} sm={4} xl={4} xs={4}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          {FormatedBolivares(totalExonerado, 'es-VE', 'VEF')}
        </Typography>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          Exento
        </Typography>
      </Grid>
      <Grid item lg={4} sm={4} xl={4} xs={4}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          {/* 0,00 */}
          {FormatedBolivares(totalExento, 'es-VE', 'VEN')}
        </Typography>
      </Grid>
      {lstGravamenes?.map((gravamen) => {
        return (
          <Grid container key={gravamen.id}>
            <Grid item lg={8} sm={8} xl={8} xs={8}>
              <Typography
                className={classes.textotitulos}
                style={{ textAlign: 'right' }}
              >
                {`IVA ${gravamen?.alicuota}%`}
              </Typography>
            </Grid>
            <Grid item lg={4} sm={4} xl={4} xs={4}>
              <Typography
                className={classes.textotitulos}
                style={{ textAlign: 'right' }}
              >
                {FormatedBolivares(gravamen.montoAlicuota, 'es-VE', 'VEN')}
              </Typography>
            </Grid>
          </Grid>
        )
      })}

      <Grid item lg={8} sm={8} xl={8} xs={8}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          Descuento
        </Typography>
      </Grid>
      <Grid item lg={4} sm={4} xl={4} xs={4}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          {FormatedBolivares(totalDescuento, 'es-VE', 'VEN')}
        </Typography>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          TOTAL
        </Typography>
      </Grid>
      <Grid item lg={4} sm={4} xl={4} xs={4}>
        <Typography
          className={classes.textotitulos}
          style={{ textAlign: 'right' }}
        >
          {FormatedBolivares(importeTotal, 'es-VE', 'VEN')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TotalesGenerales
