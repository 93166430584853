import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    // height: '22px',
    '& .MuiAutocomplete-inputRoot': {
      fontSize: '12px',
      color: '#01579b',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '10px',
      color: '#01579b',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      // marginTop: 0,
    },
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '12px',
    // '& .MuiTypography-root': {
    //   fontSize: '12px',
    // },
  },
  cardheader_icons: {
    color: 'white',
    // padding: '8px 8px 8px 0',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  texto: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  switchgenerico: {
    marginTop: '8px',
    '& .MuiTypography-body1': {
      marginLeft: '8px',
      fontSize: '14px',
    },
  },
  btn: {
    borderColor: '#becddc',
    fontSize: '0.75rem',
    // backgroundColor: '#054477',
    color: 'black',
    marginTop: '24px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 20,
    // backgroundColor: '#054477',
    // '&:hover': {
    //   backgroundColor: '#1857a4',
    // },
  },
  bbtnref: {
    border: '2px solid #054477',
    borderRadius: '6px',
    backgroundColor: '#054477',
    color: 'white',
    padding: '15px 15px 15px 15px',
    textDecorationLine: 'none',
    textDecorationColor: 'none',
    fontSize: '16px',
    margin: '4px 2pxx',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      color: '#05447e',
    },
  },
  textField: {
    marginTop: '16px',
  },
  nameorden: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '.780rem',
    color: '#122740',
  },
  name: {
    textAlign: 'right',
    fontWeight: 600,
    fontSize: '.780rem',
    color: '#122740',
  },
  caption: {
    fontSize: '0.775rem',
    color: '#758392',
    marginTop: -4,
    textAlign: 'right',
  },
  caption2: {
    fontSize: '0.775rem',
    color: '#758392',
    marginTop: -4,
    textAlign: 'left',
  },
  wrapper: {
    display: 'grid',
    gridGap: '10px',
    gridTemplateColumns: 'repeat(3, 1fr)',
    /* Aquí puedes especificar el tamaño de las columnas */
    gridTemplateRows: '33% 33% 33%',

    // gridtemplate-columns: [col1-start] 100px [col2-start] 100px [col3-start] 100px;
    // grid-template-rows: [row1-start] auto [row2-start] auto [row2-end];
    // background-color: #fff;
    // color: #444;
  },
  button: {
    // margin: '8px 8px 8px 8px',
    //   margin: '10px',
    width: '100%',
    borderRadius: '20px',
    // backgroundColor: 'gray',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  listarItem: {
    margin: '0px 0px 0px 0px',
    width: '100%',
    fontSize: '10px',
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  listar: {
    width: 'auto',
    //   maxWidth: 360,
    backgroundColor: '#ebedef',
    position: 'relative',
    overflow: 'auto',
    height: 200,
    '& ul': { padding: 0 },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: 'white',
    margin: 2,
  },
  formControl: {
    // margin: theme.spacing(1),
    width: 320,
    maxWidth: 380,
  },
}))

export default useStyles
