import { useDispatch, useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
// import useStyles from './Styles'

// import { startLoadProductosByLocalidad } from '../../../../redux/actions/productoActions'

export default function VerLogs(logs) { 
  const { reporteLogPdf } = useSelector((state) => state.reporte)
  // const classes = useStyles()

  console.log('reporteLogPdf WWWWW', reporteLogPdf)

  return (
    <Grid container style={{ alignItems: 'center' }} spacing={0}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        {/* mostar archivo pdf */}
        {reporteLogPdf ? (
          <iframe
            src={`data:application/pdf;base64,${reporteLogPdf}`}
            title='Logs de Eventos'
            style={{
              width: '100%',
              height: '50vh',
            }}
          />
        ) : (
          <div>No hay datos para mostrar</div>
        )}
      </Grid>
    </Grid>
  )
}
