import { types } from '../types/types'

const initialState = {
  controlesByLcalidad: [],
  controlesActiveLcalidad: null,
  controlesBySucursal: [],
  controlesActivesucursal: null,
  cantidadFact: null,
  nroSubControl: null,
  nroControlDesde: null,
  nroControlHasta: null,
}

export const controlReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.controlFacturaSetActive:
      return {
        ...state,
        controlesActiveLcalidad: action.payload,
      }

    case types.controlFacturaAddNew:
      return {
        ...state,
        controlesByLcalidad: [action.payload, ...state.controlesByLcalidad],
      }

    case types.controlFacturaClearActive:
      return {
        ...state,
        controlesActiveLcalidad: null,
      }
    case types.controlFacturaClearLoaded:
      return {
        ...state,
        controlesByLcalidad: [],
        controlesActiveLcalidad: null,
      }

    case types.controlFacturaLoaded:
      return {
        ...state,
        controlesByLcalidad: action.payload,
      }

    // Sucursal
    case types.controlSucursaloaded:
      return {
        ...state,
        controlesBySucursal: action.payload,
      }

      
      case types.ControlSucursalClearloded:
        return {
          ...state,
          controlesBySucursal: [],
          controlesActivesucursal: null,
        }
  

    // case types.bancoDelete:
    //   return {
    //     ...state,
    //     bancos: state.bancos.map((b) =>
    //       b.id === action.payload.id ? action.payload : b
    //     ),
    //     activeBanco: null,
    //   }

    // case types.bancoReactived:
    //   return {
    //     ...state,
    //     bancos: state.bancos.map((b) =>
    //       b.id === action.payload.id ? action.payload : b
    //     ),
    //     activeBanco: null,
    //   }

    default:
      return state
  }
}
