import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Grid,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'

import useStyles from './Styles'
// import toast from 'react-hot-toast'
// import Visibility from '@material-ui/icons/Visibility'
// import VisibilityOff from '@material-ui/icons/VisibilityOff'

// import * as yup from 'yup'
// import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'

import { startActivaenvioEmail } from '../../../redux/actions/localidadActions'
import ParamsNroFactura from './ParamsNroFactura'
import ActivaMarcaAgua from './ActivaMarcaAgua'

export default function Parametros({ open, handleClose, title }) {
  // const [formdata, setFormData] = useState()
  const { activeLocalidad } = useSelector((state) => state.localidad)
  // const [showPassword, setshowPassword] = useState(false)
  const [activaEnvioEmail, setactivaEnvioEmail] = useState(
    activeLocalidad?.envioEmail
  )

  const classes = useStyles()
  const dispatch = useDispatch()
  // const { handleSubmit, control } = useForm()
  // { resolver: yupResolver(schema) }

  // const notifyerror = (res) => toast.error(res)

  const onSubmitParamtros = async () => {
    // console.log(' dataeeeeeee', data, activeLocalidad._id)

    const paramters = {
      activaEnvioEmail,
      _id: activeLocalidad._id,
    }
    console.log(' paramters', paramters)
    dispatch(startActivaenvioEmail(paramters))
    // .then((resul) => {
    //   if (resul.payload.tipoMsg === 'sucess') {
    //     handleClose()
    //   }
    // })
  }

  const handleChange = (e) => {
    const { name, checked, value } = e.target
    console.log('e.target', name, checked, value)
    setactivaEnvioEmail(checked)
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <Grid container spacing={2} className={classes.root}>
        <Grid item lg={10} sm={10} xl={10} xs={10}>
          <FormControlLabel
            style={{ margitTop: '8px' }}
            className={classes.switchgenerico}
            labelPlacement='end'
            control={
              <Switch
                name='activaEnvioEmail'
                // defaultValue={activeLocalidad?.envioEmail}
                defaultChecked={activeLocalidad?.envioEmail}
                checked={activaEnvioEmail}
                onChange={(e) => handleChange(e)}
              />
            }
            label='Activar envio de Facturas por correo'
            // value={value}

            // onClick={onSubmitParamtros}
          />
        </Grid>

        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <IconButton
            // className={classes.btn}
            onClick={onSubmitParamtros}
            size='small'
            // type='submit'
            variant='contained'
            color='primary'
            fullWidth
            //  onClick={() => peticionPost()}
          >
            <CheckIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ActivaMarcaAgua />
      <ParamsNroFactura />
      <Grid container spacing={2} className={classes.margen}>
        {/* <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              // type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Aceptar
            </Button>
          </Grid> */}
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Button
            className={classes.btn}
            size='small'
            variant='contained'
            fullWidth
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Grid>
      </Grid>
      {/* </form> */}
    </ModalGenerico>
  )
}
