import NumberFormat from 'react-number-format'

import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import // phoneRegExp,
// tipodoc,
// tipoProveedor,
'../../../Constants/ConstantesFacturas'

import ModalGenerico from '../../commons/ui/Modal'
// import { startLoadEstados } from '../../../redux/actions/estadoActions'
import { useEstados } from '../../../hooks/useEstados'
// import { startSaveLocalidad } from '../../../redux/actions/localidadActions'
// import { convertBase64 } from '../../../Helpers/formater'
import { useMunicipio } from '../../../hooks/useMunicipios'
import { startLoadMunicipiosByEstado } from '../../../redux/actions/municipioActions'
import { startSaveSucursal } from '../../../redux/actions/sucursalActions'
// import { $CombinedState } from 'redux'
// import { startSaveProducto } from '../../../redux/actions/productoActions'
import useStyles from './Styles'
import { filtroNumLetras, soloTextyNumeros } from '../../../Helpers/formater'

const schema = yup.object().shape({
  correo: yup
    .string()
    .email('debe ser un correo valido')
    .required('Es requerido'),
  direccion: yup
    .string()
    .max(250, 'Maximo 250 caracteres permitidos, para la descripcion')
    .required('Es requerido'),
  estado: yup.string().required('Es requerido'),
  nombre: yup
    .string()
    .max(100, 'Maximo 100 caracteres permitidos, para el Nombre')
    .required('Es requerido'),
  municipio: yup.string().required('Es requerido'),
  telefono: yup.string().required('Es requerido'),
  localidad: yup.string(),
})

export default function AgregarSucursal({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeLocalidad, localidades } = useSelector(
    (state) => state.localidad
  )
  const { estados } = useEstados()
  const { municipiosByEstado } = useMunicipio()
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmitLocalidad = async (data) => {
    console.log(' dataeeeeeee', data, activeLocalidad?.id_localidad)
    dispatch(
      startSaveSucursal({
        id_localidad: activeLocalidad?._id || data.localidad,
        id_municipio: data.municipio,
        id_estado: data.estado,
        nombre: data.nombre,
        direccion: data.direccion,
        telefono: data.telefono,
        correo: data.correo,
        // logUsername": "sadmin"
      })
    ).then((resul) => {
      if (resul.payload.tipoMsg === 'sucess') {
        handleClose()
      }
    })
  }

  const handleChangeMunicipio = (e) => {
    console.log('handleChangeMunicipio', e)
    dispatch(startLoadMunicipiosByEstado(e))
  }
  console.log('activeLocalidad?.id_municipio', activeLocalidad?.id_municipio)

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          {activeLocalidad ? (
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <InputLabel
                className={classes.title}
                variant='standard'
                htmlFor='uncontrolled-native'
              >
                {activeLocalidad?.nombre}
              </InputLabel>
            </Grid>
          ) : (
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Controller
                control={control}
                name='localidad'
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth>
                    <InputLabel
                      variant='standard'
                      htmlFor='uncontrolled-native'
                    >
                      Razon social
                    </InputLabel>
                    <Select
                      fullWidth
                      name='localidad'
                      defaultValue=''
                      className={classes.select}
                      onChange={(e) => {
                        // handleChangeMunicipio(e.target.value)
                        onChange(e.target.value)
                      }}
                      value={value}
                      error={!!error}
                    >
                      {localidades.map((option) => {
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option._id}
                            value={option._id}
                          >
                            {option.nombre}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          )}

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='nombre'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Nombre'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  // onChange={(e) =>
                  //   onChange(soloTextyNumeros(e.target.value, filtroNumLetras))
                  // }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              defaultValue=''
              name='estado'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Estados
                  </InputLabel>
                  <Select
                    fullWidth
                    name='estados'
                    className={classes.select}
                    onChange={(e) => {
                      handleChangeMunicipio(e.target.value)
                      onChange(e.target.value)
                    }}
                    value={value}
                    error={!!error}
                  >
                    {estados
                      .sort((a, b) => {
                        if (a.estado > b.estado) return 1
                        if (a.estado < b.estado) return -1
                        return 0
                      })
                      .map((option) => {
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_estado}
                            value={option.id_estado}
                          >
                            {option.estado}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='municipio'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Municipios
                  </InputLabel>
                  <Select
                    // defaultValue={activeLocalidad?.id_municipio}
                    defaultValue=''
                    label='municipio'
                    fullWidth
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                  >
                    {municipiosByEstado
                      .sort((a, b) => {
                        if (a.municipio > b.municipio) return 1
                        if (a.municipio < b.municipio) return -1
                        return 0
                      })
                      .map((option) => {
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_municipio}
                            value={option.id_municipio}
                          >
                            {option.municipio}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='direccion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Dirección'
                  size='small'
                  fullWidth
                  multiline
                  rows={3}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='telefono'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  customInput={TextField}
                  format='(###) #######'
                  className={classes.text}
                  label='Teléfono'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : 'ej: (424) 2345665'}
                  placeholder='ej: (424) 2345665'
                />
              )}
            />
            <Controller
              name='correo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='correo'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
