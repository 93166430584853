import { types } from "../types/types";

const initialState = {
  categorias: [],
  activeCategoriaNota: null,
  modalCategoriaNotaOpen: false,
  modalCategoriaNotaDelete: false,
};

export const categoriaNotaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.categoriaNotaOpenModal:
      return {
        ...state,
        modalCategoriaNotaOpen: true,
        modalCategoriaNotaDelete: false,
      };

    case types.categoriaNotaCloseModal:
      return {
        ...state,
        modalCategoriaNotaOpen: false,
      };

    case types.categoriaNotaOpenModalDelete:
      return {
        ...state,
        modalCategoriaNotaOpen: false,
        modalCategoriaNotaDelete: true,
      };

    case types.categoriaNotaCloseModalDelete:
      return {
        ...state,
        modalCategoriaNotaDelete: false,
      };

    case types.categoriaNotaSetActive:
      return {
        ...state,
        activeCategoriaNota: action.payload,
        modalCategoriaNotaOpen: true,
      };

    case types.categoriaNotaAddNew:
      return {
        ...state,
        categorias: [action.payload, ...state.categorias],
      };

    case types.categoriaNotaClearActive:
      return {
        ...state,
        activeCategoriaNota: null,
      };

    case types.categoriaNotaUpdated:
      return {
        ...state,
        categorias: state.categorias.map((c) =>
          c.id === action.payload.id ? action.payload : c
        ),
      };

    case types.categoriaNotaLoaded:
      return {
        ...state,
        categorias: action.payload,
      };

    case types.categoriaNotaDelete:
      return {
        ...state,
        categorias: state.categorias.map((c) =>
          c.id === action.payload.id ? action.payload : c
        ),
        activeCategoriaNota: null,
      };

    case types.categoriaNotaReactived:
      return {
        ...state,
        categorias: state.categorias.map((c) =>
          c.id === action.payload.id ? action.payload : c
        ),
        activeCategoriaNota: null,
      };

    default:
      return state;
  }
};
