import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EstadoModal } from './EstadoModal';

import MaterialTable from 'material-table';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';

import { estadoOpenModal, estadoOpenModalDelete, estadoSetActive, startLoadEstados, startReactivedEstado } from '../../../redux/actions/estadoActions';
import { EstadoModalDelete } from './EstadoModalDelete';
import { DatosSeguimiento } from '../../commons/DatosSeguimiento';
import { DatosmunicipiosAsoc } from './DatosmunicipiosAsoc';


const Estado = () => {

    const dispatch = useDispatch();

    const { estados, modalEstadoDelete } = useSelector( state => state.estado );

    const columnas = [
  
        {
            title: 'Nombre',
            field: 'estado'
        },
        {
            title: 'codigo iso_3166-2',
            field: 'iso_3166-2'
        }
    ];

    useEffect(() => {
       
        dispatch( startLoadEstados() );

    }, [dispatch]);

    const onUpdateEstado = (e) => {
        dispatch(estadoSetActive(e));
        dispatch(estadoOpenModal());
    }

    const handleDelete = (e) => {
        dispatch(estadoSetActive(e));
        dispatch(estadoOpenModalDelete());
    }

    const handleReactive = (e) => {
        dispatch( startReactivedEstado(e) );
    }

    const openModal = () => {
        dispatch(estadoOpenModal());
    }

    return (

        <div>
            <br />
            <EstadoModal />
            { (modalEstadoDelete) && <EstadoModalDelete /> }
            <MaterialTable
                title="Estados"
                columns={columnas}
                data={estados}
                detailPanel={rowData => {
                    return (
                      <DatosmunicipiosAsoc estados={rowData} />
                    )
                  }
                }

                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                
                actions={[
                
                    {
                        icon: 'add',
                        tooltip: 'Nuevo Estado',
                        isFreeAction: true,
                        onClick: (event) => { openModal() }
                    },
                    rowData => ({
                        icon: 'edit',
                        tooltip: 'Modificar',
                        onClick: (event, rowData) => onUpdateEstado(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <PowerSettingsNewIcon />,
                        tooltip: 'Inactivar',
                        onClick: (event, rowData) => handleDelete(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <SettingsPowerIcon />,
                        tooltip: 'Activar',
                        onClick: (event, rowData) => handleReactive(rowData),
                        hidden: rowData.logEstatusRegistro ==="ACTIVO",
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#085c9f',
                        color: '#FFF'
                    },
                    exportButton: true,
                    paginationType: 'stepped',
                    rowStyle: rowData => {
                        if(rowData.logEstatusRegistro === "INACTIVO") {
                          return {backgroundColor: '#F3EFEE'};
                        }
                        
                        return {};
                      }
                }}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    toolbar: {
                        searchPlaceholder: 'Buscar',
                        searchTooltip: 'Buscar',
                        exportTitle: "Exportar",
                        exportCSVName: "Exportar en formato excel",
                        exportPDFName: "Exportar como pdf"
                    },
                    pagination: {
                        firstAriaLabel: 'Primera página',
                        firstTooltip: 'Primera página',
                        previousAriaLabel: 'Página anterior',
                        previousTooltip: 'Página anterior',
                        nextAriaLabel: 'Página siguiente',
                        nextTooltip: 'Página siguiente',
                        lastAriaLabel: 'Última página',
                        lastTooltip: 'Última página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos para mostrar',
                    }
                }}

            />
            
        </div>
    )
}

export default Estado;