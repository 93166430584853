import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Button,
} from '@material-ui/core'

import useStyles from '../Styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  startLoadUsuarios,
  usuarioSetActive,
} from '../../../../redux/actions/usuarioActions'
import { startLoadReportLogs } from '../../../../redux/actions/reporteActions'

export default function ParamtersLogs({ setLogsPdf }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { usuarios, activeUsuario } = useSelector((state) => state.usuario)
  const [StateUsusario, setStateUsusario] = useState({
    fechaInicio: '',
    fechaFin: '',
    // idUsuario: '',
  })

  useState(() => {
    dispatch(startLoadUsuarios())
  }, [])

  const Verificador = [
    StateUsusario.fechaInicio !== '',
    StateUsusario.fechaFin !== '',
  ].some((valor) => {
    return valor === false
  })

  const handleClickGenerarLogs = () => {
    dispatch(
      startLoadReportLogs({
        fechaInicio: StateUsusario.fechaInicio,
        fechaFin: StateUsusario.fechaFin,
        idUsuario: activeUsuario?.id,
      })
    )
  }

  const handlechangeUsusario = (e) => {
    const { name, value } = e.target
    console.log('RRRR', name, value)
    setStateUsusario({ ...StateUsusario, [name]: value })
  }

  const handleUsusariActivo = (e, usuario) => {
    console.log('USUSARIO', e, usuario)
    dispatch(usuarioSetActive(usuario))
  }

  console.log('StateUsusario', StateUsusario)
  return (
    <Card
      style={{
        minHeight: 250,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        title='Generar Reporte de Logs'
        className={classes.CardHeader}
      />
      <CardContent>
        <Grid container spacing={0}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Autocomplete
              size='medium'
              id='idUsuario'
              name='idUsuario'
              clearText='Limpiar'
              noOptionsText='No hay Ususarios'
              options={usuarios}
              getOptionLabel={(option) => option.email || ''}
              value={activeUsuario}
              // filterSelectedOptions
              onChange={handleUsusariActivo}
              renderOption={(option) => {
                return <h6 className={classes.texto}>{option.email}</h6>
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.root}
                  variant='standard'
                  label='Usuarios'
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='fechaInicio'
              id='date'
              label='Desde'
              type='date'
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handlechangeUsusario(e)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='fechaFin'
              id='date'
              label='Hasta'
              type='date'
              onChange={(e) => handlechangeUsusario(e)}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // color='#054477'

              // variant='outlined'
              onClick={handleClickGenerarLogs}
              disabled={Verificador}
              fullWidth
              className={classes.btn}
            >
              <>
                <i className='far fa-save'></i>
                <span style={{ paddingLeft: '12px' }}> Generar Reporte</span>
              </>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
