import { types } from '../types/types'

const initialState = {
 id_localidad: null,
 id_sucursal: null,
 tipoDocumento: null,
 numeroDocumento: null,
 nombres: '',
 correo: null,
 telefono: null,
 direccion: null,
 vendedores: [],
 activeVendedor: null,
}

export const vendedorReducer = (state = initialState, action) => {
 switch (action.type) {
  case types.vendedoresLoaded:
   return {
    ...state,
    vendedores: action.payload,
   }

  case types.vendedorActive:
   return {
    ...state,
    activeVendedor: action.payload,
   }

  case types.vendedoresClearAvtive:
   return {
    ...state,
    activeVendedor: null,
   }

  case types.vendedoresClear:
   return {
    ...state,
    vendedores: [],
   }

  case types.vendedorAdd:
   return {
    ...state,
    vendedores: [...state.vendedores, action.payload],
   }

  default:
   return state
 }
}
