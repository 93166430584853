import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { types } from '../types/types'

import { errorSetActive, errorStatusLoading } from './errorActions'
import { config } from '../../config/configProperties'
import { setfacturaUpdateVendedor } from './facturaGeneralActions'

const URL = `${config.urlServer}/mantenimiento/vendedores`

export const StartAddVendedor = (vendedor) => {
 return async (dispatch) => {
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPost(`${URL}/addvendedor`, 'POST', vendedor)
   dispatch(setfacturaUpdateVendedor(res.result.nombre))
   dispatch(setAddvendedor(res.result))
   dispatch(SetvendedorActive(res.result))
   return dispatch(errorSetActive('Vendedor registrado', 'sucess'))
  } catch (error) {
   // EXTRAER ESTE ERROR DE ABAJO DEL CATCH PARA MOSTRARLO EN EL MODAL DE ERROR LOS PRMEROS 10 CARACTERES?
   const Mensaje = error?.response?.data.message?.substring(0, 6)
   const errorMensaje =
    Mensaje !== 'E11000' ? error.response.data.message : 'Ya existe el vendedor'

   return dispatch(
    errorSetActive(errorMensaje || `Ocurrio un error ${error}`, 'error')
   )
  }
 }
}

export const StartGetVendedores = (id_localidad, id_sucursal) => {
 const params = id_sucursal
  ? `?id_sucursal=${id_sucursal}`
  : `?id_localidad=${id_localidad}`

 return async (dispatch) => {
  try {
   const res = await ApiAxiosGet(`${URL}/getvendedores/${params}`, 'GET')
   dispatch(vendedoresLoaded(res.result))
  } catch (error) {
   console.log('🚀 ~ StartGetVendedores ~ error:', error)
  }
 }
}

export const SetvendedorActive = (vendedor) => {
 return {
  type: types.vendedorActive,
  payload: vendedor,
 }
}

export const vendedoresLoaded = (vendedores) => {
 return {
  type: types.vendedoresLoaded,
  payload: vendedores,
 }
}

export const setVendedoresClear = () => {
 return {
  type: types.vendedoresClear,
 }
}

export const setVendedoresClearActive = () => {
 return {
  type: types.vendedoresClearAvtive,
 }
}

export const setAddvendedor = (vendedor) => {
 return {
  type: types.vendedorAdd,
  payload: vendedor,
 }
}
