import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento/incoterm`;

export const startSaveIncoterm = (incoterm) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: incoterm
        })
            .then((response) => {
                
                Swal.fire('Guardado', incoterm.nombre, 'success');
                dispatch(incotermAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeleteIncoterm = () => {
    
    return async (dispatch, getState) => {
       
        const { activeIncoterm } = getState().incoterm;
        
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeIncoterm.id}`
        })
            .then((response) => {
                
                Swal.fire('Inactivado', activeIncoterm.nombre, 'success');
                dispatch(deleteIncoterm(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
    
};

export const startReactivedIncoterm = (incoterm) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${incoterm.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', incoterm.nombre, 'success');
                dispatch(reactiveIncoterm(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateIncoterm = (incoterm) => {

    return (dispatch, getState) => {

        const { activeIncoterm } = getState().incoterm;
        const id = activeIncoterm.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: incoterm
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.data.nombre, 'success');
                dispatch(incotermUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}

export const startLoadIncoterms = () => {
    return (dispatch) => {

        axios.get(`${url}/listar`)
        .then(res => {
            dispatch(incotermLoaded(res.data));
        });
    }
}

const incotermLoaded = (incoterms) => ({
    type: types.incotermLoaded,
    payload: incoterms
});

export const incotermOpenModal = () => ({
    type: types.incotermOpenModal
});

export const incotermCloseModal = () => ({
    type: types.incotermCloseModal
});

export const incotermOpenModalDelete = () => ({
    type: types.incotermOpenModalDelete
});

export const incotermCloseModalDelete = () => ({
    type: types.incotermCloseModalDelete
})

export const incotermAddNew = ( incoterm ) => ({
    type: types.incotermAddNew,
    payload: incoterm
});

export const incotermSetActive = ( incoterm ) => ({
    type: types.incotermSetActive,
    payload: incoterm
});

export const incotermClearActive = ( ) => ({
    type: types.incotermClearActive,
   
});

export const incotermUpdated = ( incoterm ) => ({
    type: types.incotermUpdated,
    payload: incoterm
   
});

export const deleteIncoterm = (incoterm) => ({
    type: types.incotermDelete,
    payload: incoterm
});

export const reactiveIncoterm = ( incoterm ) => ({
    type: types.incotermReactived,
    payload: incoterm
});