import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reporteClear } from '../../../../redux/actions/reporteActions'

import { Button, Container, Grid } from '@material-ui/core'
// import RazonSocial from './RazonSocial'
import { useToast } from '../../../../hooks/useToast'
import GenerarLogs from './ParamtersLogs'
import VerLogs from './VerLogs'
// import VerLibroVentas from './VerLibroVentas'
// import ResumenLibroVentas from './ResumenLibroVentas'

const Logs = () => {
  // const history = useHistory()
  // const classes = useStyles()
  const { notifyError, notifysucess } = useToast()
  const { isError, error, tipoMsg } = useSelector((state) => state.error)
  const dispatch = useDispatch()
  const [LogsPdf, setLogsPdf] = useState(null)

  useEffect(() => {
    dispatch(reporteClear())
  }, [dispatch])

  return (
    <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
      <Grid container spacing={2}>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <GenerarLogs setLogsPdf={setLogsPdf} />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <VerLogs LogsPdf={LogsPdf} />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {/* <VerLibroVentas /> */}
        </Grid>
      </Grid>

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' &&
          error !== 'no hay Sucursal para esta localidad' &&
          notifyError()}
    </Container>
  )
}

export default Logs
