import { types } from "../types/types";

const initialState = {
  valoresGravamenes: [],
  valoresGravamanesActuales: [],
  activeValorGravamen: null,
  modalValorGravamenOpen: false,
  modalValorGravamenDelete: false,
};

export const valorGravamenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.valorGravamenOpenModal:
      return {
        ...state,
        modalValorGravamenOpen: true,
        modalValorGravamenDelete: false,
      };

    case types.valorGravamenCloseModal:
      return {
        ...state,
        modalValorGravamenOpen: false,
      };

    case types.valorGravamenOpenModalDelete:
      return {
        ...state,
        modalValorGravamenOpen: false,
        modalValorGravamenDelete: true,
      };

    case types.valorGravamenCloseModalDelete:
      return {
        ...state,
        modalValorGravamenDelete: false,
      };

    case types.valorGravamenSetActive:
      return {
        ...state,
        activeValorGravamen: action.payload,
        modalValorGravamenOpen: true,
      };

    case types.valorGravamenAddNew:
      return {
        ...state,
        valoresGravamenes: [action.payload, ...state.valoresGravamenes],
      };

    case types.valorGravamenClearActive:
      return {
        ...state,
        activeValorGravamen: null,
      };

    case types.valorGravamenUpdated:
      return {
        ...state,
        valoresGravamenes: state.valoresGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.valorGravamenLoaded:
      return {
        ...state,
        valoresGravamenes: action.payload,
      };

    case types.valorGravamenActuales:

      return {
          ...state,
          valoresGravamanesActuales: action.payload,
      };

    case types.valorGravamenDelete:
    return {
        ...state,
        valoresGravamenes: state.valoresGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeValorGravamen: null,
      };

    case types.valorGravamenReactived:
      return {
        ...state,
        valoresGravamenes: state.valoresGravamenes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeValorGravamen: null,
      };

    default:
      return state;
  }
};
