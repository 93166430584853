import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  Card,
  Grid,
  Typography,
  CardHeader,
  // Avatar,
  // IconButton,
  CardContent,
  CardActions,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormatedBolivares } from '../../../Helpers/formater'
// import useTasas from '../../../hooks/useTasas'
import useDivisas from '../../../hooks/useDivisas'
import { setUpdateFactura } from '../../../redux/actions/facturaGeneralActions'
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(32),
      height: theme.spacing(16),
    },
  },
  paper: {
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: 5,
    opacity: '0.7',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'left',
  },
  textotitulos: {
    textAlign: 'left',
    fontSize: '12px',
  },
  textMontos: {
    textAlign: 'right',
    fontSize: '12px',
  },
  textoPorPagarNega: {
    color: 'red',
    fontSize: '14px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  textoPorPagarPosi: {
    color: 'green',
    fontSize: '14px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '8px',
  },
  switchgenerico: {
    marginTop: '12px',
    '& .MuiTypography-body1': {
      marginLeft: '8px',
      fontSize: '14px',
    },
  },
}))

const TotalesFacturaGeneral = () => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const { buscardivisa } = useDivisas()
  const {
    subTotal,
    exento,
    exonerado,
    importeTotal,
    faltaPorPagar,
    totalDescuento,
    lstGravamenes,
    facturaDivisa,
    igtfAcumulado,
    totalNeto,
    tipoColetilla,
  } = useSelector((state) => state.facturaGeneral)
  // const { divisas } = useSelector((state) => state.divisa)
  // var USD = divisas.data.find((b) => b.iso === 'USD')
  // const USD = divisas.data[1].value
  const USD = buscardivisa('USD')
  const totalDolares =
    Number(importeTotal).toFixed(2) / Number(USD?.value).toFixed(2)

  const handleChange = (e) => {
    const { name, checked: value } = e.target
    dispatch(setUpdateFactura({ name, value }))
  }

  return (
    <Card
      style={{
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader
        title='Totales'
        subheader=''
        className={classes.CardHeader}
        action={
          facturaDivisa === 'VEF' && (
            <FormControlLabel
              name='tipoColetilla'
              className={classes.switchgenerico}
              labelPlacement='end'
              control={
                <Switch
                  defaultValue={tipoColetilla}
                  defaultChecked={tipoColetilla}
                  size='small'
                />
              }
              label={tipoColetilla ? 'Agregar Coletilla' : 'Sin coletilla'}
              // value={tipoColetilla}
              checked={tipoColetilla}
              onChange={handleChange}
            />
          )
        }
      />
      <CardContent>
        <Grid container>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textotitulos}>SubTotal</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(subTotal, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textotitulos}>Exonerado</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(exonerado, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textotitulos}>Exento</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(exento, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textotitulos}>Descuento</Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(totalDescuento, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
        </Grid>
        {lstGravamenes?.map((grava, i) => {
          return (
            <Grid key={grava.i} container className={classes.gridtotales}>
              <Grid item lg={4} sm={4} xl={4} xs={4}>
                <Typography className={classes.textotitulos}>
                  Total IVA: {grava.alicuota}%
                </Typography>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={4}>
                <Typography className={classes.textMontos}>
                  {FormatedBolivares(
                    grava.baseImponible,
                    'es-ES',
                    facturaDivisa
                  )}
                </Typography>
              </Grid>
              <Grid item lg={4} sm={4} xl={4} xs={4}>
                <Typography className={classes.textMontos}>
                  {FormatedBolivares(
                    grava.montoAlicuota,
                    'es-ES',
                    facturaDivisa
                  )}
                </Typography>
              </Grid>
            </Grid>
          )
        })}

        <Grid container className={classes.gridtotales}>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography
              style={{ fontWeight: 'bold' }}
              className={classes.textotitulos}
            >
              Importe total
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(importeTotal, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>

          {!facturaDivisa && (
            <>
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  className={classes.textotitulos}
                >
                  Total $
                </Typography>
              </Grid>
              <Grid item lg={6} sm={6} xl={6} xs={6}>
                <Typography className={classes.textMontos}>
                  {FormatedBolivares(totalDolares, 'en-US', !facturaDivisa)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Grid container className={classes.gridtotales}>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography
              style={{ fontWeight: 'bold' }}
              // className={classes.textotitulos}
            >
              Por pagar
            </Typography>
          </Grid>

          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography
              className={
                Number(faltaPorPagar) < 0.03
                  ? classes.textoPorPagarPosi
                  : classes.textoPorPagarNega
              }
            >
              {Number(faltaPorPagar) <= 0.03
                ? FormatedBolivares(0.0, 'es-ES', facturaDivisa)
                : FormatedBolivares(
                    Number(faltaPorPagar),
                    'es-ES',
                    facturaDivisa
                  )}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography
              style={{ fontWeight: 'bold' }}
              className={classes.textotitulos}
            >
              IGTF
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(igtfAcumulado, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography
              style={{ fontWeight: 'bold' }}
              className={classes.textotitulos}
            >
              Neto a pagar:
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Typography className={classes.textMontos}>
              {FormatedBolivares(totalNeto, 'es-ES', facturaDivisa)}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

export default TotalesFacturaGeneral
