import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'

import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { startLoadReportProvi32Art28 } from '../../../../redux/actions/reporteActions'
// import { Autocomplete } from '@material-ui/lab'
// import { useToast } from '../../../../hooks/useToast'
import Localidades from '../SeniatParam/Localidades'

export default function ReporteProvi32Art28({ tipoReport, title }) {
  //   const { notifysucess, notify } = useToast()
  const [loading, setLoading] = useState(true)
  const [localidadesSelect, setLocalidadesSelect] = useState([])

  const dispatch = useDispatch()
  const {
    // handleselectSucursal,
    // HandleselectLocalidad,
    // localidadActive,
    // sucursalActive,
    localidades,
    // sucursalesByLocalidad,
  } = useRazonSocial()

  const [email, setEmail] = useState('')
  const [errorMail, seterrorMail] = useState(false)
  const [estadoReport, setEstadoReport] = useState({
    fechaInicio: '',
    fechaFin: '',
  })

  const Verificador = [
    estadoReport.fechaInicio !== '',
    estadoReport.fechaFin !== '',
    email !== '',
    errorMail === false,
  ].some((valor) => {
    return valor === false
  })

  const handleChangeEmail = (event) => {
    const inputValue = event.target.value
    // Validar si es un correo electrónico válido
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)

    setEmail(inputValue)
    seterrorMail(!isValidEmail)
  }

  const handleSubmit = () => {
    dispatch(
      startLoadReportProvi32Art28({
        fechaInicio: estadoReport.fechaInicio,
        fechaFin: estadoReport.fechaFin,
        rif: localidadesSelect,
        // localidad: localidadActive?._id,
        // sucursal: sucursalActive?.id || '',
        email,
      })
    )
    //   .then((res) => {
    //    console.log("🚀 ~ ).then ~ res:", res)
    //    notifyAliatorio(res?.payload?.Error)
    //    if (res?.libro?.consolidado > 0 && res?.libro?.consolidado !== undefined)
    //     notifysucess('Reporte generado con exito, revisa tu correo')
    // notify('Reporte generado con exito, revisa tu correo')

    //    else if (res !== undefined) notifyAliatorio(res?.payload?.Error)
    //   })
  }

  const HandleClickRepProvi32art28 = (e) => {
    const { name, value } = e.target
    setEstadoReport({ ...estadoReport, [name]: value })
  }

  return (
    <Container
      style={{
        backgroundColor: '#ebedef',
        marginTop: '14px',
        marginLeft: '18px',
        marginRight: '18px',
        marginBottom: '12px',
        borderRadius: '8px',
        paddingBottom: '30px',
      }}
    >
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Typography variant='h6' align='center'>
          {title}
        </Typography>
        <Divider component={'div'} />
      </Grid>

      <Grid container spacing={1} alignItems='center'>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Localidades
            localidades={localidades}
            setLocalidadesSelect={setLocalidadesSelect}
            localidadesSelect={localidadesSelect}
          />
        </Grid>

        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <TextField
            name='fechaInicio'
            id='date'
            label='Desde'
            type='date'
            fullWidth
            // defaultValue={new Date().toISOString().substr(0, 10)}
            onChange={(e) => HandleClickRepProvi32art28(e)}
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split('T')[0],
            }}
          />
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <TextField
            name='fechaFin'
            id='date'
            fullWidth
            label='Hasta'
            type='date'
            min={new Date().toISOString().split('T')[0]}
            onChange={(e) => HandleClickRepProvi32art28(e)}
            // defaultValue={new Date().toISOString().substr(0, 10)}
            //   className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              max: new Date().toISOString().split('T')[0],
            }}
          />
        </Grid>

        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <TextField
            label='Correo Electronico'
            variant='standard'
            value={email}
            onChange={handleChangeEmail}
            error={errorMail}
            helperText={errorMail ? 'Ingresa un correo electronico valido' : ''}
            placeholder='Ingresa un correo electronico'
            fullWidth
          />
        </Grid>

        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Button
            //   disabled={!email || errorMail}
            disabled={Verificador}
            style={{
              width: '100%',
              borderRadius: '20px',
              backgroundColor: Verificador ? '#6c757d' : '#054477',
              color: 'white',
              boxShadow: '0 5px 5px rgba(182, 182, 182, 0.75)',
            }}
            variant='contained'
            onClick={handleSubmit}
          >
            Generar y enviar
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
