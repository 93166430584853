export function CalcularImporte(
 facturaDivisa,
 tipoDescuento,
 valueProducto,
 valorDescuento,
 valueCantidad
) {
 let valueDivisa = ''
 let valueInporte = 0.0

 if (facturaDivisa === 'USD') valueDivisa = valueProducto?.divisaPrecio
 if (facturaDivisa === 'VEF') valueDivisa = valueProducto?.precio
 if (facturaDivisa === 'PTR') valueDivisa = valueProducto?.precioPtr
 if (facturaDivisa === 'EUR') valueDivisa = valueProducto?.precioEur

 console.log('🚀 ~ valueDivisa:', valueDivisa)

 valueInporte =
  tipoDescuento === 'monto'
   ? valueDivisa * valueCantidad - valorDescuento
   : tipoDescuento === 'sindescuento'
   ? valueDivisa * valueCantidad
   : (valueDivisa - (valueDivisa * valorDescuento) / 100) * valueCantidad

 console.log('🚀 ~ valueInporte:', valueInporte)
 return valueInporte
}
