import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { errorSetActive } from './errorActions'
import { ApiAxiosGet } from '../../Helpers/ApiAxios'

const url = `${config.urlServer}/mantenimiento/divisa`

export const startSaveDivisa = (divisa) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'POST',
      url: `${url}/crear`,
      data: divisa,
    })
      .then((response) => {
        Swal.fire('Guardado', divisa.nombre, 'success')
        dispatch(divisaAddNew(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startDeleteDivisa = () => {
  return async (dispatch, getState) => {
    const { activeDivisa } = getState().divisa

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeDivisa.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivada', activeDivisa.nombre, 'success')
        dispatch(deleteDivisa(response.data))
      })
      .catch((error) => {
        const msgError = error
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedDivisa = (divisa) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${divisa.id}`,
    })
      .then((response) => {
        Swal.fire('Activada', divisa.nombre, 'success')
        dispatch(reactiveDivisa(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateDivisa = (divisa) => {
  return (dispatch, getState) => {
    const { activeDivisa } = getState().divisa
    const id = activeDivisa.id

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/editar/${id}`,
      data: divisa,
    })
      .then((response) => {
        Swal.fire('Actualizado', response.data.nombre, 'success')
        dispatch(divisaUpdated(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startDivisaLoadActive = (iso) => {
  return (dispatch, getState) => {
    const { divisas } = getState().divisa
    const Divisa = divisas?.data?.find((d) => d.iso === iso)
    console.log('Divisa SALOSSSS', Divisa)
    dispatch(divisaSetActive(Divisa))
  }
}

export const startLoadDivisas = () => {
  return async (dispatch) => {
    await axios
      .get(`${url}/DivisasActualizar`)
      .then((res) => {
        dispatch(divisaLoaded(res.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export const startgetDivisas = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/GetDivisasBcv`, 'GET')
      console.log('res DIVISA', res)
      return dispatch(
        errorSetActive('Control sucusal/Localidad ', 'sucess', res.result)
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

const divisaLoaded = (divisas) => ({
  type: types.divisaLoaded,
  payload: divisas,
})

export const divisaOpenModal = () => ({
  type: types.divisaOpenModal,
})

export const divisaCloseModal = () => ({
  type: types.divisaCloseModal,
})

export const divisaOpenModalDelete = () => ({
  type: types.divisaOpenModalDelete,
})

export const divisaCloseModalDelete = () => ({
  type: types.divisaCloseModalDelete,
})

export const divisaAddNew = (divisa) => ({
  type: types.divisaAddNew,
  payload: divisa,
})

export const divisaSetActive = (divisa) => ({
  type: types.divisaSetActive,
  payload: divisa,
})

export const divisaClearActive = () => ({
  type: types.divisaClearActive,
})

export const divisaUpdated = (divisa) => ({
  type: types.divisaUpdated,
  payload: divisa,
})

export const deleteDivisa = (divisa) => ({
  type: types.divisaDelete,
  payload: divisa,
})

export const reactiveDivisa = (divisa) => ({
  type: types.divisaReactived,
  payload: divisa,
})
