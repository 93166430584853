import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { UsuarioModal } from './UsuarioModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import Edit from '@material-ui/icons/Edit'
import ChangePssword from '@material-ui/icons/VpnKey'

import {
 usuarioOpenModal,
 usuarioOpenModalDelete,
 usuarioSetActive,
 startLoadUsuarios,
 startReactivedUsuario,
 startDeleteUsuario,
} from '../../../redux/actions/usuarioActions'
import { UsuarioModalDelete } from './UsuarioModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import AgregarUsusario from './AgregarUsuario'
import { useToast } from '../../../hooks/useToast'
import UpdateUsuario from './UpdateUsuario'
import { hasPermission } from '../../../Helpers/AuthPermisos'
import { startLoadLocalidades } from '../../../redux/actions/localidadActions'
import ResetPassword from './ResetPassword'

const Usuario = () => {
 const dispatch = useDispatch()
 const { isError, tipoMsg } = useSelector((state) => state.error)
 const { usuarios, modalUsuarioDelete } = useSelector((state) => state.usuario)
 const { roles } = useSelector((state) => state.role)
 const { UsuarioLogiado: user } = useSelector((state) => state.auth)

 const { notifyError, notifysucess } = useToast()
 const { t } = useTranslation()
 const [agregarUsuario, setagregarUsuario] = useState(false)
 const [updateUsuario, setupdateUsuario] = useState(false)
 const [resetpass, setResetpass] = useState(false)

 const columnas = [
  {
   title: 'Username',
   field: 'userAlias',
   type: 'string',
   cellStyle: {
    width: 20,
    maxWidth: 20,
   },
   defaultSort: 'desc',
  },
  {
   title: 'Correo',
   field: 'email',
   // render: rowData => rowData.apellidos + ", " + rowData.nombres
  },
  // {
  //   title: 'Role',
  //   field: 'role',
  //   render: (rowData) => {
  //     const rol = roles.filter((role) => role.id === rowData.role)
  //     return rol[0].rol
  //   },
  // },
  // {
  //   id: 'logEstatusRegistro',
  //   title: 'Estatus',
  //   field: 'logEstatusRegistro',
  //   numeric: false,
  //   render: (rowData) => (
  //     <div style={{ fontSize: '12px' }}>
  //       {rowData?.logEstatusRegistro === 'INACTIVO' ? (
  //         <div style={{ color: 'tomato' }}>Anulada</div>
  //       ) : (
  //         <div style={{ color: 'blue' }}>Activa</div>
  //       )}
  //     </div>
  //   ),
  // },
 ]

 useEffect(() => {
  //Llenar la lista de Usuarios
  dispatch(startLoadUsuarios())
  dispatch(startLoadLocalidades())
 }, [dispatch])

 //Editar
 // const onUpdateUsuario = (e) => {
 //   dispatch(usuarioSetActive(e))
 //   dispatch(usuarioOpenModal())
 // }

 //Inactivar / Borrar
 const handleDelete = (e) => {
  dispatch(usuarioSetActive(e))
  dispatch(usuarioOpenModalDelete())
 }

 const handleResetPass = (e) => {
  console.log('🚀 ~ file: Usuario.js:106 ~ handleResetPass ~ e:', e)
  dispatch(usuarioSetActive(e))
  setResetpass(true)
 }
 const closeModalResetpass = () => {
  setResetpass(false)
 }

 //Reactivar
 const handleReactive = (e) => {
  dispatch(usuarioSetActive(e))
  dispatch(startDeleteUsuario())
  // dispatch(startReactivedUsuario(e))
 }

 //Abrir el modal
 // const openModal = () => {
 //   dispatch(usuarioOpenModal())
 // }

 // abrir el modal de agregar usuario
 const openModalAgregarUsuario = () => {
  setagregarUsuario(true)
 }
 // cerrar el modal de agregar usuario
 const closeModalAgregarUsuario = () => {
  setagregarUsuario(false)
 }

 // abrir el modal de actualizar usuario
 const openModalUpdateUsuario = (user) => {
  dispatch(usuarioSetActive(user))
  setupdateUsuario(true)
 }
 // cerrar el modal de actualizar usuario
 const closeModalUpdateUsuario = () => {
  setupdateUsuario(false)
 }

 console.log(
  'usuario PERMISO ',
  !hasPermission(
   roles.filter((r) => r.id === user.user.role),
   '/seguridad/usuarios',
   'add'
  )
 )

 return (
  <div>
   <br />
   {/* <UsuarioModal /> */}
   {modalUsuarioDelete && <UsuarioModalDelete />}
   <MaterialTable
    title={t('seguridad.usuario.base.listado')}
    columns={columnas}
    data={usuarios}
    detailPanel={(rowData) => {
     return <DatosSeguimiento datos={rowData} />
    }}
    onRowClick={(event, rowData, togglePanel) => togglePanel()}
    actions={[
     {
      icon: 'add',
      tooltip: 'Nuevo Usuario',
      isFreeAction: true,
      onClick: (event) => {
       openModalAgregarUsuario()
      },
      hidden: !hasPermission(
       roles.filter((r) => r.id === user.user.role),
       '/seguridad/usuarios',
       'add',
       user.user
      ),
     },
     (rowData) => ({
      icon: () => <Edit fontSize='small' htmlColor='green' />,
      tooltip: 'Editar Usuario',
      onClick: (event, rowData) => openModalUpdateUsuario(rowData),
      hidden:
       rowData.logEstatusRegistro === 'INACTIVO' ||
       !hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/seguridad/usuarios',
        'update',
        user.user
       ),
     }),
     (rowData) => ({
      icon: () => <PowerSettingsNewIcon fontSize='small' htmlColor='tomato' />,
      tooltip: 'Inactivar Usuario',
      onClick: (event, rowData) => handleDelete(rowData),
      hidden:
       rowData.logEstatusRegistro === 'INACTIVO' ||
       !hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/seguridad/usuarios',
        'cancel',
        user.user
       ),
     }),
     (rowData) => ({
      icon: () => <SettingsPowerIcon fontSize='small' htmlColor='0288d1' />,
      tooltip: 'Reactivar Usuario',
      onClick: (event, rowData) => handleReactive(rowData),
      hidden:
       rowData.logEstatusRegistro === 'ACTIVO' ||
       !hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/seguridad/usuarios',
        'cancel',
        user.user
       ),
     }),
     (rowData) => ({
      icon: () => <ChangePssword fontSize='small' htmlColor='red' />,
      tooltip: 'Cambiar PassWord Usuario',
      onClick: (event, rowData) => handleResetPass(rowData),
      hidden:
       rowData.logEstatusRegistro === 'ACTIVO' &&
       !hasPermission(
        roles.filter((r) => r.id === user.user.role),
        '/seguridad/usuarios',
        'updatePasw',
        user.user
       ),
     }),
    ]}
    options={{
     actionsColumnIndex: -1,
     headerStyle: {
      backgroundColor: '#085c9f',
      color: '#FFF',
     },
     exportButton: true,
     paginationType: 'stepped',
     rowStyle: (rowData) => {
      if (rowData.logEstatusRegistro === 'INACTIVO') {
       return { backgroundColor: '#F3EFEE' }
      }

      return {}
     },
    }}
    localization={{
     header: {
      actions: t('general.base.actions'),
     },
     toolbar: {
      searchPlaceholder: t('general.base.searchPlaceholder'),
      searchTooltip: t('general.base.searchTooltip'),
      exportTitle: t('general.base.exportTitle'),
      exportCSVName: t('general.base.exportCSVName'),
      exportPDFName: t('general.base.exportPDFName'),
     },
     pagination: {
      firstAriaLabel: t('general.base.firstAriaLabel'),
      firstTooltip: t('general.base.firstTooltip'),
      previousAriaLabel: t('general.base.previousAriaLabel'),
      previousTooltip: t('general.base.previousTooltip'),
      nextAriaLabel: t('general.base.nextAriaLabel'),
      nextTooltip: t('general.base.nextTooltip'),
      lastAriaLabel: t('general.base.lastAriaLabel'),
      lastTooltip: t('general.base.lastTooltip'),
      labelDisplayedRows: t('general.base.labelDisplayedRows'),
      labelRowsSelect: t('general.base.labelRowsSelect'),
     },
     body: {
      emptyDataSourceMessage: t('general.base.emptyDataSourceMessage'),
     },
    }}
   />

   {isError && tipoMsg === 'sucess'
    ? notifysucess()
    : tipoMsg === 'error' && notifyError()}

   {agregarUsuario && (
    <AgregarUsusario
     open={agregarUsuario}
     handleClose={closeModalAgregarUsuario}
     title='Agregar Usuario'
    />
   )}
   {updateUsuario && (
    <UpdateUsuario
     open={updateUsuario}
     handleClose={closeModalUpdateUsuario}
     title='Modificar Usuario'
    />
   )}
   {resetpass && (
    <ResetPassword
     open={ResetPassword}
     handleClose={closeModalResetpass}
     title='Cambiar Password'
    />
   )}
  </div>
 )
}

export default Usuario
