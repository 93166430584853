import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
 Grid,
 InputLabel,
 TextField,
 Radio,
 RadioGroup,
 FormControlLabel,
 FormControl,
 Card,
 CardHeader,
 CardContent,
 MenuItem,
 Select,
 IconButton,
 Button,
} from '@material-ui/core'
import PeopleAlt from '@material-ui/icons/PeopleAlt'
import DeleteIcon from '@material-ui/icons/Delete'

import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import {
 setUpdateFactura,
 setClearlstDetalles,
 setClearcuentaTerceros,
 asignaNroFacturaClear,
 // startLoadCanbioDivisa,
} from '../../../../redux/actions/facturaGeneralActions'
import { MODELO_FACTURA, TIPOORDEN } from '../../nota/constants'
import { hasPermission } from '../../../../Helpers/AuthPermisos'
import { startLoadAsignaNroFactura } from '../../../../redux/actions/facturaGeneralActions'
import CuentaTerceros from '../../General/CrearFactura/CuentaTerceros'

// import { startLoadProductosByLocalidad } from '../../../../redux/actions/productoActions'

const useStyles = makeStyles((theme) => ({
 root: {
  // height: '22px',
  '& .MuiAutocomplete-inputRoot': {
   fontSize: '12px',
   color: '#01579b',
  },
  '& .MuiFormControlLabel-label': {
   fontSize: '10px',
   color: '#01579b',
  },
  '& .MuiSvgIcon-root': {
   fontSize: 16,
   // marginTop: 0,
  },
 },
 CardHeader: {
  backgroundColor: '#054477',
  color: 'white',
  padding: '12px',
  ' & .MuiCardHeader-title': {
   fontSize: '24px',
  },
  '& .MuiCardHeader-avatar': {
   padding: '0px',
   color: 'red',
  },
 },
 cardheader_icons: {
  color: 'white',
  // padding: '8px 8px 8px 0',
 },

 title: {
  fontWeight: 'bold',
  fontSize: '18px',
 },
 texto: {
  fontWeight: 'bold',
  fontSize: '12px',
 },
 switchgenerico: {
  marginTop: '8px',
  '& .MuiTypography-body1': {
   marginLeft: '8px',
   fontSize: '14px',
  },
 },
 btncta: {
  width: '100%',
  color: 'black',
  backgroundColor: '#fd7e14',
  '&:hover': {
   backgroundColor: '#f99f54',
  },
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  padding: '4px',
 },
 btnctagrey: {
  width: '100%',
  color: 'black',
  backgroundColor: '#C9C3C3',
  '&:hover': {
   backgroundColor: '#D4D0D1',
  },
  padding: '4px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
 },
 btncta_text: {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '12px',
  color: 'black',
 },

 btncta_textp: {
  margin: '0px 0px 0px 8px',
  fontSize: '12px',
  color: 'black',
  alignSelf: 'center',
 },
 eliminarTerceros: {
  backgroundColor: '#e0e0e0',
 },
}))

export default function RazonSocial() {
 const { UsuarioLogiado: user } = useSelector((state) => state.auth)
 const { roles } = useSelector((state) => state.role)
 const [opencuentaTerceros, setOpencuentaTerceros] = useState(false)
 const [NroFacturaAuto, setNroFacturaAuto] = useState(false)

 const dispatch = useDispatch()
 const classes = useStyles()
 const {
  handleChangeTipoventa,
  handleselectSucursal,
  HandleselectLocalidad,
  handleChangenroFactura,
  localidadActive,
  sucursalActive,
  tipoVentaActive,
  localidades,
  sucursalesByLocalidad,
  nroFactura,
  facturaDivisa,
  modeloFactura,
  cuentaTerceros,
  // nombrePrestador,
  rifPrestador,
  // cambioDivisa
 } = useRazonSocial()

 useEffect(() => {
  if (
   localidadActive?.asignaNroFacturas === 'GLOBAL' ||
   localidadActive?.asignaNroFacturas === 'INDIVIDUAL'
  ) {
   dispatch(
    startLoadAsignaNroFactura({
     id: localidadActive?._id,
     tipo: 'id_localidad',
     sucursalTipo: localidadActive?.asignaNroFacturas,
    })
   )
   setNroFacturaAuto(true)
  } else {
   dispatch(asignaNroFacturaClear())
   setNroFacturaAuto(false)
  }
 }, [dispatch, localidadActive])

 useEffect(() => {
  if (
   sucursalActive?.asignaNroFacturas === 'GLOBAL' ||
   sucursalActive?.asignaNroFacturas === 'INDIVIDUAL'
  ) {
   dispatch(
    startLoadAsignaNroFactura({
     id: sucursalActive?.id,
     tipo: 'id_sucursal',
     sucursalTipo: sucursalActive?.asignaNroFacturas,
    })
   )
   setNroFacturaAuto(true)
  } else {
   dispatch(asignaNroFacturaClear())
   setNroFacturaAuto(false)
  }
 }, [dispatch, sucursalActive])

 const handleChangerazonSocial = (e) => {
  const { name, value } = e.target
  dispatch(setClearlstDetalles())
  dispatch(setUpdateFactura({ name, value }))
  // dispatch(startLoadCanbioDivisa())
 }

 return (
  <>
   <Card
    style={{
     height: 315,
     boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    }}
   >
    <CardHeader
     title='Datos de la Orden/Guia'
     className={classes.CardHeader}
     action={
      <FormControlLabel
       style={{ margin: '6px' }}
       className={classes.switchgenerico}
       labelPlacement='start'
       control={
        // <swtichGenerico defaultChecked/>
        <Select
         style={{
          paddingLeft: '8px',
          fontSize: '14px',
          marginLeft: '16px',
          color: '#054477',
          backgroundColor: 'white',
          width: '60px',
          fontWeight: 'bold',
          borderRadius: '10px',
         }}
         labelId='facturaDivisa'
         id='facturaDivisa'
         name='facturaDivisa'
         // value={age}
         defaultValue={facturaDivisa}
         // onChange={handleChange}
        >
         <MenuItem
          style={{
           fontSize: '14px',
           fontWeight: 'bold',
           color: '#054477',
           backgroundColor: 'white',
          }}
          value='VEF'
         >
          Bs.
         </MenuItem>
         <MenuItem
          style={{
           fontSize: '14px',
           fontWeight: 'bold',
           color: '#054477',
           backgroundColor: 'white',
          }}
          value='USD'
         >
          USD
         </MenuItem>
         <MenuItem
          style={{
           fontSize: '14px',
           fontWeight: 'bold',
           color: '#054477',
           backgroundColor: 'white',
          }}
          value='EUR'
         >
          EUR
         </MenuItem>
        </Select>
       }
       label='Factura en:'
       onChange={(e) => handleChangerazonSocial(e)}
       // style={{ fontSize: '10px', marginTop: '8px',}}
      />
     }
    />
    <CardContent>
     <Grid container style={{ alignItems: 'center' }} spacing={1}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Autocomplete
        size='medium'
        id='localidad'
        clearText='Limpiar'
        noOptionsText='No hay Localidades'
        options={localidades}
        getOptionLabel={(option) => option.nombre || ''}
        value={localidadActive}
        onChange={HandleselectLocalidad}
        // filterSelectedOptions
        renderOption={(option) => {
         return <h6 className={classes.texto}>{option.nombre}</h6>
        }}
        renderInput={(params) => (
         <TextField
          {...params}
          className={classes.root}
          variant='standard'
          label='Nombre ó Razón Social'
         />
        )}
       />
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       {sucursalesByLocalidad.length > 0 && (
        <Autocomplete
         size='medium'
         id='sucursal'
         disableClearable
         noOptionsText='No hay Sucursales Asociadas'
         options={sucursalesByLocalidad}
         getOptionLabel={(option) => option.nombre || ''}
         value={sucursalActive}
         onChange={handleselectSucursal}
         filterSelectedOptions
         renderOption={(option) => {
          return <h6 className={classes.texto}>{option.nombre}</h6>
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           className={classes.root}
           variant='standard'
           label='Sucursal'
          />
         )}
        />
       )}
      </Grid>

      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <FormControl fullWidth>
        <InputLabel variant='standard' htmlFor='tipo'>
         Tipo
        </InputLabel>
        <Select
         // className={classes.select}
         fullWidth
         name='modeloFactura'
         defaultValue={modeloFactura}
         onChange={(e) => handleChangerazonSocial(e)}
        >
         {TIPOORDEN.map((option) => {
          return (
           <MenuItem className={classes.select} key={option} value={option}>
            {option}
           </MenuItem>
          )
         })}
        </Select>
       </FormControl>
      </Grid>

      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <InputLabel className={classes.texto}>Nro. Orden/Guia</InputLabel>
       <TextField
        disabled={NroFacturaAuto}
        required
        className={classes.texto}
        name='nroFactura'
        size='small'
        fullWidth
        value={nroFactura}
        onChange={(e) => handleChangenroFactura(e)}
       />
      </Grid>
     </Grid>
    </CardContent>
   </Card>
  </>
 )
}
