import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    color: 'rgb(8, 92, 159)',
    paddingLeft: '8px',
    paddingRight: '8px',
  },

  head: {
    fontSize: '10px',
  },
  title: {
    flex: '1 1 100%',
    fontSize: '1.5rem',
    fontWeight: 700,
  },

  table: {
    backgroundColor: 'white',
    borderCollapse: 'collapse',
    minHeight: '500px',
    verticalAlign: 'baseline',
  },
  tableHeader: {
    backgroundColor: 'rgb(8, 92, 159)',
    '& .MuiTableCell-head': {
      color: 'white',
      fontSize: '14px !important',
      padding: 12,
    },
    // boxShadow: theme.warningBoxShadow.boxShadow, 12648
  },
  tableBody: {
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline',
    },
    '& .MuiTableCell-root': {
      fontSize: '10px !important',
      padding: '4px',
    },
    // boxShadow: theme.warningBoxShadow.boxShadow,
  },
  colInactivo: {
    padding: '8px 16px 8px 16px ',
    color: 'white',
    backgroundColor: '#2962ff',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  colActivo: {
    padding: '8px 12px 8px 12px ',
    color: 'white',
    backgroundColor: '#ff5722',
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  btnAdd: {
    color: 'rgb(8, 92, 159)',
    '&:hover': {
      cursor: 'pointer',
      //   color: theme.infoColor[3],
    },
  },
  btnGenerarActivo: {
    color: '#ff9800',
    padding: '0px',
  },
  btnGenerarIncctivo: {
    color: '#bdbdbd',
    padding: '0px',
  },
  btnGenerarActivoPdf: {
    color: '#795548',
    padding: '0px',
  },
  btnconsultaActivoPdf: {
    color: '#00c853',
    padding: '0px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export default useStyles
