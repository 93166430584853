import { useState } from 'react'
import ModalGenerico from '../../../../commons/ui/Modal'

// import SelectedVendedor from './SelectedVendedor'
// import AddVendedor from './AddVendedor'
import AddObservacion from './AddObservacion'

export default function VendedorModal({ open, handleClose, title }) {
 const [openAddVendedor, setOpenAddVendedor] = useState(false)
 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <AddObservacion handleClose={handleClose} />
   {/* si se coloca el vendecor add se activa el vendedor */}

   {/* {openAddVendedor && (
    <AddVendedor
     handleClose={handleClose}
     handleopenAddVendedor={setOpenAddVendedor}
    />
   )} */}
  </ModalGenerico>
 )
}
