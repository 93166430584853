import axios from 'axios'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet } from '../../Helpers/ApiAxios'
import { errorSetActive } from './errorActions'
import { getToken } from '../../Helpers/VericarRutas'

const url = `${config.urlServer}/facturacion/cliente`

const URL = `${config.urlServer}//mantenimiento/cliente`

async function* streamReader(res) {
 const reader = res.body?.getReader()
 const decoder = new TextDecoder()
 if (reader == null) return

 while (true) {
  const { done, value } = await reader.read()
  const chunk = decoder.decode(value)
  yield chunk
  if (done) break
 }
}

export const startLoadClientes = () => {
 return async (dispatch) => {
  // setStep(STEPS.LOADING)
  
  // try {
  //  const res = await fetch(`${URL}/getclientes`, {
  //   headers: {
  //    Authorization: `Bearer ${
  //     JSON.parse(getToken())?.UsuarioLogiado.tokens.access.token
  //    }`,
  //    'Content-Type': 'application/json',
  //   },
  //  })
  //  console.log('🚀 ~ file: clienteActions.js:31 ~ return ~ res:', res)

  //  if (!res.ok || res.body == null) {
  //   // setStep(STEPS.ERROR)
  //   throw new Error('Error al generar el código')
  //  }

  //  const streamReader = await res.json()
  //  console.log(
  //   '🚀 ~ file: clienteActions.js:45 ~ return ~ streamReader:',
  //   streamReader
  //  )
  //  const decoder = new TextDecoder()
  //  console.log('🚀 ~ file: clienteActions.js:51 ~ return ~ decoder:', decoder)

  //  while (true) {
  //   console.log('🚀 ~ file: clienteActions.js:53 ~ return ~ while (true):')
  //   const { done, value } = await decoder.read()
  //   console.log('🚀 ~ file: clienteActions.js:55 ~ return ~ done:', done)
  //   console.log('🚀 ~ file: clienteActions.js:55 ~ return ~ value:', value)
  //   const chunk = decoder.decode(value)
  //   console.log('🚀 ~ file: clienteActions.js:56 ~ return ~ chunk:', chunk)
  //   dispatch(clienteLoaded(chunk))
  //   if (done) break
  //  }

  //  //  for await (const chunk of streamReader) {
  //  //   dispatch(clienteLoaded(chunk))
  //  //  }
  // } catch (error) {
  //  console.log('🚀 ~ file: clienteActions.js:46 ~ return ~ error:', error)
  //  dispatch(
  //   errorSetActive(
  //    error.response?.data.message || 'Ocurrioooo un error al cargar Clientes',
  //    'error'
  //   )
  //  )
  // }

  try {
   const res = await ApiAxiosGet(`${URL}/getclientes`, 'GET')
   // dispatch(errorClearActive())
   dispatch(clienteLoaded(res))
//    console.log('CLEOEEEEEEEEEEEEEEEEEEE', res)
  } catch (error) {
   // const msgError = error
   console.log('AQUI HUBO UN ERROR', error)
   dispatch(
    errorSetActive(
     error.response?.data.message || 'Ocurrio un error al cargar Clientes',
     'error'
    )
   )
  }
 }
}

export const startLoadClienteDetalle = (idCliente) => {
 return (dispatch) => {
  axios.get(`${url}/detalle/${idCliente}`).then((res) => {
   dispatch(clienteLoadedDetalle(res.data))
  })
 }
}

const clienteLoaded = (clientes) => ({
 type: types.clienteLoaded,
 payload: clientes,
})

const clienteLoadedDetalle = (cliente) => ({
 type: types.clienteLoaded,
 payload: cliente,
})

export const clienteOpenModal = () => ({
 type: types.clienteOpenModal,
})

export const clienteCloseModal = () => ({
 type: types.clienteCloseModal,
})

export const clienteOpenModalDelete = () => ({
 type: types.clienteOpenModalDelete,
})

export const clienteCloseModalDelete = () => ({
 type: types.clienteCloseModalDelete,
})

export const clienteAddNew = (cliente) => ({
 type: types.clienteAddNew,
 payload: cliente,
})

export const clienteSetActive = (cliente) => ({
 type: types.clienteSetActive,
 payload: cliente,
})

export const clienteClearActive = () => ({
 type: types.clienteClearActive,
})

export const clienteUpdated = (cliente) => ({
 type: types.clienteUpdated,
 payload: cliente,
})

export const deletecliente = (cliente) => ({
 type: types.clienteDelete,
 payload: cliente,
})

export const reactivecliente = (cliente) => ({
 type: types.clienteReactived,
 payload: cliente,
})
