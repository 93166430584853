// import axios from 'axios'
// import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
import { errorSetActive, errorStatusLoading } from './errorActions'
import { DockSharp } from '@material-ui/icons'

const URL = `${config.urlServer}/mantenimiento`
const url = `${config.urlServer}/facturas`

export const startLoadControlFacturas = (id_localidad) => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URL}/control/getcontrolesfacturasByLocalidad/${id_localidad}`,
        'GET'
      )
      dispatch(controlFacturaLoaded(res.result))
    } catch (error) {
      console.log('ERRORRROR', error)
      dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

export const startSaveControlfacturalLocalidad = (ControlLocalidad) => {
  return async (dispatch, getState) => {
    try {
      const res = await ApiAxiosPost(
        `${url}/addcontrolLoc`,
        'POST',
        ControlLocalidad
      )
      console.log('res: ', res)
      return dispatch(errorSetActive('Control De factura registrada', 'sucess'))
      //   dispatch(controlFacturaActive(res.result))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

export const startSaveControlOrdenesLocalidad = (ControlLocalidad) => {
  return async (dispatch, getState) => {
    try {
      const res = await ApiAxiosPost(
        `${url}/addcontrolOrdenGuiaLoc`,
        'POST',
        ControlLocalidad
      )
      console.log('res: ', res)
      return dispatch(errorSetActive('Control De Ordenes/Guias registrada', 'sucess'))
      //   dispatch(controlFacturaActive(res.result))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

// Control Sucursarl Acttion
export const startLoadControlFacturasSucursal = (IDControlFacturalocalidad) => {
  // console.log(
  //   'IDControlFacturalocalidadEEEEEEEEEEEEE: ',
  //   IDControlFacturalocalidad
  // )
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(
        `${URL}/control/getcontrolesSucursalesByIDControlFacturalocalidad/${IDControlFacturalocalidad}`,
        'GET'
      )
      dispatch(controlSucursalLoaded(res.result))
      // console.log('res De Sucursal: ', res)
    } catch (error) {
      console.log('ERRORRROR', error)
      dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

export const startSaveControlfacturalSucursal = (ControlLocalidad) => {
  return async (dispatch, getState) => {
    try {
      const res = await ApiAxiosPost(
        `${url}/addcontrolsucur`,
        'POST',
        ControlLocalidad
      )
      console.log('res: ', res)
      return dispatch(
        errorSetActive('Control de  Sucursal registrada con exito', 'sucess')
      )
      //   dispatch(controlFacturaActive(res.result))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

export const startSaveControlOrdenesGuiasSucursal = (ControlLocalidad) => {
  return async (dispatch, getState) => {
    try {
      const res = await ApiAxiosPost(
        `${url}/addcontrolsucurOrdenGuia`,
        'POST',
        ControlLocalidad
      )
      console.log('res: ', res)
      return dispatch(
        errorSetActive('Control de  Sucursal registrada con exito', 'sucess')
      )
      //   dispatch(controlFacturaActive(res.result))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data?.message || 'ocurrido un error de red'
        )
      )
    }
  }
}

export const ConsultacontrolSucursalLocalidad = (data, tipo) => {
  return async (dispatch) => {
    // eslint-disable-next-line no-use-before-define
    //Llamado al endpoint usando Axios
    try {
      // dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/control/getcontrolesSucursaleLocalidad`,
        'POST',
        // agregar el tipo a el objeto
        { ...data, tipo }
      )

      // console.log('CONTRol', res.result)
      // dispatch(sucursalUpdated(res.result))
      return dispatch(
        errorSetActive('Control sucusal/Localidad ', 'sucess', res.result)
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const ConsultacontrolSucursalLocalidadOrdenesGuias = (data, tipo) => {
  return async (dispatch) => {
    // eslint-disable-next-line no-use-before-define
    //Llamado al endpoint usando Axios
    try {
      // dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/control/getcontrolesSucursaleLocalidadOrdenesGuia`,
        'POST',
        // agregar el tipo a el objeto
        { ...data, tipo }
      )

      // console.log('CONTRol', res.result)
      // dispatch(sucursalUpdated(res.result))
      return dispatch(
        errorSetActive('Control sucusal/Localidad ', 'sucess', res.result)
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const ConsultacontrolOrdenesSucursalLocalidad = (data, tipo) => {
  return async (dispatch) => {
    // eslint-disable-next-line no-use-before-define
    console.log('DATA', data)
    //Llamado al endpoint usando Axios
    try {
      // dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/control/getcontrolesOrdenesSucursaleLocalidad`,
        'POST',
        // agregar el tipo a el objeto
        { ...data, tipo }
      )

      // console.log('CONTRol', res.result)
      // dispatch(sucursalUpdated(res.result))
      return dispatch(
        errorSetActive('Control sucusal/Localidad ', 'sucess', res.result)
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const ConsultaNroFacturaAuto = (id, tipo) => {
  return async (dispatch) => {
    //Llamado al endpoint usando Axios
    try {
      // dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${URL}/nroFacturaAuto/getConsultaNroFacturaAuto`,
        'post',
        { id, tipo }
      )
      tipo === 'id_localidad'
        ? dispatch(controlNroFacturaLoaded(res.result))
        : dispatch(controlNroFacturaLoadedSucursal(res.result))

      // console.log('RES NROFACTURA', res)
      // return dispatch(
      //   errorSetActive('Control sucusal/Localidad ', 'sucess', res.result)
      // )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startSaveNroFacturaAuto = (data, tipo) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        tipo === 'id_localidad'
          ? `${URL}/nroFacturaAuto/AddUpdateControlfacturaAuto`
          : `${URL}/nroFacturaAuto/AddUpdateControlfacturaAutoSucursal`,
        'POST',
        data
      )
      console.log('RESSSSSSS', res)
      tipo === 'id_localidad'
        ? dispatch(updateAsignaNroFacturasLocalidad(res.result.res))
        : dispatch(updateAsignaNroFacturasSucursal(res.result.res))

      return dispatch(
        errorSetActive(
          `Actualizado Control de Fascturas Automatico ${res.result.res.asignaNroFacturas}`,
          'sucess'
        )
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

const updateAsignaNroFacturasLocalidad = (control) => ({
  type: types.updateAsignaNroFacturasLocalidad,
  payload: control,
})

const updateAsignaNroFacturasSucursal = (control) => ({
  type: types.updateAsignaNroFacturasSucursal,
  payload: control,
})

const controlNroFacturaLoaded = (control) => ({
  type: types.CtrNrotofacturasLoaded,
  payload: control,
})

const controlNroFacturaLoadedSucursal = (control) => ({
  type: types.CtrNrotofacturasLoadedSucursal,
  payload: control,
})  

const controlFacturaLoaded = (bancos) => ({
  type: types.controlFacturaLoaded,
  payload: bancos,
})
// const controlFacturaActive = (bancos) => ({
//   type: types.controlFacturaSetActive,
//   payload: bancos,
// })

export const ControlFacturaClearActive = () => ({
  type: types.controlFacturaClearActive,
})

export const ControlFacturaClearloded = () => ({
  type: types.controlFacturaClearLoaded,
})

// Sucursal
const controlSucursalLoaded = (bancos) => ({
  type: types.controlSucursaloaded,
  payload: bancos,
})

export const ControlSucursalClearloded = () => ({
  type: types.ControlSucursalClearloded,
})
