import React from "react";
import moment from 'moment';

//Idioma
moment.locale('es');

export const DatosSeguimiento = (props) => {
  const { datos } = props;
  return (
    <div className="container">
      <div className="row mt-2">
          <h6><strong>Datos de seguimiento</strong></h6>
      </div>
      <div className="row">
        <div className="col-sm-3">Fecha registro:</div>
        <div className="col-sm-4">{ moment(datos.logFechaRegistro).format("DD/MM/YYYY hh:mm:ss a") }</div>
      </div>
      <div className="row">
        <div className="col-sm-3">Usuario:</div>
        <div className="col-sm-4">{ datos.logUsername }</div>
      </div>
      <div className="row">
        <div className="col-sm-3">Fecha de modificación:</div>
        <div className="col-sm-4">{ (datos.logFechaModificacion !== null)? moment(datos.logFechaModificacion).format("DD/MM/YYYY hh:mm:ss a"): "No ha sido modificado!" }</div>
      </div>
    </div>
  );
};
