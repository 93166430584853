import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  detalle: {
    fontSize: '10px',
  },
  body: {
    minHeight: '155px',
    backgroundColor: '#e0e0e0',
    padding: '8px',
  },
}))

export const DatosCliente = () => {
  const { clienteActive } = useSelector((state) => state.facturaGeneral)

  const classes = useStyles()

  return (
    <Grid container spacing={0} className={classes.body}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Typography className={classes.title}>Datos del Cliente</Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Typography className={classes.detalle}>
          <strong>Rif:</strong> {clienteActive?.tipoDocumento}-
          {clienteActive?.numeroDocumento}
        </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Typography className={classes.detalle}>
          <strong>Identificación:</strong> {clienteActive?.identificacion}
        </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Typography className={classes.detalle}>
          <strong>Correo:</strong> {clienteActive?.correo}
        </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Typography className={classes.detalle}>
          <strong>Correo CC:</strong> {clienteActive?.ccCorreo}
        </Typography>
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
        <Typography className={classes.detalle}>
          <strong>Dirección:</strong> {clienteActive?.direccion}
        </Typography>
      </Grid>
    </Grid>
  )
}
