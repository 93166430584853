import React from 'react'
import { useModal } from '../../../hooks/useModal'
import ModalGenerico from '../../commons/ui/Modal'

const FacturaAerea = () => {
  const { handleClose, handleOpen, open,  } = useModal()


  return (
    <div>
      <button type='button' onClick={handleOpen}>
        Open Modal
      </button>
      <ModalGenerico handleClose={handleClose} open={open}>
        prueba
      </ModalGenerico>
    </div>
  )
}

export default FacturaAerea

// jcanare2@gmail.com
