import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";


const TotalesFacturaGeneral = () => {
    
    const useStyles = makeStyles((theme) => ({
        root: {
          "& > *": {
            margin: theme.spacing(1),
            width: theme.spacing(32),
            height: theme.spacing(16)
          }
        },
        paper: {
          backgroundColor: "white",
          padding: "15px",
          borderRadius: 5,
          opacity: "0.7",
        },
        textMontos: {
          textAlign:"right"
        }
      }));

      const classes = useStyles();

    
    return (
        <Paper elevation={2} className={classes.paper}>
            <div className="row">
                <h6><strong>Totales</strong></h6>
            </div>
            <div className="row">
              <div className="col-md-6">
                  SubTotal
              </div>
              <div className="col-md-6 text-lg-right">
                  45.000.000,00
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  Exonerado
              </div>
              <div className="col-md-6 text-lg-right">
                  0.00
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  Exento
              </div>
              <div className="col-md-6 text-lg-right">
                  12.000.000,00
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  <strong>Importe total</strong>
              </div>
              <div className="col-md-6 text-lg-right">
              <strong>56.000.000,00</strong>
              </div>
            </div>
        </Paper>
    )
}

export default TotalesFacturaGeneral;
