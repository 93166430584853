import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Swal from 'sweetalert2';

import { startSaveFacturaCompra, startUpdateFacturaCompra, facturaComprasOpenDetalleModal } from '../../../redux/actions/facturaCompraActions';
import NewCliente from '../cliente/NewCliente';
import DatosFactura from './DatosFactura';
import DetalleFacturaCompra from './DetalleFacturaCompra';
import TotalesFacturaCompra from './TotalesFacturaCompra';
import { startLoadProductos } from '../../../redux/actions/productoActions';


const initFacturaCompras = {
    nroControl: '',
    nroFactura: '',
    cliente: '',
    logEstatusRegistro: "1",
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}
export const FacturaCompraBase = () => {


    const dispatch = useDispatch();
    const { activeFacturaCompra, modalDetalleFacturaCompra } = useSelector(state => state.facturaCompra);

    useEffect(() => {
         
        dispatch( startLoadProductos() );

    }, [dispatch]);

    const [formValues, setFormValues] = useState( initFacturaCompras );

    const { nroControl, nroFactura, cliente } = formValues;
    
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (nroControl.trim().length < 1) {

            return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        }

        if (activeFacturaCompra) {
            dispatch(startUpdateFacturaCompra(formValues));
        } else {
            dispatch(startSaveFacturaCompra({
                ...formValues
            }));
        }
    }

    return (
        
        <form
                className="container"
                onSubmit={handleSubmit}
            >
                <div>
                    <div className="form-group row">
                        <div className="col-md">
                            <NewCliente />
                        </div>
                        <div className="col-md">
                            <DatosFactura />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md">
                            <DetalleFacturaCompra />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md-6">
                        <button
                        type="submit"
                        className="btn btn-outline-primary btn-block btn-lg"
                    >
                        <i className="far fa-save"></i>
                        <span> Guardar factura</span>
                    </button>
                        </div>
                        <div className="col-md-6">
                            <TotalesFacturaCompra />
                        </div>
                    </div>
                    
                </div>
            </form>
    )
}
