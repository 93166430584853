import useStyles from './Styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ArrowForward from '@material-ui/icons/ArrowForward'

const renderData = (data) => {
  return (
    <ul>
      {data.map((d) => (
        <li key={d['id']}>
          {' '}
          The passenger having id {d['id'].slice(d['id'].length - 5)} using{' '}
          {d.airline[0].name} airlines
        </li>
      ))}
    </ul>
  )
}

const Pagination = (props) => {
  const classes = useStyles()

  console.log('🚀 ~ file: Pagination.js:19 ~ Pagination ~ props:', props)
  // init
  const { currentPage, maxPageLimit, minPageLimit } = props
  const totalPages = props.totalPages // props.currentPage - 1
  const data = props?.response || []

  // build page numbers list based on total number of pages
  const pages = []
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i)
  }

  const handlePrevClick = () => {
    props.onPrevClick()
  }

  const handleNextClick = () => {
    props.onNextClick()
  }

  const handlePageClick = (e) => {
    props.onPageChange(Number(e.target.id))
  }

  const pageNumbers = pages.map((page) => {
    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        <li
          key={page}
          id={page}
          onClick={handlePageClick}
          className={currentPage === page ? classes.active : classes.li}
        >
          {page}
        </li>
      )
    } else {
      return null
    }
  })

  // page ellipses
  let pageIncrementEllipses = null
  if (pages.length > maxPageLimit) {
    pageIncrementEllipses = <li onClick={handleNextClick}>&hellip;</li>
  }
  let pageDecremenEllipses = null
  if (minPageLimit >= 1) {
    pageDecremenEllipses = <li onClick={handlePrevClick}>&hellip;</li>
  }

  return (
    <div className={classes.main}>
      {/* <div className='mainData'>{renderData(data)}</div> */}
      <ul className={classes.pageNumbers}>
        <li>
          <button
            className={classes.button}
            onClick={handlePrevClick}
            disabled={currentPage === pages[0]}
          >
            <ArrowBack fontSize='small' />
            {/* Prev. */}
          </button>
        </li>
        {pageDecremenEllipses}
        {pageNumbers}
        {pageIncrementEllipses}
        <li>
          <button
            className={classes.button}
            onClick={handleNextClick}
            disabled={currentPage === pages[pages.length - 1]}
          >
            <ArrowForward fontSize='small' />
            {/* Prox. */}
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
