import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadTiposGravamenes } from '../redux/actions/tipoGravamenActions'

export const useTipoGravamenes = () => {
  const { tiposGravamenes, activeTipoGravamen } = useSelector(
    (state) => state.tipoGravamen
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startLoadTiposGravamenes())
  }, [dispatch])

  

  return { tiposGravamenes, activeTipoGravamen }
}
