import { useSelector } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'

export default function MostrarPdf({ open, handleClose, title }) {
  const { activeNota } = useSelector((state) => state.nota)

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={
        activeNota.id_localidad === undefined
          ? `Cliente: ${activeNota.id_sucursal.nombre}`
          : `Cliente: ${activeNota.id_localidad.nombre}`
      }
      top={55}
      left={55}
    >
      <iframe
        src={`data:application/pdf;base64,${activeNota?.notaCodificada}`}
        title='Factura'
        name='Factura'
        style={{
          border: 'none',
          minHeight: '80vh',
          minWidth: '80vh',
        }}
      />
    </ModalGenerico>
  )
}
