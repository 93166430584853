import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { TipoGravamenModal } from './TipoGravamenModal'
import AgregarGravamen from './AgregarGravamen'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import { useToast } from '../../../hooks/useToast'

import {
  // tipoGravamenOpenModal,
  tipoGravamenOpenModalDelete,
  tipoGravamenSetActive,
  // startReactivedTipoGravamen,
  startLoadTiposGravamenes,
} from '../../../redux/actions/tipoGravamenActions'
import { TipoGravamenModalDelete } from './TipoGravamenModalDelete'
// import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import { ValoresGravamenAsoc } from './ValoresGravamenAsoc'
import { hasPermission } from '../../../Helpers/AuthPermisos'

const TipoGravamen = () => {
  const dispatch = useDispatch()
  const { notifyError, notifysucess } = useToast()
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { roles } = useSelector((state) => state.role)

  const [ModalAgregarGravamen, setmodalAgregarGravamen] = useState(false)

  const { tiposGravamenes, modalTipoGravamenDelete } = useSelector(
    (state) => state.tipoGravamen
  )

  const columnas = [
    {
      title: 'Nombre',
      field: 'concepto',
      cellStyle: {
        width: 20,
        maxWidth: 20,
      },
      defaultSort: 'asc',
    },
    {
      title: 'IVA General',
      field: 'ivaGeneral',
      render: (rowData) =>
        String(rowData.ivaGeneral) === 'true' ? 'Sí' : 'No',
    },
    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
    },
  ]

  useEffect(() => {
    dispatch(startLoadTiposGravamenes())
  }, [dispatch])

  const HandleAddTipoGravamen = () => {
    setmodalAgregarGravamen(true)
  }
  const handleCloseSucursalModal = () => {
    setmodalAgregarGravamen(false)
  }

  // const onUpdateTipoGravamen = (e) => {
  //   dispatch(tipoGravamenSetActive(e))
  //   dispatch(tipoGravamenOpenModal())
  // }

  const handleDelete = (e) => {
    dispatch(tipoGravamenSetActive(e))
    dispatch(tipoGravamenOpenModalDelete())
  }

  const handleReactive = (e) => {
    dispatch(tipoGravamenSetActive(e))
    dispatch(tipoGravamenOpenModalDelete())

    // dispatch(startReactivedTipoGravamen(e))
  }

  // const openModal = () => {
  //   dispatch(tipoGravamenOpenModal())
  // }

  return (
    <div>
      <br />
      {/* <TipoGravamenModal /> */}
      {modalTipoGravamenDelete && <TipoGravamenModalDelete />}
      <MaterialTable
        title='Tipos Alícuotas'
        columns={columnas}
        data={tiposGravamenes}
        detailPanel={(rowData) => {
          return <ValoresGravamenAsoc Gravamenes={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nuevo TipoGravamen',
            isFreeAction: true,
            onClick: (event) => HandleAddTipoGravamen(event),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/TipoGravamen',
              'add',
              user.user
            ),
          },
          //   (rowData) => ({
          //     icon: 'edit',
          //     tooltip: 'Modificar',
          //     onClick: (event, rowData) => onUpdateTipoGravamen(rowData),
          //     hidden: rowData.logEstatusRegistro === 'INACTIVO',
          //   }),
          (rowData) => ({
            icon: () => (
              <PowerSettingsNewIcon fontSize='small' htmlColor='red' />
            ),
            tooltip: 'Inactivar',
            onClick: (event, rowData) => handleDelete(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/TipoGravamen',
                'cancel',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon fontSize='small' htmlColor='blue' />,
            tooltip: 'Activar',
            onClick: (event, rowData) => handleReactive(rowData),
            hidden:
              rowData.logEstatusRegistro === 'ACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/TipoGravamen',
                'cancel',
                user.user
              ),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {ModalAgregarGravamen && (
        <AgregarGravamen
          open={ModalAgregarGravamen}
          handleClose={handleCloseSucursalModal}
          title='Agregar Tipo de Gravamen'
        />
      )}
    </div>
  )
}

export default TipoGravamen
