import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento`;


export const startLoadBancos = () => {
    console.log(url)
    return (dispatch) => {

        axios.get(`${url}/bancos`)
        .then(res => {
            dispatch(bancoLoaded(res.data));
        });
    }
}

export const startSaveBanco = (banco) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: banco
        })
            .then((response) => {
                
                Swal.fire('Guardado', banco.nombre, 'success');
                dispatch(bancoAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });

    }
}

export const startDeleteBanco = () => {

    return async (dispatch, getState) => {
       
        const { activeBanco } = getState().banco;
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeBanco.id}`
        })
            .then((response) => {
                
                Swal.fire('Inactivado', activeBanco.nombre, 'success');
                dispatch(deleteBanco(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedBanco = (banco) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${banco.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', banco.nombre, 'success');
                dispatch(reactiveBanco(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateBanco = (banco) => {

    return (dispatch, getState) => {

        const { activeBanco } = getState().banco;
        const id = activeBanco.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: banco
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.data, 'success');
                dispatch(bancoUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}


const bancoLoaded = (bancos) => ({
    type: types.bancoLoaded,
    payload: bancos
});

export const bancoOpenModal = () => ({
    type: types.bancoOpenModal
});

export const bancoCloseModal = () => ({
    type: types.bancoCloseModal
});

export const bancoOpenModalDelete = () => ({
    type: types.bancoOpenModalDelete
});

export const bancoCloseModalDelete = () => ({
    type: types.bancoCloseModalDelete
})

export const bancoAddNew = ( banco ) => ({
    type: types.bancoAddNew,
    payload: banco
});

export const bancoSetActive = ( banco ) => ({
    type: types.bancoSetActive,
    payload: banco
});

export const bancoClearActive = ( ) => ({
    type: types.bancoClearActive,
});

export const bancoUpdated = ( banco ) => ({
    type: types.bancoUpdated,
    payload: banco
});

export const deleteBanco = ( banco ) => ({
    type: types.bancoDelete,
    payload: banco
});

export const reactiveBanco = ( banco ) => ({
    type: types.bancoReactived,
    payload: banco
});