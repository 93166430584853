import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#fff',
    // height: '22px',
    // '& .MuiAutocomplete-inputRoot': {
    //   fontSize: '12px',
    //   color: '#01579b',
    // },
    // '& .MuiFormControlLabel-label': {
    //   fontSize: '10px',
    //   color: '#01579b',
    // },
    // '& .MuiSvgIcon-root': {
    //   fontSize: 16,
    //   // marginTop: 0,
    // },
  },
  passwordRoot: {
    width: '550px',
    minHeight: '325px',
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '12px',
    // '& .MuiTypography-root': {
    //   fontSize: '12px',
    // },
  },
  cardheader_icons: {
    color: 'white',
    // padding: '8px 8px 8px 0',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  texto: {
    fontWeight: 'bold',
    fontSize: '12px',
  },
  switchgenerico: {
    marginTop: '8px',
    '& .MuiTypography-body1': {
      marginLeft: '8px',
      fontSize: '14px',
    },
  },
  btn: {
    borderColor: '#becddc',
    fontSize: '0.75rem',
    backgroundColor: '#054477',
    color: 'white',
    marginTop: '24px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1857a4',
    },
  },
  btnModal: {
    borderColor: '#becddc',
    fontSize: '0.75rem',
    backgroundColor: '#054477',
    color: 'white',
    marginTop: '24px',
    marginBottom: '12px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1857a4',
    },
  },
  btnlimpiar: {
    borderColor: '#becddc',
    fontSize: '0.75rem',
    backgroundColor: '#fd7e14',
    color: 'white',
    marginTop: '24px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1857a4',
    },
  },
  btnDescarga: {
    borderColor: '#becddc',
    fontSize: '0.75rem',
    backgroundColor: '#198754',
    color: 'white',
    marginTop: '24px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 20,
    '&:hover': {
      backgroundColor: '#1857a4',
    },
  },

  bbtnref: {
    border: '2px solid #054477',
    borderRadius: '6px',
    backgroundColor: '#054477',
    color: 'white',
    padding: '15px 15px 15px 15px',
    textDecorationLine: 'none',
    textDecorationColor: 'none',
    fontSize: '16px',
    margin: '4px 2pxx',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'transparent',
      color: '#05447e',
    },
  },
  textField: {
    marginTop: '16px',
    width: '100%',
    fontSize: '10px',
  },
  name: {
    fontWeight: 600,
    fontSize: '.780rem',
    color: '#122740',
  },
  caption: {
    fontSize: '0.775rem',
    color: '#758392',
    marginTop: -4,
    textAlign: 'left',
  },
  tablehead: {
    '&.MuiTableCell-root': {
      // fontSize: '11px',
      // padding: '12px',
      backgroundColor: '#054477',
      color: 'white',
    },
  },
  tablerow: {
    '&.MuiTableCell-root': {
      // fontSize: '10px',
    },
  },

  // classes de paginacion

  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // .mainData{
  //   max-width: 500px;
  //   max-height: 600px;
  // }
  pageNumbers: {
    listStyle: 'none',
    display: 'flex',
  },

  li: {
    padding: '0 4px 0 4px',
    border: '1px solid white',
    backgroundColor: 'white',
    color: 'black',
  },

  active: {
    padding: '0 4px 0 4px',
    border: '1px solid black',
    backgroundColor: '#054477',
    color: 'white',
  },

  timelinedote: {
    '&.MuiTimelineDot-defaultGrey': {
      padding: '6px',
      backgroundColor: '#f9b115',
      color: 'white',
    },
  },
  typederecha: {
    fontWeight: 'bold',
  },

  tablerowFecha: {
    width: '60px',
  },

  button: {
    backgroundColor: 'transparent',
    // padding: '2px',
    border: 'none',
    // color: 'white',
    // fontSize: '1rem',
    // cursor: 'pointer',
    // '&:hover': {
    //   cursor: 'pointer',
    //   backgroundColor: 'white',
    //   color: 'black',
    // },
    // '&:focus': {
    //   outline: 'none',
    // },
  },
  strinbusquedad: {
    // fontSize: '10px',
    fontWeight: 'bold',
  },

  // .pageNumbers li.button:hover{
  //   background-color: white;
  //   color: black;
  // }

  // .pageNumbers li.button:focus{
  //   outline: none;
  // }
}))

export default useStyles
