import { Container, Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FormatedBolivares } from '../../../Helpers/formater'
import LoadingCirculo from '../../commons/ui/LoadingCirculo'
import ModalGenerico from '../../commons/ui/Modal'
// import { FormatedBolivares } from '../../../../Helpers/formater'
// import LoadingCirculo from '../../../../commons/ui/LoadingCirculo'
// import { useModal } from "../../../../hooks/useModal"
// import ModalGenerico from '../../../../commons/ui/Modal'
// import CondicionPago from './CondicionPago'
// import TotalesGenerales from './TotalesGenerales'
import { StylesConsultaNotas } from './StylesConsultaNotas'

const ConsultaNota = ({ handleClose, open, title }) => {
  const classes = StylesConsultaNotas()
  const { isError, loading } = useSelector((state) => state.error)
  const { activeNota } = useSelector((state) => state.nota)

  // const { lstDetallesFacturaGeneral, lstPagos } = activeNota

  return (
    <ModalGenerico handleClose={handleClose} open={open} title={title}>
      {loading ? (
        <LoadingCirculo size={60} height='10vh' />
      ) : (
        <Container
          maxWidth='xl'
          style={{
            backgroundColor: '#f5f5f5',
            overflowY: 'scroll',
            minHeight: '50vh',
          }}
        >
          <Typography varint='h6' style={{ fontWeight: 'bold' }}>
            Datos básicos
          </Typography>
          <Grid container spacing={0} className={classes.dbasicos}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>N° Nota: </strong>
                {activeNota?.nroNota}
              </Typography>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <Typography>
                <strong>N° Factura: </strong>
                {activeNota?.nroFactura}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>Razón social ó Sucursal: </strong>
                {activeNota?.id_localidad
                  ? activeNota?.id_localidad?.nombre
                  : activeNota?.id_sucursal?.nombre}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Typography>
                <strong>Tipo Nota: </strong> {activeNota?.tipo}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Concepto: </strong>
                {activeNota?.concepto}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 12 }}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                Cliente
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.dbasicos}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>RIF: </strong>
                {activeNota?.id_factura?.cliente?.tipoDocumento} -{' '}
                {activeNota?.id_factura?.cliente?.numeroDocumento}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Nombre: </strong>{' '}
                {activeNota?.id_factura.cliente.identificacion}
              </Typography>
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Correo: </strong>{' '}
                {activeNota?.id_factura?.cliente.correo}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <Typography>
                <strong>Dirección: </strong>
                {activeNota?.id_factura?.cliente?.direccion}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1} style={{ marginTop: 10 }}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                Detalle
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            // justifyContent='center'
            // alignItems='center'
            className={classes.headers}
          >
            <Grid item lg={4} sm={4} xl={12} xs={12}>
              <Typography style={{ textAlign: 'left' }}>Concepto</Typography>
            </Grid>
            <Grid item lg={1} sm={1} xl={12} xs={12}>
              <Typography style={{ textAlign: 'center' }}>Cant.</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Precio Uni.</Typography>
            </Grid>
            <Grid item lg={1} sm={1} xl={12} xs={12}>
              <Typography style={{ textAlign: 'center' }}>% Ali</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Monto Ali.</Typography>
            </Grid>
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Typography>Importe</Typography>
            </Grid>
          </Grid>

          {activeNota?.lstDetallesNota.length > 0 &&
            activeNota.lstDetallesNota.map((item) => {
              return (
                <Grid
                  container
                  direction='row'
                  className={classes.body}
                  key={item._id}
                >
                  <Grid item lg={4} sm={4} xl={12} xs={12}>
                    <Typography
                      style={{ textAlign: 'left' }}
                      className={classes.root}
                    >
                      {item.descripcion}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} sm={1} xl={12} xs={12}>
                    <Typography style={{ textAlign: 'center' }}>
                      {item.cantidad}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={2} xl={12} xs={12}>
                    <Typography>
                      {item?.alicuotaGravamen > 0
                        ? FormatedBolivares(item.precio, 'es-VE', 'VEF')
                        : item.exento
                        ? FormatedBolivares(item.precio, 'es-VE', 'VEF') + '(E)'
                        : FormatedBolivares(item.precio, 'es-VE', 'VEF') +
                          '(EX)'}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} sm={1} xl={12} xs={12}>
                    <Typography style={{ textAlign: 'center' }}>
                      {Number(item.alicuotaGravamen).toFixed(0)}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={2} xl={12} xs={12}>
                    <Typography>
                      {FormatedBolivares(item.montoGravamen, 'es-VE', 'VEF')}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} sm={2} xl={12} xs={12}>
                    <Typography>
                      {FormatedBolivares(item.importe, 'es-VE', 'VEF')}
                    </Typography>
                  </Grid>
                </Grid>
              )
            })}

          {/* {activeNota?.factura.subTotal && (
            <TotalesGenerales
              activeNota={activeNota.factura}
            />
          )} */}
        </Container>
      )}
    </ModalGenerico>
  )
}
export default ConsultaNota
