import { useState } from 'react'
import { Modal, Box, Typography, Divider, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { useStyles, getModalStyle } from './styles'

const ModalGenerico = ({
  children,
  open,
  handleClose,
  title = '',
  top = 40,
  left = 50,
  // w = '200%',
}) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle(top, left))


  return (
    <Modal
      // style={{ width: w }}
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div style={modalStyle} className={classes.paper}>
        {/* <div className={classes.paper}> */}
        <Box
          // maxWidth='550px'
          // maxHeight='100px'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          p={0}
          bgcolor='background.paper'
        >
          <Typography padding={0} variant='h6' color='textPrimary'>
            {title}
          </Typography>
          <IconButton padding={0} onClick={handleClose}>
            <HighlightOffIcon size='small' padding={0} />
          </IconButton>
        </Box>
        <Divider component='div' style={{ marginBottom: '16px' }} />

        {children}
      </div>
    </Modal>
  )
}
export default ModalGenerico
