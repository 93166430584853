import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';
import { bancoCloseModal, startSaveBanco, startUpdateBanco, bancoClearActive } from '../../../redux/actions/bancoActions';

//Valores default objeto Banco
const initBanco = {
    nombre: '',
    correo: '',
    urlPaginaWeb: '',
    logEstatusRegistro: "1",
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}

Modal.setAppElement('#root');

export const BancoModal = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { activeBanco, modalBancoOpen } = useSelector(state => state.banco);

    const [formValues, setFormValues] = useState( initBanco );

    const { nombre, correo, urlPaginaWeb } = formValues;

    useEffect(() => {
        if ( activeBanco ) {
            setFormValues( activeBanco );
        } else {
            setFormValues( initBanco );
        }
    }, [activeBanco, setFormValues])

    //Gestiona estado del Form
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    //Cierra el modal
    const closeModal = () => {

        dispatch(bancoCloseModal());
        dispatch(bancoClearActive());
        setFormValues(initBanco);
    }

    //Envía datos del form
    const handleSubmit = (e) => {
        e.preventDefault();

        //Validaciones
        if (nombre.trim().length < 2) {
            return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        } else {
            if (activeBanco) {
                dispatch(startUpdateBanco(formValues));
            } else {
                dispatch(startSaveBanco({
                    ...formValues
                }));
            }
        }

        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',

        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={modalBancoOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            { (activeBanco) ? <h3>{ t('mantenimiento.banco.modal.edit.title') }</h3> : <h3>{ t('mantenimiento.banco.modal.new.title') }</h3> } 
            
            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >

                <div className={styles.modal}>
                    <div className="form-group row">
                    <div className="col-md">
                    <label htmlFor="nombre" className="form-label">{ t('mantenimiento.banco.modal.field.nombre.label') }</label>
                        <input
                            type="text"
                            id='nombre'
                            className="form-control form-control-sm"
                            placeholder= { t('mantenimiento.banco.modal.field.nombre.placeholder') }
                            name="nombre"
                            autoComplete="off"
                            value={nombre}
                            onChange={handleInputChange}
                            required={true}
                            title= { t('mantenimiento.banco.modal.field.nombre.title') }
                        />
                    </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-md">
                        <label htmlFor="correo" className="form-label">{ t('mantenimiento.banco.modal.field.correo.label') }</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            id='correo'
                            placeholder={ t('mantenimiento.banco.modal.field.correo.placeholder') }
                            name="correo"
                            autoComplete="off"
                            value={correo}
                            onChange={handleInputChange}
                            title= { t('mantenimiento.banco.modal.field.correo.title') }
                        />
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-md">
                        <label htmlFor="urlPaginaWeb" className="form-label">{ t('mantenimiento.banco.modal.field.sitioWeb.label') }</label>
                        <input
                            type="text"
                            id='urlPaginaWeb'
                            className="form-control form-control-sm"
                            placeholder={ t('mantenimiento.banco.modal.field.sitioWeb.placeholder') }
                            name="urlPaginaWeb"
                            autoComplete="off"
                            value={urlPaginaWeb}
                            onChange={handleInputChange}
                            title={ t('mantenimiento.banco.modal.field.sitioWeb.title') }
                        />
                    </div>
                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block btn-lg"
                    >
                        <i className="far fa-save"></i>
                        <span> { t('general.button.guardar') } </span>
                    </button>
                </div>
            </form>
        </Modal>
    )
}


