import { types } from '../types/types'

const initialState = {
 ordenes: [],
 lstDetalles: [],
 nroOrden: null,
 facturaDivisa: 'VEF',
 importeTotal: null,
 subTotal: null,
 cambioDivisa: null,
 tipoCambioDiaUsd: null,
 fechaVencimiento: null,
 fechaEmision: null,
 modeloOrden: null,
 motivo: null,
 direccionEntrega: null,
 logEstatusRegistro: null,
 logDeletedRegistro: null,
 facturaGenerada: false,
 observacion: null,
 cliente: {},
 logFechaRegistro: null,
 logFechaModificacion: null,
 logEStatusRegistro: null,
 activeOrden: null,
 filtro: {},
 IncluirMontos: false,
}

export const ordenReducer = (state = initialState, action) => {
 switch (action.type) {
  case types.ordenesLoaded:
   return {
    ...state,
    ordenes: action.payload,
   }
  case types.ordenesClear:
   return {
    ...state,
    ordenes: [],
   }
  case types.ordenesActive:
   return {
    ...state,
    activeOrden: action.payload,
   }
  case types.ordenesClearAvtive:
   return {
    ...state,
    activeOrden: null,
   }
  case types.ordenesAdd:
   return {
    ...state,
    ordenes: [...state.ordenes, action.payload],
   }
  case types.filtroOrdenAdd:
   return {
    ...state,
    filtro: action.payload,
   }
  case types.ordenesClearFiltro:
   return {
    ...state,
    filtro: {},
   }
  case types.anularOrdenGuia:
   return {
    ...state,
    ordenes: {
     ...state.ordenes,
     results: state.ordenes.results.map((orden) => {
      if (orden.nroOrden === action.payload.idOrden) {
       return {
        ...orden,
        logEstatusRegistro: action.payload.logEstatusRegistro,
       }
      } else {
       return orden
      }
     }),
    },
    activeOrden: null,
   }
  case types.setUpdateOrden:
   return {
    ...state,
    [action.payload.name]: action.payload.value,
   }
  case types.ordenClearActive:
   return {
    ...state,
    IncluirMontos: false,
    activeOrden: null,
    lstDetalles: [],
    nroOrden: null,
    facturaDivisa: 'VEF',
    importeTotal: null,
    subTotal: null,
    cambioDivisa: null,
    tipoCambioDiaUsd: null,
    fechaVencimiento: null,
    fechaEmision: null,
    modeloOrden: null,
    motivo: null,
    direccionEntrega: null,
    logEstatusRegistro: null,
    logDeletedRegistro: null,
    facturaGenerada: false,
    observacion: null,
    cliente: {},
    logFechaRegistro: null,
    logFechaModificacion: null,
    logEStatusRegistro: null,
   }
  default:
   return state
 }
}
