import {
 Toolbar,
 Typography,
 Tooltip,
 IconButton,
 TextField,
 InputAdornment,
} from '@material-ui/core'
import useStyles from './Styles'
import SearchIcon from '@material-ui/icons/Search'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SearchSharp from '@material-ui/icons/SearchSharp'
import { doSearch } from '../../../Helpers/Search'
import FiltrarOrden from './FiltrarOrden'
import { useSelector } from 'react-redux'
import { hasPermission } from '../../../Helpers/AuthPermisos'

export const EnhancedTableToolbar = ({
 handleOpen,
 title,
 openFiltro,
 handleclosefiltro,
 hnadleOpenFiltro,
 goToBase,
}) => {
 var classes = useStyles()
 const { roles } = useSelector((state) => state.role)
 const { UsuarioLogiado: user } = useSelector((state) => state.auth)



 return (
  <Toolbar className={classes.root}>
   <Typography
    className={classes.title}
    variant='h6'
    id='tableTitle'
    component='div'
   >
    {title}
   </Typography>
   <TextField
    id='searchTerm'
    type='search'
    // variant="outlined"
    onChange={doSearch}
    placeholder='Buscar'
    margin='normal'
    InputProps={{
     startAdornment: (
      <InputAdornment position='start'>
       <SearchIcon />
      </InputAdornment>
     ),
    }}
    style={{
     // margin: '0 auto',
     width: 500,
    }}
   />

   {openFiltro && (
    <FiltrarOrden
     open={openFiltro}
     handleClose={handleclosefiltro}
     title='Filtro Orden/Guia'
    />
   )}

   {hasPermission(
    roles.filter((r) => r.id === user.user.role),
    '/facturacion/OrdenesGuias',
    'search',
    user.user
   ) && (
    <Tooltip title='filtro de busqueda Orden/Guia'>
     <IconButton aria-label='Filtrar odenes/Guias' onClick={hnadleOpenFiltro}>
      <SearchSharp className={classes.btnAdd} />
     </IconButton>
    </Tooltip>
   )}

   {hasPermission(
    roles.filter((r) => r.id === user.user.role),
    '/facturacion/OrdenesGuias',
    'add',
    user.user
   ) && (
    <Tooltip title='Agrega una Nueva Orden'>
     <IconButton aria-label='Agregar ordenes/guias' onClick={goToBase}>
      <AddShoppingCartIcon className={classes.btnAdd} />
     </IconButton>
    </Tooltip>
   )}
  </Toolbar>
 )
}
