import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';
import { usuarioCloseModal, startSaveUsuario, startUpdateUsuario, usuarioClearActive } from '../../../redux/actions/usuarioActions';
import {
    localidadClearActive,
    startLoadLocalidades
  } from "../../../redux/actions/localidadActions";

import { 
    startLoadSucursales
  } from "../../../redux/actions/sucursalActions";

//Valores default objeto Usuario
const initUsuario = {
    nombres: null,
    apellidos: null,
    tipoDocumento: 0,
    numeroDocumento: null,
    localidad: -1,
    sucursal: -1,
    username: null,
    email: null,
    logEstatusRegistro: 1,
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}

Modal.setAppElement('#root');

export const UsuarioModal = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { activeUsuario, modalUsuarioOpen } = useSelector(state => state.usuario);
    const { localidades, activeLocalidad } = useSelector(state => state.localidad);
    const { sucursales, activeSucursal } = useSelector(state => state.sucursal);

    const [formValues, setFormValues] = useState( initUsuario );

    const { 
        nombres, 
        apellidos, 
        tipoDocumento, 
        numeroDocumento, 
        localidad, 
        sucursal, 
        email, 
        username 
    } = formValues;

    useEffect(() => {

        dispatch( startLoadLocalidades() );
        dispatch( startLoadSucursales () );

        if ( activeUsuario ) {
            setFormValues( activeUsuario );
        } else {
            setFormValues( initUsuario );
        }
    }, [activeUsuario, dispatch, setFormValues])

    //Gestiona estado del Form
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    //Cierra el modal
    const closeModal = () => {

        dispatch(usuarioCloseModal());
        dispatch(usuarioClearActive());
        setFormValues(initUsuario);
    }

    //Envía datos del form
    const handleSubmit = (e) => {
        e.preventDefault();

        //Validaciones
        // if (nombres.trim().length < 2) {
        //     return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        // } else {
        //     if (activeUsuario) {
        //         dispatch(startUpdateUsuario(formValues));
        //     } else {
        //         dispatch(startSaveUsuario({
        //             ...formValues
        //         }));
        //     }
        // }

        console.log(formValues)

        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',

        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={modalUsuarioOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            { (activeUsuario) ? <h3>{ t('seguridad.usuario.modal.edit.title') }</h3> : <h3>{ t('seguridad.usuario.modal.new.title') }</h3> } 
            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >
                <div className={styles.modal}>
                    <div className="form-group row">
                    <div className="col-sm-12 col-md-6">
                    <label htmlFor="tipoDoc" className="form-label mb-0">Tipo Doc.</label>
                    <select className="form-select form-select-sm" aria-label=".form-select-sm example"
                        label="tipoDoc"
                        id="tipoDoc"
                        value={ tipoDocumento }
                        onChange={ handleInputChange }
                        name="tipoDocumento"
                        required={true}
                    >
                        <option defaultValue={ (activeLocalidad)? activeLocalidad.tipoDocumento : "Seleccione..." }>{ (activeLocalidad)? activeLocalidad.tipoDocumento : "Seleccione..." }</option>
                        <option key="0" value="V">V</option>
                        <option key="1" value="E">E</option>
                        <option key="2" value="J">J</option>
                        <option key="3" value="G">G</option>
                        <option key="4" value="P">P</option>
                        <option key="5" value="C">C</option>
                    </select>
                    </div>
                    <div className="col-sm-12 col-md-6">
                    <label htmlFor="numeroDocumento" className="form-label mb-0">N° Documento</label>
                    <input
                        id='numeroDocumento'
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="N° Doc."
                        name="numeroDocumento"
                        autoComplete="off"
                        value={ numeroDocumento }
                        onChange={handleInputChange}
                        required
                    />
                    </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="nombres" className="form-label mb-0">{ t('seguridad.usuario.modal.field.nombres.label') }</label>
                            <input
                                type="text"
                                id='nombres'
                                className="form-control form-control-sm"
                                placeholder= { t('seguridad.usuario.modal.field.nombres.placeholder') }
                                name="nombres"
                                autoComplete="off"
                                value={nombres}
                                onChange={handleInputChange}
                                required={true}
                                title= { t('seguridad.usuario.modal.field.nombres.title') }
                            />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="apellidos" className="form-label mb-0">{ t('seguridad.usuario.modal.field.apellidos.label') }</label>
                            <input
                                type="text"
                                id='apellidos'
                                className="form-control form-control-sm"
                                placeholder= { t('seguridad.usuario.modal.field.apellidos.placeholder') }
                                name="apellidos"
                                autoComplete="off"
                                value={apellidos}
                                onChange={handleInputChange}
                                required={true}
                                title= { t('seguridad.usuario.modal.field.apellidos.title') }
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="localidad" className="form-label mb-0">
                            Razón Social
                            </label>
                            <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm"
                            label="localidad"
                            id="localidad"
                            value={ localidad }
                            onChange={handleInputChange}
                            name="localidad"
                            required={ true }
                            >
                            <option defaultValue="Seleccione...">Seleccione...</option>
                            {localidades.map((loc) => (
                                <option key={loc.id} value={loc.id}>
                                {loc.nombre}
                                </option>
                            ))}
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="sucursal" className="form-label mb-0">
                             Sucursal
                            </label>
                            <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm"
                            label="sucursal"
                            id="sucursal"
                            value={ sucursal }
                            onChange={handleInputChange}
                            name="sucursal"
                            required={ true }
                            >
                            <option defaultValue="Seleccione...">Seleccione...</option>
                            {sucursales.map((suc) => (
                                <option key={suc.id} value={suc.id}>
                                {suc.nombre}
                                </option>
                            ))}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="username" className="form-label mb-0">{ t('seguridad.usuario.modal.field.username.label') }</label>
                            <input
                                type="text"
                                id='username'
                                className="form-control form-control-sm"
                                placeholder= { t('seguridad.usuario.modal.field.username.placeholder') }
                                name="username"
                                autoComplete="off"
                                value={username}
                                onChange={handleInputChange}
                                required={true}
                                title= { t('seguridad.usuario.modal.field.username.title') }
                            />
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor="email" className="form-label mb-0">{ t('seguridad.usuario.modal.field.email.label') }</label>
                            <input
                                type="text"
                                id='email'
                                className="form-control form-control-sm"
                                placeholder= { t('seguridad.usuario.modal.field.email.placeholder') }
                                name="email"
                                autoComplete="off"
                                value={email}
                                onChange={handleInputChange}
                                required={true}
                                title= { t('seguridad.usuario.modal.field.email.title') }
                            />
                        </div>
                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block btn-lg"
                    >
                        <i className="far fa-save"></i>
                        <span> { t('general.button.guardar') } </span>
                    </button>
                </div>
            </form>
        </Modal>
    )
}


