import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { reporteClear } from '../../../../redux/actions/reporteActions'

import { Container, Grid } from '@material-ui/core'
import { useToast } from '../../../../hooks/useToast'
import VerLogsEmail from './VerLogsEmail'
import BuscarLogsEmail from './BuscarLogsEmail'

const LogsEmail = () => {
  // const history = useHistory()
  // const classes = useStyles()
  const { notifyError, notifysucess } = useToast()
  const { isError, error, tipoMsg } = useSelector((state) => state.error)
  const dispatch = useDispatch()
  // const [LogsPdf, setLogsPdf] = useState(null)

  useEffect(() => {
    dispatch(reporteClear())
  }, [dispatch])

  return (
    <Container style={{ marginBottom: '12px', marginTop: '12px' }}>
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <BuscarLogsEmail />
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <VerLogsEmail />
        </Grid>
      </Grid>

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' &&
          error !== 'no hay Sucursal para esta localidad' &&
          notifyError()}
    </Container>
  )
}

export default LogsEmail
