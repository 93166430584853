import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { NotaModal } from './NotaModal'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import SearchRounded from '@material-ui/icons/SearchRounded'
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined'
import SearchSharp from '@material-ui/icons/SearchSharp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'
import PictureSupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle'

import { useToast } from '../../../hooks/useToast'

import {
  notaClearActive,
  notaOpenModal,
  notaOpenModalDelete,
  notaSetActive,
  startGenerarNota,
  startLoadNotas,
  startReactivedNota,
} from '../../../redux/actions/notaActions'
// import { NotaModalDelete } from './NotaModalDelete'
// import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import ConsultaNota from './ConsultaNota.js'
import MostrarPdf from './MostrarPdf'
import AnularNota from './AnularNota'
import EliminarNota from './EliminarNota'
import AddNota from './AddNota'
import { hasPermission } from '../../../Helpers/AuthPermisos'
import FiltrarNotas from './FiltrarNotas'
import ConsultaSeniatNotas from './ConsultaSeniatNotas'

//Testing GitLab
const Nota = () => {
  const dispatch = useDispatch()
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { notas } = useSelector((state) => state.nota)
  const { roles } = useSelector((state) => state.role)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const [openView, setOpenView] = useState(false)
  const [openViewPdf, setOpenViewPdf] = useState(false)
  const [openAnularNota, setAnularNota] = useState(false)
  const [openEliminarNota, setEliminarNota] = useState(false)
  const [openConsultaSeniatNota, setConsultaSeniatNota] = useState(false)

  const [openAddNota, setAddNota] = useState(false)
  const [openConsultaNota, setOpenConsultaNota] = useState(false)

  const { notifyError, notifysucess } = useToast()

  const columnas = [
    {
      title: 'Nro. Nota',
      field: 'nroNota',
      render: (rowData) => {
        return (
          <div
            style={{
              fontSize: '14px',
              backgroundColor: '#054477',
              color: 'whitesmoke',
              textAlign: 'right',
            }}
          >
            {rowData?.nroNota}
          </div>
        )
      },
    },
    {
      title: 'Nro. Control',
      field: 'nrocontrol',
      numeric: false,
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: '80px',
              fontSize: '12px',
              backgroundColor: '#054477',
              color: 'whitesmoke',
              textAlign: 'right',
            }}
          >
            {rowData?.nroControl
              ? rowData?.serie + rowData?.nroControl
              : 'No generada'}
          </div>
        )
      },
    },
    {
      title: 'Nro. Factura Afectada',
      field: 'nroNota',
      render: (rowData) => {
        return (
          <div
            style={{
              fontSize: '14px',
              backgroundColor: '#054477',
              color: 'whitesmoke',
              textAlign: 'right',
            }}
          >
            {rowData?.id_factura?.nroFactura}
          </div>
        )
      },
    },
    {
      title: 'Tipo',
      field: 'tipo',
    },
    {
      title: 'Fecha',
      field: 'logFechaRegistro',
      // defaultSort: 'desc',
      render: (rowData) => {
        return (
          <div style={{ fontSize: '12px' }}>
            {rowData?.fechaEmision === undefined ||
            rowData?.fechaEmision === null
              ? console.log('quer paso nota')
              : format(
                new Date(
                  `${String(rowData?.fechaEmision).substring(0, 10)}T00:00:00`
                ),
                'dd/MM/yyyy',
                {
                  locale: es,
                  timeZone: 'America/Caracas',
                }
              )}
          </div>
        )
        // return <div>{format(new Date(rowData.fechaEmision), 'dd/MM/yyyy')}</div>
      },
    },
    // {
    //   title: 'Fecha',
    //   field: 'logFechaRegistro',
    //   numeric: false,
    //   // defaultSort: 'desc',
    //   render: (rowData) =>
    //     format(new Date(rowData?.logFechaRegistro), 'dd/MM/yyyy'),
    // },
    {
      title: 'Razón Social',
      field: 'localidad',
      render: (rowData) => (
        <div style={{ fontSize: '12px' }}>
          {rowData?.id_sucursal === null
            ? rowData?.id_localidad?.nombre
            : rowData?.id_sucursal?.id_localidad?.nombre}
        </div>
      ),
    },
    {
      title: 'Sucursal',
      field: 'sucursal',
      render: (rowData) => (
        <div style={{ fontSize: '12px' }}>
          {rowData?.id_sucursal?.nombre || (
            <div style={{ color: 'tomato' }}>No Aplica</div>
          )}
        </div>
      ),
    },
    {
      title: 'Cliente',
      field: 'cliente',
      render: (rowData) => (
        <div style={{ fontSize: '12px' }}>
          {rowData?.id_factura?.cliente?.identificacion}
        </div>
      ),
    },
    // {
    //   title: 'Factura Origen',
    //   field: 'nroFactura',
    //   render: (rowData) => (
    //     <div style={{ fontSize: '12px' }}>{rowData?.nroFactura}</div>
    //   ),
    // },
    {
      id: 'notaCodificada',
      title: 'Gen. Nota',
      field: 'notaCodificada',
      numeric: false,
      render: (rowData) => (
        <div style={{ fontSize: '14px', textAlign: 'center' }}>
          {rowData?.notaCodificada ? (
            <div style={{ color: 'blue', fontWeight: 'bold' }}>Si</div>
          ) : (
            <div style={{ color: 'tomato', fontWeight: 'bold' }}>No</div>
          )}
        </div>
      ),
    },
    {
      id: 'logEstatusRegistro',
      title: 'Estatus',
      field: 'logEstatusRegistro',
      // numeric: false,
      render: (rowData) => (
        <div style={{ fontSize: '12px' }}>
          {rowData?.logEstatusRegistro === 'INACTIVO' ? (
            <div style={{ color: 'tomato' }}>Anulada</div>
          ) : (
            <div style={{ color: 'blue' }}>Activa</div>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    dispatch(startLoadNotas())
  }, [dispatch])

  // const onUpdateNota = (e) => {
  //   dispatch(notaSetActive(e))
  //   dispatch(notaOpenModal())
  // }

  const handleAnularNota = (e) => {
    dispatch(notaSetActive(e))
    setAnularNota(true)
  }

  const handleCloseAnularNota = (e) => {
    // dispatch(notaClearActive())
    setAnularNota(false)
  }

  // const handleReactive = (e) => {
  //   dispatch(startReactivedNota(e))
  // }

  const openModal = () => {
    dispatch(notaOpenModal())
  }

  const handleConsultaNota = (e, detalleNota) => {
    dispatch(notaSetActive(detalleNota))
    setOpenView(true)
  }
  const handleCloseConsultaNota = () => {
    setOpenView(false)
  }
  // generar Nota pdefe Firmada
  const onGenerarNotaGeneral = (detalleNota) => {
    dispatch(notaSetActive(detalleNota))
    dispatch(startGenerarNota(detalleNota))
  }
  const ViewPdf = (idFactura) => {
    dispatch(notaSetActive(idFactura))
    setOpenViewPdf(true)
  }

  const handleCerrarViewPdf = () => {
    setOpenViewPdf(false)
  }

  const handleDeletedNota = (e) => {
    dispatch(notaSetActive(e))
    setEliminarNota(true)
    // dispatch(notaOpenModalDelete())
  }
  const handleConsultaSeniatNotas = (e) => {
    dispatch(notaSetActive(e))
    setConsultaSeniatNota(true)
  }

  const handleCerrarConsultaSeniatNotas = () => {
    setConsultaSeniatNota(false)
  }

  const handleCloseDeletedNota = () => {
    setEliminarNota(false)
  }

  const handleAddNota = () => {
    // dispatch(notaClearActive())
    setAddNota(true)
  }
  const handleCloseNota = () => {
    setAddNota(false)
  }
  const handleOpenBuscarNotas = () => {
    setOpenConsultaNota(true)
  }
  const handleCloseBuscarNotas = () => {
    setOpenConsultaNota(false)
  }


  return (
    <div>
      <br />
      {/* <NotaModal /> */}
      {/* {modalNotaDelete && <NotaModalDelete />} */}
      <MaterialTable
        title='Notas'
        columns={columnas}
        data={notas}
        // detailPanel={(rowData) => {
        //   return <DatosSeguimiento datos={rowData} />
        // }}
        // onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: () => <SearchSharp fontSize='small' htmlColor='#39f' />,
            tooltip: 'Busqueda de Notas',
            isFreeAction: true,
            onClick: (event) => handleOpenBuscarNotas(event),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'search',
              user.user
            ),
          },
          {
            icon: 'add',
            tooltip: 'Nueva Nota',
            isFreeAction: true,
            onClick: (event) => {
              handleAddNota()
            },
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'add',
              user.user
            ),
          },
          // consulta seniat
          (rowData) => ({
            icon: () => (
              <PictureSupervisedUserCircle
                fontSize='small'
                htmlColor={
                  rowData?.notaCodificada !== null ||
                  rowData?.logEstatusRegistro === 'INACTIVO'
                    ? 'tomato'
                    : 'grey'
                }
              />
            ),
            tooltip: 'Consulta Seniat Notas',
            onClick: (_event, rowData) => handleConsultaSeniatNotas(rowData),
            disabled:
              rowData?.notaCodificada === null ||
              rowData?.logEstatusRegistro === 'INACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'consultaseniatnotas',
              user.user
            ),
          }),
          // fin consulta seniat

          (rowData) => ({
            icon: () => (
              <DeleteForeverOutlined
                fontSize='small'
                // htmlColor='#822784'
                htmlColor={
                  rowData?.notaCodificada !== null ||
                  rowData?.logEstatusRegistro === 'INACTIVO'
                    ? 'grey' //'#822784'
                    : 'tomato'
                }
              />
            ),
            tooltip: 'Eliminar Nota',
            onClick: (_event, rowData) => handleDeletedNota(rowData),
            disabled:
              rowData?.notaCodificada !== null ||
              rowData?.logEstatusRegistro === 'INACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'delete',
              user.user
            ),
          }),
          {
            icon: () => <SearchRounded fontSize='small' htmlColor='#2eb85c' />,
            tooltip: 'Consultar',
            onClick: (e, rowData) => handleConsultaNota(e, rowData),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'view',
              user.user
            ),
          },
          (rowData) => ({
            icon: () => (
              <PowerSettingsNewIcon
                fontSize='small'
                htmlColor={
                  rowData?.notaCodificada !== null ||
                  rowData?.logEstatusRegistro === 'INACTIVO'
                    ? 'tomato'
                    : 'grey'
                }
              />
            ),
            tooltip: 'Anular Nota',
            onClick: (event, rowData) => handleAnularNota(rowData),
            hidden:
              rowData?.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/facturacion/generales/notas',
                'cancel',
                user.user
              ),
            disabled: rowData?.notaCodificada === null,
          }),
          // (rowData) => ({
          //   icon: () => <SettingsPowerIcon />,
          //   tooltip: 'Activar',
          //   onClick: (event, rowData) => handleReactive(rowData),
          //   hidden: rowData.logEstatusRegistro === 'ACTIVO',
          // }),
          (rowData) => ({
            icon: () => <PlaylistAddCheckOutlinedIcon fontSize='small' />,
            tooltip: 'Generar y firmar PDF',
            onClick: (event, rowData) => onGenerarNotaGeneral(rowData),
            disabled:
              rowData?.notaCodificada ||
              rowData?.logEstatusRegistro !== 'ACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'generar',
              user.user
            ),
          }),
          (rowData) => ({
            icon: () => (
              <PictureAsPdfIcon
                fontSize='small'
                htmlColor={
                  rowData?.notaCodificada !== null ? '#F68519' : 'grey'
                }
              />
            ),
            tooltip: 'Ver PDF de Nota',
            onClick: (_event, rowData) => ViewPdf(rowData),
            disabled: rowData?.notaCodificada === null,
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales/notas',
              'print',
              user.user
            ),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData?.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {openView && (
        <ConsultaNota
          open={openView}
          handleClose={handleCloseConsultaNota}
          title='Consulta Nota'
        />
      )}
      {openViewPdf && (
        <MostrarPdf
          open={openViewPdf}
          handleClose={handleCerrarViewPdf}
          title='Vista de Factura en pdf'
        />
      )}
      {openAnularNota && (
        <AnularNota
          open={openAnularNota}
          handleClose={handleCloseAnularNota}
          title='Anular Nota'
        />
      )}
      {openEliminarNota && (
        <EliminarNota
          open={openEliminarNota}
          handleClose={handleCloseDeletedNota}
          title='Eliminar Nota'
        />
      )}

      {openAddNota && (
        <AddNota
          open={openAddNota}
          handleClose={handleCloseNota}
          title='Agregar Nota'
        />
      )}
      {openConsultaNota && (
        <FiltrarNotas
          open={openConsultaNota}
          handleClose={handleCloseBuscarNotas}
          title='Filtrar Notas'
        />
      )}
      {openConsultaSeniatNota && (
        <ConsultaSeniatNotas
          open={openConsultaSeniatNota}
          handleClose={handleCerrarConsultaSeniatNotas}
          title='Consulta Factura-Json Notas'
        />
      )}
    </div>
  )
}

export default Nota
