import {
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import Localidades from './Localidades'
// import Sucursales from './Sucursales'
import { MESES } from './constants'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { startReporteMensualparams } from '../../../../redux/actions/reporteActions'
import SelecUnMes from './SelecUnMes'

export default function ReporteProcertSemanal({ tipoReport, title }) {
  //  console.log("🚀 ~ ReporteSeniatParams ~ tipoReport:", tipoReport)
  const dispatch = useDispatch()
  const { localidades } = useRazonSocial()
  const [selectedMeses, setSelectedMeses] = useState([])
  const [selectedLocalidades, setSelectedLocalidades] = useState(true)
  const [anio, setAnio] = useState()
  const [errorAnio, seterrorAnio] = useState(false)
  const [email, setEmail] = useState('')
  const [errorMail, seterrorMail] = useState(false)
  const [localidadesSelect, setLocalidadesSelect] = useState([])
  const [value, setValue] = useState('XLSX')
  const [error, setError] = useState(false)
  const [helperText, setHelperText] = useState('Choose wisely')

  const handleRadioChange = (event) => {
    setValue(event.target.value)
    setHelperText(' ')
    setError(false)
  }
  //  const [tipoReporte, setTipoReporte] = useState(2)

  const handleChangeMeses = (event) => {
    setSelectedMeses(event.target.value)
  }

  const handleChangeAnio = (event) => {
    const inputValue = event.target.value
    // Validar que solo sean números
    if (/^\d*$/.test(inputValue)) {
      setAnio(inputValue)
      seterrorAnio(!inputValue)
    }
  }

  const handleChangeEmail = (event) => {
    const inputValue = event.target.value
    // Validar si es un correo electrónico válido
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)

    setEmail(inputValue)
    seterrorMail(!isValidEmail)
  }

  const handleChangeLocalidades = (event) => {
    setSelectedLocalidades(event.target.checked)
    if (event.target.checked) {
      setLocalidadesSelect([])
    }
  }

  const handleSubmit = () => {
    // setTipoReport()
    dispatch(
      startReporteMensualparams({
        anio,
        email,
        selectedMeses,
        localidadesSelect,
        tipoReport,
        valueFormato: value,
      })
    )
  }

  return (
    <Container
      style={{
        backgroundColor: '#ebedef',
        marginTop: '14px',
        marginLeft: '18px',
        marginRight: '18px',
        marginBottom: '12px',
        borderRadius: '8px',
        paddingBottom: '30px',
      }}
    >
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Typography variant='h6' align='center'>
          {title}
        </Typography>
        <Divider component={'div'} />
      </Grid>

      <Grid container spacing={1} alignItems='center'>
        <Grid
          container
          spacing={2}
          alignItems='baseline'
          style={{ marginTop: '10px' }}
          justifyContent='center'
        >
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              label='Año'
              size='small'
              inputProps={{ maxLength: 4 }}
              value={anio}
              onChange={handleChangeAnio}
              variant='standard'
              placeholder='Ingresa los cuatro digitos del año'
              fullWidth
              error={errorAnio}
              helperText={errorAnio ? 'Ingresa un anio valido' : ''}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              label='Correo Electronico'
              variant='standard'
              value={email}
              onChange={handleChangeEmail}
              error={errorMail}
              helperText={
                errorMail ? 'Ingresa un correo electronico valido' : ''
              }
              placeholder='Ingresa un correo electronico'
              fullWidth
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <FormControl
              component='fieldset'
              error={error}
              // className={classes.formControl}
            >
              <FormLabel component='label' style={{ fontSize: '12px' }}>
                Formato Reporte
              </FormLabel>
              <RadioGroup
                aria-label='formato'
                name='formato'
                value={value}
                onChange={handleRadioChange}
                row
                style={{ marginTop: '2px' }}
              >
                <FormControlLabel
                  value='XLSX'
                  control={<Radio size='small' />}
                  label='XLSX'
                />
                <FormControlLabel
                  value='PDF'
                  control={<Radio size='small' />}
                  label='PDF'
                />
              </RadioGroup>
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {tipoReport === 7 && (
          <Grid item lg={6} sm={6} xl={12} xs={12} style={{ marginTop: '6px' }}>
            <SelecUnMes
              meses={MESES}
              handleChangeMeses={handleChangeMeses}
              selectedMeses={selectedMeses}
            />
          </Grid>
        )}

        <Grid item lg={6} sm={6} xl={12} xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name='localidades'
                  onChange={handleChangeLocalidades}
                  checked={selectedLocalidades}
                />
              }
              label='Todas las Localidades'
            />
            {/* <FormControlLabel required control={<Switch />} label='Required' />
     <FormControlLabel disabled control={<Switch />} label='Disabled' /> */}
          </FormGroup>
        </Grid>

        {!selectedLocalidades && (
          <Grid container style={{ padding: '12px' }}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Localidades
                localidades={localidades}
                setLocalidadesSelect={setLocalidadesSelect}
                localidadesSelect={localidadesSelect}
              />
            </Grid>
          </Grid>
        )}
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Button
            disabled={!email || !anio || errorMail || errorAnio}
            style={{
              width: '100%',
              backgroundColor: '#054477',
              color: 'white',
            }}
            variant='contained'
            onClick={handleSubmit}
          >
            Generar y enviar
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
