import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import {
 Grid,
 TextField,
 Button,
 Typography,
 Divider,
 Select,
 MenuItem,
 FormControl,
 InputLabel,
} from '@material-ui/core'
import useStyles from './Styles'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import { useControl } from '../../../hooks/useControl'
import {
 startLoadControlFacturasSucursal,
 startSaveControlfacturalSucursal,
 startSaveControlOrdenesGuiasSucursal,
} from '../../../redux/actions/controlFacturaActions'
// import {
//  filtroLetrasMayusculas,
//  soloTextyNumeros,
// } from '../../../Helpers/formater'

const schema = yup.object().shape({
 tipoFacturaOrden: yup.string().required('Es Requerido'),
 cantidadFacturas: yup
  .number()
  .typeError('debe ser un numero')
  .required('Es requerido'),
 nroControl: yup
  .number()
  .typeError('debe ser un numero')
  .required('Es requerido'),
 nroControlDesde: yup
  .number()
  .typeError('debe ser un numero')
  .required('Es requerido'),
 // nroControlHasta: yup.number().required('Es requerido'),
 // localidadGeneraFac: yup.boolean().required('Es requerido'),
 serie: yup
  .string()
  // .matches(/^[A-Z]$/, 'Solo se permiten letras mayusculas')
  .max(2, 'maximo un caracter')
  .required('Es requerido'),
 logEstatusRegistro: yup.string().required('Es Requerido'),
})

export default function AgregarControlfacturasucursal({
 open,
 handleClose,
 title,
}) {
 const TIPOCONTROL = ['FACTURACION', 'ORDENES/GUIA']
 const tipodoc = ['ACTIVO', 'ENCOLA']
 const classes = useStyles()
 const dispatch = useDispatch()
 const { activeSucursal } = useSelector((state) => state.sucursal)
 const { controlesByLcalidad } = useControl(activeSucursal?.id_localidad?.id)
 const CotrolLocalidadActivo = controlesByLcalidad?.find(
  (b) => b.logEstatusRegistro === 'ACTIVO'
 )

 const [ValoresControlFactura, setValoresControlFactura] = useState({
  id_sucursal: activeSucursal?.id,
  cantidadFacturas: 0,
  nroControl: 0,
  nroControlDesde: 0,
  nroControlHasta: 0,
  nroSubControl: 0,
  IDControlFacturalocalidad: '',
  serie: '',
  logEstatusRegistro: 'ACTIVO',
 })

 useEffect(() => {
  if (CotrolLocalidadActivo?.id !== undefined) {
   dispatch(startLoadControlFacturasSucursal(CotrolLocalidadActivo?.id))
   setValoresControlFactura((prevState) => ({
    ...prevState,
    IDControlFacturalocalidad: CotrolLocalidadActivo?.id,
   }))
  }
 }, [dispatch, CotrolLocalidadActivo])

 const { handleSubmit, control } = useForm({
  resolver: yupResolver(schema),
 })

 const handleChangeNroControl = (e) => {
  const { value, name, checked } = e.target

  switch (name) {
   case 'nroControl':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHasta:
      parseInt(value) + parseInt(ValoresControlFactura.cantidadFacturas),
     nroControlDesde: parseInt(value),
    }))
    break
   case 'cantidadFacturas':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: parseInt(value),
     nroControlHasta:
      parseInt(ValoresControlFactura.nroControl) + parseInt(value),
    }))
    break
   case 'localidadGeneraFac':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: checked,
    }))
    break
   case 'serie':
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: value,
     // [name]: soloTextyNumeros(value, filtroLetrasMayusculas),
    }))
    break
   case 'tipoFacturaOrden':
    setValoresControlFactura((prevState) => ({
     ...prevState,
    }))
    break
   default:
    setValoresControlFactura((prevState) => ({
     ...prevState,
     [name]: value,
    }))
    break
  }
 }

 const onSubmitSucursal = async (data) => {
  console.log('🚀 ~ onSubmitSucursal ~ data:', data)
  if (data.tipoFacturaOrden === 'FACTURACION') {
   dispatch(startSaveControlfacturalSucursal(ValoresControlFactura)).then(
    (resul) => {
     if (resul.payload.tipoMsg === 'sucess') {
      handleClose()
     }
    }
   )
  } else if (data.tipoFacturaOrden === 'ORDENES/GUIA') {
   dispatch(startSaveControlOrdenesGuiasSucursal(ValoresControlFactura)).then(
    (resul) => {
     if (resul.payload.tipoMsg === 'sucess') {
      handleClose()
     }
    }
   )
  }
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   {CotrolLocalidadActivo === undefined && (
    <Typography variant='button'>
     No hay Razon social con Numero de control asiganda o no tiene ACTIVA
    </Typography>
   )}
   {CotrolLocalidadActivo !== undefined && (
    <form onSubmit={handleSubmit(onSubmitSucursal)} autoComplete='off'>
     <Grid container spacing={2} className={classes.root}>
      <Grid item lg={3} sm={3} xl={12} xs={12}>
       {activeSucursal?.id_localidad?.rutaLogo && (
        <div>
         <img
          className={classes.logo}
          src={
           activeSucursal?.id_localidad?.rutaLogo ? (
            activeSucursal?.id_localidad?.rutaLogo
           ) : (
            <AddPhotoAlternateIcon />
           )
          }
          alt='Logo'
         />
        </div>
       )}
      </Grid>
      <Grid item lg={9} sm={9} xl={12} xs={12}>
       <Typography align='jusify' variant='h5'>
        {activeSucursal.nombre}
       </Typography>
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Divider className={classes.linea} />
      </Grid>

      <Grid container spacing={0} className={classes.marco}>
       {CotrolLocalidadActivo !== undefined && (
        <Grid container spacing={0}>
         <Grid item lg={6} sm={6} xl={12} xs={12}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
           <Typography align='jusify' variant='subtitle1'>
            <b>Razon Social Control</b>
           </Typography>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
           <Typography align='jusify' variant='caption'>
            <b>
             {`Total Facturas: ${CotrolLocalidadActivo?.cantidadFacturas}`}
            </b>
           </Typography>
          </Grid>
         </Grid>
         <Grid item lg={6} sm={6} xl={12} xs={12}>
          {!CotrolLocalidadActivo?.localidadGeneraFac && (
           <Typography
            variant='subtitle2'
            style={{ color: 'tomato', fontWeight: 'bold' }}
           >
            No puede asignar Numero De control a una Razón Social con Nro. de
            control Global
           </Typography>
          )}
          {CotrolLocalidadActivo?.cantidadFacturas -
           CotrolLocalidadActivo?.nroControlActual ===
           0 && (
           <Typography
            variant='subtitle2'
            style={{
             color: 'tomato',
             fontWeight: 'bold',
             textAlign: 'justify',
             padding: '0px 10px',
            }}
           >
            Ha ocupado la totalidad de control de facturas, debe ingresar un
            nuevo lote Global.
           </Typography>
          )}
         </Grid>
        </Grid>
       )}
      </Grid>

      {/* tipo de control de facturas o  ordenes/guias */}
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Controller
        name='tipoFacturaOrden'
        control={control}
        // defaultValue={ValoresControlFactura.cantidadFacturas}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <FormControl fullWidth>
          <InputLabel variant='standard' htmlFor='uncontrolled-native'>
           Tipo de Control
          </InputLabel>
          <Select
           name='tipoFacturaOrden'
           fullWidth
           // disabled={
           //   !CotrolLocalidadActivo?.localidadGeneraFac ? true : false
           // }
           // className={classes.select}
           // onChange={onChange}
           onChange={(e) => {
            handleChangeNroControl(e)
            onChange(e.target.value)
           }}
           value={value}
           error={!!error}
           helperText={error ? error.message : null}
          >
           {TIPOCONTROL.map((option) => {
            return (
             <MenuItem
              // className={classes.sublabel}
              key={option}
              value={option}
             >
              {option}
             </MenuItem>
            )
           })}
          </Select>
         </FormControl>
        )}
       />
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '1rem' }}>
       <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Controller
         name='logEstatusRegistro'
         control={control}
         // defaultValue={ValoresControlFactura.cantidadFacturas}
         render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth>
           <InputLabel variant='standard' htmlFor='uncontrolled-native'>
            Status
           </InputLabel>
           <Select
            name='logEstatusRegistro'
            fullWidth
            disabled={!CotrolLocalidadActivo?.localidadGeneraFac ? true : false}
            // className={classes.select}
            // onChange={onChange}
            onChange={(e) => {
             handleChangeNroControl(e)
             onChange(e.target.value)
            }}
            value={value}
            error={!!error}
            helperText={error ? error.message : null}
           >
            {tipodoc.map((option) => {
             return (
              <MenuItem
               // className={classes.sublabel}
               key={option.key}
               value={option}
              >
               {option}
              </MenuItem>
             )
            })}
           </Select>
          </FormControl>
         )}
        />
       </Grid>
       <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Controller
         name='serie'
         control={control}
         // defaultValue={ValoresControlFactura.cantidadFacturas}
         render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
           disabled={!CotrolLocalidadActivo?.localidadGeneraFac ? true : false}
           name='serie'
           className={classes.text}
           label='Serie'
           // variant='outlined'
           defaultValue=''
           size='small'
           fullWidth
           value={ValoresControlFactura.serie}
           onChange={(e) => {
            handleChangeNroControl(e)
            onChange(e.target.value)
           }}
           error={!!error}
           helperText={error ? error.message : null}
          />
         )}
        />
       </Grid>
      </Grid>

      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='cantidadFacturas'
        control={control}
        // defaultValue={ValoresControlFactura.cantidadFacturas}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <NumberFormat
          disabled={!CotrolLocalidadActivo?.localidadGeneraFac ? true : false}
          format='#########'
          thousandSeparator={true}
          allowNegative={true}
          allowLeadingZeros={false}
          customInput={TextField}
          name='cantidadFacturas'
          className={classes.text}
          label='Cantidad de Facturas'
          // variant='outlined'
          defaultValue={0}
          size='small'
          fullWidth
          value={value}
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControl'
        control={control}
        // defaultValue=''
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <NumberFormat
          format='#########'
          disabled={!CotrolLocalidadActivo?.localidadGeneraFac ? true : false}
          thousandSeparator={true}
          allowNegative={true}
          allowLeadingZeros={false}
          customInput={TextField}
          name='nroControl'
          className={classes.text}
          label='Nro. de Control Inicial'
          // variant='outlined'
          defaultValue={0}
          size='small'
          s
          fullWidth
          value={value}
          onChange={(e) => {
           handleChangeNroControl(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>

      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControlDesde'
        control={control}
        defaultValue={ValoresControlFactura.nroControlDesde}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <TextField
          disabled
          className={classes.text}
          name='nroControlDesde'
          label='Desde'
          size='small'
          fullWidth
          value={ValoresControlFactura.nroControlDesde}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Controller
        name='nroControlHasta'
        control={control}
        defaultValue={ValoresControlFactura.nroControlHasta}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
         <TextField
          disabled
          name='nroControlHasta'
          className={classes.text}
          label='Hasta'
          // variant='outlined'
          size='small'
          fullWidth
          value={ValoresControlFactura.nroControlHasta}
          onChange={onChange}
          error={!!error}
          helperText={error ? error.message : null}
         />
        )}
       />
      </Grid>
     </Grid>

     <Grid container spacing={2} className={classes.margen}>
      <Grid item lg={6} sm={6} xl={12} xs={12}>
       <Button
        className={classes.btn}
        size='small'
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
        disabled={
         !CotrolLocalidadActivo?.localidadGeneraFac ||
         CotrolLocalidadActivo?.cantidadFacturas -
          CotrolLocalidadActivo?.nroControlActual ===
          0
          ? true
          : false
        }
       >
        Agregar
       </Button>
      </Grid>
      <Grid item md={6} xs={6}>
       <Button
        className={classes.btn}
        size='small'
        variant='contained'
        fullWidth
        onClick={handleClose}
       >
        Cancelar
       </Button>
      </Grid>
     </Grid>
    </form>
   )}
  </ModalGenerico>
 )
}
