import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento/pais`;

export const startSavePais = (pais) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: pais
        })
            .then((response) => {
                
                Swal.fire('Guardado', pais.nombre, 'success');
                dispatch(paisAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeletePais = () => {
    
    return async (dispatch, getState) => {
       
        const { activePais } = getState().pais;
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activePais.id}`
        })
            .then((response) => {
                
                Swal.fire('Eliminado', activePais.nombre, 'success');
                dispatch(deletePais(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedPais = (pais) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${pais.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', pais.nombre, 'success');
                dispatch(reactivePais(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdatePais = (pais) => {

    return (dispatch, getState) => {

        const { activePais } = getState().pais;
        const id = activePais.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: pais
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.data.nombre, 'success');
                dispatch(paisUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}

export const startLoadPaises = () => {
    
    var config = {
        headers: { 'Access-Control-Allow-Origin': '*' }
      };
    
    return (dispatch) => {

        axios.get(`${url}/listar`, config)
        .then(res => {
            dispatch(paisLoaded(res.data));
        });
    }
}

const paisLoaded = (paises) => ({
    type: types.paisLoaded,
    payload: paises
});

export const paisOpenModal = () => ({
    type: types.paisOpenModal
});

export const paisCloseModal = () => ({
    type: types.paisCloseModal
});

export const paisOpenModalDelete = () => ({
    type: types.paisOpenModalDelete
});

export const paisCloseModalDelete = () => ({
    type: types.paisCloseModalDelete
})

export const paisAddNew = ( pais ) => ({
    type: types.paisAddNew,
    payload: pais
});

export const paisSetActive = ( pais ) => ({
    type: types.paisSetActive,
    payload: pais
});

export const paisClearActive = ( ) => ({
    type: types.paisClearActive,
});

export const paisUpdated = ( pais ) => ({
    type: types.paisUpdated,
    payload: pais
});

export const deletePais = (pais) => ({
    type: types.paisDelete,
    payload: pais
});

export const reactivePais = ( pais ) => ({
    type: types.paisReactived,
    payload: pais
});