import { types } from "../types/types";

const initialState = {
  usuarios: [],
  activeUsuario: null,
  modalUsuarioOpen: false,
  modalUsuarioDelete: false,
};

export const usuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.usuarioOpenModal:
      return {
        ...state,
        modalUsuarioOpen: true,
        modalUsuarioDelete: false,
      };

    case types.usuarioCloseModal:
      return {
        ...state,
        modalUsuarioOpen: false,
      };

    case types.usuarioOpenModalDelete:
      return {
        ...state,
        modalUsuarioOpen: false,
        modalUsuarioDelete: true,
      };

    case types.usuarioCloseModalDelete:
      return {
        ...state,
        modalUsuarioDelete: false,
      };

    case types.usuarioSetActive:
      return {
        ...state,
        activeUsuario: action.payload,
        modalUsuarioOpen: true,
      };

    case types.usuarioAddNew:
      return {
        ...state,
        usuarios: [action.payload, ...state.usuarios],
      };

    case types.usuarioClearActive:
      return {
        ...state,
        activeUsuario: null,
      };

    case types.usuarioUpdated:
      return {
        ...state,
        usuarios: state.usuarios.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.usuarioLoaded:
      return {
        ...state,
        usuarios: action.payload,
      };

    case types.usuarioDelete:
      return {
        ...state,
        usuarios: state.usuarios.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeUsuario: null,
      };

    case types.usuarioReactived:
      return {
        ...state,
        usuarios: state.usuarios.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeUsuario: null,
      };

    default:
      return state;
  }
};
