import { types } from '../types/types'

const initialState = {
  lstTasas: { USD: {}, EUR: {} },
  isLoadingTasas: true,
}

export const tasaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.tasasLoaded:
      return {
        ...state,
        lstTasas: action.payload,
        isLoadingTasas: false,
      }

    default:
      return state
  }
}
