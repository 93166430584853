import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
// import logo from '../../../assets/imagenes/flor-w.png'
import Loading from './LogoProcert'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'center',
    // height: '75vh',
    animation: 'mover 1s infinite alternate',
  },
  top: {
    color: '#1a90ff',
    fontSize: '120px',
    alignSelf: 'center',
  },
}))

export default function LoadingCirculo({ size = 24, height = '75vh' }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        <CircularProgress size={size} className={classes.top} />
      </div>
    </div>
  )
}
