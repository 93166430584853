import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  startLoadControlFacturas,
  // startLoadControlFacturasSucursal,
} from '../redux/actions/controlFacturaActions'

export const useControl = (id) => {
  const {
    controlesByLcalidad,
    controlesActiveLcalidad,
    cantidadFact,
    nroSubControl,
    nroControlDesde,
    nroControlHasta,
  } = useSelector((state) => state.controlFactura)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startLoadControlFacturas(id))
    // setloading(false)
  }, [dispatch, id])

  // useEffect(() => {
  //   dispatch(startLoadControlFacturasSucursal(controlesByLcalidad[0]?.id))
  // }, [dispatch, controlesByLcalidad])

  return {
    controlesByLcalidad,
    controlesActiveLcalidad,
    cantidadFact,
    nroSubControl,
    nroControlDesde,
    nroControlHasta,
  }
}
