import { types } from '../types/types'

const initialState = {
  roles: [],
  activeRole: null,
  modalRoleOpen: false,
  modalRoleDelete: false,
}

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.roleOpenModal:
      return {
        ...state,
        modalRoleOpen: true,
        modalRoleDelete: false,
      }

    case types.roleCloseModal:
      return {
        ...state,
        modalRoleOpen: false,
      }

    case types.roleOpenModalDelete:
      return {
        ...state,
        modalRoleOpen: false,
        modalRoleDelete: true,
      }

    case types.roleCloseModalDelete:
      return {
        ...state,
        modalRoleDelete: false,
      }

    case types.roleSetActive:
      return {
        ...state,
        activeRole: action.payload,
        modalRoleOpen: true,
      }

    case types.roleAddNew:
      return {
        ...state,
        roles: [action.payload, ...state.roles],
      }

    case types.roleClearActive:
      return {
        ...state,
        activeRole: null,
      }

    case types.roleClear:
      return {
        ...state,
        roles: [],
        activeRole: null,
        modalRoleOpen: false,
        modalRoleDelete: false,
      }

    case types.roleUpdated:
      return {
        ...state,
        roles: state.roles.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      }

    case types.roleLoaded:
      return {
        ...state,
        roles: action.payload,
      }

    case types.roleDelete:
      return {
        ...state,
        roles: state.roles.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeRole: null,
      }

    case types.roleReactived:
      return {
        ...state,
        roles: state.roles.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeRole: null,
      }

    default:
      return state
  }
}
