export const NOTA_TIPO = [
  {
    value: 'Credito',
    label: 'Crédito',
  },
  {
    value: 'Debito',
    label: 'Débito',
  },
]

export const MODELO_FACTURA = [
  {
    value: 'GENERAL',
    label: 'General',
  },
  {
    value: 'SERVICIO',
    label: 'Servicio',
  },
]

export const NOTA__CREDITO_CATYEGORIA = [
  {
    value: 0,
    label: 'Reverso de una factura por devolución parcial del producto',
  },
  {
    value: 1,
    label: 'Reverso de una factura por devolución total del producto',
  },
  {
    value: 2,
    label: 'Por error en los datos contenidos en la misma',
  },
]

export const NOTA__DEBITO_CATYEGORIA = [
  {
    value: 0,
    label: 'Por el cargo de interés moratorio en el pago de una factura',
  },
  {
    value: 1,
    label:
      'Por el aumento del precio de un producto en el procedimiento de la negociación',
  },
  {
    value: 2,
    label:
      'Por la venta de productos y servicios facturados en divisas con un equivalente en Bs.',
  },
]

export const TIPOORDEN = ['ORDENENTREGA', 'GUIADESPACHO'];

