import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// import { RoleModal } from './RoleModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import Edit from '@material-ui/icons/Edit'

import {
  roleClearActive,
  // roleOpenModal,
  roleOpenModalDelete,
  roleSetActive,
  startLoadRoles,
  startReactivedRole,
} from '../../../redux/actions/roleActions'
// import { startLoadModulos } from '../../../redux/actions/moduloActions'
import { hasPermission } from '../../../Helpers/AuthPermisos'

// import { RoleModalDelete } from './RoleModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import AgregarRol from './AgregarRol'
import UpdateRol from './UpdateRol'
import { useToast } from '../../../hooks/useToast'

const Role = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [addUsuario, setAddUsuario] = useState(false)
  const [UpdateRolState, setUpdateRolState] = useState(false)
  const { roles } = useSelector((state) => state.role)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { notifyError, notifysucess } = useToast()

  const columnas = [
    {
      title: 'Rol',
      field: 'rol',
    },
    {
      title: 'Ruta',
      field: 'ruta',
    },

    {
      title: 'Descripcion',
      field: 'descripcion',
    },
  ]

  useEffect(() => {
    //Llenar la lista de Roles
    dispatch(startLoadRoles())
  }, [dispatch])

  //Editar
  // const onUpdateRole = (e) => {
  //   dispatch(roleSetActive(e))
  //   dispatch(roleOpenModal())
  // }

  //Inactivar / Borrar
  const handleDelete = (e) => {
    dispatch(roleSetActive(e))
    dispatch(roleOpenModalDelete())
  }

  //Reactivar
  const handleReactive = (e) => {
    dispatch(startReactivedRole(e))
  }

  //Abrir el modal
  // const openModal = () => {
  //   dispatch(roleOpenModal())
  //   dispatch(startLoadModulos())
  // }

  // add role
  const handleAddRole = () => {
    setAddUsuario(true)
  }
  // close modal
  const handleCloseRole = () => {
    setAddUsuario(false)
  }
  // update role
  const handleUpdateRole = (e) => {
    dispatch(roleSetActive(e))
    setUpdateRolState(true)
  }
  // close modal update role
  const handleCloseUpdateRole = () => {
    dispatch(roleClearActive())
    setUpdateRolState(false)
  }

  return (
    <div>
      <br />
      {/* <RoleModal />
      {modalRoleDelete && <RoleModalDelete />} */}
      <MaterialTable
        title={t('seguridad.role.base.listado')}
        columns={columnas}
        data={roles}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: t('seguridad.role.base.registrar.tooltip'),
            isFreeAction: true,
            onClick: (event) => {
              handleAddRole()
            },
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/seguridad/roles',
              'add',
              user.user
            ),
          },
          (rowData) => ({
            icon: () => <Edit fontSize='small' htmlColor='#ff6f00' />,
            tooltip: t('seguridad.role.base.editar.tooltip'),
            onClick: (event, rowData) => handleUpdateRole(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/seguridad/roles',
                'update',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <PowerSettingsNewIcon />,
            tooltip: t('seguridad.role.base.inactivar.tooltip'),
            onClick: (event, rowData) => handleDelete(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/seguridad/roles',
                'cancel',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon />,
            tooltip: t('seguridad.role.base.reactivar.tooltip'),
            onClick: (event, rowData) => handleReactive(rowData),
            hidden:
              rowData.logEstatusRegistro === 'ACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/seguridad/roles',
                'cancel',
                user.user
              ),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: t('general.base.actions'),
          },
          toolbar: {
            searchPlaceholder: t('general.base.searchPlaceholder'),
            searchTooltip: t('general.base.searchTooltip'),
            exportTitle: t('general.base.exportTitle'),
            exportCSVName: t('general.base.exportCSVName'),
            exportPDFName: t('general.base.exportPDFName'),
          },
          pagination: {
            firstAriaLabel: t('general.base.firstAriaLabel'),
            firstTooltip: t('general.base.firstTooltip'),
            previousAriaLabel: t('general.base.previousAriaLabel'),
            previousTooltip: t('general.base.previousTooltip'),
            nextAriaLabel: t('general.base.nextAriaLabel'),
            nextTooltip: t('general.base.nextTooltip'),
            lastAriaLabel: t('general.base.lastAriaLabel'),
            lastTooltip: t('general.base.lastTooltip'),
            labelDisplayedRows: t('general.base.labelDisplayedRows'),
            labelRowsSelect: t('general.base.labelRowsSelect'),
          },
          body: {
            emptyDataSourceMessage: t('general.base.emptyDataSourceMessage'),
          },
        }}
      />

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {addUsuario && (
        <AgregarRol
          open={addUsuario}
          handleClose={handleCloseRole}
          title='Agregar Rol'
        />
      )}

      {UpdateRolState && (
        <UpdateRol
          open={UpdateRolState}
          handleClose={handleCloseUpdateRole}
          title='Modificar Rol'
        />
      )}
    </div>
  )
}

export default Role
