import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
 root: {
  minWidth: 400,
  alignItems: 'end',
  '& .MuiInputBase-input': {
   fontSize: '12px',
   color: '#01579b',
  },
 },
 error: {
  fontSize: 10,
  fontWeight: 500,
  color: 'red',
 },
 sublabel: {
  fontSize: '12px',
  color: '#546E7A',
  alignItems: 'center',
 },
 select: {
  fontSize: '12px',
  color: '#546E7A',
  textAlign: 'center',
  alignItems: 'center',
 },
 text: {
  fontSize: '12px',
  color: '#546E7A',
  textAlign: 'center',
  margin: 0,
  padding: 0,
 },
 margen: {
  marginTop: '24px',
 },
 textborde: {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  verticalAlign: 'top',
 },

 inputtelefono: {
  fontSize: '12px',
  width: '100%',
  border: 'none',
  borderBottom: '1px solid red',
 },

 btn: {
  width: '100%',
  fontSize: 16,
  padding: '6px 12px',
  borderRadius: 10,
 },
 subTitle: {
  marginTop: '10px',
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#546E7A',
  alignItems: 'center',
 },
}))
