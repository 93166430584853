import { types } from "../types/types";

const initialState = {
  divisas: [],
  activeDivisa: null,
  modalDivisaOpen: false,
  modalDivisaDelete: false,
};

export const divisaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.divisaOpenModal:
      return {
        ...state,
        modalDivisaOpen: true,
        modalDivisaDelete: false,
      };

    case types.divisaCloseModal:
      return {
        ...state,
        modalDivisaOpen: false,
      };

    case types.divisaOpenModalDelete:
      return {
        ...state,
        modalDivisaOpen: false,
        modalDivisaDelete: true,
      };

    case types.divisaCloseModalDelete:
      return {
        ...state,
        modalDivisaDelete: false,
      };

    case types.divisaSetActive:
      return {
        ...state,
        activeDivisa: action.payload,
        modalDivisaOpen: true,
      };

    case types.divisaAddNew:
      return {
        ...state,
        divisas: [action.payload, ...state.divisas],
      };

    case types.divisaClearActive:
      return {
        ...state,
        activeDivisa: null,
      };

    case types.divisaUpdated:
      return {
        ...state,
        divisas: state.divisas.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
      };

    case types.divisaLoaded:
      return {
        ...state,
        divisas: action.payload,
      };

    case types.divisaDelete:
      return {
        ...state,
        divisas: state.divisas.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeDivisa: null,
      };

    case types.divisaReactived:
      return {
        ...state,
        divisas: state.divisas.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeDivisa: null,
      };

    default:
      return state;
  }
};
