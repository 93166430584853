import { useDispatch, useSelector } from 'react-redux'
import {
  CardHeader,
  Card,
  CardContent,
  Grid,
  TextField,
} from '@material-ui/core'
import useStyles from './Styles'
import { format } from 'date-fns'

const Datosfactura = () => {
  const classes = useStyles()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Datos generales de la factura'
        style={{ backgroundColor: 'green' }}
        className={classes.cardheader}
      />
      <CardContent>
        <Grid container spacing={0} className={classes.root}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TextField
              name='cliente'
              label='Cliente'
              disabled
              value={
                activeFacturaGeneral?.id_localidad === undefined
                  ? activeFacturaGeneral?.id_sucursal?.nombre
                  : activeFacturaGeneral?.id_localidad?.nombre
              }
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <TextField
              name='nroFactura'
              label='Número de Factura'
              disabled
              value={activeFacturaGeneral?.nroFactura}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <TextField
              name='nroControl'
              label='Número de Control'
              disabled
              value={activeFacturaGeneral?.nroControl}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>

          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <TextField
              name='fechaVencimiento'
              label='Fecha de la Factura'
              disabled
              value={format(
                new Date(
                  activeFacturaGeneral?.fechaVencimiento
                    ? activeFacturaGeneral?.fechaVencimiento
                    : new Date()
                ),
                'dd/MM/yyyy'
              )}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <TextField
              name='rif'
              label='Doc. de identidad'
              disabled
              value={`${activeFacturaGeneral?.cliente.tipoDocumento}-${activeFacturaGeneral?.cliente.numeroDocumento}`}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <TextField
              name='cliente'
              label='Cliente'
              disabled
              value={activeFacturaGeneral?.cliente.identificacion}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='correo'
              label='Correo'
              disabled
              value={activeFacturaGeneral?.cliente.correo}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <TextField
              name='telefonoMovil'
              label='Telf. Movil'
              disabled
              value={activeFacturaGeneral?.cliente.telefonoMovil}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <TextField
              name='direccion'
              label='Dirección'
              disabled
              value={activeFacturaGeneral?.cliente.direccion}
              rows={2}
              className={classes.text}
              size='small'
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Datosfactura
