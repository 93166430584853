import { types } from "../types/types";

const initialState = {
  facturasCompras: [],
  lstDetalles: [],
  activeFacturaCompra: null,
  modalFacturaCompraOpen: false,
  modalFacturaCompraDelete: false,
  modalDetalleFacturaCompra: false,
  modalPagoFacturaCompra: false,
  proveedorSelected: false,
  proveedorActive: null,
  localidadSelected: false,
  localidadActive: null,
  sucursalSelected: false,
  sucursalActive: null,
  tipoCompraSelected: false,
  tipoCompraActive: null,
};

export const facturaCompraReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.facturaCompraOpenModal:
      return {
        ...state,
        modalFacturaCompraOpen: true,
        modalFacturaCompraDelete: false,
      };

    case types.facturaCompraCloseModal:
      return {
        ...state,
        modalFacturaCompraOpen: false,
      };

      case types.facturaCompraOpenDetalleModal:
        return {
          ...state,
          modalDetalleFacturaCompra: true,
          modalFacturaCompraDelete: false,
          modalPagoFacturaCompra: false
        };
  
      case types.facturaCompraCloseDetalleModal:
        return {
          ...state,
          modalDetalleFacturaCompra: false,
        };

      case types.facturaCompraOpenModalDelete:
      return {
        ...state,
        modalFacturaCompraOpen: false,
        modalFacturaCompraDelete: true,
      };

      case types.facturaCompraCloseModalDelete:
      return {
        ...state,
        modalFacturaCompraDelete: false,
      };

      case types.facturaCompraSetActive:
      return {
        ...state,
        activeFacturaCompra: action.payload,
        modalFacturaCompraOpen: true,
      };

      case types.facturaCompraProveedorSelected:
        return {
          ...state,
          proveedorSelected: true,
          proveedorActive: action.payload
        };

      case types.facturaCompraProveedorClearSelected:
          return {
            ...state,
            proveedorSelected: false,
            proveedorActive: null
          };

      case types.facturaCompraLocalidadSelected:
            return {
              ...state,
              localidadSelected: true,
              localidadActive: action.payload
            };
    
      case types.facturaCompraLocalidadClearSelected:
              return {
                ...state,
                localidadSelected: false,
                localidadActive: null
              };

      case types.facturaCompraSucursalSelected:
                return {
                  ...state,
                  sucursalSelected: true,
                  sucursalActive: action.payload
                };
        
      case types.facturaCompraSucursalClearSelected:
                  return {
                    ...state,
                    sucursalSelected: false,
                    sucursalActive: null
                  };

      case types.facturaCompraTipoCompraSelected:
                    return {
                      ...state,
                      tipoCompraSelected: true,
                      tipoCompraActive: action.payload
                    };
            
      case types.facturaGeneraTipoCompraClearSelected:
                      return {
                        ...state,
                        tipoCompraSelected: false,
                        tipoCompraActive: null
                      };

    case types.facturaCompraAddNew:
      return {
        ...state,
        facturasCompras: [action.payload, ...state.facturasCompras],
      };

      case types.facturaCompraAddNewDetalle:
        return {
          ...state,
          lstDetalles: [action.payload, ...state.lstDetalles],
        };

    case types.facturaCompraClearActive:
      return {
        ...state,
        activeFacturaCompra: null,
      };

    case types.facturaCompraUpdated:
      return {
        ...state,
        facturasCompras: state.facturasCompras.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.facturaCompraLoaded:
      return {
        ...state,
        facturasCompras: action.payload,
      };

    case types.facturaCompraDelete:
      return {
        ...state,
        facturasCompras: state.facturasCompras.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeFacturaCompra: null,
      };

      case types.facturaCompraDeleteDetalle:
        console.log(action.payload)
        return {
          ...state,
          lstDetalles: state.lstDetalles.filter((det, i ) => i !== action.payload),
        };

        case types.facturaCompraDeletePago:
          return {
            ...state,
            lstPagos: state.facturasCompras.map((p) =>
              p.id === action.payload.id ? action.payload : p
            ),
          };

    case types.facturaCompraReactived:
      return {
        ...state,
        facturasCompras: state.facturasCompras.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeFacturaCompra: null,
      };

    default:
      return state;
  }
};
