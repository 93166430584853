import { types } from "../types/types";

const initialState = {
  clientes: [],
  activeCliente: null,
  modalClienteOpen: false,
  modalClienteDelete: false,
};

export const clienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.clienteOpenModal:
      return {
        ...state,
        modalClienteOpen: true,
        modalClienteDelete: false,
      };

    case types.clienteCloseModal:
      return {
        ...state,
        modalClienteOpen: false,
      };

    case types.clienteOpenModalDelete:
      return {
        ...state,
        modalClienteOpen: false,
        modalClienteDelete: true,
      };

    case types.clienteCloseModalDelete:
      return {
        ...state,
        modalClienteDelete: false,
      };

    case types.clienteSetActive:
      return {
        ...state,
        activeCliente: action.payload,
        modalClienteOpen: true,
      };

    case types.clienteAddNew:
      return {
        ...state,
        clientes: [action.payload, ...state.clientes],
      };

    case types.clienteClearActive:
      return {
        ...state,
        activeCliente: null,
      };

    case types.clienteUpdated:
      return {
        ...state,
        clientes: state.clientes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.clienteLoaded:
      return {
        ...state,
        clientes: action.payload,
      };

    case types.clienteDelete:
      return {
        ...state,
        clientes: state.clientes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeCliente: null,
      };

    case types.clienteReactived:
      return {
        ...state,
        clientes: state.clientes.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeCliente: null,
      };

    default:
      return state;
  }
};
