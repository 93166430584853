import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SucursalModal } from './SucursalModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import DashboardOutlined from '@material-ui/icons/DashboardOutlined'

import {
  //   sucursalOpenModal,
  //   sucursalOpenModalDelete,
  sucursalSetActive,
  startLoadSucursales,
  sucursalClearActive,
  //   startReactivedSucursal,
  //   sucursalOpenModalShow,
} from '../../../redux/actions/sucursalActions'
// import { SucursalModalDelete } from './SucursalModalDelete'
// import { SucursalModalShow } from './SucursalModalShow'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import AgregarSucursal from './AgregarSucursal'
import { useToast } from '../../../hooks/useToast'
import AgregarControlfacturasucursal from '../Control/AgregarControlSucursal'
import {
  ConsultaNroFacturaAuto,
  ControlFacturaClearloded,
  ControlSucursalClearloded,
} from '../../../redux/actions/controlFacturaActions'
import { startLoadLocalidades } from '../../../redux/actions/localidadActions'
import ConsultaSucursal from './ConsultaSucursal'
import Edit from '@material-ui/icons/Edit'
import BuildSharp from '@material-ui/icons/BuildSharp'
import UpdateSucursal from './UpdateSucursal'
import { hasPermission } from '../../../Helpers/AuthPermisos'
import Parametros from './Parametros'

const Sucursal = () => {
  const dispatch = useDispatch()
  const { notifyError, notifysucess } = useToast()
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { roles } = useSelector((state) => state.role)
  const [ModalAgregarSucursal, setmodalAgregarSucursal] = useState(false)
  const [ModalConsultaSucursal, setmodalConsultaSucursal] = useState(false)
  const [ModalUpdateSucursal, setmodalUpdateSucursal] = useState(false)
  const [ModalControlFactura, setmodalControlFactura] = useState(false)
  const [ModalParamter, setmodalParamter] = useState(false)

  const { isError, tipoMsg } = useSelector((state) => state.error)

  const { sucursales } = useSelector((state) => state.sucursal)

  const columnas = [
    {
      title: 'Razon Social',
      field: 'id_localidad.nombre',
      // type: 'numeric',
      width: '5%',
      defaultSort: 'asc',
    },
    {
      title: 'Sucursal',
      field: 'nombre',
      width: '30%',
    },
    {
      title: 'Domicilio fiscal',
      field: 'direccion',
      width: '30%',
    },

    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
    },
  ]

  useEffect(() => {
    setTimeout(() => {
      dispatch(startLoadSucursales())
    }, 2000)
  }, [dispatch])

  const handleCloseSucursalModal = () => {
    setmodalAgregarSucursal(false)
    // dispatch(localidadClearActive())
  }

  const handleSucursalAdd = (e) => {
    setmodalAgregarSucursal(true)
    dispatch(startLoadLocalidades())
  }

  const handleControlAdd = (e) => {
    setmodalControlFactura(true)
    dispatch(sucursalSetActive(e))
  }
  const handleCloseControlModal = () => {
    setmodalControlFactura(false)
    dispatch(sucursalClearActive())
    dispatch(ControlFacturaClearloded())
    dispatch(ControlSucursalClearloded())
  }

  const handleSucursalConsulta = (sucursal) => {
    setmodalConsultaSucursal(true)
    dispatch(sucursalSetActive(sucursal))
  }
  const handleCloseConsultaModal = () => {
    setmodalConsultaSucursal(false)
    dispatch(sucursalClearActive())
    // dispatch(ControlFacturaClearloded())
    // dispatch(ControlSucursalClearloded())
  }

  const handleSucursalupdate = (sucursal) => {
    setmodalUpdateSucursal(true)
    dispatch(sucursalSetActive(sucursal))
  }

  const handleCloseUpdateModal = () => {
    setmodalUpdateSucursal(false)
    dispatch(sucursalClearActive())
    // dispatch(ControlFacturaClearloded())
    dispatch(ControlSucursalClearloded())
  }

  const handleParamsOpen = async (e) => {
    await dispatch(ConsultaNroFacturaAuto(e.id, 'id_sucursal'))
    dispatch(sucursalSetActive(e))
    setmodalParamter(true)
  }
  const handleCloseParamsModal = () => {
    dispatch(sucursalClearActive())
    setmodalParamter(false)
  }

  return (
    <div>
      <br />
      {/* <SucursalModal /> */}
      {/* {modalSucursalDelete && <SucursalModalDelete />}
      {modalSucursalShow && <SucursalModalShow />} */}
      <MaterialTable
        title='Sucursal'
        columns={columnas}
        data={sucursales}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nueva Sucursal',
            isFreeAction: true,
            onClick: (event) => {
              handleSucursalAdd()
            },
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Sucursal',
              'add',
              user.user
            ),
          },
          {
            icon: () => <BuildSharp fontSize='small' htmlColor='#0099ff' />,
            tooltip: 'Parametros',
            onClick: (event, rowData) => handleParamsOpen(rowData),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Sucursal',
              'params',
              user.user
            ),
          },
          {
            icon: 'search',
            tooltip: 'Consultar',
            onClick: (event, rowData) => handleSucursalConsulta(rowData),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Sucursal',
              'view',
              user.user
            ),
          },
          (rowData) => ({
            icon: () => <Edit fontSize='small' htmlColor='#ff6f00' />,
            tooltip: 'Modificar',
            onClick: (event, rowData) => handleSucursalupdate(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Sucursal',
                'update',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <DashboardOutlined fontSize='small' color='primary' />,
            tooltip: 'Agregar Control Factura',
            onClick: (event, rowData) => handleControlAdd(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Sucursal',
                'addControlsucusal',
                user.user
              ),
          }),

          // rowData => ({
          //     icon: () => <PowerSettingsNewIcon />,
          //     tooltip: 'Inactivar',
          //     onClick: (event, rowData) => handleDelete(rowData),
          //     hidden: rowData.logEstatusRegistro ==="INACTIVO"
          // }),
          // rowData => ({
          //     icon: () => <SettingsPowerIcon />,
          //     tooltip: 'Activar',
          //     onClick: (event, rowData) => handleReactive(rowData),
          //     hidden: rowData.logEstatusRegistro ==="ACTIVO",
          // })
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {ModalAgregarSucursal && (
        <AgregarSucursal
          open={ModalAgregarSucursal}
          handleClose={handleCloseSucursalModal}
          title='Agregar Sucursal'
        />
      )}

      {ModalControlFactura && (
        <AgregarControlfacturasucursal
          open={ModalControlFactura}
          handleClose={handleCloseControlModal}
          title='Agregar Control Facturas (sucursal)'
        />
      )}
      {ModalConsultaSucursal && (
        <ConsultaSucursal
          open={ModalConsultaSucursal}
          handleClose={handleCloseConsultaModal}
          title='Consulta Sucursal'
        />
      )}
      {ModalUpdateSucursal && (
        <UpdateSucursal
          open={ModalUpdateSucursal}
          handleClose={handleCloseUpdateModal}
          title='Modificar Sucursal'
        />
      )}
      {ModalParamter && (
        <Parametros
          open={ModalParamter}
          handleClose={handleCloseParamsModal}
          title='Parametros sucursal'
        />
      )}
    </div>
  )
}

export default Sucursal
