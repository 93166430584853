import { types } from "../types/types";

const initialState = {
  paises: [],
  activePais: null,
  modalPaisOpen: false,
  modalPaisDelete: false,
};

export const paisReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paisOpenModal:
      return {
        ...state,
        modalPaisOpen: true,
        modalPaisDelete: false,
      };

    case types.paisCloseModal:
      return {
        ...state,
        modalPaisOpen: false,
      };

    case types.paisOpenModalDelete:
      return {
        ...state,
        modalPaisOpen: false,
        modalPaisDelete: true,
      };

    case types.paisCloseModalDelete:
      return {
        ...state,
        modalPaisDelete: false,
      };

    case types.paisSetActive:
      return {
        ...state,
        activePais: action.payload,
        modalPaisOpen: true,
      };

    case types.paisAddNew:
      return {
        ...state,
        paises: [action.payload, ...state.paises],
      };

    case types.paisClearActive:
      return {
        ...state,
        activePais: null,
      };

    case types.paisUpdated:
      return {
        ...state,
        paises: state.paises.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
      };

    case types.paisLoaded:
      return {
        ...state,
        paises: action.payload,
      };

    case types.paisDelete:
      return {
        ...state,
        paises: state.paises.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
        activePais: null,
      };

    case types.paisReactived:
      return {
        ...state,
        paises: state.paises.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
        activePais: null,
      };

    default:
      return state;
  }
};
