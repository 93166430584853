import { types } from '../types/types'

const initialState = {
  productos: [],
  productosByLocalidad: [],
  activeProducto: null,
  modalProductoOpen: false,
  modalProductoDelete: false,
  modalProductoShow: false,
  localidadSelected: false,
  localidadActive: null,
  tipoGravamenSelected: false,
  tipoGravamenActive: null,
  tipoVentaSelected: false,
  tipoVentaActive: null,
}

export const productoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.productoOpenModal:
      return {
        ...state,
        modalProductoOpen: true,
        modalProductoDelete: false,
      }

    case types.productoCloseModal:
      return {
        ...state,
        modalProductoOpen: false,
      }

    case types.productoOpenModalDelete:
      return {
        ...state,
        modalProductoOpen: false,
        modalProductoDelete: true,
      }

    case types.productoCloseModalDelete:
      return {
        ...state,
        modalProductoDelete: false,
      }

    case types.productoOpenModalShow:
      return {
        ...state,
        modalProductoShow: true,
        modalProductoOpen: false,
        modalProductoDelete: false,
      }

    case types.productoCloseModalShow:
      return {
        ...state,
        modalProductoShow: false,
      }

    case types.productoSetActive:
      return {
        ...state,
        activeProducto: action.payload,
      }

    case types.productoClear:
      return {
        ...state,
        productos: [],
        productosByLocalidad: [],
        activeProducto: null,
      }

    case types.productoAddNew:
      return {
        ...state,
        productos: [...state.productos, action.payload],
        localidadSelected: false,
        localidadActive: null,
        tipoGravamenSelected: false,
        tipoGravamenActive: null,
        tipoVentaSelected: false,
        tipoVentaActive: null,
      }

    case types.productoClearActive:
      return {
        ...state,
        activeProducto: null,
      }

    case types.productoUpdated:


      return {
        ...state,
        productos: state.productos.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
        localidadSelected: false,
        localidadActive: null,
        tipoGravamenSelected: false,
        tipoGravamenActive: null,
        tipoVentaSelected: false,
        tipoVentaActive: null,
      }

    case types.productoLoaded:
      return {
        ...state,
        productos: action.payload,
      }

    case types.productosLoadedByLocalidad:
      return {
        ...state,
        // aqui habia un filter creo que debe ser un reducer
        productosByLocalidad: state.productos.filter(
          (p) => p.id === action.payload
        ),
      }

    case types.ActivaDesavtivaProducto:
      return {
        ...state,
        productos: state.productos.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
        activeProducto: null,
      }

    case types.productoReactived:
      return {
        ...state,
        productos: state.productos.map((p) =>
          p.id === action.payload.id ? action.payload : p
        ),
        activeProducto: null,
      }

    case types.productoSetLocalidadActive:
      return {
        ...state,
        localidadSelected: true,
        localidadActive: action.payload,
      }

    case types.productoSetTipoGravamenActive:
      return {
        ...state,
        tipoGravamenSelected: true,
        tipoGravamenActive: action.payload,
      }

    case types.productoTipoVentaSelected:
      return {
        ...state,
        tipoVentaSelected: true,
        tipoVentaActive: action.payload,
      }

    case types.productoTipoVentaClearSelected:
      return {
        ...state,
        tipoVentaSelected: false,
        tipoVentaActive: null,
      }
    case types.ProductosByLocalidadByID:
      return {
        ...state,
        productosByLocalidad: action.payload,
      }
    case types.setproductoClear:
      return {
        ...state,
        productos: [],
      }
    case types.productosClearByLocalidadByID:
      return {
        ...state,
        productosByLocalidad: [],
      }

    default:
      return state
  }
}
