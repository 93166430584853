import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// import Swal from 'sweetalert2'

import {
 facturaGeneralClearActive,
 // setClearlstDetalles,
 // startClearFacturaActive,
 // startLoadCambioDiaUsd,
 startSaveFacturaGeneral,
} from '../../../../redux/actions/facturaGeneralActions'
import BuscarClienteph from '../../cliente/BuscarClenteph'

import TotalesFacturaGeneralph from '../TotalesFacturaGeneralph'
// import { startLoadProductos } from '../../../../redux/actions/productoActions'
// import { sucursalClearActive } from '../../../../redux/actions/sucursalActions'
import { Button, Container, Grid } from '@material-ui/core'
import RazonSocial from './RazonSocial'
import DetalleProductos from './DetalleProductos'
import useFacturaGeneral from '../../../../hooks/useFacturaGeneral'
import DetallePagoFacturaGeneralph from '../FormaPago/DetallePagoFacturaGeneralph'
// import { useGravamen } from '../../../../hooks/useGravamen'
// import { startLoadValorGravamenActuales } from '../../../../redux/actions/valorGravamenActions'
// import LoadingCirculo from '../../../commons/ui/LoadingCirculo'
// import LoadingSvg from '../../../commons/ui/LoadingProcet'
import { useToast } from '../../../../hooks/useToast'
import { startLoadTiposGravamenes } from '../../../../redux/actions/tipoGravamenActions'
import FacturaServicio from './FacturaServicio'

const useStyles = makeStyles((theme) => ({
 btn: {
  marginTop: '24px',
  fontSize: '12px',
  textAlign: 'center',
  padding: '8px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  '&:hover': {
   backgroundColor: '#054477',
   color: 'white',
  },
 },
}))

export const FacturaGeneralBase = () => {
 // const history = useHistory()
 const classes = useStyles()
 const { notifyError, notifysucess, Toaster } = useToast()
 const { loading, isError, error, tipoMsg } = useSelector(
  (state) => state.error
 )
 const { divisas } = useSelector((state) => state.divisa)
 const dispatch = useDispatch()
 const {
  // activeFacturaGeneral,
  // modalDetalleFacturaGeneral,
  lstDetalles,
  lstPagos,
  lstGravamenes,
  clienteSelected,
  clienteActive,
  localidadSelected,
  localidadActive,
  sucursalSelected,
  sucursalActive,
  tipoVentaSelected,
  tipoVentaActive,
  condicionSelected,
  condicionActive,
  diasCreditoSelected,
  diasCreditoActive,
  subTotal,
  tipoColetilla,
  exonerado,
  exento,
  nroFactura,
  facturaDivisa,
  tipoCambioDiaUsd,
  totalDescuento,
  importeTotal,
  faltaPorPagar,
  cambioDivisa,
  modeloFactura,
  periodoFacturacion,
  pagueAntes,
  nroContrato,
  direccionServicio,
  cuentaTerceros,
  domicilioPrestador,
  nombrePrestador,
  rifPrestador,
  observacion,
 } = useFacturaGeneral(false)
 // const { valoresGravamanesActuales } = useGravamen()

 const Verificador = [
  clienteSelected,
  localidadSelected,
  // sucursalSelected,
  tipoVentaSelected,
  lstDetalles?.length > 0,
  (condicionActive === 'CONTADO' && lstPagos?.length > 0) ||
   (condicionActive === 'CREDITO' && !diasCreditoSelected),
  parseFloat(faltaPorPagar).toPrecision(2) < 0.9,
 ]

 const resultadovalidar = Verificador.some((valor) => {
  return valor === false
 })

 const handleAddFactura = () => {
  const validarLocalidadSucusal = sucursalSelected
   ? 'id_sucursal'
   : 'id_localidad'

  dispatch(
   startSaveFacturaGeneral({
    [validarLocalidadSucusal]: sucursalSelected
     ? sucursalActive.id
     : localidadActive._id,
    nroFactura,
    importeTotal,
    subTotal,
    facturaDivisa,
    tipoColetilla,
    cambioDivisa,
    modeloFactura,
    tipoCambioDiaUsd: divisas.data.find((divisa) => divisa.iso === 'USD').value,
    montoDescuento: totalDescuento,
    totalExento: exento,
    tipoVenta: tipoVentaSelected ? tipoVentaActive : null,
    condicionPago: condicionSelected ? condicionActive : null,
    diasCredito: diasCreditoSelected ? diasCreditoActive.dias : 0,
    estatusCredito: false,
    totalExonerado: exonerado,
    fechaVencimiento: new Date().toISOString(),
    periodoFacturacion:
     modeloFactura === 'SERVICIO' ? periodoFacturacion : 'no aplica',
    pagueAntes:
     modeloFactura === 'SERVICIO' ? pagueAntes : new Date().toISOString(),
    nroContrato: modeloFactura === 'SERVICIO' ? nroContrato : 'no aplica',
    direccionServicio:
     modeloFactura === 'SERVICIO' ? direccionServicio : 'no aplica',
    cuentaTerceros,
    domicilioPrestador,
    nombrePrestador,
    rifPrestador,
    observacion: observacion !== null ? observacion : undefined,
    // buscar en lst pagos que exita un pago en USD y si exite coletillaIGTF en true o false
    coletillaIGTF:
     lstPagos.find((pago) => pago.igtfValor > 0) === undefined ? false : true,
    lstPagos,
    cliente: {
     tipoDocumento: clienteActive.tipoDocumento,
     numeroDocumento: clienteActive.numeroDocumento,
     identificacion: clienteActive.identificacion,
     direccion: clienteActive.direccion,
     telefonoMovil: clienteActive.telefonoMovil,
     correo: clienteActive.correo,
     ccCorreo:
      clienteActive.ccCorreo === '' ? undefined : clienteActive.ccCorreo,
     tipoPersona: 'CLIENTE',
    },
    lstDetallesFacturaGeneral: lstDetalles,
    lstGravamenes,
    // logUsername: 'sadmin',
   })
  )

  // dispatch(startLoadFacturasGenerales())
  // dispatch(facturaGeneralClearActive())
  // history.push('/facturacion/generales')
 }

 // if (loading) {
 //   return <LoadingSvg size={60} />
 // }

 // if (isError) {
 //   return (
 //     <div
 //       style={{
 //         display: 'flex',
 //         alignContent: 'center',
 //         alignItems: 'center',
 //         width: '100%',
 //       }}
 //     >
 //       <p style={{ width: '100%', textAlign: 'center' }}>Ocurrio un error {error}</p>
 //     </div>
 //   )
 // }

 useEffect(() => {
  dispatch(startLoadTiposGravamenes())
  dispatch(facturaGeneralClearActive())
 }, [dispatch])

 // if (isError && tipoMsg === 'error') notifyError()
 // else if (isError && tipoMsg === 'suc ess') notifysucess()

 return (
  <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
   <Toaster />
   <Grid container spacing={2}>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <BuscarClienteph />
    </Grid>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <RazonSocial />
    </Grid>
    <Grid item lg={12} sm={12} xl={12} xs={12}>
     <DetalleProductos />
    </Grid>
    <Grid item lg={12} sm={12} xl={12} xs={12}>
     <DetallePagoFacturaGeneralph />
    </Grid>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <TotalesFacturaGeneralph />

     {/* <TotalesFacturaGeneral /> */}
    </Grid>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <Grid container spacing={0}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       {modeloFactura === 'SERVICIO' && <FacturaServicio />}
      </Grid>

      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Button
        size='small'
        variant='outlined'
        onClick={handleAddFactura}
        disabled={resultadovalidar}
        fullWidth
        className={classes.btn}
       >
        <>
         <i className='far fa-save'></i>
         <span style={{ paddingLeft: '12px' }}> Guardar factura</span>
        </>
       </Button>
      </Grid>
     </Grid>
    </Grid>
   </Grid>
   {isError && tipoMsg === 'sucess'
    ? notifysucess()
    : tipoMsg === 'error' &&
      error !== 'no hay Sucursal para esta localidad' &&
      notifyError()}
  </Container>
 )
}
