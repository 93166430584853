import { useSelector } from 'react-redux'
import { useState } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import useStyles from './Styles'
import { useLibroVenta } from '../../../hooks/useLibroVenta'

export default function ResumenLibroVentas() {
  const { Reporte } = useSelector((state) => state.reporte)
  const classes = useStyles()
  const { newAcumuladoGravamenTerceros } = useLibroVenta()
  const excel =
    Reporte?.libro?.libroExcel === undefined || Reporte?.libro?.consolidado > 0
      ? ''
      : 'Excel se genero con exito'

  if (
    Reporte?.libro?.AcumuladoImporteTerceros === undefined ||
    Reporte?.libro?.consolidado === 0
  ) {
    return (
      <Card
        style={{
          overflowY: 'scroll',
          height: 360,
          boxShadow:
            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <CardHeader title='Resumen' className={classes.CardHeader} />
        <CardContent>
          <Grid container spacing={0}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Typography variant='h5' align='center'>
                {excel}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card
      style={{
        overflowY: 'scroll',
        height: 360,
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      <CardHeader title='Resumen' className={classes.CardHeader} />
      <CardContent>
        <Grid container spacing={0} style={{}}>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>Cantidad </div>
            <div className={classes.caption}>{Reporte?.libro?.consolidado}</div>
            <Divider component={'div'} />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>Fac. Propias</div>
            <div className={classes.caption}>{Reporte?.libro?.SinTerceros}</div>
            <Divider component={'div'} />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>Fac. Terceros</div>
            <div className={classes.caption}>{Reporte?.libro?.ConTerceros}</div>
            <Divider component={'div'} />
          </Grid>

          {/* iniciocon Procios  */}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography variant='subtitle2' align='center'>
              Cuenta Propios
            </Typography>
            <Divider
              component={'div'}
              variant='fullWidth'
              style={{ padding: '2px' }}
            />
          </Grid>

          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>T. Exentos P.</div>
            <div className={classes.caption}>
              {Reporte?.libro?.totalFacturas?.totalExentoSinTerceros?.toLocaleString(
                'es-VE',
                { maximumFractionDigits: 2 }
              )}
            </div>
            <Divider component={'div'} />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>T. Exonerado P.</div>
            <div className={classes.caption}>
              {Reporte?.libro?.totalFacturas?.totalExoneradoSinTerceros.toLocaleString(
                'es-VE',
                { maximumFractionDigits: 2 }
              )}
            </div>
            <Divider component={'div'} />
          </Grid>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <div className={classes.name}>T. Importe P.</div>
            <div className={classes.caption}>
              {Reporte?.libro?.totalFacturas?.totalImporteTotalSinTerceros.toLocaleString(
                'es-VE',
                {
                  maximumFractionDigits: 2,
                }
              )}
            </div>
          </Grid>

          {Reporte?.libro?.totalGravamenes?.map((gravamen, index) => (
            <Grid container key={index}>
              <Grid item lg={4} sm={4} xl={12} xs={12}>
                <div
                  className={classes.name}
                >{`B. Imponible P. ${gravamen.alicuota}%`}</div>
                <div className={classes.caption}>
                  {gravamen?.baseImponible?.toLocaleString('es-VE', {
                    maximumFractionDigits: 2,
                  })}
                </div>
                <Divider component={'div'} />
              </Grid>
              <Grid item lg={4} sm={4} xl={12} xs={12}>
                <div className={classes.name}>
                  {`Alicuota P. ${gravamen.alicuota}%`}
                </div>
                <div className={classes.caption}>
                  {gravamen?.montoAlicuota?.toLocaleString('es-VE', {
                    maximumFractionDigits: 2,
                  })}
                </div>
                <Divider component={'div'} />
                {/* <div className={classes.caption}>{gravamen.montoAlicuota}</div> */}
              </Grid>
            </Grid>
          ))}

          {/* inicio con terceros  */}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography variant='subtitle2' align='center'>
              Cuenta Terceros
            </Typography>
            <Divider
              component={'div'}
              variant='fullWidth'
              style={{ padding: '2px' }}
            />
          </Grid>

          {newAcumuladoGravamenTerceros?.map((tercero, indx) => {
            const Terceros = Object.keys(tercero)[0]

            return (
              <Grid container style={{ width: '100%' }} key={indx}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <Typography
                    variant='subtitle2'
                    style={{ fontWeight: 'bold' }}
                  >
                    {`${Object.keys(tercero)[0]} Cant. Facturas: ${
                      tercero[Object.keys(tercero)[0]].Totalfacturas
                    }`}
                  </Typography>
                </Grid>

                <Grid item lg={4} sm={4} xl={12} xs={12}>
                  <div className={classes.name}>T. Exento Ter.</div>
                  <div className={classes.caption}>
                    {tercero[
                      Object.keys(tercero)[0]
                    ].totalExentoConTerceros?.toLocaleString('es-VE', {
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </Grid>
                <Grid item lg={4} sm={4} xl={12} xs={12}>
                  <div className={classes.name}>T. Exonerado Ter.</div>
                  <div className={classes.caption}>
                    {tercero[
                      Object.keys(tercero)[0]
                    ].totalExoneradoConTerceros?.toLocaleString('es-VE', {
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </Grid>
                <Grid item lg={4} sm={4} xl={12} xs={12}>
                  <div className={classes.name}>T. importe Ter.</div>
                  <div className={classes.caption}>
                    {tercero[
                      Object.keys(tercero)[0]
                    ]?.totalImporteTotalConTerceros?.toLocaleString('es-VE', {
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </Grid>

                {/* Gravamenes  */}
                {tercero[`${Terceros}`][`${Terceros}`]?.map(
                  (gravamen, indx2) => {
                    return (
                      <Grid container key={indx2}>
                        <Grid item lg={4} sm={4} xl={4} xs={4}>
                          <div
                            className={classes.name}
                          >{`B. Imponible T. ${gravamen.alicuota}%`}</div>
                          <div className={classes.caption}>
                            {gravamen.baseImponible?.toLocaleString('es-VE', {
                              maximumFractionDigits: 2,
                            })}
                          </div>
                        </Grid>
                        <Grid item lg={4} sm={4} xl={4} xs={4} key={indx2}>
                          <div
                            className={classes.name}
                          >{`B. Alicuota T. ${gravamen.alicuota}%`}</div>
                          <div className={classes.caption}>
                            {gravamen.montoAlicuota?.toLocaleString('es-VE', {
                              maximumFractionDigits: 2,
                            })}
                          </div>
                        </Grid>
                      </Grid>
                    )
                  }
                )}
              </Grid>
            )
          })}

          {/* fin  con  terceros  */}
        </Grid>
      </CardContent>
    </Card>
  )
}
