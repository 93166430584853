import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, InputLabel, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import useStyles from './Styles'
import { startSaveTipoGravamen } from '../../../redux/actions/tipoGravamenActions'

const schema = yup.object().shape({
  codigo: yup.string().required('Es requerido'),
  concepto: yup.string().required('Es requerido'),
  valor: yup.number().required('Es  requerido').label('Valor'),
  fec_desde: yup
    .date()
    .required('Required field')
    .nullable()
    .default(undefined),
  fec_hasta: yup
    .date()
    .required('Required field')
    .nullable()
    .default(undefined),
})

export default function AgregarGravamen({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmitTipoGravamen = async (data) => {
    console.log(' dataeeeeeee', data)
    dispatch(
      startSaveTipoGravamen({
        codigo: data.codigo,
        concepto: data.concepto,
        tipo: 6,
        lstValores: [
          {
            valor: data.valor,
            fechaHasta: data.fec_desde,
            fechaDesde: data.fec_hasta,
          },
        ],
        // logUsername": "sadmin"
      })
    ).then((resul) => {
      if (resul.payload.tipoMsg === 'sucess') {
        handleClose()
      }
    })
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitTipoGravamen)} autoComplete='off'>
        <Grid container spacing={1} className={classes.root}>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <Controller
              name='codigo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Codigo'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <Controller
              name='concepto'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Concepto'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='valor'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Valor'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.fechas}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='fec_desde'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputLabel variant='standard' htmlFor='fech_hasta'>
                  Fecha Vigencia Desde
                  <TextField
                    id='outlined-multiline-static'
                    className={classes.text}
                    type='date'
                    // variant='outlined'
                    //   label='Fecha Desde'
                    size='small'
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                </InputLabel>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='fec_hasta'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputLabel variant='standard' htmlFor='fech_hasta'>
                  Fecha Vigencia Hasta
                  <TextField
                    id='fech_hasta'
                    className={classes.text}
                    type='date'
                    // variant='outlined'
                    //   label='Fecha Desde'
                    size='small'
                    fullWidth
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error && error.message}
                  />
                </InputLabel>
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
