import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { errorClearActive } from '../redux/actions/errorActions'

export const useToast = () => {
 const dispatch = useDispatch()
 const { isError, error, loading, tipoMsg } = useSelector(
  (state) => state.error
 )

 const notifyError = (msg) => {
  toast.error(msg || error, {
   // icon: '👏',
   style: {
    border: 'solid 1px #D8000C',
    borderRadius: '10px',
    color: '#D8000C',
    backgroundColor: '#fbe9e7',
   },
  })
  dispatch(errorClearActive())
 }

 const notifysucess = (msg) => {
  toast.success(msg || error, {
   style: {
    border: 'solid 1px #D8000C',
    borderRadius: '10px',
    color: '#33691e',
    backgroundColor: '#f1f8e9',
   },
  })
  dispatch(errorClearActive())
 }

 const notify = (msg) => {
  toast(msg, {
   icon: '👏',
   style: {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
   },
  })
  dispatch(errorClearActive())
 }

 const notifyAliatorio = () =>
  toast.error(error, {
   style: {
    border: 'solid 1px #D8000C',
    borderRadius: '10px',
    color: '#D8000C',
    backgroundColor: '#fbe9e7',
   },
  })

 return {
  notify,
  notifyError,
  notifysucess,
  notifyAliatorio,
  Toaster,
  isError,
  loading,
  error,
  tipoMsg,
 }
}
