import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '10px',
    textAlign: 'right',
  },
  dbasicos: {
    '& .MuiTypography-body1': {
      fontSize: '10px',
      padding: '0px 8px 0px 0px',
    },
  },
  headers: {
    '& .MuiTypography-body1': {
      fontSize: '10px',
      textAlign: 'right',
      fontWeight: 'bold',
      padding: '2px 4px 2px 4px',
      border: '.1px solid #000',
    },
    backgroundColor: '#e0e0e0',
  },
  body: {
    '& .MuiTypography-body1': {
      fontSize: '9px',
      textAlign: 'right',

      // fontWeight: 'bold',
      // padding: "2px 4px 2px 4px",
      // border: '.1px solid #000'
    },
    alignItems: 'end',
  },
  textotitulos: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  margen: {
    marginTop: '16px',
  },
  jsonView: {
    maxWidth: '70vh',
    padding: '8px',
    backgroundColor: '#f5f5f5',
    overflowY: 'scroll',
    maxHeight: '65vh',
  },
  jsonItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignContent: 'center',
    alignItems: 'stretch',
    gap: '12px',
  },
  jsonKey: {
    color: '#00f',
    fontSize: '14px',
  },
  jsonValue: {
    maxWidth: '250px',
    color: '#f00',
    fontSize: '12px',
    alignSelf: 'justify',
  },
  jsonItemProducto: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    // gap: '6px',
  },
  ViewGenerico: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignContent: 'center',
    alignItems: 'stretch',
    gap: '12px',
  },
}))
