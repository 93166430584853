import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Modal from 'react-modal'
import { makeStyles } from '@material-ui/core/styles'

import {
  localidadClearActive,
  localidadCloseModalDelete,
  startDeleteLocalidad,
} from '../../../redux/actions/localidadActions'

const initLocalidad = {
  tipoDocumento: '',
  numeroDocumento: '',
  nombre: '',
  municipio: '',
  direccion: '',
  logEstatusRegistro: '1',
  logFechaRegistro: new Date(),
  logUsername: 'sadmin',
  logFechaModificacion: null,
}

Modal.setAppElement('#root')

export const LocalidadModalDelete = () => {
  const dispatch = useDispatch()

  const { activeLocalidad, modalLocalidadDelete } = useSelector(
    (state) => state.localidad
  )

  const [formValues, setFormValues] = useState(initLocalidad)

  const { nombre } = formValues

  useEffect(() => {
    if (activeLocalidad) {
      setFormValues(activeLocalidad)
    } else {
      setFormValues(initLocalidad)
    }
  }, [activeLocalidad, setFormValues])

  const closeModal = () => {
    dispatch(localidadCloseModalDelete())
    dispatch(localidadClearActive())
    setFormValues(initLocalidad)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    dispatch(startDeleteLocalidad())

    closeModal()
  }

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    inputMaterial: {
      width: '100%',
    },
    modal: {
      background: 'white',
      borderRadius: '5px',
      color: 'rgb(51, 51, 51)',
      maxHeight: '620px',
      maxWidth: '600px',
      outline: 'none',
      padding: '10px',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-25%',
      transform: 'translate(-50%, -50%)',
    },
  }))

  const styles = useStyles()

  return (
    <Modal
      isOpen={modalLocalidadDelete}
      closeTimeoutMS={200}
      onRequestClose={closeModal}
      className={styles.modal}
      overlayClassName='modal-fondo'
    >
      <h3>Inactivar Razón Social</h3>

      <hr />
      <form className='container' onSubmit={handleSubmit}>
        <div className={styles.modal}>
          <div className='form-group'>
            <label>Nombre</label>
            <input
              type='text'
              className='form-control input-lg'
              placeholder='Nombre'
              name='nombre'
              autoComplete='off'
              value={nombre}
              readOnly={true}
            />
          </div>
          <br />
          <button type='submit' className='btn btn-outline-primary btn-block'>
            <i className='fas fa-power-off'></i>
            <span> Inactivar</span>
          </button>
        </div>
      </form>
    </Modal>
  )
}
