import { types } from '../types/types'

//opciones de tipoMSG: error, sucess, SoloLoading, pagina

export const errorSetActive = (Error, tipoMsg = 'error', resultado = {}) => ({
  type: types.errorManejo,
  payload: { Error, tipoMsg, resultado },
})

export const errorClearActive = () => ({
  type: types.errorClearActive,
})

export const errorStatusLoading = (tipoMsg = 'SoloLoading') => ({
  type: types.setStatusLoaidng,
  payload: { tipoMsg },
})
