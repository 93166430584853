import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
 Grid,
 TextField,
 InputLabel,
 FormControl,
 Button,
 Fab,
 Input,
 InputAdornment,
 IconButton,
} from '@material-ui/core'
import useStyles from './Styles'
import toast from 'react-hot-toast'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'

import {
 localidadClearActive,
 startSaveCertficado,
} from '../../../redux/actions/localidadActions'

const schema = yup.object().shape({
 keyStoreAlias: yup.string().required('Es requerido'),
 keyStorePassword: yup.string().required('Password is requerido'),
 RekeyStorePassword: yup
  .string()
  .required('Password is requerido')
  .oneOf([yup.ref('keyStorePassword'), null], 'Passwords no Coinsiden'),
 keyStoreName: yup
  .mixed()
  .test('required', 'Debe Selecionar un Certificado', (value) => {
   return value && value.length
  })
  .test('fileSize', 'El archivo es muy Pesado', (value, context) => {
   return value && value[0] && value[0].size <= 200000
  }),
})

export default function AgregarCertificado({ open, handleClose, title }) {
 const { activeLocalidad } = useSelector((state) => state.localidad)
 const [showPassword, setshowPassword] = useState(false)
 const [values, setvalues] = useState({
  keyStoreAlias: null,
  keyStorePassword: null,
  RekeyStorePassword: null,
  keyStoreName: null,
 })

 const classes = useStyles()
 const dispatch = useDispatch()
 // const { estados, municipio, buscarMunicipio } = useEstados(),
 const {
  handleSubmit,
  control,
  register,
  formState: { errors },
 } = useForm({
  resolver: yupResolver(schema),
 })

 // const notifyerror = (res) => toast.error(res)

 const onSubmitCertificado = async (data) => {
  // setvalues(data)

  const formDataNew = new FormData()
  formDataNew.append('certificado', data.keyStoreName[0])
  formDataNew.append('keyStoreAlias', data.keyStoreAlias)
  formDataNew.append('keyStorePassword', data.keyStorePassword)

  dispatch(startSaveCertficado(formDataNew, activeLocalidad._id))
   .then((resul) => {
    if (resul.payload.tipoMsg === 'sucess') {
     dispatch(localidadClearActive())
     handleClose()
    }
   })
   .catch((error) => {
    console.log(
     '🚀 ~ file: AgregarCertficado.js:101 ~ onSubmitCertificado ~ error:',
     error
    )
   })
 }

 const uploadImage = async (e) => {
  const file = e.target.files[0]

  setvalues({ ...values, keyStoreName: file })
 }

 const handleClickShowPassword = () => {
  setshowPassword(!showPassword)
 }

 const handleMouseDownPassword = (event) => {
  event.preventDefault()
 }

 const handleChange = (e) => {
  setvalues((prevState) => ({
   ...prevState,
   [e.target.name]: e.target.value,
  }))
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form
    enctype='multipart/form-data'
    onSubmit={handleSubmit(onSubmitCertificado)}
    autoComplete='off'
   >
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='keyStoreAlias'
       control={control}
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         id='keyStoreAlias'
         name='keyStoreAlias'
         autoComplete='false'
         className={classes.text}
         label='Alias'
         // variant='outlined'
         size='small'
         fullWidth
         value={value}
         onChange={(e) => {
          handleChange(e)
          onChange(e.target.value)
         }}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='keyStorePassword'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel htmlFor='standard-adornment-password'>Password</InputLabel>
         <Input
          id='keyStorePassword'
          name='keyStorePassword'
          // id='standard-adornment-password'
          className={classes.text}
          // label='Repita el password'
          type={showPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={value}
          onChange={(e) => {
           handleChange(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
          endAdornment={
           <InputAdornment position='end'>
            <IconButton
             aria-label='toggle password visibility'
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
           </InputAdornment>
          }
         />
         <p className={classes.error}>{error ? error.message : null}</p>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='RekeyStorePassword'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel htmlFor='standard-adornment-Repassword'>
          Repita el Password
         </InputLabel>
         <Input
          name='RekeyStorePassword'
          id='standard-adornment-Repassword'
          className={classes.text}
          // label='Repita el password'
          type={showPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={value}
          onChange={onChange}
          error={!!error}
          // helperText={error ? error.message : null}
          endAdornment={
           <InputAdornment position='end'>
            <IconButton
             aria-label='toggle password visibility'
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
           </InputAdornment>
          }
         />
         <p className={classes.error}>{error ? error.message : null}</p>
        </FormControl>
       )}
      />
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <label htmlFor='keyStoreName' style={{ width: '100%' }}>
       <input
        style={
         {
          // display: 'none',
          // alingItems: 'center',
          // width: '100%',
         }
        }
        {...register('keyStoreName')}
        type='file'
        id='keyStoreName'
        name='keyStoreName'
        onChange={(e) => uploadImage(e)}
       />
       {/* <Fab
                style={{ alingItems: 'center', width: '100%' }}
                color='secondary'
                size='small'
                component='span'
                aria-label='add'
                variant='extended'
              >
                Subir logo empresa
              </Fab> */}
      </label>
      {errors.keyStoreName && (
       <p style={{ color: 'red' }}>{errors.keyStoreName.message}</p>
      )}
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <TextField
       id='vercertificado'
       disabled
       fullWidth
       label={
        activeLocalidad?.keystoreArchivo
         ? 'Certificado Actual'
         : 'no posee certificado'
       }
       value={activeLocalidad?.keystoreArchivo}
      />
     </Grid>
    </Grid>
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       //  onClick={() => peticionPost()}
      >
       Agregar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
