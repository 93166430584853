import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento/valorGravamen`;

export const startLoadValoresGravamenes = () => {
    return (dispatch) => {

        axios.get(`${url}/listar`)
        .then(res => {
            dispatch(valoresGravamenesLoaded(res.data));
        });
    }
}

export const startLoadValorGravamenActuales = () => {
    return (dispatch) => {

        axios.get(`${url}/findValoresActuales`)
        .then(res => {
            dispatch(valoresGravamenActuales(res.data));
        });
    }
}

export const startSaveValorGravamen = (valorGravamen) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: valorGravamen
        })
            .then((response) => {
                
                Swal.fire('Guardado', valorGravamen.valor, 'success');
                dispatch(valorGravamenAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeleteValorGravamen = () => {

    return async (dispatch, getState) => {
       
        const { activeValorGravamen } = getState().valorGravamen;
       
       //TODO: Validar sí existen Facturas con este Valor
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeValorGravamen.id}`
        })
            .then((response) => {
                Swal.fire('Inactivado', response.data.valor.toString(), 'success');
                dispatch(deleteValorGravamen(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedValorGravamen = (valorGravamen) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${valorGravamen.id}`
        })
            .then((response) => {
                Swal.fire('Activado', response.data.valor.toString(), 'success');
                dispatch(reactiveValorGravamen(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateValorGravamen = (valorGravamen) => {

    return (dispatch, getState) => {

        const { activeValorGravamen } = getState().valorGravamen;
        const id = activeValorGravamen.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: valorGravamen
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.valor, 'success');
                dispatch(valorGravamenUpdated(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}


const valoresGravamenesLoaded = (valoresGravamenes) => ({
    type: types.valorGravamenLoaded,
    payload: valoresGravamenes
});

const valoresGravamenActuales = (valoresActuales) => ({
    type: types.valorGravamenActuales,
    payload: valoresActuales
});

export const valorGravamenOpenModal = () => ({
    type: types.valorGravamenOpenModal
});

export const valorGravamenCloseModal = () => ({
    type: types.valorGravamenCloseModal
});

export const valorGravamenOpenModalDelete = () => ({
    type: types.valorGravamenOpenModalDelete
});

export const valorGravamenCloseModalDelete = () => ({
    type: types.valorGravamenCloseModalDelete
})

export const valorGravamenAddNew = ( valorGravamen ) => ({
    type: types.valorGravamenAddNew,
    payload: valorGravamen
});

export const valorGravamenSetActive = ( valorGravamen ) => ({
    type: types.valorGravamenSetActive,
    payload: valorGravamen
});

export const valorGravamenClearActive = ( ) => ({
    type: types.valorGravamenClearActive,
});

export const valorGravamenUpdated = ( valorGravamen ) => ({
    type: types.valorGravamenUpdated,
    payload: valorGravamen
});

export const deleteValorGravamen = ( valorGravamen ) => ({
    type: types.valorGravamenDelete,
    payload: valorGravamen
});

export const reactiveValorGravamen = ( valorGravamen ) => ({
    type: types.valorGravamenReactived,
    payload: valorGravamen
});