import { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import ModalGenerico from '../../../commons/ui/Modal'
import useFacturaGeneral from '../../../../hooks/useFacturaGeneral'
// import useTasas from '../../../../hooks/useTasas'

import {
 Grid,
 Box,
 FormControl,
 InputLabel,
 Select,
 MenuItem,
 FormHelperText,
 TextField,
 Chip,
 Switch,
 FormControlLabel,
} from '@material-ui/core'
import useStyles from './Styles'
import { mediosPago } from '../../../../Constants/ConstantesFacturas'
import { useSelector, useDispatch } from 'react-redux'
// import { startLoadBancos } from '../../../../redux/actions/bancoActions'
// import { startLoadDivisas } from '../../../../redux/actions/divisaActions'
import BotonesAccionesDuo from '../../../commons/ui/BotonAccionesDuo'
import {
 eliminarComas,
 filtroNumeros,
 formatDecimal,
 FormatedBolivares,
 numeroFormat,
 parseMil,
 soloNumeros,
 sustituirCaracteres,
} from '../../../../Helpers/formater'
import {
 // setDivisaActive,
 setMedioPagoActive,
 setMedioPagoClearActive,
 setDivisaClearActive,
 // startNewPago,
 facturaGeneralAddNewPago,
 setBancoActive,
 setUpdateFactura,
 startLoadCanbioDivisa,
 startNewPago,
} from '../../../../redux/actions/facturaGeneralActions'
import useDivisaBancos from '../../../../hooks/usedivisaBancos'
import {
 divisaClearActive,
 startDivisaLoadActive,
} from '../../../../redux/actions/divisaActions'
import { useToast } from '../../../../hooks/useToast'

const AgregarPago = ({ handleClose, open, title }) => {
 const classes = useStyles()
 const {
  faltaPorPagar,
  condicionSelected,
  condicionActive,
  medioPagoSelected,
  medioPagoActive,
  bancoSelected,
  bancoActive,
  // divisaSelected,
  // divisaActive,
  localidadActive,
  lstPagos,
  diasCreditoSelected,
  diasCreditoActive,
  facturaDivisa,
  cambioDivisa,
 } = useFacturaGeneral()

 const { divisas, bancos, activeDivisa } = useDivisaBancos()
 const dispatch = useDispatch()
 const { notifyAliatorio } = useToast()

 // var tasaDiaDivisa = 0.0
 let MontoF = 0

 // const [Numero, setNumero] = useState(0)
 const [Opcionespago, setOpcionespago] = useState({
  mediopago: false,
  DivisaSelec: facturaDivisa,
  ConverActiva: '0',
  nroComprobante: '0',
  bank: 0,
  igtf: facturaDivisa === 'USD' || facturaDivisa === 'EUR' ? true : false,
  // coletillaIGTF: false,
 })

 useEffect(() => {
  dispatch(startDivisaLoadActive(facturaDivisa))

  dispatch(startLoadCanbioDivisa())
 }, [facturaDivisa, dispatch])

 const handleChange = (e) => {
  const { name, value, checked } = e.target
  setOpcionespago({ ...Opcionespago, [name]: value })

  if (name === 'mediopago') {
   HandleClarPago()
   dispatch(setMedioPagoActive(value))
   if (value !== 'EFECTIVO') dispatch(startDivisaLoadActive('VEF'))
  }
  if (name === 'DivisaSelec') {
   console.log(value)
   dispatch(startDivisaLoadActive(value))
   if (
    value === 'VEF' ||
    value === 'PTR' ||
    !localidadActive?.contribuyenteEspecial
   )
    setOpcionespago({ ...Opcionespago, igtf: false })
   else setOpcionespago({ ...Opcionespago, igtf: true })
  }
  if (name === 'bank') {
   dispatch(setBancoActive(value))
  }
  if (name === 'igtf') {
   setOpcionespago({ ...Opcionespago, [name]: checked })
  }
  if (name === 'ConverActiva') {
   setOpcionespago({
    ...Opcionespago,
    [name]: soloNumeros(value, filtroNumeros),
   })
   MontoF = Number(parseFloat(value)).toFixed(2)
  }
 }

 const handleSumitpago = (e) => {
  e.preventDefault()

  // validar que no se repita la divisa de pago
  const divisaRepetida = lstPagos?.find(
   (pago) =>
    pago.divisa === activeDivisa.iso && pago.modoPago === medioPagoActive
  )
  if (divisaRepetida) {
   notifyAliatorio('Atención: No se puede repetir pagos con la misma divisa')
   return
  }

  // console.log(
  //  '🚀 ~ file: AgregarPagoModal.js:152 ~ handleSumitpago ~ activeDivisa:',
  //  activeDivisa
  // )
  // console.log(
  //  '🚀 ~ file: AgregarPagoModal.js:162 ~ handleSumitpago ~ Opcionespago:',
  //  Opcionespago
  // )
  dispatch(
   startNewPago({
    modoPago: medioPagoSelected ? medioPagoActive : null,
    banco: bancoSelected ? bancoActive : '',
    nro: Opcionespago.nroComprobante,
    fechaComprobantePago: new Date().toISOString(),
    divisa: activeDivisa.iso,
    tasaDiaDivisa: activeDivisa.value,
    monto: sustituirCaracteres(Opcionespago.ConverActiva),
    condicion: condicionSelected ? condicionActive : null,
    diasCredito: diasCreditoSelected ? diasCreditoActive : 0,
    igtf: Opcionespago.igtf,
    // coletillaIGTF: Opcionespago.coletillaIGTF,
   })
  )
  handleClose()
 }

 const handleChangeNumero = (e) => {
  const { value } = e.target
  setOpcionespago((prev) => ({ ...prev, ConverActiva: value }))
  // MontoF = parseFloat(eliminarComas(value)).toFixed(2)
  MontoF = parseFloat(value).toFixed(2)
 }

 const handleClickPagar = (value) => {
  setOpcionespago((prev) => ({ ...prev, ConverActiva: value }))
 }

 const HandleClarPago = () => {
  dispatch(setMedioPagoClearActive())
  dispatch(setDivisaClearActive())
  dispatch(setBancoActive())
  // dispatch(setMedioPagoClearActive())
  // dispatch(divisaClearActive())
 }

 return (
  <ModalGenerico handleClose={handleClose} open={open} title={title}>
   <form onSubmit={handleSumitpago}>
    <Box className={classes.boxtamano}>
     <Grid container style={{ alignItems: 'center' }} spacing={2}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <FormControl required className={classes.formcontrol}>
        <InputLabel className={classes.listapago} id='required-label'>
         Medio de pago
        </InputLabel>
        <Select
         className={classes.listapago}
         labelId='mediopago'
         defaultValue={''}
         // id='mediopago-select-required'
         name='mediopago'
         // key={mpago.id}
         value={Opcionespago.mediopago}
         label='Medio de pago *'
         onChange={(e) => handleChange(e)}
        >
         {mediosPago?.map((mpago) => {
          return (
           <MenuItem
            className={classes.listapago}
            key={mpago.nombre}
            value={mpago.nombre}
           >
            <em>{mpago.nombre}</em>
           </MenuItem>
          )
         })}
        </Select>

        <FormHelperText>Seleccione un medio de pago</FormHelperText>
       </FormControl>
      </Grid>

      {Opcionespago.mediopago === 'EFECTIVO' && (
       <>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
         <FormControl required className={classes.formcontrol}>
          <InputLabel
           // className={classes.listapago}
           id='required-label-divisas'
          >
           Divisas
          </InputLabel>
          <Select
           className={classes.listapago}
           labelId='DivisaSelec'
           name='DivisaSelec'
           id='DivisaSelec'
           defaultValue={Opcionespago.DivisaSelec}
           // value={Opcionespago.DivisaSelec}
           label='Divisa *'
           onChange={(e) => handleChange(e)}
          >
           {divisas.data?.map((divi) => {
            return (
             <MenuItem
              className={classes.listapago}
              key={divi.iso}
              value={divi.iso}
             >
              <em>{divi.name}</em>
             </MenuItem>
            )
           })}
          </Select>
         </FormControl>
        </Grid>

        <Grid item lg={6} sm={6} xl={12} xs={12}>
         <InputLabel className={classes.listapago}>Monto</InputLabel>
         <TextField
          required
          className={classes.textinput}
          name='ConverActiva'
          size='small'
          fullWidth
          value={Opcionespago.ConverActiva}
          onChange={(e) => handleChange(e)}
         />
        </Grid>
        <Grid container className={classes.resumenpago}>
         <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Grid
           container
           style={{ alignItems: 'center', justifyContent: 'center' }}
           spacing={2}
          >
           <Grid item lg={12} sm={12} xl={12} xs={12}>
            <InputLabel className={classes.titlefrmpago}>Forma Pago</InputLabel>
           </Grid>
           <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Chip
             className={classes.textofrmpago}
             style={{ width: '90%' }}
             label={`Cambio:  ${FormatedBolivares(
              activeDivisa?.value,
              'es-ES',
              activeDivisa?.iso
             )}`}
            />
           </Grid>
           <Grid item lg={6} sm={6} xl={6} xs={6}>
            <Chip
             style={{
              color: 'white',
              backgroundColor: '#43a047',
              width: '90%',
              padding: '20px',
              fontSize: '14px',
              fontWeight: 'bold',
             }}
             label={FormatedBolivares(
              facturaDivisa === activeDivisa?.iso
               ? Number(faltaPorPagar)
               : (Number(faltaPorPagar) / Number(activeDivisa?.value)) *
                  cambioDivisa,
              'es-ES',
              activeDivisa?.iso
             )}
             onClick={(e) => handleClickPagar(e.target.innerText)}
            />
           </Grid>
           <Grid
            container
            spacing={2}
            style={{
             marginTop: '16px',
            }}
           >
            <Grid item lg={6} sm={6} xl={12} xs={12}>
             <InputLabel className={classes.apagar}>
              A Pagar:{' '}
              {FormatedBolivares(faltaPorPagar, 'es-ES', facturaDivisa)}
             </InputLabel>
            </Grid>
            {/* impusto a grandes trancsacioness finaciereas IGTF */}
            <Grid item lg={6} sm={6} xl={12} xs={12}>
             {localidadActive?.contribuyenteEspecial &&
              activeDivisa?.fueraSistemaFianaciero && (
               <>
                <div>
                 <InputLabel className={classes.textigtf}>
                  {`IGTF: ${FormatedBolivares(
                   parseFloat(Opcionespago?.ConverActiva).toFixed(2) * 0.03,
                   'es-ES',
                   activeDivisa?.iso
                  )}`}
                 </InputLabel>
                 <InputLabel className={classes.textigtf}>
                  {`IGTF: ${FormatedBolivares(
                   parseFloat(Opcionespago.ConverActiva).toFixed(2) *
                    1 *
                    parseFloat(activeDivisa.value).toFixed(2) *
                    0.03,
                   'es-ES',
                   'VEF'
                  )}`}
                 </InputLabel>
                </div>
                <InputLabel className={classes.textigtfcoletilla}>
                 Esta factura tendrá un recargo del 3% por percepción de IGTF
                 por los pagos recibidos en moneda distinta a la de curso legal
                 en el país, criptomonedas o criptoactivos diferentes a los
                 emitidos por la República Bolivariana de Venezuela de acuerdo a
                 la Providencia Administrativa SNAT/2022/000013, en Gaceta
                 Oficial N° 42.339 de fecha 17/03/2022
                </InputLabel>
               </>
              )}
            </Grid>
           </Grid>
          </Grid>
         </Grid>
        </Grid>
       </>
      )}
      {/* Otro medio de pago que no es efectivo */}
      {medioPagoSelected === true && Opcionespago.mediopago !== 'EFECTIVO' && (
       <>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
         <FormControl required className={classes.formcontrol}>
          <InputLabel
           // className={classes.listapago}
           id='required-label-banco'
          >
           Banco
          </InputLabel>
          <Select
           className={classes.listapago}
           labelId='bank'
           name='bank'
           id='bank'
           // key={mpago.id}
           value={Opcionespago.bank}
           label='Banco *'
           onChange={(e) => handleChange(e)}
          >
           {bancos?.map((banco) => {
            return (
             <MenuItem
              className={classes.listapago}
              key={banco.codigo}
              value={banco.nombre}
             >
              <em>{banco.nombre}</em>
             </MenuItem>
            )
           })}
          </Select>
         </FormControl>
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
         <InputLabel className={classes.listapago}>Monto</InputLabel>
         <TextField
          required
          className={classes.textinput}
          name='ConverActiva'
          size='small'
          fullWidth
          value={Opcionespago.ConverActiva}
          onChange={(e) => handleChange(e)}
          // onChange={(e) => handleChangeNumero(e)}
         />
        </Grid>
        <Grid item lg={6} sm={6} xl={12} xs={12}>
         <InputLabel className={classes.listapago}>Comprobante</InputLabel>
         <TextField
          required
          className={classes.textinput}
          name='nroComprobante'
          size='small'
          fullWidth
          InputProps={{
           inputProps: { maxlength: 15 },
          }}
          value={Opcionespago.nroComprobante}
          onChange={(e) => handleChange(e)}
         />
        </Grid>
       </>
      )}
     </Grid>
     {/* //botones de accion */}
     <BotonesAccionesDuo
      // disabled={
      //   Opcionespago.ConverActiva === '' ||
      //   Opcionespago.ConverActiva === '0' ||
      //   Number(MontoF) > Number(faltaPorPagar)
      //     ? true
      //     : false
      // }
      handleClose={handleClose}
      handleClickAccion={handleSumitpago}
      type='submit'
      handleClear={HandleClarPago}
      faltaPorPagar={faltaPorPagar}
     />
    </Box>
   </form>
  </ModalGenerico>
 )
}
export default AgregarPago
