import { useSelector } from 'react-redux'

import { Grid, Link } from '@material-ui/core'
import { downloadPDF, verificaNavegador } from '../../../Helpers/VericarRutas'
import useStyles from './Styles'

export default function VerLibroVentas() {
  const classes = useStyles()

  const { Reporte } = useSelector((state) => state.reporte)

  console.log(
    '🚀 ~ file: VerLibroVentas.js:18 ~ VerLibroVentas ~ reporteVenta:',
    Reporte?.libro?.reporteVenta?.length,
    verificaNavegador()
  )

  return (
    // <Grid container spacing={1}>
    <Grid
      item
      lg={12}
      sm={12}
      xl={12}
      xs={12}
      style={{ alignContent: 'center', alignItems: 'center' }}
    >
      {Reporte?.libro?.reporteVenta !== undefined &&
        Reporte?.libro?.consolidado > 0 &&
        verificaNavegador() !== 'Chrome' && (
          <iframe
            src={`data:application/pdf;base64,${Reporte?.libro?.reporteVenta}`}
            title='Libro de Ventas'
            style={{
              width: '100%',
              height: '50vh',
            }}
          />
        )}
      {verificaNavegador() === 'Chrome' &&
        Reporte?.libro?.consolidado > 0 &&
        Reporte?.libro?.reporteVenta?.length < 2097152 && (
          <iframe
            src={`data:application/pdf;base64,${Reporte?.libro?.reporteVenta}`}
            title='Libro de Ventas'
            style={{
              width: '100%',
              height: '50vh',
            }}
          />
        )}
      {verificaNavegador() === 'Chrome' &&
        Reporte?.libro?.consolidado > 0 &&
        Reporte?.libro?.reporteVenta?.length > 2097152 && (
          <button
            onClick={() => downloadPDF(Reporte?.libro?.reporteVenta)}
            className={classes.bbtnref}
          >
            Descargar libro de Venta en Pdf
          </button>
        )}
      {/* {Reporte?.libro?.reporteVenta === undefined && (
        <div>debe generar un reporte</div>
      )} */}
      {/* </Grid> */}
    </Grid>
  )
}
