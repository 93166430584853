import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core'
import PageviewIcon from '@material-ui/icons/Pageview'
import FileCopy from '@material-ui/icons/FileCopy'
import ClearAll from '@material-ui/icons/ClearAll'

import useStyles from './Styles'
import { Autocomplete } from '@material-ui/lab'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'
import {
  logsEmaiParams,
  startLoadReportLogEmail,
} from '../../../../redux/actions/reporteActions'
import { logsEmaiClear } from '../../../../redux/actions/reporteActions'
import {
  setLocalidadClearActive,
  setSucursalClearActive,
} from '../../../../redux/actions/facturaGeneralActions'
import { s2ab } from '../../../../Helpers/Search'

export default function BuscarLogsEmail() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState({
    buscar: true,
    Limpiar: true,
    descargar: true,
  })
  const logsEmailsTODO = useSelector((state) => state.logsemail)
  console.log(
    '🚀 ~ file: BuscarLogsEmail.js:23 ~ BuscarLogsEmail ~ logsEmailsTODO:',
    logsEmailsTODO
  )

  const {
    handleselectSucursal,
    HandleselectLocalidad,
    localidadActive,
    sucursalActive,
    localidades,
    sucursalesByLocalidad,
  } = useRazonSocial()

  const handleValueBusqueda = (e) => {
    const { name, value } = e.target
    // setEstadoReport({ ...estadoReport, [name]: value })
    dispatch(logsEmaiParams({ name, value }))
  }

  const HandleClearbuscar = () => {
    setLoading((prevState) => ({
      ...prevState,
      Limpiar: false,
    }))

    dispatch(logsEmaiClear())

    dispatch(setSucursalClearActive())
    dispatch(setLocalidadClearActive())

    setLoading((prevState) => ({
      ...prevState,
      Limpiar: true,
    }))
  }

  const handleClickBuscarEmail = (tipoReport) => {
    const valor = tipoReport === 1 ? 'buscar' : 'descargar'

    setLoading((prevState) => ({
      ...prevState,
      [valor]: false,
    }))

    dispatch(
      startLoadReportLogEmail({
        fechaInicio: logsEmailsTODO.fechaInicio,
        fechaFin: logsEmailsTODO.fechaFin,
        nroDoc: logsEmailsTODO.nroDoc,
        recibidoEmail: logsEmailsTODO.recibidoEmail,
        localidad: localidadActive?._id || '',
        sucursal: sucursalActive?.id || '',
        limit: logsEmailsTODO.limit,
        page: logsEmailsTODO.page,
        status: tipoReport,
      })
    ).then((res) => {
      console.log(
        '🚀 ~ file: GenerarLibroVentas.js:78 ~ ).then ~ res',
        res.payload
      )
      if (res.payload.tipo === 'excel')
        handleGenerarXls(res.payload.resultExcel)

      setLoading((prevState) => ({
        ...prevState,
        [valor]: true,
      }))
    })
  }

  const handleGenerarXls = (ReportesXls) => {
    console.log(
      '🚀 ~ file: BuscarLogsEmail.js:114 ~ handleGenerarXls ~ ReportesXls:',
      ReportesXls
    )
    if (ReportesXls) {
      var ab = s2ab(ReportesXls)
      const blob = new Blob([ab], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      })
      const url = URL.createObjectURL(blob) //some browser may use window.URL
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = url
      a.download = `LogsEmail_${new Date().getTime()}.xlsx`
      a.click()

      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }
  }

  const Verificador = [
    logsEmailsTODO.fechaInicio !== '',
    logsEmailsTODO.fechaFin !== '',
    localidadActive !== null,
    // sucursalActive === null,
  ].some((valor) => {
    return valor === false
  })

  return (
    <Card>
      <CardHeader title='Buscar logs Correos' className={classes.CardHeader} />
      <CardContent>
        <Grid container spacing={1} alignContent='center' alignItems='center'>
          <Grid
            item
            lg={sucursalesByLocalidad.length > 0 ? 2 : 4}
            sm={sucursalesByLocalidad.length > 0 ? 2 : 4}
            xl={12}
            xs={12}
          >
            <Autocomplete
              id='localidad'
              clearText='Limpiar'
              noOptionsText='No hay Localidades'
              options={localidades}
              getOptionLabel={(option) => option.nombre || ''}
              value={localidadActive}
              onChange={HandleselectLocalidad}
              className={classes.textField}
              // filterSelectedOptions
              // inputProps={{ style: { width: '100%' } }} // font size of input text
              renderOption={(option) => {
                return <h6 className={classes.texto}>{option.nombre}</h6>
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // className={classes.root}
                  variant='standard'
                  label='Razón Social'
                />
              )}
            />
          </Grid>

          {sucursalesByLocalidad.length > 0 && (
            <Grid item lg={2} sm={2} xl={12} xs={12}>
              <Autocomplete
                id='sucursal'
                // disableClearable
                noOptionsText='No hay Sucursales Asociadas'
                options={sucursalesByLocalidad}
                getOptionLabel={(option) => option.nombre || ''}
                value={sucursalActive}
                onChange={handleselectSucursal}
                filterSelectedOptions
                className={classes.textField}
                renderOption={(option) => {
                  return <h6 className={classes.texto}>{option.nombre}</h6>
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={classes.root}
                    variant='standard'
                    label='Sucursal'
                  />
                )}
              />
            </Grid>
          )}

          {/*  fechas  */}
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              name='fechaInicio'
              id='date'
              label='Desde'
              type='date'
              className={classes.textField}
              value={logsEmailsTODO.fechaInicio}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              onChange={(e) => handleValueBusqueda(e)}
              // inputProps={{ style: { fontSize: '12px' } }} // font size of input text
              InputLabelProps={{
                shrink: true,
                // style: { fontSize: '12px' },
              }}
            />
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              name='fechaFin'
              id='date'
              label='Hasta'
              type='date'
              onChange={(e) => handleValueBusqueda(e)}
              value={logsEmailsTODO.fechaFin}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              className={classes.textField}
              // inputProps={{ style: { fontSize: '12px' } }} // font size of input text
              InputLabelProps={{
                shrink: true,
                // style: { fontSize: '12px' },
              }}
            />
          </Grid>
          {/* nro documento */}
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              name='nroDoc'
              id='nroDoc'
              label='Nro. Factura/Nota'
              onChange={(e) => handleValueBusqueda(e)}
              value={logsEmailsTODO.nroDoc}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* email recibidos */}
          <Grid item lg={2} sm={2} xl={12} xs={12}>
            <TextField
              name='recibidoEmail'
              id='recibidoEmail'
              type='recibidoEmail'
              label='Correo entregado'
              onChange={(e) => handleValueBusqueda(e)}
              // defaultValue={new Date().toISOString().substr(0, 10)}
              value={logsEmailsTODO.recibidoEmail}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* Botom de busquedad */}
          <Grid item lg={2} sm={2} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // variant='outlined'
              onClick={() => handleClickBuscarEmail(1)}
              disabled={Verificador}
              fullWidth
              className={classes.btn}
            >
              <>
                {loading.buscar ? (
                  <PageviewIcon />
                ) : (
                  // <i className='far fa-save'></i>
                  <CircularProgress color='white' size={18} />
                )}

                <span style={{ marginLeft: '12px' }}>Buscar</span>
              </>
            </Button>
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // variant='outlined'
              onClick={() => HandleClearbuscar()}
              // disabled={Verificador}
              fullWidth
              className={classes.btnlimpiar}
            >
              <>
                {loading.Limpiar ? (
                  <ClearAll />
                ) : (
                  // <i className='far fa-save'></i>
                  <CircularProgress color='white' size={18} />
                )}

                <span style={{ marginLeft: '12px' }}>Limpiar</span>
              </>
            </Button>
          </Grid>
          <Grid item lg={2} sm={2} xl={12} xs={12} style={{}}>
            <Button
              size='large'
              // variant='outlined'
              onClick={() => handleClickBuscarEmail(2)}
              disabled={
                logsEmailsTODO?.logsEmails?.results?.length > 0 ? false : true
              }
              fullWidth
              className={classes.btnDescarga}
            >
              <>
                {loading.descargar ? (
                  <FileCopy />
                ) : (
                  // <i className='far-regular fa-download'></i>
                  // <i className='far fa-excel'></i>
                  <CircularProgress color='white' size={18} />
                )}

                <span style={{ marginLeft: '12px' }}>Descargar</span>
              </>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
