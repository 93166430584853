import {
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { MESES } from './constants'
import useStyles from '../Styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function SelecUnMes({ selectedMeses, handleChangeMeses }) {
  const classes = useStyles()
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id='meses-mutiple-chip-label'>Meses</InputLabel>
      <Select
        name='meses'
        labelId='meses-mutiple-chip-label'
        id='meses-mutiple-chip'
        // multiple
        value={selectedMeses}
        onChange={(e) => handleChangeMeses(e)}
        input={<Input id='select-multiple-chip' />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {/* {selected.map((value) => ( */}
            <Chip key={selected} label={selected} className={classes.chip} />
            {/* ))} */}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {MESES.map((mes) => (
          <MenuItem
            key={mes.value}
            value={mes.label}
            //  style={getStyles(name, personName, theme)}
          >
            <ListItemText primary={mes.label} />
            {/* {mes.label} */}
            <Checkbox checked={selectedMeses.indexOf(mes.label) > -1} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
