import { types } from '../types/types'

const initialState = {
  modulos: [],
  activeModulo: null,
  modalModuloOpen: false,
  modalModuloDelete: false,
  modalModuloShow: false,
}

export const moduloReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.moduloOpenModal:
      return {
        ...state,
        modalModuloOpen: true,
        modalModuloDelete: false,
        modalModuloShow: false,
      }

    case types.moduloCloseModal:
      return {
        ...state,
        modalModuloOpen: false,
      }

    case types.moduloOpenModalDelete:
      return {
        ...state,
        modalModuloOpen: false,
        modalModuloDelete: true,
        modalModuloShow: false,
      }

    case types.moduloCloseModalDelete:
      return {
        ...state,
        modalModuloDelete: false,
      }

    case types.moduloOpenModalShow:
      return {
        ...state,
        modalModuloShow: true,
        modalModuloOpen: false,
        modalModuloDelete: false,
      }

    case types.moduloCloseModalShow:
      return {
        ...state,
        modalModuloShow: false,
      }

    case types.moduloSetActive:
      return {
        ...state,
        activeModulo: action.payload,
        modalModuloOpen: true,
      }

    case types.moduloAddNew:
      return {
        ...state,
        modulos: [action.payload, ...state.modulos],
      }

    case types.moduloClearActive:
      return {
        ...state,
        activeModulo: null,
      }

    case types.moduloClear:
      return {
        ...state,
        modulos: [],
        activeModulo: null,
        modalModuloOpen: false,
        modalModuloDelete: false,
        modalModuloShow: false,
      }

    case types.moduloUpdated:
      return {
        ...state,
        modulos: state.modulos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      }

    case types.moduloLoaded:
      return {
        ...state,
        modulos: action.payload,
      }

    case types.moduloDelete:
      return {
        ...state,
        modulos: state.modulos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeModulo: null,
      }

    case types.moduloReactived:
      return {
        ...state,
        modulos: state.modulos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeModulo: null,
      }

    default:
      return state
  }
}
