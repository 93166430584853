import NumberFormat from 'react-number-format'
import {
 Grid,
 TextField,
 MenuItem,
 Select,
 InputLabel,
 FormControl,
 Button,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import useStyles from './Styles'
import { useRazonSocial } from '../../../hooks/useRazonSocial'
import {
 startFiltroOrdenesAdd,
 startLoadOrdenes,
} from '../../../redux/actions/ordenActions'
import { setLocalidadClearActive } from '../../../redux/actions/facturaGeneralActions'

const schema = yup.object().shape({
 localidad: yup.string(),
 tipoDocumento: yup.string(),
 fechaInicio: yup.string(),
 fechaFin: yup.string(),
 numeroDocumento: yup.string(),
 nroOrden: yup.string(),
})

export default function FiltrarOrden({ open, handleClose, title }) {
 const classes = useStyles()
 const dispatch = useDispatch()

 const {
  handleselectSucursal,
  HandleselectLocalidad,
  localidadActive,
  sucursalActive,
  localidades,
  sucursalesByLocalidad,
 } = useRazonSocial()

 const { handleSubmit, control } = useForm({
  resolver: yupResolver(schema),
 })

 const onSubmitLocalidad = async (data) => {
  let datos = {
   rif:
    `${localidadActive?.tipoDocumento}-${localidadActive?.numeroDocumento}` ||
    undefined,
   codigoSucursal: sucursalActive?.codigoSucursal || undefined,
   nroOrden: data.nroOrden || undefined,
   fechaInicio: data.fechaInicio || undefined,
   fechaFin: data.fechaFin || undefined,
  }

  dispatch(startFiltroOrdenesAdd({ limit: 10, page: 1, datos: datos }))

  dispatch(startLoadOrdenes({ limit: 10, page: 1 })).then((res) => {
   // if (res?.payload.tipoMsg === 'sucess') {
   dispatch(setLocalidadClearActive())
   handleClose()
   // }
  })
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form onSubmit={handleSubmit(onSubmitLocalidad)} autoComplete='off'>
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='localidad'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
         size='medium'
         id='localidad'
         clearText='Limpiar'
         noOptionsText='No hay Localidades'
         options={localidades}
         getOptionLabel={(option) => option.nombre || ''}
         // value={localidadActive}
         onChange={HandleselectLocalidad}
         // filterSelectedOptions
         renderOption={(option) => {
          return <h6 className={classes.texto}>{option.nombre}</h6>
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           // name='localidad'
           value={value}
           className={classes.root}
           variant='standard'
           label='Nombre ó Razón Social'
           // onChange={handleChange}
          />
         )}
        />
       )}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       name='localidad'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
         size='medium'
         id='sucursal'
         disableClearable
         clearText='Limpiar'
         noOptionsText='No hay Sucursales Asociadas'
         options={sucursalesByLocalidad}
         getOptionLabel={(option) => option.nombre || ''}
         onChange={handleselectSucursal}
         renderOption={(option) => {
          return <h6 className={classes.texto}>{option.nombre}</h6>
         }}
         renderInput={(params) => (
          <TextField
           {...params}
           className={classes.root}
           variant='standard'
           label='Sucursal'
          />
         )}
        />
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='fechaInicio'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         label='Desde'
         type='date'
         fullWidth
         // defaultValue={new Date().toISOString().substr(0, 10)}
         onChange={onChange}
         value={value}
         className={classes.textField}
         InputLabelProps={{
          shrink: true,
         }}
        />
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='fechaFin'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         label='Hasta'
         type='date'
         fullWidth
         onChange={onChange}
         value={value}
         className={classes.textField}
         InputLabelProps={{
          shrink: true,
         }}
        />
       )}
      />
     </Grid>

     {/* <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='tipoDocumento'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='uncontrolled-native'>
          Tipo Doc.
         </InputLabel>
         <Select
          defaultValue=''
          fullWidth
          className={classes.select}
          onChange={onChange}
          value={value}
          error={!!error}
         >
          {tipodoc.map((option) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={option.key}
             value={option.value}
            >
             {option.value}
            </MenuItem>
           )
          })}
         </Select>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='numeroDocumento'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <NumberFormat
         // format='#########'
         customInput={TextField}
         className={classes.text}
         label='Nro. Documento'
         // variant='outlined'
         defaultValue={0}
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      /> */}
     {/* </Grid> */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='nroOrden'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Numero de Orden/Guia'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     {/* <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='identificacion'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Cliente'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid> */}
    </Grid>
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       //  onClick={() => peticionPost()}
      >
       Filtrar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
