import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
// import useOrden from '../../../hooks/useOrden'
import { startLoadOrdenCodificada } from '../../../redux/actions/ordenActions'

export default function MostrarPdf({ open, handleClose, title, activeOrden }) {
 const [facturaCodificada, setfacturaCodificada] = useState(null)
 const dispatch = useDispatch()
//  const { activeOrden } = useOrden()
 console.log("🚀 ~ MostrarPdf ~ activeOrden:", activeOrden)

 useEffect(() => {
  dispatch(startLoadOrdenCodificada(activeOrden?.id)).then((res) => {
   setfacturaCodificada(res?.facturaCodificada)
  })
 }, [activeOrden, dispatch])

 return (
  <ModalGenerico
   open={open}
   handleClose={handleClose}
   title={
    activeOrden?.id_localidad === undefined
     ? `Cliente: ${activeOrden?.id_sucursal?.nombre}`
     : `Cliente: ${activeOrden?.id_localidad?.nombre}`
   }
   top={55}
   left={55}
  >
   <iframe
    src={`data:application/pdf;base64,${facturaCodificada}`}
    title='Factura'
    name='Factura'
    style={{
     border: 'none',
     minHeight: '80vh',
     minWidth: '80vh',
    }}
   />
  </ModalGenerico>
 )
}
