import { types } from "../types/types";

const initialState = {
  bancos: [],
  activeBanco: null,
  modalBancoOpen: false,
  modalBancoDelete: false,
};

export const bancoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.bancoOpenModal:
      return {
        ...state,
        modalBancoOpen: true,
        modalBancoDelete: false,
      };

    case types.bancoCloseModal:
      return {
        ...state,
        modalBancoOpen: false,
      };

    case types.bancoOpenModalDelete:
      return {
        ...state,
        modalBancoOpen: false,
        modalBancoDelete: true,
      };

    case types.bancoCloseModalDelete:
      return {
        ...state,
        modalBancoDelete: false,
      };

    case types.bancoSetActive:
      return {
        ...state,
        activeBanco: action.payload,
        modalBancoOpen: true,
      };

    case types.bancoAddNew:
      return {
        ...state,
        bancos: [action.payload, ...state.bancos],
      };

    case types.bancoClearActive:
      return {
        ...state,
        activeBanco: null,
      };

    case types.bancoUpdated:
      return {
        ...state,
        bancos: state.bancos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
      };

    case types.bancoLoaded:
      return {
        ...state,
        bancos: action.payload,
      };

    case types.bancoDelete:
      return {
        ...state,
        bancos: state.bancos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeBanco: null,
      };

    case types.bancoReactived:
      return {
        ...state,
        bancos: state.bancos.map((b) =>
          b.id === action.payload.id ? action.payload : b
        ),
        activeBanco: null,
      };

    default:
      return state;
  }
};
