import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DescuentoModal } from './DescuentoModal';

import MaterialTable from 'material-table';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';

import { descuentoOpenModal, descuentoOpenModalDelete, descuentoSetActive, startLoadDescuentos, startReactivedDescuento } from '../../../redux/actions/descuentoActions';
import { DescuentoModalDelete } from './DescuentoModalDelete';


const Descuento = () => {

    const dispatch = useDispatch();

    const { descuentos, modalDescuentoDelete } = useSelector( state => state.descuento );

    const [show, setShow] = useState(false);
    
    const columnas = [
        {
            title: 'ID',
            field: 'id',
            type: 'numeric',
            cellStyle: {
                width: 20,
                maxWidth: 20
            },
            defaultSort: 'asc'
        },
        {
            title: 'Título',
            field: 'titulo'
        },
        {
            title: 'Descripción',
            field: 'descripcion'
        },
        {
            title: 'Sucursal',
            field: 'sucursal.direccion'
        }, 
        {
            title: 'Monto',
            field: 'monto'
        },
        {
            title: 'Tipo Monto',
            field: 'tipoMonto'
        }, 
    ];

    useEffect(() => {
       
        dispatch( startLoadDescuentos() );

    }, [dispatch]);

    const onSelectDescuento = (e) => {
        dispatch(descuentoSetActive(e));
        setShow(!show);
    }

    const onUpdateDescuento = (e) => {
        dispatch(descuentoSetActive(e));
        dispatch(descuentoOpenModal());
    }

    const handleDelete = (e) => {
        dispatch(descuentoSetActive(e));
        dispatch(descuentoOpenModalDelete());
    }

    const handleReactive = (e) => {
        dispatch( startReactivedDescuento(e) );
    }

    const openModal = () => {
        dispatch(descuentoOpenModal());
    }

    return (

        <div>
            <br />
            <DescuentoModal />
            { (modalDescuentoDelete) && <DescuentoModalDelete /> }
            <MaterialTable
                title="Descuentos"
                columns={columnas}
                data={descuentos}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Nuevo Descuento',
                        isFreeAction: true,
                        onClick: (event) => { openModal() }
                    },
                    {
                        icon: 'search',
                        tooltip: 'Consultar',
                        onClick: (event, rowData) => onSelectDescuento(rowData)
                    },
                    rowData => ({
                        icon: 'edit',
                        tooltip: 'Modificar',
                        onClick: (event, rowData) => onUpdateDescuento(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <PowerSettingsNewIcon />,
                        tooltip: 'Inactivar',
                        onClick: (event, rowData) => handleDelete(rowData),
                        hidden: rowData.logEstatusRegistro ==="INACTIVO"
                    }),
                    rowData => ({
                        icon: () => <SettingsPowerIcon />,
                        tooltip: 'Activar',
                        onClick: (event, rowData) => handleReactive(rowData),
                        hidden: rowData.logEstatusRegistro ==="ACTIVO",
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    headerStyle: {
                        backgroundColor: '#085c9f',
                        color: '#FFF'
                    },
                    exportButton: true,
                    paginationType: 'stepped',
                    rowStyle: rowData => {
                        if(rowData.logEstatusRegistro === "INACTIVO") {
                          return {backgroundColor: '#F3EFEE'};
                        }
                        
                        return {};
                      }
                }}
                localization={{
                    header: {
                        actions: 'Acciones'
                    },
                    toolbar: {
                        searchPlaceholder: 'Buscar',
                        searchTooltip: 'Buscar',
                        exportTitle: "Exportar",
                        exportCSVName: "Exportar en formato excel",
                        exportPDFName: "Exportar como pdf"
                    },
                    pagination: {
                        firstAriaLabel: 'Primera página',
                        firstTooltip: 'Primera página',
                        previousAriaLabel: 'Página anterior',
                        previousTooltip: 'Página anterior',
                        nextAriaLabel: 'Página siguiente',
                        nextTooltip: 'Página siguiente',
                        lastAriaLabel: 'Última página',
                        lastTooltip: 'Última página',
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    body: {
                        emptyDataSourceMessage: 'No existen datos para mostrar',
                    }
                }}

            />
            
        </div>
    )
}

export default Descuento;