import ModalGenerico from '../../../commons/ui/Modal'
import ViewJson from './ViewJson'
import ViewPdfSeniat from './ViewPdfSeniat'
import { useStyles } from './Styles'

export default function ConsultaSeniat({ open, handleClose, title }) {
  const classes = useStyles()
  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      top={60}
      left={60}
    >
      <div className={classes.ViewGenerico}>
        <ViewPdfSeniat />
        <ViewJson />
      </div>
    </ModalGenerico>
  )
}
