import React from 'react'
import { useDispatch } from 'react-redux'

import {
 Grid,
 TextField,
 Card,
 CardHeader,
 CardContent,
} from '@material-ui/core'

import { useStyles } from '../../General/CrearFactura/Styles'
import useOrden from '../../../../hooks/useOrden'
import { setUpdateOrden } from '../../../../redux/actions/ordenActions'

export default function OrdenServicio() {
 const dispatch = useDispatch()
 const classes = useStyles()
 const { motivo, direccionEntrega, observacion } = useOrden()

 const handleChange = (e) => {
  let { name, value } = e.target
  dispatch(setUpdateOrden({ name, value }))
  // dispatch(startLoadCanbioDivisa())
 }

 return (
  <Card
   style={{
    height: 247,
    boxShadow:
     '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
   }}
  >
   <CardHeader
    title='Datos para envio de Orden/Guia'
    className={classes.CardHeader}
   />
   <CardContent>
    <Grid container spacing={1}>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <TextField
       required
       label='Motivo'
       className={classes.texto}
       name='motivo'
       size='small'
       fullWidth
       value={motivo}
       InputLabelProps={{
        shrink: true,
       }}
       // value={nroFactura}
       onChange={(e) => handleChange(e)}
      />
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <TextField
       required
       name='observacion'
       id='observacion'
       label='Observaciones'
       fullWidth
       value={observacion}
       // defaultValue={new Date().toISOString().substr(0, 10)}
       onChange={(e) => handleChange(e)}
       className={classes.textField}
       InputLabelProps={{
        shrink: true,
       }}
      />
     </Grid>
     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <TextField
       required
       name='direccionEntrega'
       id='direccionEntrega'
       label='Direccion Entrega'
       fullWidth
       value={direccionEntrega}
       // defaultValue={new Date().toISOString().substr(0, 10)}
       onChange={(e) => handleChange(e)}
       className={classes.textField}
       InputLabelProps={{
        shrink: true,
       }}
      />
     </Grid>
    </Grid>
   </CardContent>
  </Card>
 )
}
