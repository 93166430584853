import axios from 'axios'
import {
 ApiAxiosGet,
 ApiAxiosPost,
 ApiAxiosPostFetch,
} from '../../Helpers/ApiAxios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import {
 errorClearActive,
 errorSetActive,
 errorStatusLoading,
} from './errorActions'

const url = `${config.urlServer}/facturas/nota`

export const startLoadNotas = () => {
 return async (dispatch) => {
  try {
   const res = await ApiAxiosGet(`${url}/querynotas`, 'GET')
   console.log('res', res)
   dispatch(notaLoaded(res))
   dispatch(errorClearActive())
  } catch (error) {
   dispatch(
    errorSetActive(error?.response?.data.message || 'ocurrio un error de Red')
   )
  }
 }
}

//Acción para Generar PDF de una Factura General
export const startGenerarNota = (notaActiva) => {
 return async (dispatch, getState) => {
  const { activeNota } = getState().nota
  const id = activeNota.id

  try {
   dispatch(errorStatusLoading('SoloLoading'))
   const res = await ApiAxiosGet(`${url}/generarnotas/${id}`, 'PUT')
   console.log('resPUESTA GENERAR', res)
   console.log('id', id)
   dispatch(NotaGeneradaUpdate(id, res))
   dispatch(activeClearNota())
   dispatch(errorSetActive('Nota Generada con exito', 'sucess'))
   // dispatch(errorClearActive())
  } catch (error) {
   // const msgError = error.response?.data
   console.log(error.response)
   // Swal.fire('Error', msgError, 'error')
   dispatch(
    errorSetActive(
     error.response?.data.message || 'Ocurrio un error al Generar Factura',
     'error'
    )
   )
  }
 }
}

export const startLoadFiltroNotas = (data) => {
 return async (dispatch) => {
  dispatch(errorStatusLoading('pagina'))
  try {
   const res = await ApiAxiosPost(`${url}/FiltrarNotas/`, 'POST', data)
   console.log('reswwwwwwWWWWWW', res)
   // dispatch(errorClearActive())
   dispatch(NotaGeneraLoaded(res))
   return dispatch(errorSetActive('Busqueda Ejecutada', 'sucess'))
  } catch (error) {
   console.log('ERRORRROR', error.response)
   return dispatch(
    errorSetActive(
     error?.response?.data?.message || 'ocurrido un error de red',
     'error'
    )
   )
   // dispatch(errorStatusLoading())
  }
 }
}

export const NotaGeneradaUpdate = (idNota, NotaCodficada64) => ({
 type: types.NotaGeneradaUpdate,
 payload: { idNota, NotaCodficada64 },
})

const notaLoaded = (notas) => ({
 type: types.notaLoaded,
 payload: notas,
})

export const startSaveNota = (nota) => {
 console.log('notica:', nota)
 return async (dispatch) => {
  try {
   const res = await ApiAxiosPost(`${url}/addnota`, 'POST', nota)
   console.log('res', res)
   dispatch(notaAddNew(res))
   return dispatch(errorSetActive('Nota registrada con exito', 'sucess'))
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(error?.response?.data.message || 'ocurrio un error de Red')
   )
  }
 }
}

export const startAnularNota = () => {
 return async (dispatch, getState) => {
  const { activeNota } = getState().nota
  try {
   const res = await ApiAxiosPost(`${url}/anular/${activeNota.id}`, 'PUT')
   console.log('res ANULAR NOTA', res)
   dispatch(anularNota(res))
   return dispatch(errorSetActive('Nota Anula con exito', 'sucess'))
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(error?.response?.data.message || 'ocurrio un error de Red')
   )
  }
 }
}

export const startCargaNotaJson = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(`${url}/masivanotas`, 'POST', json)
   console.log('RRRRRRRR', res)
   // dispatch(localidadCargaFacturasJsonSetActive(res.result))
   if (res.code === 200) {
    return dispatch(
     errorSetActive('Notas Cargadas y Procesadas', 'sucess', res)
    )
   } else {
    return dispatch(
     errorSetActive(`Error al cargar Notas:${res?.message} `, 'error')
    )
   }
  } catch (error) {
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startCargaNotaJsonXls = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(`${url}/masivanotasXls`, 'POST', json)
   console.log('RRRRRRRR', res)
   // dispatch(localidadCargaFacturasJsonSetActive(res.result))
   if (res.code === 200) {
    return dispatch(
     errorSetActive('Notas Cargadas y Procesadas', 'sucess', res)
    )
   } else {
    return dispatch(
     errorSetActive(`Error al cargar Notas:${res?.message} `, 'error')
    )
   }
  } catch (error) {
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const startCargaAnularNotaJsonXls = (json) => {
 return async (dispatch) => {
  //Llamado al endpoint usando Axios
  console.log('ENTRO OOjson', json)
  try {
   dispatch(errorStatusLoading())
   const res = await ApiAxiosPostFetch(`${url}/masivaanularnotas`, 'PUT', json)
   console.log('🚀 ~ file: notaActions.js:189 ~ return ~ res', res)
   if (res.result.code === 200) {
    // dispatch(localidadCargaFacturasJsonSetActive(res.result))
    return dispatch(
     errorSetActive('Archivo Cargado con Exito', 'sucess', res.result)
    )
   } else {
    return dispatch(
     errorSetActive(
      `Error al Cargar el Archivo:  ${res?.message}`,
      'error',
      res?.message
     )
    )
   }
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(
     error?.response?.data.message || `Ocurrio un error ${error}`,
     'error'
    )
   )
  }
 }
}

export const notaAddNew = (nota) => ({
 type: types.notaAddNew,
 payload: nota,
})

export const startDeleteNota = () => {
 return async (dispatch, getState) => {
  const { activeNota } = getState().nota

  try {
   const res = await ApiAxiosPost(`${url}/eliminar/${activeNota.id}`, 'PUT')
   console.log('res ELIMINAR NOTA', res)
   dispatch(deleteNota(res.id))
   return dispatch(errorSetActive('Nota Eliminada con exito', 'sucess'))
  } catch (error) {
   console.log('error', error)
   return dispatch(
    errorSetActive(error?.response?.data.message || 'ocurrio un error de Red')
   )
  }
 }
}

export const startReactivedNota = (nota) => {
 return async (dispatch, getState) => {
  //Llamado al endpoint usando Axios
  axios({
   method: 'PUT',
   url: `${url}/activar/${nota.id}`,
  })
   .then((response) => {
    Swal.fire('Activado', nota.nombre, 'success')
    dispatch(reactiveNota(response.data))
   })
   .catch((error) => {
    const msgError = error
    console.log(error)
    Swal.fire('Error', msgError, 'error')
   })
 }
}

export const startUpdateNota = (nota) => {
 return (dispatch, getState) => {
  const { activeNota } = getState().nota
  const id = activeNota.id

  //Llamado al endpoint usando Axios
  axios({
   method: 'PUT',
   url: `${url}/editar/${id}`,
   data: nota,
  })
   .then((response) => {
    Swal.fire('Actualizado', response.data.nombre, 'success')
    dispatch(notaUpdated(response.data))
   })
   .catch((error) => {
    const msgError = error.response.data.message
    console.log(error.response.data.message)
    Swal.fire('Error', msgError, 'error')
   })
 }
}

export const StartAjustelstDetalleNotaGravamen = () => {
 return (dispatch, getState) => {
  const { lstDetallesNota, lstGravamenes } = getState().nota

  console.log('lstdetallenota MODIFICADA ANTEs', lstDetallesNota)

  const lstdetallenota = lstDetallesNota.filter(
   (detalle) => detalle.isDevolucion === true
  )
  console.log('lstdetallenota MODIFICADA', lstdetallenota)

  dispatch(notaDetalleajustado(lstdetallenota))
  dispatch(updateDetalleNotaConexpcionPrecio(lstdetallenota))

  // const lstgravamen = lstGravamenes.filter(
  //   (gravamen) => gravamen.id === nota.id
  // )

  // const lstDetalleNota = lstDetallesNota.map((detalle) => {
  //   const gravamen = lstGravamenes.find(
  //     (gravamen) => gravamen.id === detalle.idGravamen
  //   )
  //   return {
  //     ...detalle,
  //     gravamen,
  //   }
  // })
 }
}

export const notaDetalleajustado = (detalle) => ({
 type: types.notaDetalleajustado,
 payload: detalle,
})

export const notaOpenModal = () => ({
 type: types.notaOpenModal,
})

export const notaCloseModal = () => ({
 type: types.notaCloseModal,
})

export const notaOpenModalDelete = () => ({
 type: types.notaOpenModalDelete,
})

export const notaCloseModalDelete = () => ({
 type: types.notaCloseModalDelete,
})

export const notaSetActive = (nota) => ({
 type: types.notaSetActive,
 payload: nota,
})

export const notaClearActive = () => ({
 type: types.notaClearActive,
})
export const activeClearNota = () => ({
 type: types.activeClearNota,
})

export const notaUpdated = (nota) => ({
 type: types.notaUpdated,
 payload: nota,
})

export const deleteNota = (nota) => ({
 type: types.notaDelete,
 payload: nota,
})

export const anularNota = (nota) => ({
 type: types.notaAnulada,
 payload: nota,
})

export const reactiveNota = (nota) => ({
 type: types.notaReactived,
 payload: nota,
})
export const notaDatosAdd = (nota) => ({
 type: types.notaDatosAdd,
 payload: nota,
})

export const notaCargaDetalles = () => {
 return (dispatch, getState) => {
  const { activeFacturaGeneral } = getState().facturaGeneral
  dispatch(cargadetalleNota(activeFacturaGeneral))
 }
}
const cargadetalleNota = (facturaActiva) => ({
 type: types.cargadetalleNota,
 payload: facturaActiva,
})

//Acciones para totales
const notaUpdateSubTotal = (operacion, monto) => ({
 type: types.notaUpdateSubTotal,
 payload: { operacion, monto },
})

// Agregar y validar el Porducto  al detalle de la nota Nota
export const startAddProductDetalleNota = (producto, cantidad) => {
 console.log('roductoproductoproductoproducto>>>>>>', cantidad)

 return (dispatch, getState) => {
  const { lstGravamenes, lstDetallesNota } = getState().nota

  const valor = cantidad !== 0 ? cantidad : parseFloat(producto.cantidad)

  const baseImponible =
   (parseFloat(producto.precio) - parseFloat(producto.descuento)) *
   parseInt(valor)

  // console.log('valorGravamenActivovalorGravamenActivo', producto)
  // console.log('baseImponible', baseImponible)

  if (producto.exento || producto.exonerado) {
   // console.log('producto.exento', producto.exento)
   // console.log('producto.exonerado', producto.exonerado)
   dispatch(
    updateDetalleNotaConexpcion({
     codigoProducto: producto.codigo, //  _id,
     importe: baseImponible,
     cantidadDevolucion: parseInt(cantidad),
     descuento: producto.descuento,
     montoDescuento: 0.0,
     isDevolucion: cantidad > 0 ? true : false,
    })
   )
  } else {
   dispatch(
    updateDetalleNotaConInpusto({
     codigoProducto: producto.codigo, //_id,
     importe: baseImponible,
     cantidadDevolucion: parseInt(cantidad),
     descuento: producto.descuento,
     montoDescuento: 0.0,
     montoGravamen: (baseImponible * producto.alicuotaGravamen) / 100,
     isDevolucion: cantidad > 0 ? true : false,
    })
   )
  }

  // Acción para llenar la lista de Gravámenes si el Producto no está Exento o Exonerado
  if (!producto.exento && !producto.exonerado) {
   dispatch(notaTotales(producto))
  }
  // totales Geenerales de la nota. modificado: 30/03/2022
  dispatch(notaUpdateSubTotal('resta', baseImponible))
 }
}

//Acciones para Detalle Notas
const notaTotales = (producto) => ({
 type: types.notaTotales,
 payload: producto,
})

export const notaAddNewDetalle = (detalle) => ({
 type: types.notaAddNewDetalle,
 payload: detalle,
})

const updateDetalleNotaConexpcion = (detalleProducto) => ({
 type: types.updateDetalleNotaConexpcion,
 payload: detalleProducto,
})
const updateDetalleNotaConInpusto = (detalleProducto) => ({
 type: types.updateDetalleNotaConInpusto,
 payload: detalleProducto,
})
const updateDetalleNotaConexpcionPrecio = (detalleProducto) => ({
 type: types.updateDetalleNotaConexpcionPrecio,
 payload: detalleProducto,
})
const updateDetalleNotaConInpustoPrecio = (detalleProducto) => ({
 type: types.updateDetalleNotaConInpustoPrecio,
 payload: detalleProducto,
})

//Acciones para Gravamen
export const notaAddNewLstGravamen = (gravamen) => ({
 type: types.notaAddNewLstGravamen,
 payload: gravamen,
})

const NotaGeneraLoaded = (notaGenerales) => ({
 type: types.NotaGeneraLoaded,
 payload: notaGenerales,
})

// Agregar y validar el Producto al detalle de l de debito Nota
export const startAddProductDetalleNotaPrecio = (producto, precio) => {
 console.log('precio>>>>>>', precio)
 console.log('Producto>>>>>>', producto)

 return (dispatch, getState) => {
  // const { lstGravamenes, lstDetallesNota } = getState().nota

  const valor = precio !== 0 ? precio : parseFloat(producto.precio)

  const baseImponible =
   (parseFloat(producto.precio) - parseFloat(producto.descuento)) *
   (parseInt(producto.precio) - parseInt(valor))

  console.log('valorGravamenActivovalorGravamenActivo', producto)
  console.log('baseImponible', baseImponible)

  if (producto.exento || producto.exonerado) {
   console.log('producto.exento', producto.exento)
   console.log('producto.exonerado', producto.exonerado)
   dispatch(
    updateDetalleNotaConexpcionPrecio({
     codigoProducto: producto._id,
     importe: precio * producto.cantidad,
     precioDevolucion: parseInt(precio),
     descuento: producto.descuento,
     montoDescuento: 0.0,
     isDevolucion: precio > 0 ? true : false,
    })
   )
  } else {
   dispatch(
    updateDetalleNotaConInpustoPrecio({
     codigoProducto: producto._id,
     importe: precio * producto.cantidad,
     precioDevolucion: parseInt(precio),
     descuento: producto.descuento,
     montoDescuento: 0.0,
     montoGravamen: (precio * producto.alicuotaGravamen) / 100,
     isDevolucion: precio > 0 ? true : false,
    })
   )
  }

  // Acción para llenar la lista de Gravámenes si el Producto no está Exento o Exonerado
  if (!producto.exento && !producto.exonerado) {
   dispatch(notaTotales(producto))
  }
  // totales Geenerales de la nota. modificado: 30/03/2022
  dispatch(notaUpdateSubTotal())
 }
}
