import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  CardHeader,
  Card,
  CardContent,
  Grid,
  IconButton,
} from '@material-ui/core'
import SaveAltTwoTone from '@material-ui/icons/SaveSharp'
// import SaveAltTwoTone from '@material-ui/icons/SaveSharp'

import AgregarProducto from '../../facturacion/General/CrearFactura/AgregarProducto'

import AssignmentRounded from '@material-ui/icons/AssignmentRounded'

import useStyles from './Styles'
import NumberFormat from 'react-number-format'
import { FormatedBolivares } from '../../../Helpers/formater'
import { useEffect, useState } from 'react'
import {
  notaCargaDetalles,
  startAddProductDetalleNota,
  startAddProductDetalleNotaPrecio,
  StartAjustelstDetalleNotaGravamen,
  startSaveNota,
} from '../../../redux/actions/notaActions'
// import { format } from 'date-fns'
import { useToast } from '../../../hooks/useToast'
import classNames from 'classnames'

const DetalleNota = () => {
  const [precioCambio, setprecioCambio] = useState()
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { notifyAliatorio } = useToast()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)
  const {
    lstDetallesNota,
    id_sucursal,
    id_localidad,
    id_factura,
    descripcion,
    nroNota,
    nroFactura,
    // exento,
    // exonerado,
    tipo,
    categoria,
    concepto,
    // importeTotal,
    // subTotal,
    montoDescuento,
    totalExento,
    totalExonerado,
    fechaEmision,
    tasaCambio,
    facturaDivisa,
    lstGravamenes,
  } = useSelector((state) => state.nota)
  const [open, setOpen] = useState(false)

  console.log('id_facturaid_facturaid_factura', id_factura)

  useEffect(() => {
    dispatch(notaCargaDetalles())
  }, [activeFacturaGeneral, dispatch])

  // const handleDelete = (row) => {
  //   dispatch(
  //     startAddProductDetalleNota(row, row.cantidad !== 0 ? row.cantidad : 0)
  //   )
  // }

  const handleClose = () => {
    setOpen(false)
  }

  const handledevolucionProducto = async (e, row) => {
    const { name, value } = e.target
    console.log('TARGET', name, value, row)
    // console.log('handledevolucionProducto', row)

    switch (name) {
      case 'cantidad_dev':
        if (value > row.cantidad) {
          notifyAliatorio(
            'La cantidad no puede ser mayor a la cantidad de la factura'
          )
        } else {
          // console.log('RORORROROROR', row)
          dispatch(startAddProductDetalleNota(row, value === '' ? 0 : value))
        }
        break
      case 'precio_dev':
        dispatch(
          startAddProductDetalleNotaPrecio(row, value === '' ? 0 : value)
        )
        setprecioCambio(value)
        break
      default:
        break
    }
  }

  // console.log('NTTOTOTOTOTQQQQQ',{ lstDetallesNota })

  // console.log('TIPO', tipo)

  const ActulizarGravamen = async () => {
    // dispatch(StartAjustelstDetalleNotaGravamen())
    const lstdetallenota = lstDetallesNota.filter(
      (detalle) => detalle.isDevolucion === true
    )

    if (lstDetallesNota.length === 0) {
      throw new Error('No hay productos selecionados paara la nota')
    }

    const lstgravamenesPrev = await lstdetallenota.reduce((acc, item) => {
      console.log('item', item)
      if (!item.exonerado && !item.exento) {
        acc.push({
          baseImponible: item.importe,
          alicuota: item.alicuotaGravamen,
          montoAlicuota: (item.importe * item.alicuotaGravamen) / 100,
          tipo: item.idgravamen,
        })
      }
      return acc
    }, [])

    const ExentoEsonerado = lstdetallenota.reduce(
      (acc, current) => {
        if (current.exento) {
          acc.exento += current.importe
        }
        if (current.exonerado) {
          acc.exonerado += current.importe
        }
        return acc
      },
      { exento: 0, exonerado: 0 }
    )

    let AcumuGragamen = []
    // acumular gramane por alicuota

    lstgravamenesPrev.reduce(function (res, value) {
      if (!value.exonerado && !value.exento) {
        if (!res[value.alicuota]) {
          res[value.alicuota] = {
            alicuota: value.alicuota,
            baseImponible: 0,
            montoAlicuota: 0,
            tipo: value.tipo,
          }

          AcumuGragamen.push(res[value.alicuota])
        }

        res[value.alicuota].montoAlicuota += value.montoAlicuota
        res[value.alicuota].baseImponible += value.baseImponible
      }
      return res
    }, [])

    const subtotal = lstdetallenota
      .map((item) => item.importe)
      .reduce((acumulador, current) => acumulador + current, 0)

    const totalGeneral =
      subtotal +
      AcumuGragamen.reduce((acc, item) => acc + item.montoAlicuota, 0)

    console.log(
      'PEDRO GRAVAMEN lstgravamenesPrev',
      lstgravamenesPrev,
      AcumuGragamen
    )
    console.log('PEDRO GRAVAMEN AcumuGragamen', AcumuGragamen)

    return {
      lstdetallenota,
      // lstgravamenes,
      ExentoEsonerado,
      totalGeneral,
      subtotal,
      AcumuGragamen,
      lstgravamenesPrev,
      lstGravamenes,
    }
  }

  const handleAddNota = async () => {
    console.log('ENTRO A ADDNTOA')

    ActulizarGravamen()
      .then((res) => {
        console.log('RESULTADO', res)
        dispatch(
          startSaveNota({
            id_sucursal: id_sucursal !== null ? id_sucursal : undefined,
            id_localidad: id_localidad !== null ? id_localidad : undefined,
            id_factura,
            nroFactura,
            tasaCambio, 
            descripcion,
            nroNota,
            exento: res.ExentoEsonerado.exento,
            exonerado: res.ExentoEsonerado.exonerado,
            tipo,
            categoria,
            concepto,
            importeTotal: res.totalGeneral,
            subTotal: res.subtotal,
            montoDescuento,
            totalExento,
            totalExonerado,
            fechaEmision: new Date().toISOString(),
            // fechaEmision,
            facturaDivisa,

            lstDetallesNota: res.lstdetallenota,
            lstGravamenes: res.AcumuGragamen, // res.lstgravamenes,
          })
        ).then((res) => {
          console.log('RESULTADO', res)
          if (res.payload.tipoMsg === 'sucess') {
            navigate('/facturacion/generales/notas', { replace: true })
          }
        })
      })
      .catch((err) => {
        console.log('ERRWWWWWWWW', err)
        console.log('ERR', Object.entries(err))
        notifyAliatorio('Error: No hay productos selecionados paara la nota')
      })
  }

  const handlePrecioDevolucion = (e, row) => {
    const { name, value } = e.target
    // validar que el precio no sea mayor al precio de la factura y boorar el value
    if (value < row.precio) {
      setprecioCambio('')
      notifyAliatorio('El precio no puede ser Menor al precio de la factura')
    }
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title='Detalle de la Factura'
        style={{ backgroundColor: 'orange', color: 'black' }}
        className={classes.cardheader}
        action={
          <IconButton
            // disabled={precoMayor}
            // onClick={handleOpenModal}
            onClick={handleAddNota}
            // disabled={clienteSelected}
            aria-label='addCliente'
          >
            <SaveAltTwoTone htmlColor='white' titleAccess='Guadar Nota' />
          </IconButton>
        }
      />
      <CardContent
        className={classes.cardcontent}
        style={{ maxHeight: '230px' }}
      >
        <Grid container spacing={0}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <table sx={{ minWidth: 700 }} aria-label='customized table'>
              <tr className={classes.encabezado}>
                <td style={{ width: '5%', textAlign: 'center' }}>Cant.</td>
                <td style={{ width: '25%', textAlign: 'left' }}>Concepto</td>
                {tipo === 'Credito' && (
                  <td style={{ width: '15%', textAlign: 'center' }}>
                    Cantidad Devolución
                  </td>
                )}
                <td style={{ width: '10%', textAlign: 'center' }}>Precio</td>
                {tipo === 'Debito' && (
                  <td style={{ width: '25%', textAlign: 'center' }}>
                    Precio Nuevo
                  </td>
                )}
                <td style={{ width: '10%', textAlign: 'center' }}>Desc.</td>
                <td style={{ width: '5%', textAlign: 'center' }}>Alicuota</td>
                <td
                  style={{
                    width: '10%',
                    textAlign: 'right',
                    paddingRight: '1rem',
                  }}
                >
                  Monto Alic.
                </td>
                <td style={{ width: '10%', textAlign: 'right' }}>Importe</td>
                <td style={{ width: '15%' }}> </td>
              </tr>
              <tbody className={classes.detalle}>
                {lstDetallesNota.map((row, i) => (
                  <tr
                    key={row._id}
                    className={classNames(classes.textdetalleActivo, {
                      // [classes.textdetalleinactivo]:
                      //   row.cantidadDevolucion !== '',
                      // [classes.textdetallprecio]: row.precioDevolucion !== 0,
                    })}
                  >
                    <td style={{ width: '5%', textAlign: 'center' }}>
                      {parseInt(row.cantidad)}
                      {/* {tipo === 'Credito'
                         ? parseInt(row.cantidad) -
                           parseInt(row.cantidadDevolucion)
                         : parseInt(row.cantidad)} */}
                    </td>
                    <td style={{ width: '25%', textAlign: 'left' }}>
                      {row.descripcion}
                    </td>
                    {tipo === 'Credito' && (
                      <td style={{ width: '15%', textAlign: 'center' }}>
                        <NumberFormat
                          disabled={row.cantidad === 0}
                          name='cantidad_dev'
                          size='small'
                          fullWidth
                          style={{ width: '50%', height: '50%' }}
                          onChange={(e) => handledevolucionProducto(e, row)}
                        />
                      </td>
                    )}
                    <td style={{ width: '10%' }}>
                      {FormatedBolivares(
                        row.precio,
                        'es-US',
                        activeFacturaGeneral?.facturaDivisa
                      )}
                    </td>
                    {tipo === 'Debito' && (
                      <td style={{ width: '25%', textAlign: 'center' }}>
                        <NumberFormat
                          disabled={row.cantidad === 0}
                          name='precio_dev'
                          size='small'
                          fullWidth
                          // value={precioCambio}
                          style={{ width: '50%', height: '50%' }}
                          onChange={(e) => handledevolucionProducto(e, row)}
                          onBlur={(e) => handlePrecioDevolucion(e, row)}
                        />
                      </td>
                    )}

                    <td style={{ width: '10%', textAlign: 'center' }}>
                      {`${Number(row.descuento).toFixed(2)} %`}
                    </td>
                    <td style={{ width: '5%', textAlign: 'center' }}>
                      {`${Number(row.alicuotaGravamen).toFixed(2)} %`}
                    </td>
                    <td style={{ width: '15%', paddingRight: '1rem' }}>
                      {FormatedBolivares(
                        row.montoGravamen,
                        'es-US',
                        activeFacturaGeneral?.facturaDivisa
                      )}
                    </td>
                    <td style={{ width: '20%' }}>
                      {FormatedBolivares(
                        row.importe,
                        'es-US',
                        activeFacturaGeneral?.facturaDivisa
                      )}
                    </td>
                    {/* {tipo === 'Credito' && (
                      <td style={{ width: '5%' }}>
                        <IconButton
                          size='small'
                          // disabled={row.cantidad === 0 ? true : false}
                          onClick={() => handleDelete(row)}
                        >
                          <AssignmentRounded
                            color='primary'
                            fontSize='small'
                            viewBox='0 0 24 24'
                            titleAccess='Devolución total del producto'
                          />
                        </IconButton>
                      </td>
                    )} */}
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </CardContent>
      {open && (
        <AgregarProducto
          open={open}
          handleClose={handleClose}
          notaIdLocalidad={
            activeFacturaGeneral.id_localidad === undefined
              ? activeFacturaGeneral.id_sucursal.id_localidad
              : activeFacturaGeneral.id_localidad.id
          }
          title='Nuevo Pruoducto'
        />
      )}
    </Card>
  )
}

export default DetalleNota
