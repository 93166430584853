import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
 FormControl,
 Grid,
 InputLabel,
 MenuItem,
 Select,
 TextField,
 Button,
 Input,
 InputAdornment,
 IconButton,
 Checkbox,
 Switch,
 FormControlLabel,
 ListItem,
 List,
 ListItemText,
 ListItemSecondaryAction,
} from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import CheckBoxIcon from '@material-ui/icons/CheckBox'
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import useStyles from './Styles'

import { tipodoc } from '../../../Constants/ConstantesFacturas'
import NumberFormat from 'react-number-format'
// import { Autocomplete } from '@material-ui/lab'
import { useRazonSocial } from '../../../hooks/useRazonSocial'
import { startSaveUsuario } from '../../../redux/actions/usuarioActions'
import { sucursalLoadedByLocalidadID } from '../../../redux/actions/sucursalActions'
import { USERPORTAL } from '../../mantenimiento/Reportes/SeniatParam/constants'

const schema = yup.object().shape({
 tipoDocumento: yup.string().required('Tipo de documento es requerido'),
 numeroDocumento: yup.string().required('Número de documento es requerido'),
 firstName: yup.string().required('El nombre es requerido'),
 lastName: yup.string().required('Los apellidos son requeridos'),
 userAlias: yup.string().required('El alias es requerido'),
 email: yup
  .string()
  .email('El email no es válido')
  .required('El email es requerido'),
 password: yup.string().required('La contraseña es requerida'),
 repassword: yup
  .string()
  .required('Password is requerido')
  .oneOf([yup.ref('password'), null], 'Passwords no Coinsiden'),
 tipoUsuarioYup: yup.boolean(),
 role: yup.string().when('tipoUsuarioYup', {
  is: false,
  then: yup.string().required('El rol es requerido'),
 }),
 localidad: yup.string(), // .required('La localidad es requerida'),
 sucursales: yup.array(), //.min(1, 'La sucursal es requerida'),
 userseniat: yup.string(),
})

export default function AgregarUsuario({ open, handleClose, title }) {
 const { roles } = useSelector((state) => state.role)
 const dispatch = useDispatch()
 const classes = useStyles()
 const [showPassword, setshowPassword] = useState(false)
 const [Bysucursales, setBysucursales] = useState([])
 const [addLocalidades, setaddLocalidades] = useState([])

 const [tipousuario, setTipoUsuario] = useState(false)

 const {
  localidades,
  // localidadActive,
  // HandleselectLocalidad,
  sucursalesByLocalidad,
 } = useRazonSocial()

 const { handleSubmit, control } = useForm({
  resolver: yupResolver(schema),
 })

 const onSubmitUsusario = (data) => {
  const user = {
   tipoDocumento: data.tipoDocumento,
   numeroDocumento: data.numeroDocumento,
   email: data.email,
   masterAdmin: tipousuario,
   firstName: data.firstName,
   lastName: data.lastName,
   userAlias: data.userAlias,
   password: data.password,
   role: data.role !== '' ? data.role : undefined,
   id_localidad: !tipousuario ? addLocalidades : [],
   sucursales: !tipousuario ? Bysucursales : [],
   userPortal: data.userseniat,
  }


  dispatch(startSaveUsuario(user))
   .then((res) => {
    if (res.payload.tipoMsg === 'sucess') {
     handleClose()
    }
   })
   .catch((error) => {
    console.log(error)
   })
 }

 const handleClickShowPassword = () => {
  setshowPassword(!showPassword)
 }

 const handleMouseDownPassword = (event) => {
  console.log('event', event)
  event.preventDefault()
 }

 const handleChange = (e, localidad) => {
  const { checked, name } = e.target
  // console.log('LOCALIDADES handleChange', localidad)
  // console.log('LOCALIDADES handleChange 2', checked, name)
  if (name === 'localidad') {
   checked
    ? setaddLocalidades([...addLocalidades, localidad._id])
    : setaddLocalidades(addLocalidades.filter((item) => item !== localidad._id))
  } else if (name === 'sucursal') {
   checked
    ? setBysucursales([...Bysucursales, localidad.id])
    : setBysucursales(Bysucursales.filter((item) => item !== localidad.id))
  }
 }

 const handleChangeSwch = (e) => {
  console.log('e', e.target.name)
  setTipoUsuario(e.target.checked)
 }

 const renderOpciones = (e, local) => {
  console.log('LOCALIDADES handleChange Click', local)
  dispatch(sucursalLoadedByLocalidadID(local._id))
 }

 return (
  <ModalGenerico open={open} handleClose={handleClose} title={title}>
   <form onSubmit={handleSubmit(onSubmitUsusario)} autoComplete='off'>
    <Grid container spacing={2} className={classes.root}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='tipoDocumento'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Tipo Documento
         </InputLabel>
         <Select
          fullWidth
          // defaultValue=''
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {tipodoc.map((option) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={option.key}
             value={option.value}
            >
             {option.value}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='numeroDocumento'
       control={control}
       defaultValue=''
       // value: /^[0-9]*$/,

       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <NumberFormat
         format='########'
         customInput={TextField}
         className={classes.text}
         label='Nro. Documento'
         // variant='outlined'
         defaultValue={0}
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='firstName'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Nombres'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='lastName'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Apellidos'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='userAlias'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         className={classes.text}
         label='Alias'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='email'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
         type='email'
         className={classes.text}
         label='Correo Electrónico'
         size='small'
         fullWidth
         value={value}
         onChange={onChange}
         error={!!error}
         helperText={error ? error.message : null}
        />
       )}
      />
     </Grid>

     {/* password  */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='password'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel htmlFor='standard-adornment-password'>Password</InputLabel>
         <Input
          name='password'
          id='standard-adornment-password'
          className={classes.text}
          // label='Repita el password'
          type={showPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={value}
          onChange={(e) => {
           // handleChange(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
          endAdornment={
           <InputAdornment position='end'>
            <IconButton
             aria-label='toggle password visibility'
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
           </InputAdornment>
          }
         />
         <p className={classes.error}>{error ? error.message : null}</p>
        </FormControl>
       )}
      />
     </Grid>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='repassword'
       control={control}
       defaultValue=''
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel htmlFor='standard-adornment-password'>
          Repita Password
         </InputLabel>
         <Input
          name='repassword'
          id='standard-adornment-password'
          className={classes.text}
          // label='Repita el password'
          type={showPassword ? 'text' : 'password'}
          size='small'
          fullWidth
          value={value}
          onChange={(e) => {
           // handleChange(e)
           onChange(e.target.value)
          }}
          error={!!error}
          helperText={error ? error.message : null}
          endAdornment={
           <InputAdornment position='end'>
            <IconButton
             aria-label='toggle password visibility'
             onClick={handleClickShowPassword}
             onMouseDown={handleMouseDownPassword}
            >
             {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
           </InputAdornment>
          }
         />
         <p className={classes.error}>{error ? error.message : null}</p>
        </FormControl>
       )}
      />
     </Grid>

     <Grid item lg={12} sm={12} xl={12} xs={12}>
      <Controller
       control={control}
       name='userseniat'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Usuario Portal Seniat
         </InputLabel>
         <Select
          fullWidth
          disabled={tipousuario}
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
          defaultValue={USERPORTAL[0].value}
         >
          {USERPORTAL.map((rol) => {
           return (
            <MenuItem
             className={classes.sublabel}
             key={rol.value}
             value={rol.value}
            >
             {rol.label}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       name='tipoUsuarioYup'
       control={control}
       defaultValue={tipousuario}
       render={({
        field: { onChange, value, checked },
        fieldState: { error },
       }) => (
        <FormControlLabel
         style={{ margitTop: '8px' }}
         className={classes.switchgenerico}
         labelPlacement='end'
         control={<Switch name='tipoUsuarioYup' />}
         label='Usuario admin Master'
         value={value}
         checked={checked}
         onChange={(e) => {
          onChange(e)
          handleChangeSwch(e)
         }}

         // style={{ fontSize: '10px', marginTop: '8px',}}
        />
       )}
      />
     </Grid>

     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Controller
       control={control}
       name='role'
       render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth>
         <InputLabel variant='standard' htmlFor='tipoDocumento'>
          Rol
         </InputLabel>
         <Select
          fullWidth
          disabled={tipousuario}
          // defaultValue=''
          className={classes.select}
          onChange={onChange}
          value={value ?? ''}
          error={!!error}
          helperText={error ? error.message : null}
         >
          {roles.map((rol) => {
           return (
            <MenuItem className={classes.sublabel} key={rol.id} value={rol.id}>
             {rol.rol}
            </MenuItem>
           )
          })}
         </Select>
         {error && (
          <div style={{ marginTop: '8px' }} className={classes.error}>
           {error.message}
          </div>
         )}
        </FormControl>
       )}
      />
     </Grid>

     {/* asugnar rozon social o localidad */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <InputLabel variant='standard' htmlFor='localidad'>
       Razon Social
      </InputLabel>
      <List dense className={classes.listar} disabled={tipousuario}>
       {localidades
        ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((local) => (
         <ListItem key={local._id}>
          <Button
           disabled={tipousuario}
           name='seleccion'
           onClick={(e) => renderOpciones(e, local)}
          >
           <ListItemText
            disableTypography
            className={classes.listarItem}
            primary={local.nombre}
           />
          </Button>
          <ListItemSecondaryAction>
           <Checkbox
            disabled={tipousuario}
            name='localidad'
            edge='end'
            size='small'
            onChange={(e) => handleChange(e, local)}
           />
          </ListItemSecondaryAction>
         </ListItem>
        ))}
      </List>
     </Grid>
     {/* sucrusakes seleccion */}
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <InputLabel variant='standard' htmlFor='localidad'>
       Sucursales
      </InputLabel>
      <List dense className={classes.listar} disabled={tipousuario}>
       {sucursalesByLocalidad
        ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
        .sort((a, b) => a.nombre.localeCompare(b.nombre))
        .map((sucur) => (
         <ListItem key={sucur.id}>
          <Button
           disabled={tipousuario}
           name='sucursal'
           // onClick={(e) => renderOpciones(e, sucur)}
          >
           <ListItemText
            disableTypography
            className={classes.listarItem}
            primary={sucur.nombre}
           />
          </Button>
          <ListItemSecondaryAction>
           <Checkbox
            disabled={tipousuario}
            name='sucursal'
            edge='end'
            size='small'
            onChange={(e) => handleChange(e, sucur)}
           />
          </ListItemSecondaryAction>
         </ListItem>
        ))}
      </List>
     </Grid>
    </Grid>
    {/* botton de accion    */}
    <Grid container spacing={2} className={classes.margen}>
     <Grid item lg={6} sm={6} xl={12} xs={12}>
      <Button
       className={classes.btn}
       size='small'
       type='submit'
       variant='contained'
       color='primary'
       fullWidth
       //  onClick={() => peticionPost()}
      >
       Agregar
      </Button>
     </Grid>
     <Grid item md={6} xs={6}>
      <Button
       className={classes.btn}
       size='small'
       variant='contained'
       fullWidth
       onClick={handleClose}
      >
       Cancelar
      </Button>
     </Grid>
    </Grid>
   </form>
  </ModalGenerico>
 )
}
