import { types } from '../types/types'

const initialState = {
  municipios: [],
  municipiosByEstado: [],
  activeMunicipio: null,
  modalMunicipioOpen: false,
  modalMunicipioDelete: false,
}

export const municipioReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.municipioOpenModal:
      return {
        ...state,
        modalMunicipioOpen: true,
        modalMunicipioDelete: false,
      }

    case types.municipioCloseModal:
      return {
        ...state,
        modalMunicipioOpen: false,
      }

    case types.municipioOpenModalDelete:
      return {
        ...state,
        modalMunicipioOpen: false,
        modalMunicipioDelete: true,
      }

    case types.municipioCloseModalDelete:
      return {
        ...state,
        modalMunicipioDelete: false,
      }

    case types.municipioSetActive:
      return {
        ...state,
        activeMunicipio: action.payload,
        modalMunicipioOpen: true,
      }

    case types.municipioAddNew:
      return {
        ...state,
        municipios: [...state.municipios, action.payload],
      }

    case types.municipioClearActive:
      return {
        ...state,
        activeMunicipio: null,
      }

    case types.municipioUpdated:
      return {
        ...state,
        municipios: state.municipios.map((m) =>
          m.id === action.payload.id ? action.payload : m
        ),
      }

    case types.municipioLoaded:
      return {
        ...state,
        municipios: action.payload,
      }

    case types.municipioLoadedByEstado:
      return {
        ...state,
        municipiosByEstado: state.municipios.filter(
          (m) => m.id_estado[0] === action.payload
        ),
      }

    case types.municipioDelete:
      return {
        ...state,
        municipios: state.municipios.map((m) =>
          m.id === action.payload.id ? action.payload : m
        ),
        activeMunicipio: null,
      }

    case types.municipioReactived:
      return {
        ...state,
        municipios: state.municipios.map((m) =>
          m.id === action.payload.id ? action.payload : m
        ),
        activeMunicipio: null,
      }

    default:
      return state
  }
}
