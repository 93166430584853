import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ModuloModal } from './ModuloModal'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import {
  moduloOpenModal,
  moduloOpenModalDelete,
  moduloSetActive,
  startLoadModulos,
  startReactivedModulo,
  moduloOpenModalShow,
} from '../../../redux/actions/moduloActions'
import { startLoadRolesByModulo } from '../../../redux/actions/roleActions'
import { ModuloModalDelete } from './ModuloModalDelete'
import { DatosSeguimiento } from '../../commons/DatosSeguimiento'
import { ModuloModalShow } from './ModuloModalShow'

const Modulo = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { modulos, modalModuloDelete, modalModuloShow } = useSelector(
    (state) => state.modulo
  )

  const columnas = [
    {
      title: 'Modulo',
      field: 'name',
    },
    {
      title: 'Acceso',
      field: 'permiso',
    //   render: (rowData) => {
    //     rowData.permiso.map((permiso) => {
    //       return <div>{`Rol: S{permiso}`}</div>
    //     })
    //   },
    },

    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
    },
  ]

  useEffect(() => {
    //Llenar la lista de Modulos
    dispatch(startLoadModulos())
  }, [dispatch])

  //Editar
  const onUpdateModulo = (e) => {
    dispatch(moduloSetActive(e))
    dispatch(moduloOpenModal())
  }

  //Inactivar / Borrar
  const handleDelete = (e) => {
    dispatch(moduloSetActive(e))
    dispatch(moduloOpenModalDelete())
  }

  //Reactivar
  const handleReactive = (e) => {
    dispatch(startReactivedModulo(e))
  }

  //Abrir el modal
  const openModal = () => {
    dispatch(moduloOpenModal())
  }

  const onSelectModulo = (e) => {
    dispatch(moduloSetActive(e))
    dispatch(moduloOpenModalShow())
    dispatch(startLoadRolesByModulo(e.id))
  }

  return (
    <div>
      <br />
      <ModuloModal />
      {modalModuloDelete && <ModuloModalDelete />}
      {modalModuloShow && <ModuloModalShow />}
      <MaterialTable
        title={t('seguridad.modulo.base.listado')}
        columns={columnas}
        data={modulos}
        detailPanel={(rowData) => {
          return <DatosSeguimiento datos={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: t('seguridad.modulo.base.registrar.tooltip'),
            isFreeAction: true,
            onClick: (event) => {
              openModal()
            },
          },
          {
            icon: 'search',
            tooltip: 'Consultar',
            onClick: (event, rowData) => onSelectModulo(rowData),
          },
          (rowData) => ({
            icon: 'edit',
            tooltip: t('seguridad.modulo.base.editar.tooltip'),
            onClick: (event, rowData) => onUpdateModulo(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <PowerSettingsNewIcon />,
            tooltip: t('seguridad.modulo.base.inactivar.tooltip'),
            onClick: (event, rowData) => handleDelete(rowData),
            hidden: rowData.logEstatusRegistro === 'INACTIVO',
          }),
          (rowData) => ({
            icon: () => <SettingsPowerIcon />,
            tooltip: t('seguridad.modulo.base.reactivar.tooltip'),
            onClick: (event, rowData) => handleReactive(rowData),
            hidden: rowData.logEstatusRegistro === 'ACTIVO',
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: t('general.base.actions'),
          },
          toolbar: {
            searchPlaceholder: t('general.base.searchPlaceholder'),
            searchTooltip: t('general.base.searchTooltip'),
            exportTitle: t('general.base.exportTitle'),
            exportCSVName: t('general.base.exportCSVName'),
            exportPDFName: t('general.base.exportPDFName'),
          },
          pagination: {
            firstAriaLabel: t('general.base.firstAriaLabel'),
            firstTooltip: t('general.base.firstTooltip'),
            previousAriaLabel: t('general.base.previousAriaLabel'),
            previousTooltip: t('general.base.previousTooltip'),
            nextAriaLabel: t('general.base.nextAriaLabel'),
            nextTooltip: t('general.base.nextTooltip'),
            lastAriaLabel: t('general.base.lastAriaLabel'),
            lastTooltip: t('general.base.lastTooltip'),
            labelDisplayedRows: t('general.base.labelDisplayedRows'),
            labelRowsSelect: t('general.base.labelRowsSelect'),
          },
          body: {
            emptyDataSourceMessage: t('general.base.emptyDataSourceMessage'),
          },
        }}
      />
    </div>
  )
}

export default Modulo
