import { types } from "../types/types";

const initialState = {
  estados: [],
  activeEstado: null,
  modalEstadoOpen: false,
  modalEstadoDelete: false,
};

export const estadoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.estadoOpenModal:
      return {
        ...state,
        modalEstadoOpen: true,
        modalEstadoDelete: false,
      };

    case types.estadoCloseModal:
      return {
        ...state,
        modalEstadoOpen: false,
      };

    case types.estadoOpenModalDelete:
      return {
        ...state,
        modalEstadoOpen: false,
        modalEstadoDelete: true,
      };

    case types.estadoCloseModalDelete:
      return {
        ...state,
        modalEstadoDelete: false,
      };

    case types.estadoSetActive:
      return {
        ...state,
        activeEstado: action.payload,
        modalEstadoOpen: true,
      };

    case types.estadoAddNew:
      return {
        ...state,
        estados: [action.payload, ...state.estados],
      };

    case types.estadoClearActive:
      return {
        ...state,
        activeEstado: null,
      };

    case types.estadoUpdated:
      return {
        ...state,
        estados: state.estados.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
      };

    case types.estadoLoaded:
      return {
        ...state,
        estados: action.payload,
      };

    case types.estadoDelete:
    return {
        ...state,
        estados: state.estados.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
        activeEstado: null,
      };

    case types.estadoReactived:
      return {
        ...state,
        estados: state.estados.map((e) =>
          e.id === action.payload.id ? action.payload : e
        ),
        activeEstado: null,
      };

    default:
      return state;
  }
};
