export default function DownloadJsonNotas({ activeNota }) {
  console.log(
    '🚀 ~ file: DownloadJsonNotas.js:2 ~ DownloadJsonNotas ~ activeNota:',
    activeNota
  )

  const downloadJSON = () => {
    delete activeNota.notaCodificada
    delete activeNota.id_factura
    delete activeNota.id_sucursal
    delete activeNota.id_localidad

    console.log(
      '🚀 ~ file: DownloadJson.js:2 ~ DownloadJson ~ activeFacturaGeneral:',
      activeNota
    )
    const data = JSON.stringify(activeNota)
    const blob = new Blob([data], { type: 'application/json' })
    console.log('🚀 ~ file: downloadJson.js:7 ~ downloadJson ~ blob:', blob)
    // saveAs(blob, 'data.json');

    const url = URL.createObjectURL(blob) //some browser may use window.URL
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = `Nota_${activeNota.nroNota}_${new Date().getTime()}.json`
    a.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  return (
    <div>
      <button onClick={downloadJSON}>Descargar JSON</button>
    </div>
  )
}
