import axios from 'axios';
import Swal from 'sweetalert2';
import { types } from "../types/types";
import { config } from '../../config/configProperties';

const url = `${config.urlServer}/mantenimiento/categoriaNota`;

export const startLoadCategoriaNotas = () => {
    return (dispatch) => {

        axios.get(`${url}/listar`)
        .then(res => {
            dispatch(categoriaNotaLoaded(res.data));
        });
    }
}
export const startSaveCategoriaNota = (categoriaNota) => {

    return async (dispatch, getState) => {
        
        //Llamado al endpoint usando Axios
        axios({
            method:'POST',
            url: `${url}/crear`,
            data: categoriaNota
        })
            .then((response) => {
                
                Swal.fire('Guardado', categoriaNota.nombre, 'success');
                dispatch(categoriaNotaAddNew(response.data));
            })
            .catch((error) => {
                
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
}

export const startDeleteCategoriaNota = () => {
    
    return async (dispatch, getState) => {
       
        const { activeCategoriaNota } = getState().categoriaNota;
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/eliminar/${activeCategoriaNota.id}`
        })
            .then((response) => {
                
                Swal.fire('Inactivado', activeCategoriaNota.nombre, 'success');
                dispatch(deleteCategoriaNota(response.data));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startReactivedCategoriaNota = (categoria) => {
    
    return async (dispatch, getState) => {
       
        //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/activar/${categoria.id}`
        })
            .then((response) => {
                
                Swal.fire('Activado', categoria.nombre, 'success');
                dispatch(reactiveCategoriaNota(response.data));
            })
            .catch((error) => {
                const msgError = error;
                console.log(error);
                Swal.fire('Error', msgError, 'error');
            });
    }
};

export const startUpdateCategoriaNota = (categoriaNota) => {

    return (dispatch, getState) => {

        const { activeCategoriaNota } = getState().categoriaNota;
        const id = activeCategoriaNota.id;

         //Llamado al endpoint usando Axios
        axios({
            method:'PUT',
            url: `${url}/editar/${id}`,
            data: categoriaNota
        })
            .then((response) => {
                
                Swal.fire('Actualizado', response.data.nombre, 'success');
                dispatch(categoriaNotaUpdated(categoriaNota));
            })
            .catch((error) => {
                const msgError = error.response.data.message;
                console.log(error.response.data.message);
                Swal.fire('Error', msgError, 'error');
            });       
    }
}

const categoriaNotaLoaded = (categoriaNotas) => ({
    type: types.categoriaNotaLoaded,
    payload: categoriaNotas
});

export const categoriaNotaOpenModal = () => ({
    type: types.categoriaNotaOpenModal
});

export const categoriaNotaCloseModal = () => ({
    type: types.categoriaNotaCloseModal
});

export const categoriaNotaOpenModalDelete = () => ({
    type: types.categoriaNotaOpenModalDelete
});

export const categoriaNotaCloseModalDelete = () => ({
    type: types.categoriaNotaCloseModalDelete
})

export const categoriaNotaAddNew = ( categoriaNota ) => ({
    type: types.categoriaNotaAddNew,
    payload: categoriaNota
});

export const categoriaNotaSetActive = ( categoriaNota ) => ({
    type: types.categoriaNotaSetActive,
    payload: categoriaNota
});

export const categoriaNotaClearActive = ( ) => ({
    type: types.categoriaNotaClearActive,
   
});

export const categoriaNotaUpdated = ( categoriaNota ) => ({
    type: types.categoriaNotaUpdated,
    payload: categoriaNota
   
});

export const deleteCategoriaNota = (categoria) => ({
    type: types.categoriaNotaDelete,
    payload: categoria
});

export const reactiveCategoriaNota = ( categoria ) => ({
    type: types.categoriaNotaReactived,
    payload: categoria
});