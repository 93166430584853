import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import {
  errorClearActive,
  errorSetActive,
  errorStatusLoading,
} from './errorActions'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'

const url = `${config.urlServer}/mantenimiento/producto`

/**
 * Traer Productos
 * @returns
 */
export const startLoadProductos = () => {
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/getproductos`, 'GET')
      dispatch(productoLoaded(res))
      // dispatch(errorSetActive('Generada con exito', 'sucess'))
    } catch (error) {
      // console.log(error.response.data.message)
      dispatch(setProductosClear())
      dispatch(
        errorSetActive(
          error?.response?.data.message ||
            'Ocurrio un error al Despelgar productos',
          'error'
        )
      )
    }
  }
}

export const startSaveProducto = (producto) => {
  return async (dispatch) => {
    //Llamado al endpoint usando Axios
    try {
      const res = await ApiAxiosPost(`${url}/addproducto`, 'POST', producto)
      console.log('producto', res)
      dispatch(productoAddNew(res))
      return dispatch(errorSetActive('Producto Registrado!!!', 'sucess'))
      // dispatch(facturaGeneralClearActive())
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message ||
            `Ocurrio un error al Crear productos, ${error}`,
          'error'
        )
      )
    }
  }
}

export const startActivaDesactivaProducto = () => {
  return async (dispatch, getState) => {
    const { activeProducto } = getState().producto

    //Llamado al endpoint usando Axios
    try {
      const res = await ApiAxiosPost(
        `${url}/ActDesProducto`,
        'PUT',
        activeProducto
      )
      dispatch(ActivaDesavtivaProducto(res.result))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message ||
            `Ocurrio un error al Activar/Desavtivar productos, ${error}`,
          'error'
        )
      )
    }
  }
}

export const startReactivedProducto = (producto) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${producto.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', producto.descripcion, 'success')
        dispatch(reactiveProducto(response.data))
      })
      .catch((error) => {
        const msgError = error
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateProducto = (producto) => {
  return async (dispatch) => {
    //Llamado al endpoint usando Axios
    try {
      const res = await ApiAxiosPost(`${url}/updateproducto`, 'POST', producto)
      console.log('producto AXIO', res)
      dispatch(productoUpdated(res))
      return dispatch(errorSetActive('Producto Actualizado!!!', 'sucess'))
      // dispatch(facturaGeneralClearActive())
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message ||
            `Ocurrio un error al Crear productos, ${error}`,
          'error'
        )
      )
    }
  }
}

const productoLoaded = (productos) => ({
  type: types.productoLoaded,
  payload: productos,
})

/* Cargar Productos por Localidad */
export const startLoadProductosByLocalidad = (idLocalidad) => {
  return (dispatch) => {
    dispatch(productosLoadedByLocalidad(idLocalidad))
  }
}
// ph cargar porductos api rest directo
export const startLoadProductosByLocalidadByID = (idLocalidad) => {
  return async (dispatch, getState ) => {
    // console.log('getState Productos', getState().facturaGeneral)
    const { facturaDivisa } =  getState().facturaGeneral
    try {
      const res = await ApiAxiosGet(
        `${url}/prductosbylocalidad/${idLocalidad}/divisa/${facturaDivisa}`,
        'GET'
      )
      dispatch(productosLoadedByLocalidadByID(res))
    } catch (error) {
      dispatch(productosClearByLocalidadByID())
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }

    // axios({
    //   method: 'GET',
    //   url: `${url}/prductosbylocalidad/${idLocalidad}`,
    // })
    //   .then((response) => {
    //     // console.log(response.data)
    //     dispatch(productosLoadedByLocalidadByID(response.data))
    //   })
    //   .catch((error) => {
    //     console.log(error.response)
    //   })
  }
}

const productosLoadedByLocalidadByID = (productos) => ({
  type: types.ProductosByLocalidadByID,
  payload: productos,
})

export const productosLoadedByLocalidad = (idLocalidad) => ({
  type: types.productosLoadedByLocalidad,
  payload: idLocalidad,
})

export const startSetLocalidadActive = (idLocalidad) => {
  return (dispatch, getState) => {
    const { localidades } = getState().localidad
    const localidad = localidades.find((l) => l.id === Number(idLocalidad))

    dispatch(productoSetLocalidadActive(localidad))
  }
}

export const startSetTipoGravamenActive = (idTipoGravamen) => {
  return (dispatch, getState) => {
    const { tiposGravamenes } = getState().tipoGravamen
    const tipoGravamen = tiposGravamenes.find(
      (t) => t.id === Number(idTipoGravamen)
    )

    dispatch(productoSetTipoGravamenActive(tipoGravamen))
  }
}

export const productoOpenModal = () => ({
  type: types.productoOpenModal,
})

export const productoCloseModal = () => ({
  type: types.productoCloseModal,
})

export const productoOpenModalDelete = () => ({
  type: types.productoOpenModalDelete,
})

export const productoCloseModalDelete = () => ({
  type: types.productoCloseModalDelete,
})

export const productoOpenModalShow = () => ({
  type: types.productoOpenModalShow,
})

export const productoCloseModalShow = () => ({
  type: types.productoCloseModalShow,
})

export const productoAddNew = (producto) => ({
  type: types.productoAddNew,
  payload: producto,
})

export const productoSetActive = (producto) => ({
  type: types.productoSetActive,
  payload: producto,
})

export const productoClearActive = () => ({
  type: types.productoClearActive,
})
export const productoClear = () => ({
  type: types.productoClear,
})

export const productoUpdated = (producto) => ({
  type: types.productoUpdated,
  payload: producto,
})

export const ActivaDesavtivaProducto = (producto) => ({
  type: types.ActivaDesavtivaProducto,
  payload: producto,
})

export const reactiveProducto = (producto) => ({
  type: types.productoReactived,
  payload: producto,
})

//Acciones para Localidad
export const productoSetLocalidadActive = (localidad) => ({
  type: types.productoSetLocalidadActive,
  payload: localidad,
})

//Acciones para TipoGravamen
export const productoSetTipoGravamenActive = (tipoGravamen) => ({
  type: types.productoSetTipoGravamenActive,
  payload: tipoGravamen,
})

//Acciones para Tipo Venta
export const setTipoVentaActive = (tipoVenta) => ({
  type: types.productoTipoVentaSelected,
  payload: tipoVenta,
})

export const setTipoVentaClearActive = () => ({
  type: types.productoTipoVentaClearSelected,
})

export const setProductosClear = () => ({
  type: types.setproductoClear,
})

export const productosClearByLocalidadByID = () => ({
  type: types.productosClearByLocalidadByID,
})
