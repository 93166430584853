import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import MaterialTable from 'material-table'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import AddAlarmRounded from '@material-ui/icons/AddAlarmRounded'
import ContactMail from '@material-ui/icons/ContactMail'
import PeopleAlt from '@material-ui/icons/PeopleAlt'

import DashboardOutlined from '@material-ui/icons/DashboardOutlined'
import SearchRounded from '@material-ui/icons/SearchRounded'
import BuildSharp from '@material-ui/icons/BuildSharp'

import Edit from '@material-ui/icons/Edit'
import NetworkCellSharp from '@material-ui/icons/NetworkCellSharp'
import { hasPermission } from '../../../Helpers/AuthPermisos'

import {
  localidadClearActive,
  localidadOpenModalDelete,
  localidadSetActive,
  startLoadLocalidades,
  startReactivedLocalidad,
} from '../../../redux/actions/localidadActions'
import { LocalidadModalDelete } from './LocalidadModalDelete'
import AgregarLocalidad from './AgregarLocalidad'
import { useToast } from '../../../hooks/useToast'
import ConsultaLocalidad from './ConsultaLocalidad'
import UpdateLocalidad from './UpdateLocalidad'
import AgregarSucursal from '../Sucursal/AgregarSucursal'
import AgregarControl from '../Control/AgregarControl'
import {
  ControlFacturaClearloded,
  ConsultaNroFacturaAuto,
} from '../../../redux/actions/controlFacturaActions'
import { DatosSucursalesAsoc } from './DatosSucursalesAsoc'
import AgregarCertificado from './AgregarCertficado'
import Parametros from './Parametros'
import CargaGeneracionMasiva from './CargaGeneracionMasiva'
// import AgregarCuentaTerceros from './AgregarCuentaTerceros'
import CuentaTercerosBase from './CuentaTercerosBase'

const Localidad = () => {
  const [ModalConsulta, setmodalconsulta] = useState(false)
  const [ModalUpdate, setmodalupdate] = useState(false)
  const [ModalAgregar, setmodalAgregar] = useState(false)
  const [ModalAgregarSucursal, setmodalAgregarSucursal] = useState(false)
  const [ModalControlFactura, setmodalControlFactura] = useState(false)
  const [ModalCertficadoFactura, setmodalCertficadoFactura] = useState(false)
  const [ModalGenerarMasFactura, setmodalGenerarMasFactura] = useState(false)
  const [ModalCuentaTerceros, setmodalCuentaTerceros] = useState(false)

  const [ModalParamter, setmodalParamter] = useState(false)

  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { roles } = useSelector((state) => state.role)
  const { localidades, modalLocalidadDelete } = useSelector(
    (state) => state.localidad
  )
  // const { open } = useModal()
  const { notifyError, notifysucess } = useToast()

  const dispatch = useDispatch()

  const columnas = [
    {
      title: 'Logo',
      field: 'rutaLogo',
      cellStyle: {
        minWidth: '20',
        fontSize: '12px',
      },
      render: (rowData) => (
        <div style={{ minWidth: '20%' }}>
          <img
            alt='empresa'
            src={rowData.rutaLogo}
            style={{ width: '40px', borderRadius: '50%' }}
          />
        </div>
      ),
    },
    // {
    //   title: 'Tipo Doc.',
    //   field: 'fullRif',
    //   defaultSort: 'desc',
    // },

    {
      title: 'RIF',
      field: 'Rif',
      cellStyle: {
        minWidth: '40',
        fontSize: '12px',
      },
      render: (rowData) => (
        <div style={{ width: '80px', fontSize: '12px' }}>
          {`${rowData.tipoDocumento}-${rowData.numeroDocumento}`}
        </div>
      ),
    },
    {
      title: 'Nombre',
      field: 'nombre',
      cellStyle: {
        minWidth: '40',
        fontSize: '12px',
        color: 'blue',
      },
      render: (rowData) => <div>{rowData.nombre}</div>,
    },
    {
      title: 'Estatus',
      field: 'logEstatusRegistro',
      cellStyle: {
        minWidth: '20',
        fontSize: '12px',
      },
    },
  ]

  useEffect(() => {
    dispatch(startLoadLocalidades())
  }, [dispatch])

  const onSelectLocalidad = (e) => {
    dispatch(localidadSetActive(e))
    setmodalconsulta(true)
    // dispatch(localidadOpenModalShow())
  }

  const onUpdateLocalidad = (e) => {
    // dispatch(localidadOpenModal
    dispatch(localidadSetActive(e))
    setmodalupdate(true)
  }

  const handleDelete = (e) => {
    dispatch(localidadSetActive(e))
    dispatch(localidadOpenModalDelete())
  }

  const handleReactive = (e) => {
    dispatch(localidadSetActive(e))
    dispatch(startReactivedLocalidad(e))
  }

  const openModal = () => {
    setmodalAgregar(true)
    // dispatch(localidadOpenModal());
  }
  const handleCloseConsultaModal = () => {
    setmodalconsulta(false)
  }

  const handleCloseUpdateModal = () => {
    dispatch(localidadClearActive())
    setmodalupdate(false)
  }

  const handleCloseAgregarModal = () => {
    setmodalAgregar(false)
  }
  const handleCloseSucursalModal = () => {
    setmodalAgregarSucursal(false)
    dispatch(localidadClearActive())
  }
  const handleSucursalAdd = (e) => {
    setmodalAgregarSucursal(true)
    dispatch(localidadSetActive(e))
  }
  const handleControlAdd = (e) => {
    setmodalControlFactura(true)
    dispatch(localidadSetActive(e))
  }
  const handleCloseControlModal = () => {
    setmodalControlFactura(false)
    dispatch(localidadClearActive())
    dispatch(ControlFacturaClearloded())
  }

  const handleCertificadoAdd = (e) => {
    dispatch(localidadSetActive(e))
    setmodalCertficadoFactura(true)
  }
  const handleCloseCertficadoModal = () => {
    dispatch(localidadClearActive())
    dispatch(ControlFacturaClearloded())
    setmodalCertficadoFactura(false)
  }

  const handleParamsOpen = async (e) => {
    await dispatch(ConsultaNroFacturaAuto(e._id, 'id_localidad'))
    dispatch(localidadSetActive(e))
    setmodalParamter(true)
  }
  const handleCloseParamsModal = () => {
    dispatch(localidadClearActive())
    setmodalParamter(false)
  }

  const handleGenerarMasiva = (e) => {
    dispatch(localidadSetActive(e))
    setmodalGenerarMasFactura(true)
  }
  const handleCloseGenerarMasivaModal = () => {
    dispatch(localidadClearActive())
    setmodalGenerarMasFactura(false)
  }
  const handleCuentaTerceros = (e) => {
    dispatch(localidadSetActive(e))
    setmodalCuentaTerceros(true)
  }
  const handleCloseCuentaTercerosModal = () => {
    dispatch(localidadClearActive())
    setmodalCuentaTerceros(false)
  }

  return (
    <div>
      <br />
      {modalLocalidadDelete && <LocalidadModalDelete />}
      {/* {modalLocalidadShow && <LocalidadModalShow />} */}
      <MaterialTable
        title='Razón Social'
        columns={columnas}
        data={localidades}
        detailPanel={(rowData) => {
          return <DatosSucursalesAsoc sucursales={rowData} />
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={[
          {
            icon: 'add',
            tooltip: 'Nueva Razón Social',
            isFreeAction: true,
            onClick: (event) => {
              openModal()
            },
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Localidad',
              'add',
              user.user
            ),
          },
          {
            icon: () => <BuildSharp fontSize='small' htmlColor='#0099ff' />,
            tooltip: 'Parametros',
            onClick: (event, rowData) => handleParamsOpen(rowData),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Localidad',
              'params',
              user.user
            ),
          },
          {
            icon: () => <SearchRounded fontSize='small' htmlColor='#2eb85c' />,
            tooltip: 'Consdulta',
            onClick: (event, rowData) => onSelectLocalidad(rowData),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/mantenimiento/Localidad',
              'view',
              user.user
            ),
          },

          (rowData) => ({
            icon: () => <Edit fontSize='small' htmlColor='#ff6f00' />,
            tooltip: 'Modificar',
            onClick: (event, rowData) => onUpdateLocalidad(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'update',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => (
              <PowerSettingsNewIcon fontSize='small' htmlColor='#0288d1' />
            ),
            tooltip: 'Inactivar',
            onClick: (event, rowData) => handleDelete(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'delete',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => (
              <SettingsPowerIcon
                fontSize='small'
                // style={{ color: 'primary' }}
                color='primary'
              />
            ),
            tooltip: 'Activar',
            onClick: (event, rowData) => handleReactive(rowData),
            hidden:
              rowData.logEstatusRegistro === 'ACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'delete',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => (
              <NetworkCellSharp fontSize='small' htmlColor='#33691e' />
            ),
            tooltip: 'Sucursales',
            onClick: (event, rowData) => handleSucursalAdd(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'addSucursal',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <DashboardOutlined fontSize='small' color='primary' />,
            tooltip: 'Agregar Control Factura',
            onClick: (event, rowData) => handleControlAdd(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'addControl',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <AddAlarmRounded fontSize='small' htmlColor='orange' />,
            tooltip: 'Agregar Certficado Electronico',
            onClick: (event, rowData) => handleCertificadoAdd(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'addCertificado',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <PeopleAlt fontSize='small' htmlColor='#0d6efd' />,
            tooltip: 'Cuenta de Terceros',
            onClick: (event, rowData) => handleCuentaTerceros(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'cuentaTerceros',
                user.user
              ),
          }),
          (rowData) => ({
            icon: () => <ContactMail fontSize='small' htmlColor='#03a180' />,
            tooltip: 'Generación Masiva de facturas y/o notas',
            onClick: (event, rowData) => handleGenerarMasiva(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/mantenimiento/Localidad',
                'genMasivaFactura',
                user.user
              ),
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
      {/* <Toaster position='top-center' reverseOrder={false} /> */}

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {ModalAgregar && (
        <AgregarLocalidad
          open={ModalAgregar}
          handleClose={handleCloseAgregarModal}
          title='Crear Razon Social'
        />
      )}
      {ModalParamter && (
        <Parametros
          open={ModalParamter}
          handleClose={handleCloseParamsModal}
          title='Parametros'
        />
      )}
      {ModalConsulta && (
        <ConsultaLocalidad
          open={ModalConsulta}
          handleClose={handleCloseConsultaModal}
          title='Datos basicos Localidad'
        />
      )}
      {ModalUpdate && (
        <UpdateLocalidad
          open={ModalUpdate}
          handleClose={handleCloseUpdateModal}
          title='Modificar datos basicos Localidad'
        />
      )}
      {ModalAgregarSucursal && (
        <AgregarSucursal
          open={ModalAgregarSucursal}
          handleClose={handleCloseSucursalModal}
          title='Agregar Sucursal'
        />
      )}

      {ModalControlFactura && (
        <AgregarControl
          open={ModalControlFactura}
          handleClose={handleCloseControlModal}
          title='Agregar Nro. Controles '
        />
      )}
      {ModalCertficadoFactura && (
        <AgregarCertificado
          open={ModalCertficadoFactura}
          handleClose={handleCloseCertficadoModal}
          title='Agregar Certificado Electronico'
        />
      )}
      {ModalGenerarMasFactura && (
        <CargaGeneracionMasiva
          open={ModalGenerarMasFactura}
          handleClose={handleCloseGenerarMasivaModal}
          title='Generación Masiva'
        />
      )}
      {ModalCuentaTerceros && (
        <CuentaTercerosBase
          open={ModalCuentaTerceros}
          handleClose={handleCloseCuentaTercerosModal}
          title='Cuenta de Terceros'
        />
      )}
    </div>
  )
}

export default Localidad
