import React from 'react'
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Button,
  // Divider,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import ModalGenerico from '../../commons/ui/Modal'
import { makeStyles } from '@material-ui/core/styles'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { setClienteActive } from '../../../redux/actions/facturaGeneralActions'
import { useDispatch } from 'react-redux'
import { tipodoc } from '../../../Constants/ConstantesFacturas'
import { filtroLetras, soloTextyNumeros } from '../../../Helpers/formater'
// import { applyInputMask } from '../../../Helpers/formater'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'end',
    '& .MuiInputBase-input': {
      fontSize: '12px',
      color: '#01579b',
    },
  },
  error: {
    fontSize: 10,
    fontWeight: 500,
    color: 'red',
  },
  sublabel: {
    fontSize: '12px',
    color: '#546E7A',
    alignItems: 'center',
  },
  select: {
    fontSize: '12px',
    color: '#546E7A',
    textAlign: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '12px',
    color: '#546E7A',
    textAlign: 'center',
    margin: 0,
    padding: 0,
  },
  margen: {
    marginTop: '24px',
  },
  textborde: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
  },

  inputtelefono: {
    fontSize: '12px',
    width: '100%',
    border: 'none',
    borderBottom: '1px solid red',
  },

  btn: {
    width: '100%',
    fontSize: 16,
    padding: '6px 12px',
    borderRadius: 10,
  },
}))

const schema = yup.object().shape({
  tipoDocumento: yup.string().required('Debe Seleccionarun tipo'),
  numeroDocumento: yup
    .string()
    .typeError('Debe ingresar un numero')
    .required('El Nombre es Obligatoria'),

  // .matches(phoneRegExp, 'Debe Ingresar un Valor Numerico')
  // .required('El Valor Es Obligatorio')
  // .min(8, 'Minimo son 8 Catacteres')
  // .max(10, 'Maximo son 10 Catacteres'),
  identificacion: yup
    .string()
    .max(100, 'Maximo son 100 Catacteres')
    // .matches(/^[A-Za-z0-9\s]+$/g, 'Solo permite letras y numeros')
    // .matches(/^[a-zA-Z0-9]$/, 'Solo se permiten letras mayusculas')
    .required('El Nombre es Obligatoria'),
  correo: yup
    .string()
    .email('Debe ser un Email válido')
    .required('El Email es Obligatorio'),
  ccCorreo: yup.string().email('Debe ser un Email válido'),
  // .required('El Email es Obligatori'),
  direccion: yup
    .string()
    .max(200, 'Maximo direccion son 200 caracteres')
    .required('La dirección es Obligatoria'),
  telefonoMovil: yup.string().min(10, 'Maximo son 10 Catacteres'),
  // elinput: yup.string().required('Es Obligatori'),
})

function CrearClienteph({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    // register,
    handleSubmit,
    control,
    // formState: { errors },
    // reset,
    // getValues,
  } = useForm({ resolver: yupResolver(schema) })

  const onSubmitCliente = (data) => {
    dispatch(
      setClienteActive({
        ...data,
      })
    )
    handleClose()
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitCliente)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={2}>
            {/* <label className={classes.sublabel}>Tipo Doc.</label> */}

            <Controller
              control={control}
              name='tipoDocumento'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Select
                  fullWidth
                  className={classes.select}
                  onChange={onChange}
                  value={value}
                  error={!!error}
                >
                  {tipodoc.map((option) => {
                    return (
                      <MenuItem
                        className={classes.sublabel}
                        key={option.key}
                        value={option.value}
                      >
                        {option.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
          </Grid>
          <Grid item md={10}>
            <Controller
              name='numeroDocumento'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  format='#########'
                  allowLeadingZeros={true}
                  customInput={TextField}
                  className={classes.text}
                  name='numeroDocumento'
                  label='Nro. Documento'
                  // variant='outlined'
                  defaultValue={0}
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              // rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>
          <Grid item md={12}>
            <Controller
              name='identificacion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Nombre ó Razón Social'
                  //   variant='filled'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  // onChange={(e) =>
                  //   onChange(soloTextyNumeros(e.target.value, filtroLetras))
                  // }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              // rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              type
              name='correo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Correo Electronico'
                  //   variant='filled'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>
          <Grid item md={6}>
            <Controller
              type
              name='ccCorreo'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='CC Correo Electronico'
                  //   variant='filled'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>

          <Grid item md={12}>
            <Controller
              name='direccion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Dirección'
                  //   variant='filled'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>

          <Grid item md={6}>
            <Controller
              name='telefonoMovil'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <NumberFormat
                  format='(###) #######'
                  customInput={TextField}
                  className={classes.text}
                  label='Telefono Movil'
                  placeholder='(424) 0000000'
                  //   variant='filled'
                  name='telefonoMovil'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  // onChange={(e) =>
                  //   onChange(applyInputMask(e.target.id, '(000) 00000000'))
                  // }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              rules={{ required: 'El Valor Es Requerido' }}
            />
          </Grid>
          {/* <Grid item md={6}>
            <div className={classes.textborde}>
              <input
                className={classes.inputtelefono}
                name='elinput'
                id='elinput'
                {...register('elinput', { required: true })}
                type='text'
                onChange={(e) =>
                  applyInputMask(e.target.id, '000.000.000.000,00')
                }
              />
            </div>
            <p className={classes.error}>
              {errors.elinput && errors.elinput.message}
            </p>
          </Grid> */}
        </Grid>

        <Grid container spacing={1} className={classes.margen}>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
export default CrearClienteph
