import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  Button,
  FormControlLabel,
  Card,
  CardHeader,
  CardContent,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// import MoreVertIcon from '@material-ui/icons/MoreVert'

// import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  startDeletePago,
  facturaGeneralOpenPagoModal,
  setCondicionActive,
  setDiasCreditoActive,
  setDiasCreditoClearActive,
} from '../../../../redux/actions/facturaGeneralActions'
// import { PagoFacturaGeneralModal } from '../PagoFacturaGeneralModal'
import { diasCredito } from '../../../../Constants/ConstantesFacturas'
import Credito from './Credito'
import Contado from './Contado'
import AgregarPago from './AgregarPagoModal'
import { useModal } from '../../../../hooks/useModal'

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
    color: 'white',

    // backgroundColor: 'grey',
    // boxShadow: '5px 5px teal',

    '& .MuiFormControlLabel-label': {
      fontSize: '10px',
      // color: '#01579b',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      marginLeft: 10,
      color: 'white',
    },
  },
  paper: {
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: 5,
    opacity: '0.7',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  headers: {
    fontWeight: 'bold',
  },
  condipago: {
    // marging: '2px',
    backgroundColor: '#054477',
    color: 'white',
    // backgroundColor: '#efebe9',
    // boxShadow: '5px 5px #e0e0e0',
    border: 'solid 1px white',
  },
  btn: {
    marginTop: '24px',
    fontSize: '12px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '#054477',
      color: 'white',
    },
  },
  CardHeader: {
    backgroundColor: '#054477',
    color: 'white',
    padding: '8px',
  },
  cardheader_icons: {
    color: 'white',
  },
  switchgenerico: {
    marginTop: '8px',
    '& .MuiTypography-body1': {
      fontSize: '12px',
    },
  },
}))

const DetallePagoFacturaGeneralph = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { open, handleClose, handleOpen } = useModal()

  const {
    // diasCreditoSelected,
    // diasCreditoActive,
    // condicionSelected,
    condicionActive,
    lstPagos,
    lstDetalles,
    faltaPorPagar,
  } = useSelector((state) => state.facturaGeneral)

  const agregarPago = (e) => {
    e.preventDefault()
    dispatch(facturaGeneralOpenPagoModal())
  }

  const [diasC, setDiasC] = useState(diasCredito[0])

  const selectCondicionPago = (e) => {
    const { checked } = e.target
    console.log(e.target.checked)
    if (checked) {
      dispatch(setCondicionActive('CONTADO'))
    } else {
      dispatch(setCondicionActive('CREDITO'))
    }
  }

  const selectDiasCredito = (event, diaC, reason) => {
    if (reason !== 'clear') {
      dispatch(setDiasCreditoActive(diaC))
    } else if (reason === 'clear') {
      dispatch(setDiasCreditoClearActive())
    }
  }

  const handleDelete = (index, pago) => {
    dispatch(startDeletePago(index, pago))
  }

  return (
    <Card
      style={{
        alignItems: 'center',
        boxShadow:
          '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      }}
    >
      {open && condicionActive === 'CONTADO' && (
        <AgregarPago
          open={open}
          handleClose={handleClose}
          title='Agregar Pago'
        />
      )}
      <CardHeader
        className={classes.CardHeader}
        title='Detalle de pagos'
        action={
          <FormControlLabel
            className={classes.switchgenerico}
            labelPlacement='end'
            control={
              // <swtichGenerico defaultChecked/>
              <Switch
                defaultChecked
                // color='green'
                size='small'
              />
            }
            label={condicionActive === 'CONTADO' ? 'CONTADO' : 'CREDITO'}
            onChange={selectCondicionPago}
            // style={{ fontSize: '10px', marginTop: '8px',}}
          />
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid container spacing={2}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {condicionActive === 'CREDITO' ? (
                <Credito />
              ) : (
                condicionActive === 'CONTADO' && (
                  <Contado
                    condicionActive={condicionActive}
                    lstPagos={lstPagos}
                    handleDelete={handleDelete}
                    agregarPago={agregarPago}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Button
              disabled={
                lstDetalles?.length > 0 &&
                condicionActive &&
                faltaPorPagar !== 0
                  ? false
                  : true
              }
              className={classes.btn}
              variant='outlined'
              fullWidth
              color='primary'
              onClick={handleOpen}
            >
              <i className='fas fa-cash-register'></i>
              <span style={{ paddingLeft: '12px' }}> Agregar pago</span>
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DetallePagoFacturaGeneralph
