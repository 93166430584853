import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { queryFacturasCodificada } from '../../../../redux/actions/facturaGeneralActions'

export default function ViewPdfSeniat({ open, handleClose, title }) {
  const [facturaCodificada, setfacturaCodificada] = useState(null)
  const dispatch = useDispatch()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)

  useEffect(() => {
    dispatch(queryFacturasCodificada(activeFacturaGeneral?.id)).then((res) => {
      setfacturaCodificada(res?.payload?.resultado?.facturaCodificada)
    })
  }, [activeFacturaGeneral, dispatch])


  return (
    <iframe
      src={`data:application/pdf;base64,${facturaCodificada}`}
      title='Factura'
      name='Factura'
      style={{
        border: 'none',
        minHeight: '65vh',
        minWidth: '65vh',
      }}
    />
  )
}
