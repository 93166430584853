import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { diasCredito } from '../../../../Constants/ConstantesFacturas'



const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  headers: {
    // marginTop: '16px',
    fontSize: '12px',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: '#e0e0e0',
    border: 'solid 1px black',
  },
  body: {
    fontSize: '10px',
  },
}))

const Credito = ({ condicionActive }) => {
  const classes = useStyles()

  return (
    <>
      <Grid container>
        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <Typography className={classes.headers}>Medio de Pago</Typography>
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <Typography className={classes.headers}>Banco</Typography>
        </Grid>
        <Grid item lg={2} sm={2} xl={2} xs={2}>
          <Typography className={classes.headers}>comprob.</Typography>
        </Grid>
        <Grid item lg={1} sm={1} xl={1} xs={1}>
          <Typography className={classes.headers}>Divisa</Typography>
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <Typography className={classes.headers}>Monto</Typography>
        </Grid>
        <Grid item lg={1} sm={1} xl={1} xs={1}>
          <Typography className={classes.headers}>Acción</Typography>
        </Grid>

        {/* {lstPagos.map((pago, i) => {
          return (
            <Grid container key={i} style={{ alignItems: 'center' }}>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <Typography className={classes.body}>
                  {pago.modoPago}
                </Typography>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <Typography className={classes.body}>
                  {
                    //     const prod = bancos.find(b => b.id === pago.banco);
                    // DesBanco
                    buscarBanco(pago.banco)?.nombre
                  }
                </Typography>
              </Grid>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <Typography
                  style={{ textAlign: 'center' }}
                  className={classes.body}
                >
                  {pago.nro || 0}
                </Typography>
              </Grid>
              <Grid item lg={1} sm={1} xl={1} xs={1}>
                <Typography
                  style={{ textAlign: 'center' }}
                  className={classes.body}
                >
                  {
                    buscardivisa(pago.idDivisa)?.siglas
                    // pago.idDivisa || ''
                  }
                </Typography>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <Typography
                  style={{ textAlign: 'right' }}
                  className={classes.body}
                >
                  {pago.idDivisa === 1 &&
                    FormatedBolivares(pago.monto, 'es-VE', 'VEN')}
                  {pago.idDivisa === 3 &&
                    FormatedBolivares(pago.monto, 'es-US', 'USD')}
                  {pago.idDivisa === 2 &&
                    FormatedBolivares(pago.monto, 'es-US', 'EUR')}
                  {pago.idDivisa === 4 &&
                    FormatedBolivares(pago.monto, 'es-CO', 'COP')}
                </Typography>
              </Grid>
              <Grid
                key={pago.id}
                item
                lg={1}
                sm={1}
                xl={1}
                xs={1}
                style={{ textAlign: 'center' }}
              >
                <IconButton size='small' onClick={() => handleDelete(i, pago)}>
                  <DeleteOutline
                    color='secondary'
                    fontSize='small'
                    viewBox='0 0 24 24'
                  />
                </IconButton>
              </Grid>
            </Grid>
          )
        })} */}
      </Grid>
    </>
  )
}
export default Credito
