import { Divider, Grid, Typography } from '@material-ui/core'

export const ValoresGravamenAsoc = ({ Gravamenes }) => {
  const { lstValores } = Gravamenes

  return (
    <Grid container spacing={1} style={{ padding: '1rem' }}>
      <Typography>Valores Gravamenes</Typography>
      {lstValores.length > 0 ? (
        lstValores.map((val) => {
          return (
            <Grid container spacing={0}>
              <Grid item sm={3} style={{ paddingLeft: '1rem' }}>
                <Typography variant='caption'>{val.valor}</Typography>
              </Grid>
              <Grid item sm={3} style={{ paddingLeft: '1rem' }}>
                <Typography variant='caption'>
                  {new Date(val.fechaDesde).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item sm={3} style={{ paddingLeft: '1rem' }}>
                <Typography variant='caption'>
                  {new Date(val.fechaHasta).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item sm={3} style={{ paddingLeft: '1rem' }}>
                <Typography variant='caption'>
                  {val.logEstatusRegistro}
                </Typography>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid container spacing={1}>
          <Grid item sm={12} style={{ paddingLeft: '1rem' }}>
            <Typography variant='caption'>Sin valoes</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
