import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css';
import App from './App'
import './i18n'
// import { icons } from './assets/icons'
import { Provider as ProviderProcert } from 'react-redux'
import generateStore from './redux/store'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

// import "./styles.css";

// React.icons = icons

const store = generateStore()

ReactDOM.render(
  <ProviderProcert store={store}>
    <BrowserRouter>
      <App />
      <Toaster position='top-center' reverseOrder={false} />
    </BrowserRouter>
  </ProviderProcert>,
  document.getElementById('root')
)
