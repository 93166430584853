import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLoadMunicipios } from '../redux/actions/municipioActions'

export const useMunicipio = () => {
  const dispatch = useDispatch()
  const { municipios, activeMunicipio, municipiosByEstado } = useSelector(
    (state) => state.municipio
  )

  useEffect(() => {
    dispatch(startLoadMunicipios())
  }, [dispatch])

  return {
    municipios,
    activeMunicipio,
    municipiosByEstado,
  }
}
