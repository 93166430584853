import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';
import { municipioCloseModal, startSaveMunicipio, startUpdateMunicipio, municipioClearActive } from '../../../redux/actions/municipioActions';
import { startLoadEstados } from '../../../redux/actions/estadoActions';

const initMunicipio = {
    id: '',
    nombre: '',
    estado: -1,
    logEstatusRegistro: "1",
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}

Modal.setAppElement('#root');

export const MunicipioModal = () => {

    const dispatch = useDispatch();

    const { activeMunicipio, modalMunicipioOpen } = useSelector(state => state.municipio);
    const { estados } = useSelector( state => state.estado );

    const [formValues, setFormValues] = useState(initMunicipio);

    const { nombre, estado } = formValues;

    useEffect(() => {
        if (activeMunicipio) {
            
            setFormValues(activeMunicipio);
        } else {
            setFormValues(initMunicipio);
        }
    }, [activeMunicipio, setFormValues]);

    useEffect(() => {
       
        dispatch( startLoadEstados() );

    }, [dispatch]);
    

    const handleInputChange = ({ target }) => {
        
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    const closeModal = () => {

        dispatch(municipioCloseModal());
        dispatch(municipioClearActive());
        setFormValues(initMunicipio);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (nombre.trim().length < 2) {
            return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        }

        if (activeMunicipio) {
            
            dispatch(startUpdateMunicipio({
                id: activeMunicipio.id,
                nombre,
                estado: (estado.id === activeMunicipio.estado.id )? activeMunicipio.estado.id : estado,
                logUsername: 'sadmin',
                logFechaModificacion: new Date()
            }));
            
        } else {
            dispatch(startSaveMunicipio({
                ...formValues
            }));
        }
        console.log(formValues);

        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        selectMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',
        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={modalMunicipioOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            { (activeMunicipio) ? <h3>Editar Municipio</h3> : <h3>Nuevo Municipio</h3>}

            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >

                <div className={styles.modal}>
                    <div className="form-group">
                        <label htmlFor="nombre" className="form-label">Nombre</label>
                        <input
                            type="text"
                            className="form-control input-lg"
                            placeholder="Nombre"
                            name="nombre"
                            autoComplete="off"
                            value={nombre}
                            onChange={handleInputChange}
                            required={ true }
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="estado" className="form-label">Estado</label>
                        <br />
                        <select className="form-select form-select-sm" aria-label=".form-select-sm"
                                label="estado"
                                id="estado"
                                value={ estado }
                                onChange={handleInputChange}
                                name="estado"
                                required={ true }
                            >
                            <option defaultValue={ (activeMunicipio)? activeMunicipio.estado.id : "Seleccione..." }>{ (activeMunicipio)? activeMunicipio.estado.nombre : "Seleccione..." }</option>
                            {
                            estados.map(est => (
                                <option key={est.id} value={est.id}>{ est.nombre }</option>
                            ))
                            }
                        </select>
                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block"
                    >
                        <i className="far fa-save"></i>
                        <span> Guardar</span>
                    </button>
                </div>
            </form>
        </Modal>

    )
}


