import { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { tipodoc } from '../../../../Constants/ConstantesFacturas'
import ModalGenerico from '../../../commons/ui/Modal'
import NumberFormat from 'react-number-format'
import {
  setClearcuentaTerceros,
  setSavecuentaTerceros,
} from '../../../../redux/actions/facturaGeneralActions'
import { useRazonSocial } from '../../../../hooks/useRazonSocial'

import {
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { useStyles } from './Styles'

const schema = yup.object().shape({
  cuentaTerceros: yup.boolean(),
  // es requrdio si cuentaTercero es true
  domicilioPrestador: yup
    .string()
    .nullable()
    .when('cuentaTerceros', {
      is: true,
      then: yup
        .string()
        .nullable()
        .max(250, 'Maximo 250 caracteres permitidos, para la descripcion')
        .required('El domicilio es requerido'),
    }),
  nombrePrestador: yup
    .string()
    .nullable()
    .when('cuentaTerceros', {
      is: true,
      then: yup
        .string()
        .nullable()
        .max(100, 'Maximo 100 caracteres permitidos, para la descripcion')
        .required('El Nombre Prestador  es requerido'),
    }),
  numeroDocumento: yup.string().when('cuentaTerceros', {
    is: true,
    then: yup
      .string()
      .max(11, 'minimo valor requerido es 11 caracteres')
      .required('El Nro. Documento es requerido'),
  }),
  tipoDocumento: yup.string().when('cuentaTerceros', {
    is: true,
    then: yup.string().required('El domicilio es requerido'),
  }),
})

export default function CuentaTerceros({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { localidadActive } = useRazonSocial()
  const [selectedIndex, setSelectedIndex] = useState()

  const handleclickSelect = (item, index) => {
    dispatch(
      setSavecuentaTerceros({
        cuentaTerceros: true,
        domicilioPrestador: item.domicilioPrestador,
        nombrePrestador: item.nombrePrestador,
        rifPrestador: item.rifPrestador,
      })
    )
    setSelectedIndex(index)
    handleClose()
  }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <Grid container spacing={1} className={classes.root}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <div
            style={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 200,
              '& ul': { padding: 0 },
            }}
          >
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
              }}
            >
              {localidadActive?.lstCuentaTerceros.map((item, idx) => (
                <ListItem
                  key={item.id}
                  selected={selectedIndex === idx}
                  button
                  onClick={() => handleclickSelect(item, idx)}
                >
                  <ListItemText
                    secondary={item.rifPrestador}
                    primary={item.nombrePrestador}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.margen}>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <Typography variant='body2' gutterBottom>
            Selecione una opcion
          </Typography>
        </Grid>
      </Grid>
    </ModalGenerico>
  )
}
