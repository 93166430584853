import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

import { startLoadLocalidades } from '../../../redux/actions/localidadActions'
import {
 startLoadSucursales,
 startLoadSucursalesByLocalidad,
} from '../../../redux/actions/sucursalActions'
import {
 setLocalidadActive,
 setLocalidadClearActive,
 setSucursalActive,
 setSucursalClearActive,
 setTipoVentaActive,
} from '../../../redux/actions/facturaGeneralActions'
import { startLoadProductosByLocalidad } from '../../../redux/actions/productoActions'

const DatosFactura = () => {
 const useStyles = makeStyles((theme) => ({
  root: {
   '& > *': {
    margin: theme.spacing(1),
    width: theme.spacing(32),
    height: theme.spacing(16),
   },
  },
  paper: {
   backgroundColor: 'white',
   padding: '15px',
   borderRadius: 5,
   opacity: '0.7',
  },
  title: {
   fontWeight: 'bold',
   fontSize: '18px',
  },
 }))

 const classes = useStyles()

 const dispatch = useDispatch()

 useEffect(() => {
  dispatch(startLoadLocalidades())
  dispatch(startLoadSucursales())
 }, [dispatch])

 const selectLocalidad = (event, local, reason) => {
  console.log("🚀 ~ selectLocalidad ~ local:", local)
  if (reason !== 'clear') {
   dispatch(setLocalidadActive(local))

   dispatch(startLoadSucursalesByLocalidad(local.id))
   dispatch(startLoadProductosByLocalidad(local.id))
  } else if (reason === 'clear') {
   dispatch(setLocalidadClearActive())
  }
 }

 const selectSucursal = (event, sucu, reason) => {
  if (reason !== 'clear') {
   dispatch(setSucursalActive(sucu))
  } else if (reason === 'clear') {
   dispatch(setSucursalClearActive())
  }
 }

 const selectTipoVenta = (e) => {
  dispatch(setTipoVentaActive(e.target.value))
 }

 const { localidades } = useSelector((state) => state.localidad)
 const { sucursalesByLocalidad } = useSelector((state) => state.sucursal)

 const [local, setLocal] = useState(localidades[0])
 const [sucu, setSucu] = useState(sucursalesByLocalidad[0])

 return (
  <Paper elevation={2} className={classes.paper}>
   <div className='row'>
    <h6 className={classes.title}>Datos de factura</h6>
   </div>
   <div className='row'>
    <div className='col-md'>
     <Autocomplete
      id='localidad'
      val={local}
      name='localidad'
      options={localidades}
      noOptionsText='No hay registros'
      getOptionLabel={(option) => option.nombre}
      size='small'
      selectOnFocus
      clearOnBlur
      renderInput={(params) => <TextField {...params} label='Razón Social' />}
      onChange={selectLocalidad}
      clearText='Limpiar'
      renderOption={(option) => option.nombre}
     />
    </div>
   </div>
   <div className='row'>
    <div className='col-md'>
     <Autocomplete
      id='sucursal'
      val={sucu}
      name='sucursal'
      options={sucursalesByLocalidad}
      noOptionsText='No hay registros'
      getOptionLabel={(option) => option.direccion}
      size='small'
      selectOnFocus
      clearOnBlur
      renderInput={(params) => <TextField {...params} label='Sucursal' />}
      onChange={selectSucursal}
      clearText='Limpiar'
      renderOption={(option) => option.direccion}
     />
    </div>
   </div>
   <div className='mt-2 ml-2 row'>
    <div className='form-check form-check-inline'>
     <input
      id='interna'
      className='form-check-input'
      type='radio'
      name='tipoVenta'
      value='INTERNA'
      onClick={selectTipoVenta}
     />
     <label className='form-check-label' htmlFor='interna'>
      Interna
     </label>
    </div>
    <div className='form-check form-check-inline'>
     <input
      id='exportacion'
      className='form-check-input'
      type='radio'
      name='tipoVenta'
      value='EXPORTACION'
      onClick={selectTipoVenta}
     />
     <label className='form-check-label' htmlFor='exportacion'>
      Exportación
     </label>
    </div>
   </div>
  </Paper>
 )
}

export default DatosFactura
