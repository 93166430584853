import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { queryFacturasCodificada } from '../../../../redux/actions/facturaGeneralActions'
import ModalGenerico from '../../../commons/ui/Modal'

export default function MostrarPdf({ open, handleClose, title }) {
  const [facturaCodificada, setfacturaCodificada] = useState(null)
  const dispatch = useDispatch()
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)

  useEffect(() => {
    dispatch(queryFacturasCodificada(activeFacturaGeneral?.id)).then((res) => {
      setfacturaCodificada(res?.payload?.resultado?.facturaCodificada)
    })
  }, [activeFacturaGeneral, dispatch])

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={
        activeFacturaGeneral?.id_localidad === undefined
          ? `Cliente: ${activeFacturaGeneral?.id_sucursal?.nombre}`
          : `Cliente: ${activeFacturaGeneral?.id_localidad?.nombre}`
      }
      top={55}
      left={55}
    >
      <iframe
        src={`data:application/pdf;base64,${facturaCodificada}`}
        title='Factura'
        name='Factura'
        style={{
          border: 'none',
          minHeight: '80vh',
          minWidth: '80vh',
        }}
      />
    </ModalGenerico>
  )
}
