import { useSelector } from 'react-redux'
import { useStyles } from './Styles'
import DownloadJson from './DownloadJson'

export default function ViewJson() {
  const { activeFacturaGeneral } = useSelector((state) => state.facturaGeneral)
  const classes = useStyles()

  return (
    <div className={classes.jsonView}>
      <DownloadJson activeFacturaGeneral={activeFacturaGeneral} />
      {Object.keys(activeFacturaGeneral).map((key, index) => (
        <div key={index} className={classes.jsonItem}>
          <strong className={classes.jsonKey}>
            {key !== 'facturaCodificada' && key}:
          </strong>
          {typeof activeFacturaGeneral[key] !== 'object' ? (
            <strong className={classes.jsonValue}>
              {key !== 'facturaCodificada' && activeFacturaGeneral[key]}
            </strong>
          ) : (
            <span className={classes.jsonValue}>
              {key === 'lstDetallesFacturaGeneral' &&
                activeFacturaGeneral[key]?.map((keyDetalle, index2) => {
                  return (
                    <div className={classes.jsonItemProducto} key={index2}>
                      {/* <div>
                          ------------------------------------------------
                        </div> */}
                      <strong>
                        <span style={{ color: '#68bc29' }}>
                          codigoProducto:{' '}
                        </span>{' '}
                        {keyDetalle.codigoProducto}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>descripcion: </span>{' '}
                        {keyDetalle.descripcion}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>unidadMedida: </span>{' '}
                        {keyDetalle.unidadMedida}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>cantidad: </span>{' '}
                        {keyDetalle.cantidad}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>precio: </span>{' '}
                        {keyDetalle.precio}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>exento: </span>{' '}
                        {keyDetalle.exento}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>exonerado: </span>{' '}
                        {keyDetalle.exonerado}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>importe: </span>{' '}
                        {keyDetalle.importe}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>
                          alicuotaGravamen:{' '}
                        </span>{' '}
                        {keyDetalle.alicuotaGravamen}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>
                          montoGravamen:{' '}
                        </span>{' '}
                        {keyDetalle.importe}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>
                          montoDescuento:{' '}
                        </span>{' '}
                        {keyDetalle.montoDescuento}
                      </strong>
                      <strong>
                        <span style={{ color: '#68bc29' }}>descuento: </span>{' '}
                        {keyDetalle.descuento}
                      </strong>
                      <div>
                        ------------------------------------------------
                      </div>
                    </div>
                  )
                })}
              {key === 'id_localidad' && (
                <strong className={classes.jsonItemProducto}>
                  {activeFacturaGeneral[key]?.nombre || ''}
                </strong>
              )}
              {key === 'cliente' && (
                <>
                  <div className={classes.jsonItemProducto}>
                    <div className={classes.jsonItemProducto}>
                      <strong style={{ color: '#68bc29' }}>
                        tipoDocumento:
                      </strong>
                      <strong>
                        {activeFacturaGeneral[key]?.tipoDocumento || ''}
                      </strong>
                    </div>
                    <div className={classes.jsonItemProducto}>
                      <strong style={{ color: '#68bc29' }}>
                        numeroDocumento:
                      </strong>
                      <strong>
                        {activeFacturaGeneral[key]?.numeroDocumento || ''}
                      </strong>
                    </div>
                    <strong style={{ color: '#68bc29' }}>
                      identificacion:
                    </strong>
                    <strong>
                      {activeFacturaGeneral[key]?.identificacion || ''}
                    </strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>correo:</strong>
                    <strong>{activeFacturaGeneral[key]?.correo || ''}</strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>
                      contribuyenteEspecial :
                    </strong>
                    <strong>
                      {activeFacturaGeneral[key]?.contribuyenteEspecial || ''}
                    </strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>ccCorreo:</strong>
                    <strong>{activeFacturaGeneral[key]?.ccCorreo || ''}</strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>direccion:</strong>
                    <strong>
                      {activeFacturaGeneral[key]?.direccion || ''}
                    </strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>ccCorreo:</strong>
                    <strong>{activeFacturaGeneral[key]?.ccCorreo || ''}</strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>tipoPersona:</strong>
                    <strong>
                      {activeFacturaGeneral[key]?.tipoPersona || ''}
                    </strong>
                  </div>
                  <div className={classes.jsonItemProducto}>
                    <strong style={{ color: '#68bc29' }}>tipoProveedor:</strong>
                    <strong>
                      {activeFacturaGeneral[key]?.tipoProveedor || ''}
                    </strong>
                  </div>
                </>
              )}
              {key === 'lstGravamenes' && (
                <strong className={classes.jsonItemProducto}>
                  {activeFacturaGeneral[key]?.map((lstGravamen, index3) => {
                    return (
                      <div className={classes.jsonItemProducto} key={index3}>
                        <strong>
                          <span style={{ color: '#68bc29' }}>Cterceros: </span>{' '}
                          {lstGravamen.Cterceros}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>alicuota: </span>{' '}
                          {lstGravamen.alicuota}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            unidadMedida:{' '}
                          </span>{' '}
                          {lstGravamen.unidadMedida}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            baseImponible:{' '}
                          </span>{' '}
                          {lstGravamen.baseImponible}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            montoAlicuota:{' '}
                          </span>{' '}
                          {lstGravamen.montoAlicuota}
                        </strong>
                        <div>
                          ------------------------------------------------
                        </div>
                      </div>
                    )
                  })}
                </strong>
              )}
              {key === 'lstPagos' && (
                <strong className={classes.jsonItemProducto}>
                  {activeFacturaGeneral[key]?.map((lstGravamen, index3) => {
                    return (
                      <div className={classes.jsonItemProducto} key={index3}>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            diasCredito:{' '}
                          </span>{' '}
                          {lstGravamen.diasCredito}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>divisa: </span>{' '}
                          {lstGravamen.divisa}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            fechaComprobantePago:{' '}
                          </span>{' '}
                          {lstGravamen.fechaComprobantePago}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>igtf: </span>{' '}
                          {lstGravamen.igtf}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>modoPago: </span>{' '}
                          {lstGravamen.modoPago}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>monto: </span>{' '}
                          {lstGravamen.monto}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>nro: </span>{' '}
                          {lstGravamen.nro}
                        </strong>
                        <strong>
                          <span style={{ color: '#68bc29' }}>
                            tasaDiaDivisay:{' '}
                          </span>{' '}
                          {lstGravamen.tasaDiaDivisay}
                        </strong>
                        <div>
                          ------------------------------------------------
                        </div>
                      </div>
                    )
                  })}
                </strong>
              )}
            </span>
          )}
        </div>
      ))}
    </div>
  )
}
