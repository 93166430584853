export default function DownloadJson({ activeFacturaGeneral }) {
  const downloadJSON = () => {
    delete activeFacturaGeneral.facturaCodificada
    delete activeFacturaGeneral?.id_localidad?.rutaLogo
    delete activeFacturaGeneral?.id_localidad?.keyStoreName
    delete activeFacturaGeneral?.id_localidad?.keyStorePassword
    delete activeFacturaGeneral?.id_localidad?.keyStoreAlias


    const data = JSON.stringify(activeFacturaGeneral)
    const blob = new Blob([data], { type: 'application/json' })

    const url = URL.createObjectURL(blob) //some browser may use window.URL
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = `Factura_${
      activeFacturaGeneral.nroFactura
    }_${new Date().getTime()}.json`
    a.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }, 0)
  }

  return (
    <div>
      <button onClick={downloadJSON}>Descargar JSON</button>
    </div>
  )
}
