// import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// import { startLoadFacturasGenerales } from '../redux/actions/facturaGeneralActions'
// import { startLoadProductos } from '../redux/actions/productoActions'

const useFacturaGeneral = (props) => {
 const {
  activeFacturaGeneral,
  modalDetalleFacturaGeneral,
  lstDetalles,
  lstPagos,
  lstGravamenes,
  clienteSelected,
  clienteActive,
  localidadSelected,
  localidadActive,
  sucursalSelected,
  sucursalActive,
  tipoVentaSelected,
  tipoVentaActive,
  condicionSelected,
  condicionActive,
  bancoSelected,
  bancoActive,
  cambioDivisa,
  tipoColetilla,
  facturaDivisa,
  tipoCambioDiaUsd,
  medioPagoSelected,
  medioPagoActive,
  divisaSelected,
  divisaActive,
  diasCreditoSelected,
  diasCreditoActive,
  subTotal,
  exonerado,
  exento,
  totalDescuento,
  faltaPorPagar,
  importeTotal,
  nroFactura,
  modeloFactura,
  periodoFacturacion,
  pagueAntes,
  nroContrato,
  direccionServicio,
  cuentaTerceros,
  domicilioPrestador,
  nombrePrestador,
  rifPrestador,
  observacion,
 } = useSelector((state) => state.facturaGeneral)

 // useEffect(() => {
 //   dispatch(startLoadFacturasGenerales())
 // }, [dispatch])

 const ActivarBtnProducto = [
  localidadSelected,
  // sucursalSelected,
  tipoVentaSelected,
 ].some((valor) => {
  return valor === false
 })

 const ActivarBtnProductoOrden = [localidadSelected].some((valor) => {
  return valor === false
 })

 return {
  activeFacturaGeneral,
  modalDetalleFacturaGeneral,
  lstDetalles,
  lstPagos,
  lstGravamenes,
  clienteSelected,
  clienteActive,
  localidadSelected,
  localidadActive,
  sucursalSelected,
  sucursalActive,
  tipoVentaSelected,
  tipoVentaActive,
  condicionSelected,
  condicionActive,
  bancoSelected,
  bancoActive,
  medioPagoSelected,
  medioPagoActive,
  divisaSelected,
  divisaActive,
  diasCreditoSelected,
  diasCreditoActive,
  subTotal,
  tipoColetilla,
  exonerado,
  exento,
  totalDescuento,
  importeTotal,
  facturaDivisa,
  tipoCambioDiaUsd,
  // funciones
  ActivarBtnProducto,
  ActivarBtnProductoOrden,
  nroFactura,
  faltaPorPagar,
  cambioDivisa,
  modeloFactura,
  periodoFacturacion,
  pagueAntes,
  nroContrato,
  direccionServicio,
  cuentaTerceros,
  domicilioPrestador,
  nombrePrestador,
  rifPrestador,
  observacion,
 }
}
export default useFacturaGeneral
