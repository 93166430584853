import React from 'react';
import logo from "../../assets/imagenes/LogoSinFondo.png";
import '../../styles.css';

export const Home = () => {

    return (
        <div className="container">
            <h1>Bienvenido a SIGECE!</h1>
            <h6 className="text-muted">Sistema de Gestión de Comprobantes Electrónicos</h6>
            <img className="rounded mx-auto d-block" alt='SIGECE' src={ logo } />
        </div>
    )
}
