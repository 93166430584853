import axios from 'axios'
import Swal from 'sweetalert2'
import { types } from '../types/types'
import { config } from '../../config/configProperties'
import { errorSetActive, errorStatusLoading } from './errorActions'
import { ApiAxiosGet, ApiAxiosPost } from '../../Helpers/ApiAxios'
// import ApiAxios from '../../Helpers/ApiAxios'

const url = `${config.urlServer}/mantenimiento/sucursal`

export const startLoadSucursales = () => {
  return async (dispatch) => {
    try {
      // dispatch(errorStatusLoading())
      const res = await ApiAxiosGet(`${url}/getsucursales`, 'GET')
      // console.log('SUCURSAL', res)
      dispatch(sucursalLoaded(res))
      // return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const sucursalLoadedByLocalidadID = (id) => {
  console.log('id', id)
  return async (dispatch) => {
    try {
      const res = await ApiAxiosGet(`${url}/sucursalbylocalidad/${id}`, 'GET')
      dispatch(sucursalLoadedByLocalidadph(res))
    } catch (error) {
      console.log('No hay Localidad', error?.response)
      dispatch(sucursalClearByLocalidadph())
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startSaveSucursal = (sucursal) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios

    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${url}/addsucursal`, 'POST', sucursal)
      console.log('RRRRRRRR', res.result)
      dispatch(sucursalAddNew(res.result))
      return dispatch(errorSetActive('Sucursal registrada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startDeleteSucursal = () => {
  return async (dispatch, getState) => {
    const { activeSucursal } = getState().sucursal

    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/eliminar/${activeSucursal.id}`,
    })
      .then((response) => {
        Swal.fire('Inactivado', activeSucursal.direccion, 'success')
        dispatch(deleteSucursal(response.data))
      })
      .catch((error) => {
        const msgError = error.response.data.message
        console.log(error.response.data.message)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startReactivedSucursal = (sucursal) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    axios({
      method: 'PUT',
      url: `${url}/activar/${sucursal.id}`,
    })
      .then((response) => {
        Swal.fire('Activado', sucursal.direccion, 'success')
        dispatch(reactiveSucursal(response.data))
      })
      .catch((error) => {
        const msgError = error
        console.log(error)
        Swal.fire('Error', msgError, 'error')
      })
  }
}

export const startUpdateSucursal = (sucursal) => {
  return async (dispatch) => {
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(`${url}/updatesucursal`, 'POST', sucursal)
      console.log('RRRRRRRR', res.result)
      dispatch(sucursalUpdated(res.result))
      return dispatch(errorSetActive('Sucursal Modificada', 'sucess'))
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startActivaEnvioEmail = (paramters) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${url}/activarEnvioEmail`,
        'PUT',
        paramters
      )
      console.log('RRRRRRRR', res.result)
      dispatch(sucursalUpdated(res.result))
      return dispatch(
        errorSetActive('Parametros actualizados con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}

export const startActivaMarcaAguaScursal = (paramters) => {
  return async (dispatch, getState) => {
    //Llamado al endpoint usando Axios
    try {
      dispatch(errorStatusLoading())
      const res = await ApiAxiosPost(
        `${url}/activarMarcaAguaSucursal`,
        'PUT',
        paramters
      )
      console.log('RRRRRRRR', res.result)
      dispatch(sucursalUpdated(res.result))
      return dispatch(
        errorSetActive('Parametros actualizados con exito !!!', 'sucess')
      )
    } catch (error) {
      return dispatch(
        errorSetActive(
          error?.response?.data.message || `Ocurrio un error ${error}`,
          'error'
        )
      )
    }
  }
}


const sucursalLoaded = (sucursales) => ({
  type: types.sucursalLoaded,
  payload: sucursales,
})

export const startLoadSucursalesByLocalidad = (idLocalidad) => {
  return (dispatch) => {
    dispatch(sucursalLoadedByLocalidad(idLocalidad))
  }
}

export const sucursalLoadedByLocalidad = (idLocalidad) => ({
  type: types.sucursalLoadedByLocalidad,
  payload: idLocalidad,
})

const sucursalLoadedByLocalidadph = (sucursales) => ({
  type: types.sucursalLoadedByLocalidadID,
  payload: sucursales,
})
export const sucursalClearByLocalidadph = (sucursales) => ({
  type: types.sucursalClearByLocalidadID,
})

export const sucursalClear = () => ({
  type: types.sucursalClear,
})

export const sucursalOpenModal = () => ({
  type: types.sucursalOpenModal,
})

export const sucursalCloseModal = () => ({
  type: types.sucursalCloseModal,
})

export const sucursalOpenModalDelete = () => ({
  type: types.sucursalOpenModalDelete,
})

export const sucursalCloseModalDelete = () => ({
  type: types.sucursalCloseModalDelete,
})

export const sucursalOpenModalShow = () => ({
  type: types.sucursalOpenModalShow,
})

export const sucursalCloseModalShow = () => ({
  type: types.sucursalCloseModalShow,
})

export const sucursalAddNew = (sucursal) => ({
  type: types.sucursalAddNew,
  payload: sucursal,
})

export const sucursalSetActive = (sucursal) => ({
  type: types.sucursalSetActive,
  payload: sucursal,
})

export const sucursalClearActive = () => ({
  type: types.sucursalClearActive,
})

export const sucursalUpdated = (sucursal) => ({
  type: types.sucursalUpdated,
  payload: sucursal,
})

export const deleteSucursal = (sucursal) => ({
  type: types.sucursalDelete,
  payload: sucursal,
})

export const reactiveSucursal = (sucursal) => ({
  type: types.sucursalReactived,
  payload: sucursal,
})
