import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '550px',
    alignItems: 'end',
    fontSize: '10px',
    '& .MuiInputBase-input': {
      fontSize: '10px',
      padding: '8px',
    },
  },
  fechas: {
    width: '100%',
    fontSize: '10px',
    marginTop: '12px',
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  error: {
    fontSize: 10,
    fontWeight: 500,
    color: 'red',
  },
  sublabel: {
    fontSize: '10px',
    color: '#546E7A',
    // alignItems: 'center',
  },
  select: {
    fontSize: '10px',
    textAlign: 'left',
  },
  text: {
    // marginTop: '16px',
    fontSize: '10px',
    color: '#546E7A',
    textAlign: 'left',
    // margin: 0,
    // padding: 16,
  },
  margen: {
    marginTop: '24px',
  },
  textborde: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
  },

  inputtelefono: {
    fontSize: '10px',
    width: '100%',
    border: 'none',
    borderBottom: '1px solid red',
  },

  formcontrol: {
    margin: '1px',
  },

  control: {
    width: '100%',
    border: '.1px solid grey',
    marginTop: '16px',
    marginBottom: '16px',
  },

  radio: {
    '& .MuiSvgIcon-root': {
      fontSize: 16,
      color: 'blue',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },

  title: {
    fontSize: '36px',
    fontWeight: 500,
  },

  btn: {
    width: '100%',
    fontSize: 14,
    padding: '4px 8px',
    borderRadius: 10,
  },
  field: {
    color: 'blue',
  },
}))

export default useStyles
