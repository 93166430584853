import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

// import Swal from 'sweetalert2'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { IconButton } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import Modal from 'react-modal'

import {
  setClienteActive,
  setClienteClearActive,
  // clienteOpenModal,
} from '../../../redux/actions/facturaGeneralActions'
import { startLoadClientes } from '../../../redux/actions/clienteActions'

// import { ClienteModalFG } from './ClienteModalFG'
import { DatosCliente } from './DatosCliente'

const initCliente = {
  tipoDocumento: null,
  numeroDocumento: null,
  identificacionCliente: null,
  correo: null,
  telefonoMovil: null,
  telefonoHabitacion: null,
  direccion: null,
}

const NewCliente = () => {
  const [formValues, setFormValues] = useState(initCliente)

  const { clienteSelected } = useSelector((state) => state.facturaGeneral)

  const { clientes } = useSelector((state) => state.cliente)

  const dispatch = useDispatch()

  const filterCliente = createFilterOptions({
    stringify: (option) => option.identificacionCliente,
    ignoreCase: true,
    matchFrom: "start",
    limit: 10,
  })

  const selectCliente = (event, value, reason) => {
    if (reason !== 'clear') {
      dispatch(setClienteActive(value))
    }
  }

  const handleInputChange = (event, value, reason) => {
    if (reason === 'clear') {
      dispatch(setClienteClearActive())
    }
  }

  const {
    identificacionCliente,
    tipoDocumento,
    numeroDocumento,
    correo,
    telefonoMovil,
    direccion,
  } = formValues

  const handleInputChangeModal = ({ target }) => {
    const re = /^[0-9\b]+$/

    if (target.name === 'numeroDocumento') {
      if (!re.test(target.value)) {
        target.value = ''
        return false
      } else {
        setFormValues({
          ...formValues,
          [target.name]: target.value,
        })
      }
    } else {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      })
    }
  }

  // useEffect(() => {
  //   dispatch(startLoadClientes())
  // }, [dispatch])

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(2),
        height: theme.spacing(8),
      },
    },
    paper: {
      backgroundColor: 'white',
      padding: '15px',
      borderRadius: 5,
      height: '100%',
      opacity: '0.7',
    },

    div: {
      /*IMPORTANTE*/
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }))

  const classes = useStyles()

  const ref0 = useRef()
  const [value, setValue] = useState(clientes[0])
  const [modalIsOpen, setIsOpen] = useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
    //setFormValues(initCliente);
  }

  const handleSubmitCliente = (e) => {
    e.preventDefault()

    // if (tipoDocumento === "" || tipoDocumento === null) {
    //   return Swal.fire('Error', 'Debe seleccionar el Tipo de Documento', 'error');
    // } else if (numeroDocumento.trim().length < 2) {
    //     return Swal.fire('Error', 'El N° de documento debe contener al menos 2 caracteres', 'error');
    // }

    dispatch(
      setClienteActive({
        ...formValues,
      })
    )

    closeModal()
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <Paper elevation={2} className={classes.paper}>
      {!clienteSelected && (
        <IconButton onClick={openModal} aria-label='addCliente' color='primary'>
          <PersonAddIcon />
        </IconButton>
      )}

      <div className='row'>
        <Autocomplete
          id='search-cliente'
          ref={ref0}
          val={value}
          filterOptions={filterCliente}
          name='cliente'
          options={clientes}
          getOptionLabel={(option) => option.numeroDocumento}
          renderOption={(option) => (
            <>
              <span>
                {option.tipoDocumento}-{option.numeroDocumento}{' '}
              </span>
              / <span>{option.identificacion}</span>
            </>
          )}
          size='small'
          renderInput={(params) => (
            <TextField {...params} label='Buscar cliente' />
          )}
          onChange={selectCliente}
          onInputChange={handleInputChange}
          selectOnFocus
          clearOnBlur
          clearText='Limpiar'
          noOptionsText='No hay registros'
        />
      </div>
      {clienteSelected && (
        <div className='mt-3 row'>
          <DatosCliente />
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Cliente'
      >
        <h3 className='mb-1'>Nuevo cliente</h3>
        <hr />
        <div className={classes.modal}>
          <div className='form-group row'>
            <div className='col-sm-12 col-md-6'>
              <label htmlFor='tipoDocumento' className='mb-0 form-label'>
                Tipo Doc.
              </label>
              <select
                className='form-select form-select-sm'
                aria-label='.form-select-sm'
                label='tipoDocumento'
                id='tipoDocumento'
                value={tipoDocumento}
                onChange={handleInputChangeModal}
                name='tipoDocumento'
                required={true}
              >
                <option defaultValue={'Seleccione...'}>
                  {'Seleccione...'}
                </option>
                <option key='0' value='V'>
                  V
                </option>
                <option key='1' value='E'>
                  E
                </option>
                <option key='2' value='J'>
                  J
                </option>
                <option key='3' value='G'>
                  G
                </option>
                <option key='4' value='P'>
                  P
                </option>
                <option key='5' value='C'>
                  C
                </option>
              </select>
            </div>
            <div className='col-sm-12 col-md-6'>
              <label htmlFor='numeroDocumento' className='mb-0 form-label'>
                N° Documento
              </label>
              <input
                id='numeroDocumento'
                type='text'
                pattern='[0-9]{1,9}'
                maxLength='9'
                title='Solo se permiten números.'
                className='form-control form-control-sm'
                placeholder='N° Doc.'
                name='numeroDocumento'
                autoComplete='off'
                value={numeroDocumento}
                onChange={handleInputChangeModal}
                required
              />
            </div>
          </div>
          <div className='mb-1 form-group row'>
            <div className='col-md'>
              <label
                htmlFor='identificacionCliente'
                className='mb-0 form-label'
              >
                Nombres cliente
              </label>
              <input
                type='text'
                id='identificacionCliente'
                className='form-control form-control-sm'
                placeholder='Identificación'
                name='identificacionCliente'
                autoComplete='off'
                value={identificacionCliente}
                onChange={handleInputChangeModal}
                required={true}
              />
            </div>
          </div>
          <div className='mb-1 form-group row'>
            <div className='col-md'>
              <label htmlFor='correo' className='mb-0 form-label'>
                Correo
              </label>
              <input
                type='text'
                className='form-control form-control-sm'
                id='correo'
                placeholder='Correo electrónico'
                name='correo'
                autoComplete='off'
                value={correo}
                onChange={handleInputChangeModal}
              />
            </div>
          </div>
          <div className='mb-1 form-group row'>
            <div className='col-md'>
              <label htmlFor='direccion' className='mb-0 form-label'>
                Dirección
              </label>
              <input
                type='text'
                className='form-control form-control-sm'
                id='direccion'
                placeholder='Dirección'
                name='direccion'
                autoComplete='off'
                value={direccion}
                onChange={handleInputChangeModal}
              />
            </div>
          </div>
          <div className='mb-3 form-group row'>
            <div className='col-md'>
              <label htmlFor='telefonoMovil' className='mb-0 form-label'>
                Tlf. Móvil
              </label>
              <input
                type='text'
                className='form-control form-control-sm'
                id='telefonoMovil'
                placeholder='Tlf. Móvil'
                name='telefonoMovil'
                autoComplete='off'
                value={telefonoMovil}
                onChange={handleInputChangeModal}
              />
            </div>
          </div>
          <button
            onClick={handleSubmitCliente}
            className='btn btn-outline-primary btn-block btn-lg'
          >
            <i className='far fa-save'></i>
            <span> Guardar</span>
          </button>
        </div>
      </Modal>
    </Paper>
  )
}

export default NewCliente
