import {
  Grid,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@material-ui/core'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import ModalGenerico from '../../commons/ui/Modal'
import { useEstados } from '../../../hooks/useEstados'
import { useMunicipio } from '../../../hooks/useMunicipios'
import { startLoadMunicipiosByEstado } from '../../../redux/actions/municipioActions'
import {
  startSaveSucursal,
  startUpdateSucursal,
} from '../../../redux/actions/sucursalActions'

import useStyles from './Styles'
import { filtroNumLetras, soloTextyNumeros } from '../../../Helpers/formater'
import { useEffect } from 'react'

const schema = yup.object().shape({
  correo: yup
    .string()
    .email('debe ser un correo valido')
    .required('Es requerido'),
  direccion: yup
    .string()
    .max(250, 'Maximo 250 caracteres permitidos, para la descripcion')
    .required('Es requerido'),
  estado: yup.string().required('Es requerido'),
  nombre: yup
    .string()
    .max(100, 'Maximo 100 caracteres permitidos, para el Nombre')

    .required('Es requerido'),
  municipio: yup.string().required('Es requerido'),
  telefono: yup.string().required('Es requerido'),
  localidad: yup.string(),
})

export default function UpdateSucursal({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { activeSucursal } = useSelector((state) => state.sucursal)
  const { estados } = useEstados()
  const { municipiosByEstado } = useMunicipio()

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nombre: activeSucursal.nombre,
      direccion: activeSucursal.direccion,
      telefono: activeSucursal.telefono,
      correo: activeSucursal.correo,
      estado: activeSucursal.id_estado.id,
      municipio: activeSucursal.id_municipio.id,
    },
  })

  const onSubmitSucursal = async (data) => {
    console.log('data', data)
    dispatch(
      startUpdateSucursal({
        id_sucursal: activeSucursal?.id,
        id_municipio: data.municipio,
        id_estado: data.estado,
        nombre: data.nombre,
        direccion: data.direccion,
        telefono: data.telefono,
        correo: data.correo,
        // logUsername": "sadmin"
      })
    ).then((resul) => {
      if (resul.payload.tipoMsg === 'sucess') {
        handleClose()
      }
    })
  }

  const handleChangeMunicipio = (e) => {
    console.log('handleChangeMunicipio', e)
    dispatch(startLoadMunicipiosByEstado(e))
  }

  useEffect(() => {
    console.log('activeSucursalTTTTTT', activeSucursal.id_estado)
    dispatch(startLoadMunicipiosByEstado(activeSucursal?.id_estado.id))
  }, [dispatch, activeSucursal])

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitSucursal)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <InputLabel
              className={classes.title}
              variant='standard'
              htmlFor='uncontrolled-native'
            >
              {activeSucursal?.id_localidad.nombre}
            </InputLabel>
          </Grid>

          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Controller
              name='nombre'
              control={control}
              defaultValue={activeSucursal?.nombre}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Nombre'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  // onChange={(e) =>
                  //   onChange(soloTextyNumeros(e.target.value, filtroNumLetras))
                  // }
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='estado'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Estados
                  </InputLabel>
                  <Select
                    fullWidth
                    name='estados'
                    defaultValue={activeSucursal?.id_estado.id}
                    className={classes.select}
                    onChange={(e) => {
                      handleChangeMunicipio(e.target.value)
                      onChange(e.target.value)
                    }}
                    value={value}
                    error={!!error}
                  >
                    {estados
                      .sort((a, b) => {
                        if (a.estado > b.estado) return 1
                        if (a.estado < b.estado) return -1
                        return 0
                      })
                      .map((option) => {
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_estado}
                            value={option.id_estado}
                          >
                            {option.estado}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              control={control}
              name='municipio'
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <FormControl fullWidth>
                  <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                    Municipios
                  </InputLabel>
                  <Select
                    defaultValue={activeSucursal.id_municipio.id}
                    label='municipio'
                    fullWidth
                    className={classes.select}
                    onChange={onChange}
                    value={value}
                    error={!!error}
                  >
                    {municipiosByEstado
                      .sort((a, b) => {
                        if (a.municipio > b.municipio) return 1
                        if (a.municipio < b.municipio) return -1
                        return 0
                      })
                      .map((option) => {
                        console.log('option', option)
                        return (
                          <MenuItem
                            className={classes.sublabel}
                            key={option.id_municipio}
                            value={option.id_municipio}
                          >
                            {option.municipio}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='direccion'
              control={control}
              defaultValue={activeSucursal?.direccion}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  id='outlined-multiline-static'
                  className={classes.text}
                  // variant='outlined'
                  label='Dirección'
                  size='small'
                  fullWidth
                  multiline
                  rows={3}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Controller
              name='telefono'
              control={control}
              defaultValue={activeSucursal?.telefono}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Teléfono'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : 'ej: (424) 2345665'}
                  placeholder='ej: (424) 2345665'
                />
              )}
            />
            <Controller
              name='correo'
              control={control}
              defaultValue={activeSucursal?.correo}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='correo'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Modificar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
