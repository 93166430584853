import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalGenerico from '../../commons/ui/Modal'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Grid,
  InputLabel,
  TextField,
  Button,
  Checkbox,
  List,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
} from '@material-ui/core'

// import Visibility from '@material-ui/icons/Visibility'
// import VisibilityOff from '@material-ui/icons/VisibilityOff'
// import CheckBoxIcon from '@material-ui/icons/CheckBox'
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import useStyles from './Styles'

import { startSaveRole } from '../../../redux/actions/roleActions'
import { startLoadModulos } from '../../../redux/actions/moduloActions'

const schema = yup.object().shape({
  rol: yup.string().required('Rol es requerido'),
  descripcion: yup.string().required('Descripcion del rol es requerido'),
})

// const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
// const checkedIcon = <CheckBoxIcon fontSize='small' />

export default function AgregarRol({ open, handleClose, title }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  // const { roles } = useSelector((state) => state.role)
  const { modulos } = useSelector((state) => state.modulo)
  const [ArmadoOpciones, setArmadoOpciones] = useState([])
  const [Opcion, setOpcion] = useState({
    modulo: '',
    checked: false,
    path: '',
    opciones: [],
  })
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    dispatch(startLoadModulos())
  }, [dispatch])

  const onSubmitRol = (data) => {
    console.log(data)

    const rol = {
      rol: data.rol,
      descripcion: data.descripcion,
      modulo: ArmadoOpciones,
    }
    // console.log('ROL', rol)
    dispatch(startSaveRole(rol))
      .then((res) => {
        if (res.payload.tipoMsg === 'sucess') {
          handleClose()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (e, mudulo) => {
    const { name, checked } = e.target

    console.log('OPciones', name, checked)
    console.log('OPciones opciones', mudulo)

    switch (name) {
      case 'modulo':
        setOpcion((prevState) => ({
          ...prevState,
          // [e.target.name]: e.target.checked,
          modulo: checked ? mudulo.name : '',
          estatus: checked,
          path: mudulo.path,
          opciones: checked ? mudulo.opciones : [],
        }))
        checked
          ? setArmadoOpciones([
              ...ArmadoOpciones,
              {
                nombre: mudulo.name,
                path: mudulo.path,
                permiso: checked,
                acciones: mudulo.opciones,
              },
            ])
          : // ELEMINAR ELEMENTO DE UN array
            setArmadoOpciones(
              ArmadoOpciones.filter((item) => item.path !== mudulo.path)
            )
        break
      case 'opcion':
        // modificar el estatus de la opcion
        setArmadoOpciones(
          ArmadoOpciones.map((item) => {
            if (item.path === Opcion.path) {
              item.acciones = item.acciones.map((opcion) => {
                if (opcion._id === mudulo._id) {
                  opcion.status = checked
                }
                return opcion
              })
            }
            return item
          })
        )
        break
      default:
        break
    }
  }

  const renderOpciones = (e, opcion) => {
    console.log('OPCION CICK', opcion)
    console.log('ArmadoOpciones AAAAAA', ArmadoOpciones)
    // mostar las opciones seleccionadas
    setOpcion((prevState) => ({
      ...prevState,
      // modulo: opcion.name,
      // estatus: opcion.permiso,
      // path: opcison.path,
      opciones:
        ArmadoOpciones.filter((item) => item.path === opcion.path)[0]
          ?.acciones || [],
    }))
  }
  console.log('OPCIONES RESULTADO', Opcion)

  // console.log('SESSSSSS', Opcion)
  // console.log('ArmadoOpciones', ArmadoOpciones)
  // console.log('modulos', modulos)

  // const handleClick = (e, mudulo) => {
  //   console.log('e', e)
  //   setOpcion((prevState) => ({
  //     ...prevState,
  //     // [e.target.name]: e.target.checked,
  //     modulo: checked ? mudulo.name : '',
  //     estatus: checked,
  //     path: mudulo.path,
  //     opciones: checked ? mudulo.opciones : [],
  //   }))
  // }

  return (
    <ModalGenerico open={open} handleClose={handleClose} title={title}>
      <form onSubmit={handleSubmit(onSubmitRol)} autoComplete='off'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item lg={4} sm={4} xl={12} xs={12}>
            <Controller
              name='rol'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Rol'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={8} sm={8} xl={12} xs={12}>
            <Controller
              name='descripcion'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text}
                  label='Descripcion'
                  size='small'
                  fullWidth
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <InputLabel variant='standard' htmlFor='modulos'>
              Modulos
            </InputLabel>
            <List
              dense
              className={classes.listar}
              // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {modulos
                ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((mod) => (
                  <ListItem key={mod.id}>
                    {/* <Button> */}

                    <Button
                      name='seleccion'
                      onClick={(e) => renderOpciones(e, mod)}
                    >
                      <ListItemText
                        disableTypography
                        className={classes.listarItem}
                        primary={mod.name}
                      />
                    </Button>
                    <ListItemSecondaryAction>
                      <Checkbox
                        name='modulo'
                        edge='end'
                        onChange={(e) => handleChange(e, mod)}
                      />
                    </ListItemSecondaryAction>
                    {/* </Button> */}
                  </ListItem>
                ))}
            </List>
          </Grid>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <InputLabel variant='standard' htmlFor='modulos'>
              <div>
                Opciones: <strong>{Opcion.modulo}</strong>{' '}
              </div>
            </InputLabel>
            <List dense className={classes.listar}>
              {Opcion?.opciones?.map((opcion) => (
                <ListItem key={opcion.key}>
                  <ListItemText primary={opcion.description} />
                  <ListItemSecondaryAction>
                    <Checkbox
                      defaultChecked={opcion.status}
                      edge='end'
                      name='opcion'
                      onChange={(e) => handleChange(e, opcion)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        {/* botton de accion    */}
        <Grid container spacing={2} className={classes.margen}>
          <Grid item lg={6} sm={6} xl={12} xs={12}>
            <Button
              className={classes.btn}
              size='small'
              type='submit'
              variant='contained'
              color='primary'
              fullWidth
              //  onClick={() => peticionPost()}
            >
              Agregar
            </Button>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              className={classes.btn}
              size='small'
              variant='contained'
              fullWidth
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </form>
    </ModalGenerico>
  )
}
