export function FormatedBolivares(value = 0, tipo = 'es-US', cur = 'VEF') {
  const Bolivares = new Intl.NumberFormat(tipo, {
    // style: 'currency',
    // currency: cur,
    maximumFractionDigits: cur === 'PTR' ? 8 : 2,
    minimumFractionDigits: cur === 'PTR' ? 5 : 2,
    // maximumFractionDigits: 2,
  }).format(value)

  if (cur === 'VEF') return `${Bolivares} Bs.`
  if (cur === 'USD') return `${Bolivares} USD`
  if (cur === 'PTR') return `${Bolivares} PTR`
  if (cur === 'EUR') return `${Bolivares} EUR`
  if (cur === 'RUB') return `${Bolivares} RUB`
  if (cur === 'TRY') return `${Bolivares} TRY`
  if (cur === 'CNY') return `${Bolivares} CNY`

  return Bolivares
}

export const numeroFormat = (value, decimales = 'VEF') => {
  const resultado = new Intl.NumberFormat('es-ES', {
    style: 'decimal',
    maximumFractionDigits: decimales === 'PTR' ? 8 : 2,
    minimumFractionDigits: decimales === 'PTR' ? 5 : 2,
  }).format(value)
  console.log('ConverActiva resultado', resultado)
  return resultado
}

export function formatDecimal(input) {
  console.log('input ConverActivaF 0 ', input)

  // let num = input.replace(/,/g, '.')
  let num = input.replace(/([0-9])([0-9]{2})$/, '$.$2')

  console.log('input ConverActivaF 1 ', num)
  num = num.replace(/[^0-9.]/g, '')
  input = num
  console.log('input ConverActivaF 2 ', input)

  // if (!isNaN(num)) {
  // .replace(/\D/g, '')
  // .replace(/([0-9])([0-9]{2})$/, '$.$2')
  // .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, '.')
  // }
  // else {
  //   alert('Solo se permiten numeros')
  //   input = input.replace(/[^\d\.]*/g, '')
  // }
  return input
}

export function sustituirCaracteres(value) {
  //  elimnar puntos de enteros y sustituir coma por puntos en los decimals y eliminar caracteres especiales
  let num = value.replace(/[^0-9.,]/g, '')
  num = num.replace(/[.]/g, '')
  num = num.replace(/[,]/g, '.')
  return num
}

export function parseMil(price) {
  var num = price
  num = num
    .toString()
    .split('')
    .reverse()
    .join('')
    .replace(/(?=\d*\.?)(\d{3})/g, '$1,')
  num = num.split('').reverse().join('').replace(/^[.]/, '')
  return num
}

export function formatDecimalph(input) {
  const num = new Intl.NumberFormat('es-VE', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(input)
  console.log(num)
  return num
}

export const eliminarComas = (value) => {
  console.log('eliminarComas', value)
  var num = value.replaceAll(',', '')
  console.log('eliminarComas despues ', value)

  return num
}

function isNumeric(char) {
  return !isNaN(char - parseInt(char))
}

function maskIt(pattern, value) {
  let position = 0
  let currentChar = 0
  let masked = ''
  while (position < pattern.length && currentChar < value.length) {
    if (pattern[position] === '0') {
      masked += value[currentChar]
      currentChar++
    } else {
      masked += pattern[position]
    }
    position++
  }
  return masked
}

function numberCharactersPattern(pattern) {
  let numberChars = 0
  for (let i = 0; i < pattern.length; i++) {
    if (pattern[i] === '0') {
      numberChars++
    }
  }
  return numberChars
}

export function applyInputMask(elementId, mask) {
  console.log('applyInputMask', elementId, mask)
  let inputElement = document.getElementById(elementId)
  // console.log('inputElement', inputElement)

  let content = ''
  let maxChars = numberCharactersPattern(mask)

  inputElement.addEventListener('keydown', function (e) {
    // console.log('addEventListener ENTRO', e)
    e.preventDefault()
    if (isNumeric(e.key) && content.length < maxChars) {
      content += e.key
    }
    if (e.keyCode === 8) {
      if (content.length > 0) {
        content = content.substr(0, content.length - 1)
      }
    }
    inputElement.value = maskIt(mask, content)
  })
}

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export var filtroNumLetras =
  'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ1234567890 áéíóúÁÉÍÓÚ .,;:' //Caracteres validos
export var filtroLetrasMayusculas = 'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ' //C
export var filtroNumeros = '1234567890,' //Caracteres validos

export var filtroLetras =
  'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ áéíóúÁÉÍÓÚ .,;:' //Caracteres validos

/**
 * Permitir solo numeros
 * @param {*} string
 * @returns
 */
export const soloNumeros = (string, filtro) => {
  //Solo numeros
  var out = ''

  //Recorrer el texto y verificar si el caracter se encuentra en la lista de validos
  for (var i = 0; i < string.length; i++)
    if (filtro.indexOf(string.charAt(i)) !== -1)
      //Se añaden a la salida los caracteres validos
      out += string.charAt(i)

  //Retornar valor filtrado
  return out
}

/**
 *  permitir solo letras y acentuados
 * @param {*} string
 * @returns
 */
export function soloTextyNumeros(string, filtro) {
  console.log('soloText', string)
  //solo letras y numeros
  var out = ''
  //Se añaden las letras validas

  for (var i = 0; i < string.length; i++)
    if (filtro.indexOf(string.charAt(i)) !== -1) out += string.charAt(i)
  console.log('out', out)
  return out
}
