import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import useStyles from '../Styles'
import { sucursalLoadedByLocalidadID } from '../../../../redux/actions/sucursalActions'
import { useDispatch } from 'react-redux'

export default function Localidades({
  localidades,
  setLocalidadesSelect,
  localidadesSelect,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const renderOpciones = (e, local) => {
    dispatch(sucursalLoadedByLocalidadID(local._id))
  }

  const handleChange = (e, local) => {
    if (e.target.checked) {
      setLocalidadesSelect((prevSelect) => {
        if (
          !prevSelect.includes(
            `${local.tipoDocumento}-${local.numeroDocumento}`
          )
        ) {
          return [
            ...prevSelect,
            `${local.tipoDocumento}-${local.numeroDocumento}`,
          ]
        }
        return prevSelect
      })
    } else {
      setLocalidadesSelect((prevSelect) => {
        const newSelect = prevSelect.filter(
          (selected) =>
            selected !== `${local.tipoDocumento}-${local.numeroDocumento}`
        )
        if (newSelect.length === 0) {
          handleSelectAll({ target: { checked: false } })
        }
        return newSelect
      })
    }
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setLocalidadesSelect(
        localidades.map(
          (local) => `${local.tipoDocumento}-${local.numeroDocumento}`
        )
      )
    } else {
      setLocalidadesSelect([])
    }
  }
  console.log('🚀 ~ localidadesSelect:', localidadesSelect)

  return (
    <div style={{ padding: '12px', backgroundColor: 'white' }}>
      <Grid container justify='space-between'>
        <Grid item lg={6} sm={6} xl={6} xs={6}>
          <Typography className={classes.title}>Localidades</Typography>
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xl={6}
          xs={6}
          align='right'
          style={{
            paddingRight: '12px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Typography>todos</Typography>
          <Checkbox
            //   disabled={tipousuario}
            name='todos'
            edge='end'
            size='medium'
            onChange={handleSelectAll}

            // onChange={(e) => handleChange(e, local)}
          />
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <List
          dense
          className={classes.listar}
          // disabled={tipousuario}
        >
          {localidades
            ?.filter((m) => m.logEstatusRegistro === 'ACTIVO')
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map((local) => (
              <ListItem key={local._id}>
                <Button
                  //  disabled={tipousuario}
                  name='seleccion'
                  onClick={(e) => renderOpciones(e, local)}
                >
                  <ListItemText
                    disableTypography
                    className={classes.listarItem}
                    primary={local.nombre}
                  />
                </Button>
                <ListItemSecondaryAction>
                  <Checkbox
                    //   disabled={tipousuario}
                    name={local.tipoDocumento}
                    edge='end'
                    size='small'
                    checked={localidadesSelect.includes(
                      `${local.tipoDocumento}-${local.numeroDocumento}`
                    )}
                    onChange={(e) => handleChange(e, local)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Grid>
    </div>
  )
}
