import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
 root: {
  width: '550px',
  alignItems: 'end',
  fontSize: '10px',
  '& .MuiInputBase-input': {
   fontSize: '10px',
   padding: '8px',
  },
 },
 passwordRoot: {
  width: '350px',
 },

 error: {
  fontSize: 10,
  fontWeight: 500,
  color: 'red',
 },
 sublabel: {
  fontSize: '10px',
  color: '#546E7A',
  // alignItems: 'center',
 },
 select: {
  fontSize: '10px',
  textAlign: 'left',
 },
 text: {
  // marginTop: '16px',
  fontSize: '10px',
  color: '#546E7A',
  textAlign: 'left',
  // margin: 0,
  // padding: 16,
 },
 margen: {
  marginTop: '12px',
  marginBottom: '12px',
 },
 textborde: {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  verticalAlign: 'top',
 },

 inputtelefono: {
  fontSize: '10px',
  width: '100%',
  border: 'none',
  borderBottom: '1px solid red',
 },

 formcontrol: {
  margin: '1px',
 },

 control: {
  width: '100%',
  border: '.1px solid grey',
  marginTop: '16px',
  marginBottom: '16px',
 },

 radio: {
  '& .MuiSvgIcon-root': {
   fontSize: 16,
   color: 'blue',
  },
  '& .MuiTypography-body1': {
   fontSize: 14,
  },
 },

 title: {
  fontSize: '36px',
  fontWeight: 500,
 },

 btn: {
  width: '100%',
  fontSize: 14,
  padding: '4px 8px',
  borderRadius: 10,
 },
 field: {
  color: 'blue',
 },
 option: {
  fontSize: 15,
  '& > span': {
   marginRight: 10,
   fontSize: 18,
  },
 },
 listarItem: {
  width: '100%',
  fontSize: '10px',
  cursor: 'pointer',
  textAlign: 'left',
  '&:hover': {
   backgroundColor: '#F5F5F5',
  },
 },
 listar: {
  width: '100%',
  // maxWidth: 60,
  bgcolor: '#ebedef',
  position: 'relative',
  overflow: 'auto',
  height: 200,
  '& ul': { padding: 0 },
 },
}))

export default useStyles
