import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useToast } from '../../../hooks/useToast'


import Datosfactura from './DatosFactura'
import DatosGnotas from './DatosGnotas'
import DetalleNota from './DetalleNota'
import TotalesNota from './TotalesNota'

const NotaBase = () => {
  const { tipo } = useSelector((state) => state.nota)
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { notifyError, notifysucess } = useToast()



  return (
    <Grid
      container
      spacing={1}
      style={{ marginBottom: '4px', marginTop: '4px' }}
    >
      <Grid item lg={5} sm={5} xl={12} xs={12}>
        <DatosGnotas />
      </Grid>
      <Grid item lg={7} sm={7} xl={12} xs={12}>
        <Datosfactura />
      </Grid>
      <Grid item lg={8} sm={8} xl={12} xs={12}>
        {tipo !== null && <DetalleNota />}
      </Grid>
      <Grid item lg={4} sm={4} xl={12} xs={12}>
        {tipo !== null && <TotalesNota />}
      </Grid>
      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}
    </Grid>
  )
}

export default NotaBase
