import { types } from '../types/types'

const initialState = {
  sucursales: [],
  sucursalesByLocalidad: [],
  activeSucursal: null,
  modalSucursalOpen: false,
  modalSucursalDelete: false,
  modalSucursalShow: false,
  CtrNrofacturas: [],
}

export const sucursalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.sucursalOpenModal:
      return {
        ...state,
        modalSucursalOpen: true,
        modalSucursalDelete: false,
      }

    case types.sucursalCloseModal:
      return {
        ...state,
        modalSucursalOpen: false,
      }

    case types.sucursalOpenModalDelete:
      return {
        ...state,
        modalSucursalOpen: false,
        modalSucursalDelete: true,
      }

    case types.sucursalCloseModalDelete:
      return {
        ...state,
        modalSucursalDelete: false,
      }

    case types.sucursalOpenModalShow:
      return {
        ...state,
        modalSucursalShow: true,
        modalSucursalOpen: false,
        modalSucursalDelete: false,
      }

    case types.sucursalCloseModalShow:
      return {
        ...state,
        modalSucursalShow: false,
      }

    case types.sucursalSetActive:
      return {
        ...state,
        activeSucursal: action.payload,
        modalSucursalOpen: true,
      }

    case types.sucursalAddNew:
      return {
        ...state,
        sucursales: [action.payload, ...state.sucursales],
      }

    case types.sucursalClearActive:
      return {
        ...state,
        activeSucursal: null,
      }

    case types.sucursalUpdated:
      return {
        ...state,
        sucursales: state.sucursales.map((s) =>
          s.id === action.payload.id ? action.payload : s
        ),
      }

    case types.sucursalLoaded:
      return {
        ...state,
        sucursales: action.payload,
      }

    case types.sucursalLoadedByLocalidad:
      return {
        ...state,
        sucursalesByLocalidad: state.sucursales.filter(
          (s) => s.id === Number(action.payload)
        ),
      }
    case types.sucursalLoadedByLocalidadID:
      //incluido un nuevo reduccer por pedro hidalgo
      return {
        ...state,
        sucursalesByLocalidad: action.payload,
      }
    case types.sucursalClearByLocalidadID:
      return {
        ...state,
        sucursalesByLocalidad: [],
      }
    case types.sucursalClear:
      return {
        ...state,
        sucursales: [],
        sucursalesByLocalidad: [],
        activeSucursal: null,
      }

    case types.sucursalDelete:
      return {
        ...state,
        sucursales: state.sucursales.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeSucursal: null,
      }

    case types.sucursalReactived:
      return {
        ...state,
        sucursales: state.sucursales.map((d) =>
          d.id === action.payload.id ? action.payload : d
        ),
        activeSucursal: null,
      }

    case types.updateAsignaNroFacturasSucursal:
      return {
        ...state,
        sucursales: state.sucursales.map((sucursal) => {
          if (sucursal.id === action.payload.id) {
            return {
              ...sucursal,
              asignaNroFacturas: action.payload.asignaNroFacturas,
            }
          }
          return sucursal
        }),
      }

    case types.CtrNrotofacturasLoadedSucursal:
      return {
        ...state,
        CtrNrofacturas: action.payload,
      }

    default:
      return state
  }
}
