import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  facturaGeneralSetActive,
  startClearFacturaActive,
  startLoadFacturasGenerales,
} from '../../../redux/actions/facturaGeneralActions'
// import { startAnularNota } from '../../../redux/actions/notaActions'
import ModalGenerico from '../../commons/ui/Modal'
import useStyles from './Styles'

const AddNota = ({ handleClose, open, title }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [Factura, setFactura] = useState(null)
  const [mensaje, setmensaje] = useState(null)
  const [activar, setactivar] = useState(false)

  const dispatch = useDispatch()
  const { facturasGenerales } = useSelector((state) => state.facturaGeneral)

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log({ e })
    if (Factura) {
      navigate('/facturacion/generales/notas/nota', { replace: true })
      handleClose()
    }
  }

  const handleChange = (_, factura) => {
    console.log('FACTURA ACTIVAR', factura)
    if (
      factura?.logEstatusRegistro === 'ACTIVO' ||
      factura?.facturaCodificada === null
    ) {
      setFactura(factura)
      dispatch(facturaGeneralSetActive(factura))
      setactivar(true)
      setmensaje({
        nroFactura: factura.nroFactura,
        cliente: factura.cliente.identificacion,
        correo: factura.cliente.correo,
        telefono: factura.cliente.telefonoMovil,
        direccion: factura.cliente.direccion,
      })
    } else {
      setactivar(false)
      setmensaje('La factura seleccionada esta ANULADA ó NO GENERADA')
    }
  }

  useEffect(() => {
    dispatch(startClearFacturaActive())
    dispatch(startLoadFacturasGenerales())
  }, [dispatch])

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      // top={55}
      // left={55}
    >
      <form onSubmit={handleSubmit}>
        <Container
          style={{
            backgroundColor: '#f5f5f5',
            width: '80vh',
            minHeight: '20vh',
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Autocomplete
                size='medium'
                id='factura'
                clearText='Limpiar'
                noOptionsText='No hay resultados'
                options={facturasGenerales}
                getOptionLabel={(option) => option.nroFactura || ''}
                // value={Factura}
                onChange={handleChange}
                // filterSelectedOptions
                renderOption={(option) => {
                  return <h6>{option.nroFactura}</h6>
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // className={classes.textproduct}
                    variant='standard'
                    label='Nro. Factura'
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              {activar ? (
                <Grid container spacing={0}>
                  <Grid item lg={6} sm={6} xl={6} xs={6}>
                    <Typography
                      variant='inherit'
                      style={{
                        color: '#00a680',
                        fontWeight: 'bold',
                        marginTop: '.4rem',
                      }}
                    >
                      {`Cliente:  ${mensaje.cliente}`}
                    </Typography>
                  </Grid>

                  <Grid item lg={6} sm={6} xl={6} xs={6}>
                    <Typography
                      variant='inherit'
                      style={{
                        color: '#00a680',
                        fontWeight: 'bold',
                        marginTop: '.4rem',
                      }}
                    >
                      {`Nro. factura: ${mensaje.nroFactura}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={6}>
                    <Typography
                      variant='inherit'
                      style={{
                        color: '#00a680',
                        fontWeight: 'bold',
                        marginTop: '.4rem',
                      }}
                    >
                      {`Correo:  ${mensaje.correo}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={6}>
                    <Typography
                      variant='inherit'
                      style={{
                        color: '#00a680',
                        fontWeight: 'bold',
                        marginTop: '.4rem',
                      }}
                    >
                      {`telefono:  ${mensaje.telefono}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                      variant='inherit'
                      style={{
                        color: '#00a680',
                        fontWeight: 'bold',
                        marginTop: '.4rem',
                      }}
                    >
                      {`Dirección:  ${mensaje.direccion}`}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  item
                  lg={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ marginBottom: '4rem' }}
                >
                  <Typography
                    variant='inherit'
                    style={{
                      color: 'tomato',
                      fontWeight: 'bold',
                      marginTop: '.4rem',
                    }}
                  >
                    {mensaje}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                disabled={!activar}
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </ModalGenerico>
  )
}

export default AddNota
