import { Container, Grid, Typography, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
// import { startDeleteFacturaGeneral } from '../../../redux/actions/facturaGeneralActions'
import { startAnularNota } from '../../../redux/actions/notaActions'
import ModalGenerico from '../../commons/ui/Modal'
import useStyles from './Styles'

const AnularNota = ({ handleClose, open, title }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { activeNota } = useSelector((state) => state.nota)

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log({ e })
    dispatch(startAnularNota (activeNota.id))
    handleClose()
  }

  return (
    <ModalGenerico
      open={open}
      handleClose={handleClose}
      title={title}
      // top={55}
      // left={55}
    >
      <form onSubmit={handleSubmit}>
        <Container
          style={{
            backgroundColor: '#f5f5f5',
            // Width: '40vh',
            // minHeight: '20vh',
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`N° Nota: ${activeNota?.nroNota}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Concepto: ${activeNota?.concepto}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`N° Factura: ${activeNota?.nroFactura}`}
              </Typography>
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={6}>
              <Typography varint='h6' style={{ fontWeight: 'bold' }}>
                {`Tipo: ${activeNota?.tipo}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.margen}>
            <Grid item lg={6} sm={6} xl={12} xs={12}>
              <Button
                className={classes.btn}
                size='small'
                type='submit'
                variant='contained'
                color='primary'
                fullWidth
                //  onClick={() => peticionPost()}
              >
                Anular
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                className={classes.btn}
                size='small'
                variant='contained'
                fullWidth
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </ModalGenerico>
  )
}

export default AnularNota
