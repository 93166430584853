import { Divider, Grid, Typography } from '@material-ui/core'

export const DatosSucursalesAsoc = ({ sucursales }) => {
  console.log('sucursales', sucursales)

  return (
    <Grid container spacing={1} style={{ padding: '1rem' }}>
      <Typography>Sucursales</Typography>
      {sucursales.lstSucursales?.length > 0 ? (
        sucursales.lstSucursales.map((sucursali) => {
          return (
            <Grid container spacing={0}>
              <Grid
                item
                lg={8}
                sm={8}
                xl={12}
                xs={12}
                style={{ paddingLeft: '1rem' }}
              >
                <Typography variant='body2'>{sucursali.nombre}</Typography>
                <Typography aling='justify' variant='caption'>
                  {sucursali.direccion}
                </Typography>
              </Grid>
            </Grid>
          )
        })
      ) : (
        <Grid container spacing={1}>
          <Grid item sm={12} style={{ paddingLeft: '1rem' }}>
            <Typography variant='caption'>Sin Sucursales</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
