import Axios from 'axios'
import { getToken } from '../Helpers/VericarRutas'

export const ApiAxiosGet = async (url, METHOD, params = '') => {
  const options = {
    url,
    baseURL: process.env.REACT_APP_URL_BACKEND_PH_V2,
    method: METHOD,
    headers: {
      Authorization: `Bearer ${
        JSON.parse(getToken())?.UsuarioLogiado.tokens.access.token
      }`,
      origin: 'x-requested-with',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
      'Content-Type': 'application/json',
    },
    params,
  }
  const response = await Axios(options)
  // console.log('response resss', response)
  return response.data
}

export const ApiAxiosPost = async (url, METHOD, data = {}, timeout = 0) => {
  // const { UsuarioLogiado } = useSelector((state) => state.auth)
  // console.log('UsuarioLogiado', UsuarioLogiado)
  // console.log('ENTRO LLEGO POST')

  const options = {
    url,
    baseURL: process.env.REACT_APP_URL_BACKEND_PH_V2,
    method: METHOD,
    timeout,
    headers: {
      Authorization: `Bearer ${
        JSON.parse(getToken())?.UsuarioLogiado.tokens.access.token
      }`,
      origin: 'x-requested-with',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
      'Content-Type': 'application/json',
    },
    data: data,
  }

  const response = await Axios(options)
  return response.data
}

export const ApiAxiosPut = async (url, METHOD, data = {}, timeout = 0) => {
  // console.log('ENTRO LLEGO')

  const options = {
    url,
    baseURL: process.env.REACT_APP_URL_BACKEND_PH_V2,
    method: METHOD,
    timeout,
    headers: {
      Authorization: `Bearer ${
        JSON.parse(getToken())?.UsuarioLogiado.tokens.access.token
      }`,
      origin: 'x-requested-with',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
      'Content-Type': 'application/json',
    },
    data: data,
  }

  const response = await Axios(options)
  return response.data
}


// funcion con fetch para hacer peticiones a la api con el metodo fetch del navegador y el metodo post

export const ApiAxiosPostFetch = async (url, METHOD, data = {}) => {
  // console.log('ENTRO LLEGO')
  console.log('dataAAAAAA', data)
  const options = {
    url,
    baseURL: process.env.REACT_APP_URL_BACKEND_PH_V2,
    method: METHOD,
    headers: {
      Authorization: `Bearer ${
        JSON.parse(getToken())?.UsuarioLogiado.tokens.access.token
      }`,
      origin: 'x-requested-with',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  const response = await fetch(options.url, options)
  return response.json()
}

