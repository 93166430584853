import { Button, Container, Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import { useToast } from '../../../../hooks/useToast'
import BuscarClienteph from '../../cliente/BuscarClenteph'
import { useDispatch } from 'react-redux'
import RazonSocial from './RazonSocial'
import DetalleProductos from './DetalleProductos'
import useFacturaGeneral from '../../../../hooks/useFacturaGeneral'
import TotalesOrdenes from './TotalesOrdenes'
import {
 facturaGeneralClearActive,
 startCargaOrdenJsonXls,
} from '../../../../redux/actions/facturaGeneralActions'
import useDivisaBancos from '../../../../hooks/usedivisaBancos'
import OrdenServicio from './OrdenServicio'
import useOrden from '../../../../hooks/useOrden'
import { ordenClearActive } from '../../../../redux/actions/ordenActions'

const useStyles = makeStyles((theme) => ({
 btn: {
  marginTop: '24px',
  fontSize: '12px',
  textAlign: 'center',
  padding: '8px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  '&:hover': {
   backgroundColor: '#054477',
   color: 'white',
  },
 },
}))

export default function OdenesGuiasBase() {
 const dispatch = useDispatch()
 const classes = useStyles()
 const { notifyError, notifysucess, Toaster } = useToast()
 //  const { isError, error, tipoMsg } = useSelector((state) => state.error)
 const {
  clienteSelected,
  localidadSelected,
  lstDetalles,
  nroFactura,
  importeTotal,
  subTotal,
  facturaDivisa,
  modeloFactura,
  localidadActive,
  sucursalActive,
  clienteActive,
 } = useFacturaGeneral(false)

 const { divisas } = useDivisaBancos()
 const { motivo, direccionEntrega, observacion, IncluirMontos } = useOrden()

 const Verificador = [
  clienteSelected,
  localidadSelected,
  lstDetalles?.length > 0,
 ]

 const resultadovalidar = Verificador.some((valor) => {
  return valor === false
 })

 const nuevolstDetalles = lstDetalles.map((detalle) => {
  if (IncluirMontos) {
   const {
    exento,
    exonerado,
    descuento,
    alicuotaGravamen,
    idgravamen,
    montoGravamen,
    montoDescuento,
    precio,
    importe,
    ...otrosDetalles
   } = detalle
   return otrosDetalles
  } else {
   const {
    exento,
    exonerado,
    descuento,
    alicuotaGravamen,
    idgravamen,
    montoGravamen,
    montoDescuento,
    ...otrosDetalles
   } = detalle
   return otrosDetalles
  }
 })

 const handleaddorden = () => {
  dispatch(
   startCargaOrdenJsonXls([
    {
     rif: `${localidadActive?.tipoDocumento}-${localidadActive?.numeroDocumento}`,
     codigoSucursal: sucursalActive
      ? sucursalActive?.codigoSucursal
      : undefined,
     nroOrden: nroFactura,
     importeTotal: IncluirMontos ? importeTotal : undefined,
     subTotal: IncluirMontos ? subTotal : undefined,
     facturaDivisa,
     cambioDivisa: divisas?.data?.filter(
      (divisa) => divisa.iso === facturaDivisa
     )[0]?.value,
     tipoCambioDiaUsd: divisas?.data?.filter(
      (divisa) => divisa.iso === 'USD'
     )[0]?.value,
     fechaVencimiento: new Date().toISOString(),
     modeloOrden: modeloFactura,
     motivo,
     observacion,
     cliente: {
      tipoDocumento: clienteActive.tipoDocumento,
      numeroDocumento: clienteActive.numeroDocumento,
      identificacion: clienteActive.identificacion,
      direccion: clienteActive.direccion,
      telefonoMovil: clienteActive.telefonoMovil,
      correo: clienteActive.correo,
      ccCorreo:
       clienteActive.ccCorreo === '' ? undefined : clienteActive.ccCorreo,
      tipoPersona: 'CLIENTE',
      direccionEntrega,
      tipoProveedor: 'PNR',
     },
     lstDetallesFacturaGeneral: nuevolstDetalles,
    },
   ])
  ).then((res) => {
   console.log('RESPONSE', res)
   if (res.payload.resultado.totalOrdenesconError > 0) {
    notifyError(
     res.payload.resultado.DetalleErrorOrdenes?.flat().map((error) => {
      return `Ocurrio un error en la orden, Nro Orden: ${error.nroOrden}, Error: ${error.msg}`
     })
    )
   } else if (res.payload.resultado.totalOrdenesNew > 0) {
    notifysucess(
     res.payload.resultado.DetalleOrdenesProcesadas?.flat().map((succes) => {
      return `Nro Orden/Guia: ${succes.nroOrden}, nro control: ${succes.nroControl},  ${succes.msg}`
     })
    )
    dispatch(ordenClearActive())
    dispatch(facturaGeneralClearActive())
   }
  })
 }

 return (
  <Container style={{ marginBottom: '16px', marginTop: '16px' }}>
   <Toaster />
   <Grid container spacing={2}>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <BuscarClienteph />
    </Grid>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <RazonSocial />
    </Grid>
    <Grid item lg={12} sm={12} xl={12} xs={12}>
     <DetalleProductos />
    </Grid>

    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <TotalesOrdenes />
    </Grid>
    <Grid item lg={6} sm={6} xl={12} xs={12}>
     <Grid container spacing={0}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <OrdenServicio />
      </Grid>

      <Grid item lg={12} sm={12} xl={12} xs={12}>
       <Button
        size='small'
        variant='outlined'
        onClick={handleaddorden}
        disabled={resultadovalidar}
        fullWidth
        className={classes.btn}
       >
        <>
         <i className='far fa-save'></i>
         <span style={{ paddingLeft: '12px' }}> Guardar Orden/Guia</span>
        </>
       </Button>
      </Grid>
     </Grid>
    </Grid>
   </Grid>
   {/* {isError && tipoMsg === 'sucess'
    ? notifysucess()
    : tipoMsg === 'error' &&
      error !== 'no hay Sucursal para esta localidad' &&
      notifyError()} */}
  </Container>
 )
}
