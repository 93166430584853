import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';
import { moduloCloseModal, startSaveModulo, startUpdateModulo, moduloClearActive } from '../../../redux/actions/moduloActions';

//Valores default objeto Modulo


Modal.setAppElement('#root');

export const ModuloModal = () => {

    const initModulo = {
        nombre: null,
        logEstatusRegistro: 1,
        logFechaRegistro: new Date(),
        logUsername: "sadmin",
        logFechaModificacion: null
    }

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { activeModulo, modalModuloOpen } = useSelector(state => state.modulo);

    const [formValues, setFormValues] = useState( initModulo );

    const { nombre } = formValues;

    useEffect(() => {
        if ( activeModulo ) {
            setFormValues( activeModulo );
        } else {
            setFormValues( initModulo );
        }
    }, [activeModulo, setFormValues])

    //Gestiona estado del Form
    const handleInputChange = ({ target }) => {
        setFormValues({
            ...formValues,
            [target.name]: target.value
        });
    }

    //Cierra el modal
    const closeModal = () => {

        dispatch(moduloCloseModal());
        dispatch(moduloClearActive());
        setFormValues(initModulo);
    }

    //Envía datos del form
    const handleSubmit = (e) => {
        e.preventDefault();

    console.log(formValues)

        //Validaciones
        if (nombre.trim().length < 2) {
            return Swal.fire('Error', 'El nombre debe contener al menos 2 caracteres', 'error');
        } else {
            if (activeModulo) {
                dispatch(startUpdateModulo(formValues));
            } else {
                dispatch(startSaveModulo({
                    ...formValues
                }));
            }
        }

        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',

        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={modalModuloOpen}
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            { (activeModulo) ? <h3>{ t('seguridad.modulo.modal.edit.title') }</h3> : <h3>{ t('seguridad.modulo.modal.new.title') }</h3> } 
            
            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >
                <div className={styles.modal}>
                    <div className="form-group row">
                    <div className="col-md">
                    <label htmlFor="nombre" className="form-label">{ t('seguridad.modulo.modal.field.nombre.label') }</label>
                        <input
                            type="text"
                            id='nombre'
                            className="form-control form-control-sm"
                            placeholder= { t('seguridad.modulo.modal.field.nombre.placeholder') }
                            name="nombre"
                            autoComplete="off"
                            value={nombre}
                            onChange={handleInputChange}
                            required={true}
                            title= { t('seguridad.modulo.modal.field.nombre.title') }
                        />
                    </div>
                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block btn-lg"
                    >
                        <i className="far fa-save"></i>
                        <span> { t('general.button.guardar') } </span>
                    </button>
                </div>
            </form>
        </Modal>
    )
}


