import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'react-modal';
import { makeStyles } from '@material-ui/core/styles';

import { bancoClearActive, bancoCloseModalDelete, startDeleteBanco } from '../../../redux/actions/bancoActions';

const initBanco = {
    nombre: '',
    correo: '',
    urlPaginaWeb: '',
    logEstatusRegistro: "1",
    logFechaRegistro: new Date(),
    logUsername: "sadmin",
    logFechaModificacion: null
}

Modal.setAppElement('#root');

export const BancoModalDelete = () => {

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { activeBanco, modalBancoDelete } = useSelector(state => state.banco);

    const [formValues, setFormValues] = useState( initBanco );

    const { nombre } = formValues;

    useEffect(() => {
        if ( activeBanco ) {
            setFormValues( activeBanco );
        } else {
            setFormValues( initBanco );
        }
    }, [activeBanco, setFormValues])

    const closeModal = () => {

        dispatch(bancoCloseModalDelete());
        dispatch(bancoClearActive());
        setFormValues(initBanco);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
       
        dispatch( startDeleteBanco() );
        
        closeModal();
    }

    const useStyles = makeStyles((theme) => ({

        button: {
            margin: theme.spacing(1),
        },
        inputMaterial: {
            width: '100%'
        },
        modal: {
            background: 'white',
            borderRadius: '5px',
            color: 'rgb(51, 51, 51)',
            maxHeight: '620px',
            maxWidth: '600px',
            outline: 'none',
            padding: '10px',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-25%',
            transform: 'translate(-50%, -50%)',

        },
    }));

    const styles = useStyles();

    return (
        <Modal
            isOpen={ modalBancoDelete }
            closeTimeoutMS={200}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName="modal-fondo"
        >
            <h3>{ t('mantenimiento.banco.base.inactivar.title') }</h3>
            
            <hr />
            <form
                className="container"
                onSubmit={handleSubmit}
            >

                <div className={styles.modal}>
                    <div className="form-group">
                        <label>{ t('mantenimiento.banco.modal.field.nombre.label') } </label>
                        <input
                            type="text"
                            className="form-control input-lg"
                            placeholder="Nombre"
                            name="nombre"
                            autoComplete="off"
                            value={ nombre }
                            readOnly= { true }
                        />

                    </div>
                    <br />
                    <button
                        type="submit"
                        className="btn btn-outline-primary btn-block"

                    >
                        <i className="fas fa-power-off"></i>
                        <span> { t('general.button.inactivar') }</span>
                    </button>
                </div>
            </form>
        </Modal>

    )
}


