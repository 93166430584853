import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
// import logo from '../../../assets/imagenes/flor-w.png'
import LogoProcert from './LogoProcert'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%', // height:'500px',
    flexWrap: 'wrap',
    align: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    animation: 'mover 1s infinite alternate',
  },
  top: {
    color: '#0c5ea0',
    fontSize: '18px',
    alignSelf: 'center',
  },

  animatedItem: {
    animation: `$myEffect 1s ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
      // transform: 'translateY(200%)',
    },
    '100%': {
      opacity: 1,
      // transform: 'translateY(100%)',
    },
  },
}))

export default function LoadingSvg({ width = 60, height = '75vh' }) {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ height: height }}>
      <div className={classes.animatedItem}>
        <LogoProcert width={width} fill='#0c5ea0' />
        <div className={classes.top}>Cargando...</div>
      </div>
    </div>
  )
}
