import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { divisaClearActive } from '../../../redux/actions/divisaActions'


const useStyles = makeStyles((theme) => ({
  btn: {
    // color: theme.palette.getContrastText('#3949AB'),
    marginTop: '24px',
    width: '100%',
    padding: '6px 12px',
    borderRadius: 10,
    // backgroundColor: 'red'
  },
}))

const BotonesAccionesDuo = ({
  handleClose,
  faltaPorPagar,
  handleClickAccion,
  type,
  handleClear,
  disabled,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()


  const handleClickCancelar = () => {
    handleClose()
    handleClear()
    dispatch(divisaClearActive())

  }
  useEffect(() => {
    if (faltaPorPagar === 0) {
      handleClose()
      handleClear()
    }
  }, [faltaPorPagar, handleClose, handleClear])

  return (
    <Grid container spacing={1}>
      <Grid item md={6} xs={6}>
        <Button
          disabled={disabled}
          type={type}
          className={classes.btn}
          size='small'
          fullWidth
          color='primary'
          variant='contained'
          //   onClick={() => handleClickAccion()}
        >
          Agregar
        </Button>
      </Grid>
      <Grid item md={6} xs={6}>
        <Button
          className={classes.btn}
          size='small'
          variant='contained'
          fullWidth
          onClick={handleClickCancelar}
        >
          Cancelar
        </Button>
      </Grid>
    </Grid>
  )
}
export default BotonesAccionesDuo
