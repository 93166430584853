import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'

import MaterialTable from 'material-table'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import SearchRounded from '@material-ui/icons/SearchRounded'
import SearchSharp from '@material-ui/icons/SearchSharp'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined'
import DeleteForeverOutlined from '@material-ui/icons/DeleteForeverOutlined'

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PicturePostAdd from '@material-ui/icons/PostAdd'
import PictureSupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle'

import FacturaModal from '../FacturaGeneralModalShow/DetaleFacturaModal'

import { useToast } from '../../../../hooks/useToast'
import {
  facturaGeneralCloseModalShow,
  facturaGeneralOpenModalDelete,
  facturaGeneralSetActive,
  startGenerarFacturaGeneral,
  startLoadFacturasGenerales,
  // startReactivedFacturaGeneral,
  startShowDetalle,
  startClearFacturaActive,
} from '../../../../redux/actions/facturaGeneralActions'
import { useModal } from '../../../../hooks/useModal'
import { FacturaGeneralModalAnular } from '../FacturaGeneralModalAnular'
import MostrarPdf from '../FacturaGeneralModalShow/MostrarPdf'
import { notaClearActive } from '../../../../redux/actions/notaActions'
import FacturaGeneralDelete from '../FacturaGeneralModalShow/FacturaGeneralDelete'
import { hasPermission } from '../../../../Helpers/AuthPermisos'
import FiltrarFacturas from './FiltrarFacturas'
import { localidadClear } from '../../../../redux/actions/localidadActions'
import ConsultaSeniat from '../FacturaGeneralModalShow/ConsultaSeniat'

const Facturas = () => {
  const navigate = useNavigate()
  const { handleOpen, open, handleClose } = useModal()
  const { isError, tipoMsg } = useSelector((state) => state.error)
  const { UsuarioLogiado: user } = useSelector((state) => state.auth)
  const { roles } = useSelector((state) => state.role)
  const { notifyError, notifysucess } = useToast()
  const dispatch = useDispatch()
  const [openView, setOpenView] = useState(false)
  const [OpenConsultaSeniat, setOpenConsultaSeniat] = useState(false)
  const [openDeleted, setOpenDeleted] = useState(false)
  const [OpenBuscarFactura, setOpenBuscarFactura] = useState(false)

  const { facturasGenerales, modalFacturaGeneralDelete } = useSelector(
    (state) => state.facturaGeneral
  )

  // console.log('facturasGenerales', facturasGenerales)

  const columnas = [
    {
      title: 'Nro. Factura',
      field: 'nroFactura',
      numeric: false,
      render: (rowData) => {
        return (
          <div
            style={{
              fontSize: '12px',
              backgroundColor: '#054477',
              color: 'whitesmoke',
              textAlign: 'right',
            }}
          >
            {rowData.nroFactura}
          </div>
        )
      },
    },
    {
      title: 'Nro. Control',
      field: 'nrocontrol',
      numeric: false,
      render: (rowData) => {
        return (
          <div
            style={{
              minWidth: '80px',
              fontSize: '12px',
              backgroundColor: '#054477',
              color: 'whitesmoke',
              textAlign: 'right',
            }}
          >
            {rowData?.nroControl
              ? rowData?.serie + rowData?.nroControl
              : 'No generada'}
          </div>
        )
      },
    },
    {
      title: 'Razón Social o Sucursal',
      field: 'nrocontrol',
      numeric: false,
      render: (rowData) => {
        return (
          <div style={{ fontSize: '10px' }}>
            {rowData?.id_sucursal === undefined
              ? rowData?.id_localidad?.nombre
              : rowData?.id_sucursal?.nombre}
          </div>
        )
      },
    },
    {
      title: 'Gen.',
      field: 'facturaGenerada',
      align: 'center',
      numeric: false,
      render: (rowData) => (
        <div style={{ fontSize: '14px', textAlign: 'center' }}>
          {rowData?.facturaGenerada === 'true' ? (
            <div style={{ color: 'blue', fontWeight: 'bold' }}>Si</div>
          ) : (
            <div style={{ color: 'tomato', fontWeight: 'bold' }}>No</div>
          )}
        </div>
      ),
    },
    {
      title: 'Fecha Emisión',
      field: 'fechaEmision  ',
      numeric: false,
      // defaultSort: 'desc',
      render: (rowData) => (
        <div style={{ fontSize: '12px', width: '125px' }}>
          {format(
            new Date(
              `${String(rowData?.fechaEmision).substring(0, 10)}T00:00:00`
            ),
            'dd/MM/yyyy',
            {
              locale: es,
              timeZone: 'America/Caracas',
            }
          )}
        </div>
      ),
    },
    {
      title: 'Cliente',
      field: 'identificacion',
      numeric: false,
      render: (rowData) => rowData.cliente.identificacion,
    },

    {
      id: 'logEstatusRegistro',
      title: 'Estatus',
      field: 'logEstatusRegistro',
      numeric: false,
      render: (rowData) => (
        <div style={{ fontSize: '12px' }}>
          {rowData?.logEstatusRegistro === 'INACTIVO' ? (
            <div style={{ color: 'tomato' }}>Anulada</div>
          ) : (
            <div style={{ color: 'blue' }}>Activa</div>
          )}
        </div>
      ),
    },
  ]

  useEffect(() => {
    dispatch(startClearFacturaActive())
    dispatch(notaClearActive())
    dispatch(startLoadFacturasGenerales())
  }, [dispatch])

  // Crear  una Factura
  const goToBase = (e) => {
    navigate('/facturacion/generales/facturaGeneral', { replace: true })
  }

  // Crear  una Nota de Credito o Debito
  const goToNotas = (detalleFac) => {
    console.log('goToNotas', detalleFac)
    dispatch(facturaGeneralSetActive(detalleFac))
    navigate('/facturacion/generales/notas/nota', { replace: true })
  }

  // consultar una Factura
  const handledetlFac = (_, detalleFac, id) => {
    console.log('detalleFac COSULTA', Object.entries(detalleFac))
    dispatch(startShowDetalle(id))
    handleOpen()
  }
  const handleCerrarModal = () => {
    dispatch(facturaGeneralCloseModalShow())
    handleClose()
  }

  // Anular y reactivar factura
  const handleAnularFactura = (detalleFac) => {
    console.log('detalleFac', detalleFac)
    dispatch(facturaGeneralSetActive(detalleFac))
    dispatch(facturaGeneralOpenModalDelete())
  }

  // const handleReactive = (detalleFac) => {
  //   dispatch(startReactivedFacturaGeneral(detalleFac))
  // }

  // generar factura pdefe Firmada
  const onGenerarFacturaGeneral = (detalleFac) => {
    console.log('onGenerarFacturaGeneral NUEVO', detalleFac)
    dispatch(facturaGeneralSetActive(detalleFac))
    dispatch(startGenerarFacturaGeneral(detalleFac))
  }

  const handleConsultaSeniat = (detalleFac) => {
    console.log('handleConsultaSeniat NUEVO', detalleFac)
    dispatch(facturaGeneralSetActive(detalleFac))
    setOpenConsultaSeniat(true)
  }

  const handleCerrarConsultaSeniat = () => {
    console.log('Entro a Boorar cnsukta')
    setOpenConsultaSeniat(false)
  }

  // visualizar pdf en el navegador
  // const ActualizarPdf = async (idFactura) => {
  //   dispatch(facturaGeneralSetActive(idFactura))
  //   ViewPdf(idFactura)
  // }

  const ViewPdf = (idFactura) => {
    // console.log('idFactura', idFactura)
    dispatch(facturaGeneralSetActive(idFactura))
    setOpenView(true)
  }
  const handleCerrarViewPdf = () => {
    console.log('Entro a Boorar cnsukta')
    setOpenView(false)
  }
  // eliminar factura
  const handleDeletedFactura = (detalleFac) => {
    setOpenDeleted(true)
    dispatch(facturaGeneralSetActive(detalleFac))
  }
  const handleCerrardeleted = () => {
    setOpenDeleted(false)
  }

  const hnadleOpenBuscarFactura = (e) => {
    setOpenBuscarFactura(true)
  }
  const handleCerrarBuscarFactura = (e) => {
    dispatch(localidadClear())
    setOpenBuscarFactura(false)
  }

  // const ViewPdf = (facturaCodificada) => {
  //   window.open(
  //     'data:application/octet-stream;charset=utf-8;base64,' + facturaCodificada,
  //     '_blank,scrollbars,status'
  //   )
  // }

  return (
    <div>
      <br />

      <MaterialTable
        title='Facturación General'
        columns={columnas}
        data={facturasGenerales}
        actions={[
          {
            icon: () => <SearchSharp fontSize='small' htmlColor='#39f' />,
            tooltip: 'Busqueda de Facturas',
            isFreeAction: true,
            onClick: (event) => hnadleOpenBuscarFactura(event),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'search',
              user.user
            ),
          },
          {
            icon: () => (
              <AddShoppingCartIcon fontSize='small' htmlColor='primary' />
            ),
            tooltip: 'Nueva Factura',
            isFreeAction: true,
            onClick: (event) => goToBase(event),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'add',
              user.user
            ),
          },

          // consulta seniat
          (rowData) => ({
            icon: () => (
              <PictureSupervisedUserCircle
                fontSize='small'
                htmlColor={
                  rowData.facturaGenerada !== 'true' ||
                  rowData.logEstatusRegistro !== 'ACTIVO'
                    ? 'grey' //'#822784'
                    : '#822784'
                }
              />
            ),
            tooltip: 'Consulta Seniat',
            onClick: (_event, rowData) => handleConsultaSeniat(rowData),
            disabled:
              rowData.facturaGenerada !== 'true' ||
              rowData.logEstatusRegistro !== 'ACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'consultaseniat',
              user.user
            ),
          }),
          // fin consulta seniat

          (rowData) => ({
            icon: () => (
              <DeleteForeverOutlined
                fontSize='small'
                // htmlColor='#822784'
                htmlColor={
                  rowData.facturaGenerada === 'true'
                    ? 'grey' //'#822784'
                    : 'tomato'
                }
              />
            ),
            tooltip: 'Eliminar Factura',
            onClick: (_event, rowData) => handleDeletedFactura(rowData),
            disabled: rowData.facturaGenerada === 'true',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'delete',
              user.user
            ),
            // ||   rowData.logEstatusRegistro !== 'ACTIVO',
          }),
          {
            icon: () => <SearchRounded fontSize='small' htmlColor='#2eb85c' />,
            tooltip: 'Consultar',
            onClick: (event, rowData) =>
              handledetlFac(event, rowData, rowData.id),
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'view',
              user.user
            ),
          },

          (rowData) => ({
            icon: () => (
              <SettingsPowerIcon
                fontSize='small'
                // htmlColor='tomato'
                htmlColor={
                  rowData.facturaGenerada === 'true' ? 'tomato' : 'grey'
                }
              />
            ),
            tooltip: 'Anular Factura',
            onClick: (_event, rowData) => handleAnularFactura(rowData),
            hidden:
              rowData.logEstatusRegistro === 'INACTIVO' ||
              !hasPermission(
                roles.filter((r) => r.id === user.user.role),
                '/facturacion/generales',
                'cancel',
                user.user
              ),
            disabled: rowData.facturaGenerada === 'false',
          }),
          // (rowData) => ({
          //   icon: () => (
          //     <PowerSettingsNewIcon fontSize='small' htmlColor='#085C9F' />
          //   ),
          //   tooltip: 'Activr Factura',
          //   onClick: (_event, rowData) => handleReactive(rowData),
          //   hidden: rowData.logEstatusRegistro === 'ACTIVO',
          //   disabled: rowData.logEstatusRegistro === 'ACTIVO',
          // }),
          (rowData) => ({
            icon: () => (
              <PlaylistAddCheckOutlinedIcon
                fontSize='small'
                // htmlColor='#822784'
                htmlColor={
                  rowData.facturaGenerada === 'true' ||
                  rowData.logEstatusRegistro !== 'ACTIVO'
                    ? 'grey' //'#822784'
                    : '#822784'
                }
              />
            ),
            tooltip: 'Generar y firmar PDF',
            onClick: (_event, rowData) => onGenerarFacturaGeneral(rowData),
            disabled:
              rowData.facturaGenerada === 'true' ||
              rowData.logEstatusRegistro !== 'ACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'generar',
              user.user
            ),
          }),
          (rowData) => ({
            icon: () => (
              <PictureAsPdfIcon
                fontSize='small'
                htmlColor={
                  rowData.facturaGenerada === 'true' ? '#F68519' : 'grey'
                }
              />
            ),
            tooltip: 'Ver PDF de Factura',
            onClick: (_event, rowData) => ViewPdf(rowData),
            disabled: rowData.facturaGenerada === 'false',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'print',
              user.user
            ),
          }),
          (rowData) => ({
            icon: () => (
              <PicturePostAdd
                fontSize='small'
                // htmlColor={
                //   rowData.facturaGenerada === true ||
                //   rowData.logEstatusRegistro !== 'ACTIVO'
                //     ? 'grey'
                //     : '#F68519'
                // }
              />
            ),
            tooltip: 'Crear una Nota',
            onClick: (_event, rowData) => goToNotas(rowData),
            disabled:
              rowData.facturaGenerada === 'false' ||
              rowData.logEstatusRegistro !== 'ACTIVO',
            hidden: !hasPermission(
              roles.filter((r) => r.id === user.user.role),
              '/facturacion/generales',
              'addNota',
              user.user
            ),
          }),
        ]}
        options={{
          sorting: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: '#085c9f',
            color: '#FFF',
          },
          exportButton: true,
          paginationType: 'stepped',
          rowStyle: (rowData) => {
            if (rowData.logEstatusRegistro === 'INACTIVO') {
              return { backgroundColor: '#F3EFEE' }
            }

            return {}
          },
        }}
        localization={{
          header: {
            actions: 'Acciones',
          },
          toolbar: {
            searchPlaceholder: 'Buscar',
            searchTooltip: 'Buscar',
            exportTitle: 'Exportar',
            exportCSVName: 'Exportar en formato excel',
            exportPDFName: 'Exportar como pdf',
          },
          pagination: {
            firstAriaLabel: 'Primera página',
            firstTooltip: 'Primera página',
            previousAriaLabel: 'Página anterior',
            previousTooltip: 'Página anterior',
            nextAriaLabel: 'Página siguiente',
            nextTooltip: 'Página siguiente',
            lastAriaLabel: 'Última página',
            lastTooltip: 'Última página',
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          body: {
            emptyDataSourceMessage: 'No existen datos para mostrar',
          },
        }}
      />
      {/* <Toaster position='top-center' reverseOrder={false} /> */}

      {isError && tipoMsg === 'sucess'
        ? notifysucess()
        : tipoMsg === 'error' && notifyError()}

      {open && (
        <FacturaModal
          open={open}
          handleClose={handleCerrarModal}
          title='Detalle Factura'
        />
      )}
      {openView && (
        <MostrarPdf
          open={openView}
          handleClose={handleCerrarViewPdf}
          title='Vista de Factura en pdf'
        />
      )}
      {openDeleted && (
        <FacturaGeneralDelete
          open={openDeleted}
          handleClose={handleCerrardeleted}
          title='Eliminar Factura'
        />
      )}
      {OpenBuscarFactura && (
        <FiltrarFacturas
          open={OpenBuscarFactura}
          handleClose={handleCerrarBuscarFactura}
          title='Filtrar Facturas'
        />
      )}
      {OpenConsultaSeniat && (
        <ConsultaSeniat
          open={OpenConsultaSeniat}
          handleClose={handleCerrarConsultaSeniat}
          title='Consulta Factura-Json'
        />
      )}

      {modalFacturaGeneralDelete && <FacturaGeneralModalAnular />}
    </div>
  )
}

export default Facturas
